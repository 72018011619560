import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  direction: rtl;
`;

export const Title = styled.div`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
  height: 9vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
