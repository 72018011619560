import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./expenseTypes.styles";
import NewExpenseType from "./NewExpenseType/NewExpenseType";
import ProviderData from "./ExpenseTypeData/ExpenseTypeData";
import { getSchoolExpenseTypes } from "../../../actions/expenseTypesAction";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const vehicleColumns = [
  {
    Header: dictionary.table.name,
    accessor: "name",
  },
  {
    Header: dictionary.table.active,
    accessor: "isActive",
    Cell: ({ row: { original } }) => {
      return original.isActive === 1
        ? dictionary.users.active
        : dictionary.users.inactive;
    },
  },
];

const FundingBodies = ({ expenseTypes, isLoaded, getSchoolExpenseTypes }) => {
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [providerData, setProviderData] = useState(false);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] = useState(
    false
  );

  const onRowClick = async (rowObject) => {
    setProviderDetailsModalOpen(true);
    setProviderData(rowObject.original);
  };

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) {
        await getSchoolExpenseTypes();
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      <NewExpenseType
        isModalOpen={isProviderModalOpen}
        setIsModalOpen={setProviderModalOpen}
      />
      <ProviderData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => setProviderDetailsModalOpen(false)}
        providerData={providerData}
      />
      <Title>
        סוגי הוצאות
        <CreateButton
          onClick={() => setProviderModalOpen(true)}
          text="צור חדש"
        />
      </Title>
      <Table
        data={expenseTypes}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    expenseTypes: state.ExpenseTypesReducer.expenseTypes,
    isLoaded: state.ExpenseTypesReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    editVehicle: state.session.user.editVehicle,
  };
}

export default connect(mapStateToProps, {
  getSchoolExpenseTypes,
})(FundingBodies);
