import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  FieldText,
  Field,
  Col,
  TableBottom
} from "./activityAnalysis.styles";
import { connect } from "react-redux";
import { getActivityAnalysisTeacher, getActivityAnalysisVehicle, getActivityAnalysis } from "../../../actions/reportActions";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import _ from "lodash";
import { useReactToPrint } from "react-to-print";
import SelectVehicle from "../../UiComponents/Select/SelectVehicle";
import Toggle from "react-toggle";
import SelectTeacher from "../../UiComponents/Select/SelectTeacher";
import Table from "../../SivanPlusTable/SivanPlusTable";

const ActivityAnalysis = ({
  drivingPermits,
  getActivityAnalysis,
  getActivityAnalysisTeacher,
  getActivityAnalysisVehicle,
  user,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [permitsTableData, setPermitsTableData] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDebtor, setTotalDebtor] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [dataTeachers, setDataTeachers] = useState([]);
  const [dataVehicles, setDataVehicles] = useState([]);
  const [tableDataTeachers, setTableDataTeachers] = useState([]);
  const [tableDataVehicle, setTableDataVehicle] = useState([]);
  const [showEachVehicle, setShowEachVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showEachTeacher, setShowEachTeacher] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [isActiveStudents, setIsActiveStudents] = useState(false);
  const columns = [
    {
      Header:'Teacher name',
      accessor: "name",
    },
    {
      Header:'סה"כ תלמידים במהלך התקופה',
      accessor: "totalStudentsInPeriod",
    },
    {
      Header: 'סה"כ תלמידים',
      accessor: "totalStudents",
    },
    {
      Header: 'סה"כ שיעורים מעשיים',
      accessor: "totalPracticalLessons",
    },
    {
      Header: 'סה"כ מבחנים פנימיים',
      accessor: "totalInternalTests",
    },
    {
      Header: 'סה"כ שיעורים לכל מורה',
      accessor: "totalLessons",
    },
    {
      Header: 'סה"כ מבחנים החיצוני',
      accessor: "totalExternalExams",
    },
    {
      Header: 'סה"כ עברו את המבחן',
      accessor: "totalStudentsWhoPassedExam",
    },
    {
      Header: 'נכשלו',
      accessor: "totalStudentsWhoFailedExam",
    },
    {
      Header: 'אחוז מעבר',
      accessor: "percentStudentsWhoPassedExam",
    },
    {
      Header: 'משך תקופת ממוצעת לתלמידים שעברו את הטסט',
      accessor: "totalStudentsLessonOrTest",
    },
    // {
    //   Header: 'כמות השיעורים הממוצעת לתלמידים שעברו את הטסט',
    //   accessor: "totalLessonsForStudentsPassedTest",
    // },
    // {
    //   Header: 'כמות הטסטים הממוצע לתלמיד שעבר את הטסט',
    //   accessor: "averageTestsPassedTest",
    // },
    {
      Header: 'סה"כ הכנסות מזומן',
      accessor: "totalAmountInCach",
    },
    {
      Header: 'סה"כ הכנסון אשראי',
      accessor: "totalAmountInCard",
    },
    {
      Header: 'סה"כ הכנסות שיקים',
      accessor: "totalAmountInCheсk",
    },
    {
      Header: 'סה"כ הכנסות אפליקציה',
      accessor: "totalAmountFromApp",
    },
    {
      Header: 'סה"כ הכנסות סליקה',
      accessor: "totalAmountPayment",
    },
    {
      Header: 'סה"כ כלל ההכנסות',
      accessor: "totalAmount",
    },
    {
      Header: 'סה"כ חיייבים',
      accessor: "totalDebtor",
    },
  ];
  useEffect(() => {
    if (user.userType === 1 ) return;
    let isMount = true;
    (async () => {
      const response = await getActivityAnalysisTeacher(startDate, endDate, selectedTeacher);
      if (isMount) setDataTeachers(response);
    })();
    return () => {
      // Очистить подписку
      isMount = false
    };
  }, [startDate, endDate, selectedTeacher]);

  useEffect(() => {
    if (showEachTeacher) {
      setTableDataTeachers(dataTeachers);
    } else {
      const _data = combineResult(dataTeachers);
      setTableDataTeachers([_data]);
    }
  }, [dataTeachers, showEachTeacher]);

  useEffect(() => {
    let isMount = true;
    (async () => {
      const response = await getActivityAnalysisVehicle(startDate, endDate, selectedVehicle);
      if (isMount) setDataVehicles(response);
    })();
    return () => {
      // Очистить подписку
      isMount = false
    };
  }, [startDate, endDate, selectedVehicle]);

  useEffect(() => {
    if (showEachVehicle) {
      setTableDataVehicle(dataVehicles);
    } else {
      const _data = combineResult(dataVehicles);
      setTableDataVehicle([_data]);
    }
  }, [dataVehicles, showEachVehicle]);

  const combineResult = (list) => {
    const result = {}
    for (const elem of list) {
      for (const k of Object.keys(elem)) {
        if (!k.includes('total')) continue;
        if (result[k] === undefined) result[k] = 0;
        result[k] += elem[k];
      }
    }
    result.name = 'הכל';
    result.id = 0;
    result.percentStudentsWhoPassedExam = result.totalStudentsWhoPassedExam > 0 ? 100 : 0;
    if (result.totalStudentsWhoFailedExam > 0) {
      result.percentStudentsWhoPassedExam = result.totalStudentsWhoPassedExam / result.totalStudentsWhoFailedExam * 100;
    }
    return result;
  }

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });

  const onRowClick = async (rowObject) => {};

  const TeachersTable = () => {
    return user.userType === 1 ? null : <Row>
      <Table
        height='auto'
        data={tableDataTeachers}
        // onRowClick={onRowClick}
        componentColumns={columns}
      />
    </Row>;
  }

  const VehiclesTable = () => {
    return <Row>
      <Table
        height='auto'
        data={tableDataVehicle}
        // onRowClick={onRowClick}
        componentColumns={columns}
      />
    </Row>;
  }

  return (
    <Container>
      <Row style={{justifyContent: 'space-between'}}>
        <>
          <Row>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
            <FieldText style={{whiteSpace: 'nowrap', paddingLeft: '10px'}}>עד תאריך</FieldText>
          </Row>
          <Row style={{justifyContent: 'space-between'}}>
            <Toggle
              id="cheese-status"
              defaultChecked={false}
              checked={isActiveStudents}
              value={isActiveStudents}
              onChange={() => setIsActiveStudents(!isActiveStudents) }
              style={{
                marginTop: 5
              }}
            />
            <FieldText style={{whiteSpace: 'nowrap', paddingLeft: '10px'}}>האם תלמידים פעילים בלבד</FieldText>
          </Row>
          <Row style={{justifyContent: 'space-between'}}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
            <FieldText style={{whiteSpace: 'nowrap', paddingLeft: '10px'}}>מתאריך</FieldText>
          </Row>
        </>
      </Row>
      <br/>
      <Row>
        <SelectVehicle selectedVehicle={(value) => setSelectedVehicle(value) }>
          <Toggle
            id="cheese-status"
            defaultChecked={false}
            checked={showEachVehicle}
            value={showEachVehicle}
            onChange={() => setShowEachVehicle(!showEachVehicle) }
            style={{
              marginTop: 5
            }}
          />
          <FieldText>האם דוח לכל רכב בנפרד</FieldText>
        </SelectVehicle>
        <div>
          <SelectTeacher selected={(value) => setSelectedTeacher(value) }>
            <Toggle
              id="cheese-status"
              defaultChecked={false}
              checked={showEachTeacher}
              value={showEachTeacher}
              onChange={() => setShowEachTeacher(!showEachTeacher) }
              style={{
                marginTop: 5
              }}
            />
            <FieldText>האם דוח לכל מורה בנפרד</FieldText>
          </SelectTeacher>
        </div>
      </Row>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={`דוח תלמידים חדשים לא פעילים ${moment(startDate).format(
          "DD/MM/YYYY"
        )} - ${moment(endDate).format("DD/MM/YYYY")}`}
        sheet="דוח תלמידים חדשים לא פעילים"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <FieldText>הדפס &nbsp;</FieldText>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      {(user.userType === 1 ? null : (<><h3>Table teachers</h3><TeachersTable /></>
      ))}
      <h3 style={{paddingTop: '20px'}}>Table vehicles</h3>
      <VehiclesTable />
      <hr/>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {
  getActivityAnalysisVehicle,
  getActivityAnalysisTeacher,
  getActivityAnalysis,
})(ActivityAnalysis);
