import { testWhatsAppMessage } from "../constants/events";
import { formatPhoneForWhatsapp, OpenWatsApp } from "../utils/device";

export const sendWhatsAppEventMessage = function(student, event, user) {
  let _text = `
  שלום לך, `+student.firstName + ' ' + student.lastName+`,
  ברצוני להזכירך כי בתאריך `+event.start.getDate() + '/' + (event.start.getMonth() + 1).toString() + '/' + event.start.getFullYear()+`, ב`+new Date(event.start).toLocaleString('he-il', {weekday:'long'})+`,
  בשעה `+event.start.getHours() +':'+event.start.getMinutes()+`
  קבענו שיעור.
  נתראה`
  if (event.eventType == 2 || event.eventType.value == 2) _text = testWhatsAppMessage({...event, firstName: student.firstName, lastName: student.lastName }, user)
  const phone = student.phone.startsWith("0") ? student.phone.substring(1) : student.phone
  OpenWatsApp(formatPhoneForWhatsapp(phone), _text)
}
