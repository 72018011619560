import React, { useState } from "react";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import {
  Container,
  Body,
  PermanentDetailsContainer,
  AdditionalDetailsContainer,
  Input,
  Button,
  Fields,
  ButtonContainer,
  ToggleContainer,
  ToggleText,
} from "./general.styles";
import { getVehicles } from "../../../actions/vehicleActions";
import { updateBasicData } from "../../../actions/userActions";
import { updateSettings } from "../../../actions/settingsActions";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle
        checked={input.value}
        onChange={(x) => input.onChange(x.target.checked ? 1 : 0)}
      />
    </ToggleContainer>
  );
};

const General = ({
  handleSubmit,
  updateBasicData,
  history,
  updateSettings,
}) => {
  const [genderSelectedOption, setGenderSelectedOption] = useState();
  const [vehicleOptions, setVehicleOptions] = useState();
  const [registrationFees, setRegistrationFees] = useState(false);
  const { t, i18n } = useTranslation("common");

  const updateData = (formValues) => {
    updateBasicData({
      id: formValues.id,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      phoneNumber: formValues.phoneNumber,
      address: formValues.address,
      email: formValues.email,
    });
    updateSettings({
      ticketShowCanceled: formValues.ticketShowCanceled,
      ticketShowCanceledAndCharged: formValues.ticketShowCanceledAndCharged,
      ticketLessonTime: formValues.ticketLessonTime,
      id: formValues.settingsId,
    });
    history.push("/calendar");
  };

  return (
    <Container onSubmit={handleSubmit(updateData)}>
      <Body>
        <Fields>
          <PermanentDetailsContainer>
            <Field
              name="email"
              component={InputWrappedWithField}
              placeholder="Email"
            />
            <Field
              name="userId"
              component={InputWrappedWithField}
              placeholder="תז"
              disabled
            />
            <Field
              name="firstName"
              component={InputWrappedWithField}
              placeholder="שם פרטי"
            />
            <Field
              name="lastName"
              component={InputWrappedWithField}
              placeholder="שם משפחה"
            />
            <Field
              name="phoneNumber"
              component={InputWrappedWithField}
              placeholder="מספר טלפון"
            />
            <Field
              name="address"
              component={InputWrappedWithField}
              placeholder="כתובת"
            />
          </PermanentDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="ticketShowCanceled"
              component={renderToggle}
              label={t("settings.ticketShowCanceled")}
            />
            <Field
              name="ticketShowCanceledAndCharged"
              component={renderToggle}
              label={t("settings.ticketShowCanceledAndCharged")}
            />
            <Field
              name="ticketLessonTime"
              component={InputWrappedWithField}
              placeholder="חישוב שיעורים בכרטסת לפי"
              type="number"
            />
            {/* <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={vehicleOptions}
              placeholder="Default Vehicle For Oz Meter"
              styles={customStyles}
            />
            <ToggleContainer>
              <ToggleText>Alert Or Stop</ToggleText>
              <Toggle
                defaultChecked={registrationFees}
                icons={false}
                onChange={(option) => setRegistrationFees(option)}
              />
            </ToggleContainer>
            <Input placeholder="Default Entry For Student" />
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={genderOptions}
              placeholder="Default Teacher"
              styles={customStyles}
            />
            <Select
              value={genderSelectedOption}
              onChange={(option) => setGenderSelectedOption(option)}
              options={genderOptions}
              placeholder="Professional Teacher"
              styles={customStyles}
            /> */}
          </AdditionalDetailsContainer>
        </Fields>
      </Body>
      <ButtonContainer>
        <Button reset>שמור</Button>
      </ButtonContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    initialValues: {
      registrationFees: false,
      smsOnEventCreation: false,
      glasses: false,
      email: state.session.user.email,
      id: state.session.user.id,
      settingsId: state.session.user.settingsId,
      firstName: state.session.user.firstName,
      ticketShowCanceledAndCharged:
        state.session.user.ticketShowCanceledAndCharged,
      ticketShowCanceled: state.session.user.ticketShowCanceled,
      lastName: state.session.user.lastName,
      phoneNumber: state.session.user.phoneNumber,
      ticketLessonTime: state.session.user.ticketLessonTime,
      address: state.session.user.address,
      userId: state.session.user.userId,
      cityNewStudent: state.session.user.cityNewStudent,
    },
  };
}

export default withRouter(
  connect(mapStateToProps, { getVehicles, updateBasicData, updateSettings })(
    reduxForm({
      form: "settingsGeneral",
    })(General)
  )
);
