import React from "react";
import { connect } from "react-redux";
import i18n from "../../../../lib/i18n/i18n";
import { Field, reduxForm } from "redux-form";
import SivanModal from "../../../Modal/Modal";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
} from "./newExpenseType.styles";
import Toggle from "react-toggle";
import { create } from "../../../../actions/expenseTypesAction";
import CreateButton from "../../../UiComponents/Buttons/Default";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle
        checked={input.value}
        onChange={(x) => input.onChange(x.target.checked ? 1 : 0)}
      />
    </ToggleContainer>
  );
};

const NewExpenseType = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  create,
}) => {
  const fundingBodyCreateAction = async (formValues) => {
    await create(formValues);
    setIsModalOpen(false);
  };

  return (
    <SivanModal
      titleText="צור סוג הוצאה"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="name"
              component={InputWrappedWithField}
              placeholder="שם"
              type="text"
            />
            <Field
              name="vatExemption"
              component={renderToggle}
              label="פטור ממע״מ"
            />
            <Field
              name="vatRecognized"
              component={InputWrappedWithField}
              placeholder="אחוז מעמ מוכר"
              type="number"
            />
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      vatRecognized: 100,
    },
  };
}

export default connect(mapStateToProps, { create })(
  reduxForm({
    form: "NewExpenseType",
  })(NewExpenseType)
);
