import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  FieldText,
  Field,
  Table,
} from "./canceledReport.styles";
import { connect } from "react-redux";
import { getAllSchoolCanceledEvents } from "../../../actions/schedulerActions";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import moment from "moment";

const TeacherWeekly = ({ getAllSchoolCanceledEvents, user }) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [tableData, setTableData] = useState(new Date());
  useEffect(() => {
    (async () => {
      const canceledEvents = await getAllSchoolCanceledEvents(
        startDate,
        endDate
      );
      buildDataArray(canceledEvents);
    })();
  }, [startDate, endDate]);

  const buildDataArray = (canceledEvents) => {
    let arrayData = [];

    for (let index = 0; index < canceledEvents.length; index++) {
      const element = canceledEvents[index];
      const day = moment(element.start).format("DD/MM/YYYY");
      if (typeof arrayData[day] === "undefined") {
        arrayData[day] = {
          "המשרד ביטל": 0,
          "המורה ביטל": 0,
          "התלמיד ביטל": 0,
          "טעות הקלדה": 0,
          "התלמיד לא הגיע": 0,
          "בוטל עקב מזג אוויר": 0,
          "אישור רפואי": 0,
          "ביטול זכיין": 0,
        };
      }

      arrayData[day][element.cancelReason] += 1;
    }

    setTableData(arrayData);
  };
  return (
    <Container>
      <Row>
        <>
          <Field>
            <FieldText>תאריך סוף הדוח</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>תאריך התחלת הדוח</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <Table>
        <thead>
          <tr>
            <th> </th>
            {[
              "המשרד ביטל",
              "המורה ביטל",
              "התלמיד ביטל",
              "טעות הקלדה",
              "התלמיד לא הגיע",
              "בוטל עקב מזג אוויר",
              "אישור רפואי",
              "ביטול זכיין",
            ].map((x, i) => {
              return <th>{x}</th>;
            })}
          </tr>
        </thead>
        {Object.keys(tableData).map((x, i) => (
          <tbody>
            <tr>
              <td>{x}</td>
              {Object.values(tableData[x]).map((y) => {
                return <td>{y}</td>;
              })}
            </tr>
          </tbody>
        ))}
      </Table>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {
  getAllSchoolCanceledEvents,
})(TeacherWeekly);
