import React, { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../lib/i18n/i18n";
import { Field, reduxForm } from "redux-form";
import { Body, Section, Row } from "./fundingBodiesDetails.styles";
import {
  update,
  deleteItem,
} from "../../../../../actions/fundingBodiesActions";
import CreateButton from "../../../../UiComponents/Buttons/Default";
import SivanQuestionModal from "../../../../Modal/QuestionModal";
import InputWrappedWithField from "../../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../../UiComponents/TextArea/Default";

const FundingBodiesDetails = ({
  handleSubmit,
  update,
  fundingBodyData,
  deleteItem,
  closeModal,
}) => {
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);

  const fundingBodyCreateAction = async (formValues) => {
    await update(formValues);
    closeModal();
  };

  const fundingBodyDeleteAction = async () => {
    setIsQuestionModalOpen(false);
    await deleteItem(fundingBodyData.id);
    closeModal();
  };

  return (
    <div>
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <SivanQuestionModal
          titleText="מחיקה"
          bodySecondRow="גוף המימון ימחק לצמיתות"
          modalIsOpen={isQuestionModalOpen}
          closeModal={() => setIsQuestionModalOpen(false)}
          deleteItem={fundingBodyDeleteAction}
          isDelete
        />
        <Section basicData noBorder>
          <Row>
            <Field
              name="name"
              component={InputWrappedWithField}
              placeholder="שם"
              type="text"
            />
            <Field
              name="pc"
              component={InputWrappedWithField}
              placeholder="ח.פ"
              type="number"
            />
            <Field
              name="openingBalance"
              component={InputWrappedWithField}
              placeholder="מאזן פתיחה"
              type="number"
            />
            <Field
              name="contactName"
              component={InputWrappedWithField}
              placeholder="איש קשר"
              type="text"
            />
            <Field
              name="address"
              component={InputWrappedWithField}
              placeholder="כתובת"
              type="text"
            />
            <Field
              name="city"
              component={InputWrappedWithField}
              placeholder="עיר"
              type="text"
            />
          </Row>
          <Row>
            <Field
              name="providerNumber"
              component={InputWrappedWithField}
              placeholder="מספר ספק"
              type="number"
            />
            <Field
              name="postalCode"
              component={InputWrappedWithField}
              placeholder="מיקוד"
              type="number"
            />
            <Field
              name="phoneNumber"
              component={InputWrappedWithField}
              placeholder="טלפון"
              type="text"
            />
            <Field
              name="email"
              component={InputWrappedWithField}
              placeholder="מייל"
              type="email"
            />
            <Field name="notes" component={NotesInput} placeholder="הערות" />
          </Row>
        </Section>
        <CreateButton
          text="מחק"
          type="button"
          isDelete
          marginRight={2}
          onClick={() => setIsQuestionModalOpen(true)}
        />
        <CreateButton text="עדכן" type="submit" />
      </Body>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      ...ownProps.fundingBodyData,
    },
  };
}

export default connect(mapStateToProps, { update, deleteItem })(
  reduxForm({
    form: "UpdateFundingBodies",
  })(FundingBodiesDetails)
);
