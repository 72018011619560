import styled from "styled-components";
import i18n from "../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.div`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
  display: block;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
  align-items: center;
  margin-bottom: 5px;
`;

export const TitleText = styled.span`
  font-size: 2.5vh;
  font-weight: bold;
  color: #404041;
`;
