import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import LoginImage from "./login.png";
import { withRouter } from "react-router-dom";
import { userSignIn } from "../../actions/session";
import SivanPlusLogo from "./sivanLogo.png";
import {
  Container,
  Body,
  Section,
  Input,
  LoginButton,
  ImageContainer,
  BackgroundImage,
  Logo,
  SubHeader,
  ForgotPassword,
  JoinUsText,
  ColoredText,
} from "./login.styles";

class Login extends PureComponent {
  handleSignInClicked = async (formValues) => {
    const result = await this.props.userSignIn(formValues);
    if (result) this.props.history.push("calendar");
  };

  renderInput = ({ input, placeholder, meta, type, autocomplete }) => {
    return (
      <Input
        type={type}
        placeholder={placeholder}
        {...input}
        isError={meta.error && meta.touched}
        autocomplete="off"
      />
    );
  };

  render() {
    return (
      <Container>
        <ImageContainer>
          <BackgroundImage src={LoginImage} />
        </ImageContainer>
        <Section>
          <Logo src={SivanPlusLogo} />
          <SubHeader>
            Please login to your account to continue <br /> with Sivan+
          </SubHeader>
          <form onSubmit={this.props.handleSubmit(this.handleSignInClicked)}>
            <Field
              name="id"
              component={this.renderInput}
              placeholder="Enter your ID"
              type="number"
            />
            <Field
              name="password"
              component={this.renderInput}
              placeholder="Enter your password"
              type="password"
            />
            <LoginButton disabled={this.props.pristine || this.props.invalid}>
              Login
            </LoginButton>
          </form>
          <ForgotPassword>Forgot Password?</ForgotPassword>
          <JoinUsText>want to join us?</JoinUsText>
          <ColoredText>Contact Us</ColoredText>
        </Section>
      </Container>
    );
  }
}

export default withRouter(
  connect(null, { userSignIn })(
    reduxForm({
      form: "login",
    })(Login)
  )
);
