import { useEffect, useState } from "react";
import { optionsArrrayWithTwoParams } from "../../utils/buildOptionsArray";

const useProfessionalTeachers = (
  isProfessionalTeachersLoaded,
  getProfessionalTeachers,
  professionalTeachers
) => {
  const [
    professionalTeachersOptions,
    setProfessionalTeachersOptionsOptions,
  ] = useState([]);
  useEffect(() => {
    async function fetchData() {
      if (!isProfessionalTeachersLoaded) await getProfessionalTeachers();
      else {
        setProfessionalTeachersOptionsOptions([
          { value: 999, label: "ללא" },
          ...optionsArrrayWithTwoParams(
            professionalTeachers,
            "id",
            "firstName",
            "lastName"
          ),
        ]);
      }
    }
    fetchData();
  }, [isProfessionalTeachersLoaded]);
  return professionalTeachersOptions;
};

export default useProfessionalTeachers;
