import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 3vh;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  padding: 3vh;
`;
export const Row = styled.div``;
export const Column = styled.div``;
export const ColumnsContainer = styled.div``;

export const Title = styled.span`
  font-weight: bold;
`;

export const AdditionalDetailsContainer = styled.div`
  padding-left: 2vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  height: 6vh;
  margin-top: 1vh;
  padding-left: 1vw;

  &::placeholder {
    color: #c2c2c2;
  }
`;
