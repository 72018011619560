import React, { useState } from "react";
import { ChromePicker } from "react-color";
import {
  Cover,
  Swatch,
  Color,
  Popover,
  GraphColorContainer,
  GraphColorText,
} from "./colorPicker.styles";

const ColorPicker = ({ text, input: { value, onChange } }) => {
  const [displayPicker, setDisplayPicker] = useState(false);

  return (
    <GraphColorContainer>
      <GraphColorText>{text}</GraphColorText>
      <Swatch onClick={() => setDisplayPicker(!displayPicker)}>
        <Color color={value} />
      </Swatch>
      {displayPicker ? (
        <Popover>
          <Cover
            onClick={() => {
              setDisplayPicker(false);
            }}
          />
          <ChromePicker
            color={value}
            value={value}
            onChange={(color) => {
              onChange(color.hex);
            }}
          />
        </Popover>
      ) : null}
    </GraphColorContainer>
  );
};

export default ColorPicker;
