import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import { isMobile } from "react-device-detect";
import validate from "./validate";
import { useTranslation } from "react-i18next";
import CreateButton from "../../UiComponents/Buttons/Default";
import SivanModal from "../../Modal/NewModal";
import { Body, Section, Row } from "./newLesson.styles";
import { getVehicles } from "../../../actions/vehicleActions";
import useVehicles from "../../useComponents/useVehicles";
import moment from "moment";
import { getAgreementDetails } from "../../../actions/agreementsActions";
import usePickupLocations from "../../useComponents/usePickupLocations";
import { getPickupLocations } from "../../../actions/pickupLocationsActions";
import { getStudents } from "../../../actions/studentesActions";
import {
  updateEvent,
  getStudentEventCount,
} from "../../../actions/schedulerActions";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import {
  getStudentTextBooks,
  fetchTextBook,
  decreaseCoutner,
  increaseCounter,
} from "../../../actions/textBooksActions";
import { getBranches } from "../../../actions/branchActions";
import { getAll } from "../../../actions/drivingPermits";
import { getSchoolTesters } from "../../../actions/testersActions";
import useTesters from "../../useComponents/useTesters";
import { getSchoolProfessionalTeachers } from "../../../actions/professionalTeachersActions";
import useProfessionalTeachers from "../../useComponents/useProfessionalTeachers";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../UiComponents/TextArea/Default";
import DefaultSelect from "../../UiComponents/Select/Default";
import DefaultDatePicker from "../../UiComponents/DatePicker/WithTime";
import { OpenWatsApp } from "../../../utils/device";

const eventTypes = [
  { value: 1, label: "שיעור" },
  { value: 2, label: "טסט" },
  { value: 3, label: "מבחן פנימי" },
  { value: 4, label: "פגישה" },
];

const agreementsDetailTypes = {
  1: "lessonsAgreementDetails",
  2: "testsAgreementDetails",
  3: "internalTestsAgreementDetails",
};

const timeOptions = [
  { value: 0.5, label: "0.5" },
  { value: 1, label: "1" },
  { value: 1.5, label: "1.5" },
  { value: 2, label: "2" },
  { value: 2.5, label: "2.5" },
  { value: 3, label: "3" },
  { value: 3.5, label: "3.5" },
  { value: 4, label: "4" },
  { value: 4.5, label: "4.5" },
  { value: 5, label: "5" },
  { value: 5.5, label: "5.5" },
  { value: 6, label: "6" },
  { value: 6.5, label: "6.5" },
  { value: 7, label: "7" },
  { value: 7.5, label: "7.5" },
  { value: 8, label: "8" },
  { value: 8.5, label: "8.5" },
  { value: 9, label: "9" },
  { value: 9.5, label: "9.5" },
  { value: 10, label: "10" },
];

const onlyIntTimeOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
];

const EditEvent = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  handleSubmit,
  submitting,
  pickupLocations,
  isPickupLocationsLoaded,
  getPickupLocations,
  isStudentsLoaded,
  getStudents,
  students,
  isUsersLoaded,
  users,
  updateEvent,
  getUsers,
  getStudentTextBooks,
  change,
  textBookId,
  fetchTextBook,
  selectedEvent,
  eventType,
  step,
  getVehicles,
  isVehiclesLoaded,
  vehicles,
  start,
  amount,
  studentId,
  getAgreementDetails,
  getStudentEventCount,
  getBranches,
  drivingPermits,
  getAll,
  getSchoolTesters,
  isTestersLoaded,
  testers,
  isProfessionalTeachersLoaded,
  professionalTeachers,
  getSchoolProfessionalTeachers,
  user,
  decreaseCoutner,
  increaseCounter,
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [textBookOptions, setTextBookOptions] = useState([]);
  let pickupLocationsOptions = usePickupLocations(
    isPickupLocationsLoaded,
    pickupLocations,
    getPickupLocations
  );
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);
  const [textBooksData, setTextBookData] = useState([]);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users.filter((x) => x.isActive === 1));
  const [branchOptions, setBranchOptions] = useState([]);
  const [duration, setDuration] = useState(step);
  const [drivingPermitsOptions, setDrivingPermitsOptions] = useState([]);
  const testersOptions = useTesters(isTestersLoaded, getSchoolTesters, testers);
  const professionalTeachersOptions = useProfessionalTeachers(
    isProfessionalTeachersLoaded,
    getSchoolProfessionalTeachers,
    professionalTeachers
  );

  const formatPhoneForWhatsapp = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    phoneNumber = phoneNumber.replaceAll('-', '');
    if (phoneNumber.includes('+972')) return phoneNumber;
    else if (phoneNumber.includes('972')) return '+' + phoneNumber;
    else {
      return '+972' + phoneNumber;
    }
  }

  const isDisabled =
    selectedEvent.eventStatus === 1 || selectedEvent.eventStatus === 2;
  const createNewEvent = async (formValues) => {
    let currStudent = undefined;
    if (formValues.studentId) {
      currStudent = students.find(
        (x) => x.studentId === formValues.studentId.value
      );
    }
    const end = new Date(
      formValues.start.getTime() + duration * 60000 * formValues.amount.value
    );

    let eventToSave = {
      id: formValues.id,
      pickupLocationId: formValues.pickupLocationId,
      notes: (formValues.notes === undefined || formValues.notes === null || formValues.notes === '') ? '-' : formValues.notes,
      eventType: formValues.eventType,
      start: formValues.start,
      studentId: currStudent,
      vehicleId: formValues.vehicleId ? formValues.vehicleId.value : null,
      teacherId: formValues.teacherId ? formValues.teacherId.value : null,
      updateUsername: `${user.firstName} ${user.lastName}`,
      end,
    };
    if (formValues.eventType.value !== 4) {
      eventToSave = {
        ...eventToSave,
        ...{
          textBookId: formValues.textBookId.value,
          price: formValues.price,
        },
      };
    }

    if (formValues.drivingPermitId)
      eventToSave = {
        ...eventToSave,
        drivingPermitId: formValues.drivingPermitId.value,
      };

    if (formValues.eventType.value === 2 && formValues.testerId) {
      eventToSave = { ...eventToSave, testerId: formValues.testerId.value };
    }

    if (formValues.eventType.value === 3 && formValues.professionalTeacherId) {
      eventToSave = {
        ...eventToSave,
        professionalTeacherId: formValues.professionalTeacherId.value,
      };
    }
    await updateEvent(eventToSave);

    if (
      formValues.eventType.value !== selectedEvent.eventType &&
      moment(end).isBefore(moment(new Date()))
    ) {
      await increaseCounter(
        formValues.eventType.value,
        formValues.textBookId.value
      );
      await decreaseCoutner(selectedEvent.eventType, selectedEvent.textBookId);
    }

    if (
      currStudent.smsType === "whatsapp" &&
      !moment(selectedEvent.start).isSame(formValues.start)
    ) {

      const _text = `${`שלום לך ${
        currStudent.firstName
      } ${currStudent.lastName},%0aברצוני להזכירך כי מועד ה${
        formValues.eventType.label
      } שנקבע בתאריך ${moment(selectedEvent.start).format(
        "DD/MM/YYYY"
      )}, ביום ${moment(selectedEvent.start).format("dddd")} בשעה ${moment(
        selectedEvent.start
      ).format("HH:mm")} השתנה לתאריך ${moment(formValues.start).format(
        "DD/MM/YYYY"
      )} ביום ${moment(formValues.start).format("dddd")} בשעה ${moment(
        formValues.start
      ).format("HH:mm")}.%0aנתראה, ${
        formValues.teacherId.label
      }`}`;
      
      OpenWatsApp(formatPhoneForWhatsapp(currStudent.phone), _text);
    }

    setNewAgreementModalIsOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      const optionsArray = [];
      const allBranches = await getBranches();
      allBranches.map(({ id, name }) =>
        optionsArray.push({ value: id, label: name })
      );
      setBranchOptions(optionsArray);
      change(
        "branchId",
        optionsArray.find(
          (x) => x.value.toString() === selectedEvent.branchId.toString()
        )
      );

      if (drivingPermits.length === 0) {
        await getAll();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isStudentsLoaded && isVehiclesLoaded) {
      const studentOptions = students.map((x) => ({
        value: x.studentId,
        label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
        searchString: `${x.phone} - ${x.studentId} - ${x.firstName} ${x.lastName}`
      }));
      setSuggestions(studentOptions);

      if (selectedEvent.eventType !== 4) {
        change(
          "studentId",
          studentOptions.find((x) => x.value === selectedEvent.studentId)
        );

        change(
          "teacherId",
          teachersOptions.find(
            (x) => x.value.toString() === selectedEvent.teacherId.toString()
          )
        );
        const licenseType = vehicles.find(
          (x) => x.id.toString() === selectedEvent.vehicleId.toString()
        ).licenseType;

        const options = drivingPermits
          .filter((x) => x.licenseTypeId.toString() === licenseType.toString())
          .map((x) => ({ value: x.id, label: x.name }));
        setDrivingPermitsOptions(options);

        if (options.length > 0 && selectedEvent.drivingPermitId !== null) {
          change(
            "drivingPermitId",
            options.find(
              (x) =>
                x.value.toString() === selectedEvent.drivingPermitId.toString()
            )
          );
        }

        change(
          "vehicleId",
          vehicleOptions.find(
            (x) => x.value.toString() === selectedEvent.vehicleId.toString()
          )
        );
      }
    }
  }, [isStudentsLoaded, vehicles]);

  useEffect(() => {
    if (
      selectedEvent.eventType !== 4 &&
      isStudentsLoaded &&
      drivingPermits.length > 0
    ) {
      const licenseType = vehicles.find(
        (x) => x.id.toString() === selectedEvent.vehicleId.toString()
      ).licenseType;
      const options = drivingPermits
        .filter((x) => x.licenseTypeId.toString() === licenseType.toString())
        .map((x) => ({ value: x.id, label: x.name }));
      setDrivingPermitsOptions(options);
      if (options.length > 0 && selectedEvent.drivingPermitId !== null) {
        change(
          "drivingPermitId",
          options.find(
            (x) =>
              x.value.toString() === selectedEvent.drivingPermitId.toString()
          )
        );
      }
    }
  }, [drivingPermits]);

  useEffect(() => {
    (async () => {
      if (
        typeof textBookId !== "undefined" &&
        typeof textBookId === "string" &&
        selectedEvent.eventType !== 4
      ) {
        calculateUserPrice(studentId.value);
      }
    })();
  }, [textBookId]);

  useEffect(() => {
    (async () => {
      if (
        typeof textBookId !== "undefined" &&
        textBooksData.length > 0 &&
        studentId
      ) {
        calculateUserPrice(studentId.value);
      }
    })();
  }, [eventType, amount]);

  useEffect(() => {
    (async () => {
      if (selectedEvent.eventType !== 4) {
        const fetchedStundetTextBooks = await getStudentTextBooks(
          selectedEvent.studentId
        );
        setTextBookData(fetchedStundetTextBooks);
        const options = fetchedStundetTextBooks.map((x) => ({
          value: x.textBookId,
          label: x.vehicleNumber.toString(),
        }));
        setTextBookOptions(options);
        change(
          "textBookId",
          options[
            options.findIndex(
              (x) => x.value.toString() === selectedEvent.textBookId.toString()
            )
          ]
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedEvent.teacherId)
      change(
        "teacherId",
        teachersOptions.find(
          (x) => x.value.toString() === selectedEvent.teacherId.toString()
        )
      );

    if (selectedEvent.vehicleId)
      change(
        "vehicleId",
        vehicleOptions.find(
          (x) => x.value.toString() === selectedEvent.vehicleId.toString()
        )
      );
  }, [vehicleOptions]);

  useEffect(() => {
    if (selectedEvent.testerId)
      change(
        "testerId",
        testersOptions.find(
          (x) => x.value.toString() === selectedEvent.testerId.toString()
        )
      );
  }, [testersOptions]);

  useEffect(() => {
    if (selectedEvent.professionalTeacherId)
      change(
        "professionalTeacherId",
        professionalTeachersOptions.find(
          (x) =>
            x.value.toString() ===
            selectedEvent.professionalTeacherId.toString()
        )
      );
  }, [professionalTeachersOptions]);

  const calculateUserPrice = async (idOfStudent) => {
    try {
      const eventTypeToOpeningBalance = {
        1: "remaininglessonsInMinutes",
        2: "remainingTests",
        3: "remainingInternalTests",
      };

      const response = await fetchTextBook(
        typeof textBookId === "string" ? textBookId : textBookId.value
      );

      const activeAgreement = findActiveAgreement(
        response.agreements,
        "startDate"
      );
      const agreementsDetails = await getAgreementDetails(
        activeAgreement.agreementId
      );
      const numberOfEvents = await getStudentEventCount(
        eventType.value,
        idOfStudent,
        typeof textBookId === "string" ? textBookId : textBookId.value,
        start
      );
      const agreementDetails =
        agreementsDetails[agreementsDetailTypes[eventType.value]];
      const activeAgreementEventType = findActiveAgreement(
        agreementDetails,
        "from"
      );

      setDuration(activeAgreementEventType.duration);
      const eventsOpeningBalanceType = eventTypeToOpeningBalance[eventType.value];
      let openingBalance = 0;
      if (eventType.value === 1) {
        openingBalance = response[eventsOpeningBalanceType] / step;
      } else {
        openingBalance = response[eventsOpeningBalanceType];
      }

      if (
        activeAgreementEventType.amount -
          numberOfEvents -
          amount.value -
          openingBalance +
          1 >
        0
      ) {
        change("price", activeAgreementEventType.price * amount.value);
      } else {
        change("price", activeAgreementEventType.priceAfter * amount.value);
      }
    } catch(err){
    }
  };

  const findActiveAgreement = (agreements, startDateFieldName) => {
    const agreementsArray = agreements.sort(
      (a, b) =>
        new Date(b[startDateFieldName]) - new Date(a[startDateFieldName])
    );
    let index = 0;
    let tata = true;
    for (index; index < agreementsArray.length; index++) {
      const element = agreementsArray[index];
      if (!moment(element[startDateFieldName]).isSameOrAfter(start)) {
        tata = false;
        break;
      }
    }
    if (tata) {
      index = index - 1;
    }
    return agreementsArray[index];
  };

  return (
    <SivanModal
      titleText="עריכת אירוע"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body onSubmit={handleSubmit(createNewEvent)}>
        <div style={{ width: "100%", maxHeight: "80vh" }}>
          <Section basicData noBorder>
            <Row>
              <Field
                name="studentId"
                component={DefaultSelect}
                options={suggestions}
                placeholder="בחר תלמיד"
                disabled={isDisabled}
              />
              <Field
                name="eventType"
                component={DefaultSelect}
                placeholder="סוג אירוע"
                options={eventTypes}
                disabled={isDisabled}
              />
              {eventType === eventTypes[0] &&
                drivingPermitsOptions.length !== 0 && (
                  <Field
                    name="drivingPermitId"
                    component={DefaultSelect}
                    placeholder="היתר נהיגה"
                    options={drivingPermitsOptions}
                  />
                )}
              {eventType === eventTypes[2] && (
                <Field
                  name="professionalTeacherId"
                  component={DefaultSelect}
                  placeholder="מנהל מקצועי"
                  options={professionalTeachersOptions}
                  disabled={isDisabled}
                />
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <Field
                  name="start"
                  component={DefaultDatePicker}
                  placeholder="התחלה"
                  disabled={isDisabled}
                />
                <Field
                  name="amount"
                  component={DefaultSelect}
                  disabled={isDisabled}
                  placeholder="כמות"
                  options={
                    eventType === eventTypes[2] || eventType === eventTypes[1]
                      ? onlyIntTimeOptions
                      : timeOptions
                  }
                />
              </div>
              {eventType !== eventTypes[3] && (
                <Field
                  name="textBookId"
                  component={DefaultSelect}
                  placeholder="תיק לימוד"
                  options={textBookOptions}
                />
              )}
              <Field
                name="branchId"
                component={DefaultSelect}
                placeholder={t("newStudent.branch")}
                options={branchOptions}
                isDisabled={branchOptions.length === 1}
                disabled={isDisabled}
              />
              {eventType === eventTypes[1] && (
                <Field
                  name="testerId"
                  component={DefaultSelect}
                  placeholder="טסטר"
                  options={testersOptions}
                />
              )}
            </Row>
            <Row>
              <Field
                name="teacherId"
                component={DefaultSelect}
                placeholder="מורה"
                options={teachersOptions}
              />
              <Field
                name="vehicleId"
                component={DefaultSelect}
                placeholder="רכב"
                options={vehicleOptions}
              />
              <Field
                name="pickupLocationId"
                component={DefaultSelect}
                placeholder="נקודת איסוף"
                options={pickupLocationsOptions}
                disabled={isDisabled}
              />
              {eventType !== eventTypes[3] && (
                <Field
                  name="price"
                  component={InputWrappedWithField}
                  placeholder={t("newAgreement.agreementPrice")}
                  type="number"
                  disabled
                />
              )}
              <Field name="notes" component={NotesInput} placeholder="הערות" />
            </Row>
          </Section>
        </div>
        <CreateButton
          type="submit"
          disabled={submitting}
          text="עדכון"
          marginTop={2}
        />
        <div>
          <div>
            נוצר ע״י&nbsp;
            <span>
              &nbsp;{
                users.find(
                  (x) => x.id.toString() === selectedEvent.userId.toString()
                )?.firstName
              }
              {
                users.find(
                  (x) => x.id.toString() === selectedEvent.userId.toString()
                )?.lastName
              }
            </span>
            &nbsp;בתאריך&nbsp;
            <span>
              {moment(selectedEvent.created).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
          <div>
            עודכן ע״י&nbsp;
            <span>{selectedEvent.updateUsername}</span>
            &nbsp;בתאריך&nbsp;
            <span>
              {moment(selectedEvent.updated).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
        </div>
      </Body>
    </SivanModal>
  );
};
const selector = formValueSelector("editEvent"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    testers: state.TestersReducer.testers,
    isTestersLoaded: state.TestersReducer.isLoaded,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    professionalTeachers:
      state.ProfessionalTeachersReducer.professionalTeachers,
    isProfessionalTeachersLoaded: state.ProfessionalTeachersReducer.isLoaded,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    user: state.session.user,
    users: state.UsersReducer.users,
    pickupLocations: state.PickupLocationsReducer.locations,
    isPickupLocationsLoaded: state.PickupLocationsReducer.isLoaded,
    textBookId: selector(state, "textBookId"),
    amount: selector(state, "amount"),
    eventType: selector(state, "eventType"),
    studentId: selector(state, "studentId"),
    start: selector(state, "start"),
    students: state.StudentsReducer.students,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    initialValues: {
      ...ownProps.selectedEvent,
      start: new Date(ownProps.selectedEvent.start),
      price: ownProps.selectedEvent.price,
      amount: {
        value:
          (new Date(ownProps.selectedEvent.end) -
            new Date(ownProps.selectedEvent.start)) /
          60000 /
          ownProps.step,
        label: (
          (new Date(ownProps.selectedEvent.end) -
            new Date(ownProps.selectedEvent.start)) /
          60000 /
          ownProps.step
        ).toString(),
      },
      eventType: eventTypes.find(
        (x) => x.value === ownProps.selectedEvent.eventType
      ),
      pickupLocationId:
        ownProps.selectedEvent.pickupLocationId === 999
          ? { value: 999, label: "ללא" }
          : {
              value: ownProps.selectedEvent.pickupLocationId,
              label: ownProps.selectedEvent.pickupLocationName,
            },
      studentId:
        ownProps.selectedEvent.eventType === 4
          ? null
          : `${ownProps.selectedEvent.name}`,
    },
  };
}

export default connect(mapStateToProps, {
  useVehicles,
  getPickupLocations,
  getVehicles,
  getStudents,
  updateEvent,
  getUsers,
  getStudentTextBooks,
  change,
  fetchTextBook,
  getAgreementDetails,
  getStudentEventCount,
  getBranches,
  getAll,
  getSchoolTesters,
  getSchoolProfessionalTeachers,
  decreaseCoutner,
  increaseCounter,
})(
  reduxForm({
    form: "editEvent",
    validate,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true,
  })(EditEvent)
);
