import drivingPermitsApi from "../apis/drivingPermits";
import {
  DRIVING_PERMITS_FETCH_FAILED,
  DRIVING_PERMITS_FETCH_SUCCESS,
  DRIVING_PERMITS_FETCH_STARTED,
  DRIVING_PERMIT_CREATE_SUCCESS,
  DRIVING_PERMIT_DELETE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (name, licenseTypes) => async (dispatch, getState) => {
  try {
    // dispatch({
    //   type: VEHICLE_FETCH_STARTED,
    // });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await drivingPermitsApi.post(
      "/create",
      {
        drivingPermitId: name,
        schoolId,
        licenseTypes,
      },
      {
        headers,
      }
    );
  } catch (error) {
    // dispatch({
    //   type: VEHICLE_FETCH_FAILED,
    // });
  }
};

export const getAll = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DRIVING_PERMITS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await drivingPermitsApi.get(`/getAll/${schoolId}`, {
      headers,
    });

    dispatch({
      type: DRIVING_PERMITS_FETCH_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: DRIVING_PERMITS_FETCH_FAILED,
    });
  }
};

export const allDrivingPermits = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DRIVING_PERMITS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await drivingPermitsApi.get(`/allDrivingPermits`, {
      headers,
    });

    // dispatch({
    //   type: DRIVING_PERMITS_FETCH_SUCCESS,
    //   payload: response.data,
    // });
    return response.data;
  } catch (error) {
    dispatch({
      type: DRIVING_PERMITS_FETCH_FAILED,
    });
  }
};
