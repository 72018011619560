import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import i18n from "../../../../lib/i18n/i18n";
import EditAgreement from "../../Agreements/EditAgreement/EditAgreement";
import { connect } from "react-redux";
import EventStatusChange from "../../../Calendar/EventStatusChange/EventStatusChange";
import { Container, BalanceTable } from "./studentTicket.styles";
import Table from "../../../SivanPlusTable/SivanPlusTable";
import {
  getStudentEvents,
  getStudentEventCount,
  updateEventPrice,
  getStudentData,
} from "../../../../actions/schedulerActions";
import {
  updateIsChecked,
  sendPaymentRequest,
} from "../../../../actions/studentesActions";
import {
  getStudentTextBooks,
  fetchTextBook,
  setStudentBalance,
} from "../../../../actions/textBooksActions";
import {
  getAgreementDetails,
  getAgreements,
} from "../../../../actions/agreementsActions";
import { getStudentDiscountsAndObligations } from "../../../../actions/discountsAndObligationsActions";
import IncomeData from "../../../Financial/IncomesFromStudents/IncomeData/IncomeData";
import {
  getStudentIncomes,
  getClientIncomes,
} from "../../../../actions/incomesAction";
import {
  getPaymentRequestById,
  getSchoolFundingBodies,
} from "../../../../actions/fundingBodiesActions";
import moment from "moment";
import _ from "lodash";
import { getUsers } from "../../../../actions/userActions";
import { getLicenseTypes } from "../../../../actions/licenseTypes";
import { getVehicles } from "../../../../actions/vehicleActions";
import ReactTooltip from "react-tooltip";
import Button from "../../../UiComponents/Buttons/Default";
import NewLesson from "../../../Calendar/NewLesson/NewLesson";
import UpdateCharge from "./UpdateCharge/UpdateCharge";
import NewCharge from "../../../Calendar/NewCharge/NewCharge";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FadeLoader from "react-spinners/FadeLoader";
import EditEvent from "../../../Calendar/EditEvent/EditEvent";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import {
  eventPriceCalculator,
  findActiveAgreement,
} from "../../../../utils/calculator";
import PrintIcon from '../../../../images/print.ico';
import AddIncomeIcon from '../../../../images/newcharge.ico';
import ReCalculateIcon from '../../../../images/recalculate.ico';
import CreateEventIcon from '../../../../images/addevent.ico';
import Promt from "../../../App/Promt/Promt";
import { DefaultSendEmail } from "../../../../const";
import PromtEmail from "../../../App/Promt/Email";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const internalTestStatus = [
  { icon: "icons8-crying", text: "נכשל" },
  { icon: "icons8-happy", text: "עבר" },
  { icon: "icons8-crying", text: "נכשל" },
];

const testStatus = [
  { icon: "icons8-question", text: "בהזמנה" },
  { icon: "icons8-happy", text: "עבר" },
  { icon: "icons8-crying", text: "נכשל" },
];

const shotStudentColumnsAccesors = ['number', 'start', 'eventStatus', 'eventType', 'balance', 'price'];

let studentColumns = [
  {
    Header: dictionary.table.number,
    accessor: "number",
    Cell: ({ row: { original } }) => {
      return (
        <div>
          <p
            data-tip={original.notes}
            style={{ color: original.notes ? "red" : "black" }}
          >
            {original.number}
          </p>
          <ReactTooltip />
        </div>
      );
    },
  },
  {
    Header: dictionary.table.date,
    accessor: "start",
  },
  {
    Header: dictionary.table.eventType,
    accessor: "eventType",
  },
  {
    Header: dictionary.table.status,
    accessor: "eventStatus",
    Cell: ({ row: { original } }) => {
      if (
        original.data.eventType === 2 &&
        original.data.eventStatus !== 3 &&
        original.data.eventStatus !== 4
      ) {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className={testStatus[original.data.testStatus].icon} />
            <span>{testStatus[original.data.testStatus].text}</span>
          </div>
        );
      } else if (
        original.data.eventType === 3 &&
        original.data.eventStatus !== 3 &&
        original.data.eventStatus !== 4
      ) {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              className={
                internalTestStatus[original.data.internalTestStatus].icon
              }
            />
            <span>
              {internalTestStatus[original.data.internalTestStatus].text}
            </span>
          </div>
        );
      } else {
        return <span>{original.eventStatus}</span>;
      }
    },
  },
  {
    Header: "משך",
    accessor: "time",
  },
  {
    Header: "סוג רישיון",
    accessor: "license",
  },
  {
    Header: "תיאור רכב",
    accessor: "vehicleDescription",
  },
  {
    Header: "מורה",
    accessor: "teacher",
    Cell: ({ row: { original } }) => {
      if (
        original.teacher === "תיק לימוד תקול" ||
        original.teacher === "תיק לימוד נמחק" ||
        original.teacher === "undefined undefined"
      ) {
        return (
          <span style={{ fontWeight: "bold", color: "red" }}>
            תקלה נא לעדכן את האירוע
          </span>
        );
      } else {
        return <span>{original.teacher}</span>;
      }
    },
  },
  {
    Header: "נוצר ע״י",
    accessor: "createdBy",
  },
];

const StudentTicket = ({
  getStudentEvents,
  studentData,
  studentId,
  getStudentTextBooks,
  fetchTextBook,
  getAgreementDetails,
  getStudentEventCount,
  ticketShowCanceledAndCharged,
  ticketShowCanceled,
  getStudentIncomes,
  isUsersLoaded,
  users,
  getUsers,
  getLicenseTypes,
  getVehicles,
  getAgreements,
  phone,
  isChecked,
  id,
  updateIsChecked,
  isAllowedTicketCheck,
  getStudentDiscountsAndObligations,
  user,
  updateEventPrice,
  updateagreement,
  glasses,
  studentTicket,
  setStudentBalance,
  ticketLessonTime,
  sendPaymentRequest,
  studentname,
  getClientIncomes,
  getPaymentRequestById,
  getSchoolFundingBodies,
  getStudentData,
}) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studentTextBookData, setStudentTextBookData] = useState([]);
  const [isNewLessonModalOpen, setIsNewLessonModalOpen] = useState(false);
  const [defaultStudent, setDefaultStudent] = useState({});
  const [fundingBodies, setFundingBodies] = useState([]);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] =
    useState(false);
  const [reCalculateUserPriceRunned, setReCalculateUserPriceRunned] =
    useState(false);
  const [isEditAgreementModalOpen, setEditAgreementModalIsOpen] =
    useState(false);
  const [providerData, setProviderData] = useState(false);
  const [textBookId, setTextBookId] = useState();
  const [isNewChargeModalOpen, setIsNewChargeModalOpen] = useState(false);
  const [ordersCount, setOrdersCount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceWithFutureOrders, setBalanceWithFutureOrders] = useState(0);
  const [testsCount, setTestsCount] = useState(0);
  const [internalTestsCount, setInternalTestsCount] = useState(0);
  const [lessonInAgreementTime, setLessonInAgreementTime] = useState(0);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [openingBalanceTest, setOpeningBalanceTest] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [openingBalanceInternalTest, setOpeningBalanceInternalTest] =
    useState(0);
  const [isShowCanceled, setIsShowCanceled] = useState(false);
  const [openingBalanceLesson, setOpeningBalanceLesson] = useState(0);
  const [studentEvents, setStudentEvents] = useState([]);
  const [agreement, setAgreement] = useState("");
  const [isTicketChecked, setIsTicketChecked] = useState(isChecked);
  const [agreementData, setAgreementData] = useState({});
  const [textbookOptions, setTextbookOptions] = useState([]);
  const [selectedTextBook, setSelectedTextBook] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [lessonsWithFutureLessons, setLessonsWithFutureLessons] = useState(0);
  const [
    internalTestsWithFutureInternalTests,
    setInternalTestsWithFutureInternalTests,
  ] = useState(0);
  const [testsWithFutureTests, setTestsWithFutureTests] = useState(0);
  const [totalStudentDidntShowedUp, setTotalStudentDidntShowedUp] = useState(0);
  const [
    totalStudentDidntShowedUpAndCharged,
    setTotalStudentDidntShowedUpAndCharged,
  ] = useState(0);
  const [totalStudentDiscounts, setTotalStudentDiscounts] = useState(0);
  const [isPassedInternal, setIsPassedInternal] = useState(0);
  const [isPassedTest, setIsPassedTest] = useState(0);
  const [isEventStatusChangeModalOpen, setIsEventStatusChangeModalOpen] =
    useState(false);
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const [isIncomeDataOpen, setIsIncomeDataOpen] = useState(false);
  const [currEvent, setCurrEvent] = useState({});
  const [isShowPrintModal, setIsShowPrintModal] = useState(false);

  useEffect(() => {
    (async () => {
      const fetchedStundetTextBooks = await getStudentTextBooks(studentId);
      const options = fetchedStundetTextBooks.map((x) => ({
        value: x.textBookId,
        label: x.description || "",
      }));
      if (options.length > 0) {
        setTextbookOptions(options);
        setSelectedTextBook(options[options.length - 1]);
      }
    })();

    if (
      studentTicket &&
      studentColumns.findIndex((x) => x.Header === "מחיר") === -1
    ) {
      studentColumns = [
        ...studentColumns,
        {
          Header: dictionary.table.price,
          accessor: "price",
          Cell: ({ row: { original } }) => {
            if (
              original.eventType === "חשבונית" ||
              original.eventType === "חשבונית זיכוי"
            ) {
              return (
                <span style={{ fontWeight: "bold" }}>{original.price}</span>
              );
            } else if (
              original.eventType === "קבלה" ||
              original.eventType === "חשבונית/קבלה" ||
              original.eventType === "הנחה" ||
              original.eventType === "חיוב"
            ) {
              return (
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {original.price}
                </span>
              );
            } else {
              return (
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {original.price}
                </span>
              );
            }
          },
        },
        {
          Header: dictionary.table.balance,
          accessor: "balance",
          Cell: ({ row: { original } }) => {
            return original.balance < 0 ? (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {original.balance}
              </span>
            ) : (
              <span style={{ color: "green", fontWeight: "bold" }}>
                {original.balance}
              </span>
            );
          },
        },
      ];
    }
    if (user.shortStudentTicket) {
      studentColumns = studentColumns.filter((val) => shotStudentColumnsAccesors.includes(val.accessor))
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      await initTable();
      setIsLoading(false);
    }
    if (
      !isNewLessonModalOpen &&
      !isNewChargeModalOpen &&
      !isEditLessonModalOpen &&
      !reCalculateUserPriceRunned &&
      !isIncomeDataOpen &&
      !isEventStatusChangeModalOpen &&
      selectedTextBook.length !== 0
    )
      fetchData();
  }, [
    isUsersLoaded,
    isNewLessonModalOpen,
    isNewChargeModalOpen,
    reCalculateUserPriceRunned,
    selectedTextBook,
    isEditLessonModalOpen,
    isIncomeDataOpen,
    isEventStatusChangeModalOpen,
    isShowCanceled,
  ]);

  const reCalculateUserPrice = async (userEvents) => {
    setIsLoading(true);
    setReCalculateUserPriceRunned(true);
    const studentEventsFiltered = userEvents.filter(
      (x) => x.eventStatus !== 3 && x.textBookId === studentTextBookData.id
    );
    const calculates = [];
    studentEventsFiltered.map((x) => {
      calculates.push(checkIfEventPriceUpdateNeeded(x, studentEventsFiltered));
    });
    await Promise.all(calculates);
    setReCalculateUserPriceRunned(false);
    setIsLoading(false);
  };

  const checkIfEventPriceUpdateNeeded = async (x, studentEventsFiltered) => {
    const activeAgreement = findActiveAgreement(
      studentTextBookData.agreements,
      "startDate"
    );
    const agreementsDetails = await getAgreementDetails(
      activeAgreement.agreementId
    );
    const eventPrice = eventPriceCalculator(
      x,
      studentEventsFiltered,
      agreementsDetails,
      studentTextBookData
    );

    if (eventPrice !== x.price) {
      await updateEventPrice(x.id, eventPrice);
    }
  };

  const updateStudentIsChecked = async () => {
    if (isAllowedTicketCheck)
      if (await updateIsChecked(id, !isTicketChecked, studentId)) {
        setIsTicketChecked(!isTicketChecked);
      }
  };

  const openAgreement = async () => {
    if (updateagreement) {
      const activeAgreement = findActiveAgreement(
        studentTextBookData.agreements,
        "startDate"
      );
      const agreementsDetails = await getAgreementDetails(
        activeAgreement.agreementId
      );
      setAgreementData({ ...activeAgreement, ...agreementsDetails });

      setEditAgreementModalIsOpen(true);
    }
  };

  const initTable = async () => {
    if (!isUsersLoaded) {
      await getUsers();
    } else {
      try {
        const id = selectedTextBook.value;
        setTextBookId(id);
        const userData = await getStudentData(studentId, id, ticketShowCanceledAndCharged, isShowCanceled)
        setFundingBodies(userData.fundingBodies);
        setStudentEvents(userData.userEvents.filter((x) => x.textBookId === id));
        // const data = _.uniq(
        //   userEvents.map((x) => x.fundingBodyStatus).filter((x) => x !== 0)
        // );
        setStudentTextBookData(userData.textBooksData);
        setAgreement(
          userData.agreements.find(
            (x) => x.id === userData.textBooksData.agreements[0].agreementId
          ).description
        );
        setOrdersCount(userData.lessons);
        setIsPassedInternal(userData.isPassedInternal);
        setOpeningBalance(userData.textBooksData.openingBalance);
        setOpeningBalanceTest(userData.textBooksData.remainingTests);
        setOpeningBalanceInternalTest(userData.textBooksData.remainingInternalTests);
        setTestsCount(userData.testsCount);
        setInternalTestsCount(userData.internalTestsCount);

        setIsPassedTest(userData.testStatus);
        setIsPassedInternal(userData.internalTestStatus);
        setTotalStudentDidntShowedUp(userData.totalDidntShowedUp);
        setTotalStudentDidntShowedUpAndCharged(userData.totalDidntShowedUpAndCharged);
        setTotalStudentDiscounts(userData.totalDiscounts);
        setTotalPaid(userData.totalPaymentRecived);
        setEvents(userData.tableEvents);
        setBalance(userData.studentBalance.toFixed(2));
        setBalanceWithFutureOrders(userData.studentBalanceWithFutureBalance.toFixed(2));
        await setStudentBalance(id, userData.studentBalance.toFixed(2));
        setLessonInAgreementTime(userData.lessonInAgreementTime);
        setInternalTestsWithFutureInternalTests(userData.internalTestsWithFutureInternalTests);
        setTestsWithFutureTests(userData.testsWithFutureTests);
        setLessonsWithFutureLessons(userData.lessonsWithFutureLessons);
        setOpeningBalanceLesson(userData.textBooksData.remaininglessonsInMinutes);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onClickSendToStudent = async (emails = '') => {
    setIsShowPrintModal(false)
    if (emails === false) return;
    const title = `כרטסת תלמיד ${studentname} תז ${studentId}`;
    const result = await sendPaymentRequest(
      {
        name: `${user.firstName} ${user.lastName}`,
        schoolFullName: user.schoolFullName,
        schoolId: user.schoolId,
        bnNumber: user.bnNumber,
        logo: user.logo,
      },
      events,
      openingBalance,
      openingBalanceLesson,
      openingBalanceInternalTest,
      openingBalanceTest,
      ticketLessonTime,
      balance,
      lessonInAgreementTime,
      internalTestsCount,
      testsCount,
      balanceWithFutureOrders,
      lessonsWithFutureLessons,
      internalTestsWithFutureInternalTests,
      testsWithFutureTests,
      agreement,
      totalStudentDidntShowedUp,
      totalPaid,
      totalStudentDidntShowedUpAndCharged,
      totalStudentDiscounts,
      glasses,
      title,
      emails
    );
    if (emails.length > 0) {
      return toast('File sent to email.', { type: 'success' })
    }
  };

  const onRowClick = async (event, e, d) => {
    if (event.column.Header === "סטטוס") {
      if (
        event.original.type === "income" ||
        event.original.type === "discount"
      ) {
        setCurrEvent(event.original.data);
        setIsIncomeDataOpen(true);
      } else if (event.original.type === "event") {
        setCurrEvent(event.original.data);
        setIsEventStatusChangeModalOpen(true);
      }
    } else if (event.column.Header === "סוג אירוע") {
      if (event.original.type === "event") {
        setCurrEvent(event.original.data);
        setIsEditLessonModalOpen(true);
      } else if (event.original.type === "income") {
        setProviderData(event.original.data);
        setProviderDetailsModalOpen(true);
      }
    }
  };
  return (
    <Container>
      {isLoading && (
        <div
          style={{
            height: "73%",
            width: "82%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 5454,
          }}
        >
          <FadeLoader size={150} color={"#123abc"} loading={true} />
        </div>
      )}
      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "3px" }}
      >
        <Toggle
          id="cheese-status"
          defaultChecked={false}
          onChange={() => setIsShowCanceled(!isShowCanceled)}
        />
        <label htmlFor="cheese-status">הראה מבוטלים</label>
        <IncomeData
          isModalOpen={isProviderDetailsModalOpen}
          closeModal={() => setProviderDetailsModalOpen(false)}
          providerData={providerData}
        />
        {textbookOptions.length > 1 && ( <Autocomplete
          id="combo-box-demo"
          options={textbookOptions}
          getOptionLabel={(option) => option.label}
          value={selectedTextBook}
          onChange={(event, value) => setSelectedTextBook(value)}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="בחר תיק לימוד"
              variant="outlined"
            />
          )}
        />
        )}
        &nbsp;
        <img src={CreateEventIcon} style={{width: 50, marginRight:"1", height: 52}} onClick={() => {
          setIsNewLessonModalOpen(true);
        }} />
        &nbsp;
        {studentEvents.length > 0 && (
          <>
          &nbsp;
          <img src={ReCalculateIcon} style={{width: 50, height: 52}} onClick={() => reCalculateUserPrice(studentEvents)}  marginRight="1" />
          &nbsp;
          </>
        )}
        {user.createStudentIncome ? (
          <>{/*<Button
            text="מסמך כספי"
            onClick={() => setIsNewChargeModalOpen(true)}
            marginRight="1"
          />*/}
          &nbsp;
          <img src={AddIncomeIcon} style={{width: 50, height: 52}} onClick={() => setIsNewChargeModalOpen(true)} marginRight="1"/>
          &nbsp;
          </>
        ) : null}

        {/*<Button text="הדפס" onClick={onClickSendToStudent} marginRight="1" />*/}
        &nbsp;
        <img src={PrintIcon} style={{width: 50, height: 52}} onClick={() => setIsShowPrintModal(true)} marginRight="1"/>
        &nbsp;
      </div>
      {isNewLessonModalOpen && (
        <NewLesson
          isNewAgreementModalOpen={setIsNewLessonModalOpen}
          setNewAgreementModalIsOpen={() => setIsNewLessonModalOpen(false)}
          selectedEvent={{
            start: new Date(),
            slots: [{}, {}],
          }}
          step={user.calendarSpacingInMinutes}
          defaultStudent={studentId}
          studentFromCalendar={studentId}
        />
      )}
      {isEditAgreementModalOpen && (
        <EditAgreement
          isEditAgreementModalOpen={isEditAgreementModalOpen}
          setEditAgreementModalIsOpen={setEditAgreementModalIsOpen}
          agreementData={agreementData}
        />
      )}
      {isNewChargeModalOpen && (
        <NewCharge
          isNewAgreementModalOpen={isNewChargeModalOpen}
          setNewAgreementModalIsOpen={() => setIsNewChargeModalOpen(false)}
          studentIdFromTextBook={studentId}
          textBookId={textBookId}
          defaultStudent={studentId}
        />
      )}
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={40}
          selectedEvent={{
            ...currEvent,
            name: currEvent.name
              ? currEvent.name
              : `${currEvent.studentId} - ${currEvent.firstName} ${currEvent.lastName}`,
          }}
        />
      )}
      {isEventStatusChangeModalOpen && (
        <EventStatusChange
          isNewAgreementModalOpen={isEventStatusChangeModalOpen}
          setNewAgreementModalIsOpen={setIsEventStatusChangeModalOpen}
          selectedEvent={{
            ...currEvent,
            name: currEvent.name
              ? currEvent.name
              : `${currEvent.studentId} - ${currEvent.firstName} ${currEvent.lastName}`,
          }}
        />
      )}
      {isIncomeDataOpen && (
        <UpdateCharge
          isNewAgreementModalOpen={isIncomeDataOpen}
          setNewAgreementModalIsOpen={() => setIsIncomeDataOpen(false)}
          income={currEvent}
        />
      )}
      {isShowPrintModal && <PromtEmail isModalOpen={true} defaultValue={studentData.email} onClose={onClickSendToStudent} />}
      <BalanceTable>
        {(openingBalance > 0 ||
          openingBalanceLesson > 0 ||
          openingBalanceInternalTest > 0 ||
          openingBalanceTest > 0) && (
          <tr>
            <td>י.פ</td>
            <td style={{ color: openingBalance < 0 ? "red" : "green" }}>
              {openingBalance}
            </td>
            <td>שיעורים={openingBalanceLesson / ticketLessonTime}</td>
            <td>מ.פ={openingBalanceInternalTest}</td>
            <td>טסטים={openingBalanceTest}</td>
          </tr>
        )}
        <tr>
          <td>יתרה נוכחית</td>
          {/* <td>מפגשים={ordersCount}</td> */}
          {studentTicket ? (
            <td style={{ color: balance < 0 ? "red" : "green" }}>{balance}</td>
          ) : null}
          <td>
            שיעורים לפי {ticketLessonTime} דקות=
            {lessonInAgreementTime.toFixed(2)}
          </td>
          <td>מ.פ={internalTestsCount}</td>
          <td>טסטים={testsCount}</td>
          <td>
            גוף מימון=
            {
              fundingBodies.find(
                (x) => x.id === studentTextBookData.fundingBody
              )?.name
            }
          </td>
        </tr>
        <tr>
          <td>כולל הזמנות</td>
          {studentTicket ? (
            <td
              style={{ color: balanceWithFutureOrders < 0 ? "red" : "green" }}
            >
              {balanceWithFutureOrders}
            </td>
          ) : null}
          <td>
            שיעורים לפי {ticketLessonTime} דקות=
            {lessonsWithFutureLessons.toFixed(2)}
          </td>
          <td>מ.פ={internalTestsWithFutureInternalTests}</td>
          <td>טסטים={testsWithFutureTests}</td>
        </tr>
        <tr>
          <td onClick={openAgreement}>הסכם:{agreement}</td>
          <td>
            <span
              className={isTicketChecked ? "icons8-checkmark" : "icons8-cancel"}
              onClick={updateStudentIsChecked}
              style={{
                color: isTicketChecked ? "green" : "red",
                cursor: "pointer",
              }}
            />
          </td>
          <td>סה״כ לא הופיע={totalStudentDidntShowedUp}</td>
          <td>
            <span
              className="icons8-phone"
              onClick={() => window.open(`tel:${phone}`)}
              style={{ cursor: "pointer" }}
            />
          </td>
          <td>{phone}</td>
          <td>נוצר ע״י {studentTextBookData.createdBy}</td>
          <td>
            בתאריך {moment(studentTextBookData.createdAt).format("YYYY-MM-DD")}
          </td>
        </tr>
        <tr>
          <td>{glasses ? "צריך משקפי ראייה" : null}</td>
          {studentTicket ? (
            <td>
              סה״כ ששולם:{" "}
              <span style={{ color: "green" }}>{totalPaid.toFixed(2)}</span>
            </td>
          ) : null}
          {studentTicket ? (
            <td>סה״כ לא הופיע וחוייב={totalStudentDidntShowedUpAndCharged}</td>
          ) : null}
          {studentTicket ? <td>סה״כ הנחות={totalStudentDiscounts}</td> : null}
          <td>
            מפ:
            <span className={internalTestStatus[isPassedInternal].icon}></span>
          </td>
          <td>
            טסט: <span className={testStatus[isPassedTest].icon}></span>
          </td>
          <td>{studentId}</td>
        </tr>
      </BalanceTable>
      <Table
        data={events}
        height="40vh"
        componentColumns={studentColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ticketShowCanceledAndCharged:
      state.session.user.ticketShowCanceledAndCharged,
    isAllowedTicketCheck: state.session.user.isAllowedTicketCheck,
    ticketShowCanceled: state.session.user.ticketShowCanceled,
    ticketLessonTime: state.session.user.ticketLessonTime,
    studentTicket: state.session.user.studentTicket,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    user: state.session.user,
    updateagreement: state.session.user.updateagreement,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getStudentEvents,
    getStudentTextBooks,
    fetchTextBook,
    getAgreementDetails,
    getStudentEventCount,
    getStudentIncomes,
    getUsers,
    getLicenseTypes,
    getVehicles,
    getAgreements,
    updateIsChecked,
    getStudentDiscountsAndObligations,
    updateEventPrice,
    setStudentBalance,
    sendPaymentRequest,
    getClientIncomes,
    getPaymentRequestById,
    getSchoolFundingBodies,
    getStudentData,
  })(StudentTicket)
);
