import styled from "styled-components";
import { isMobile } from "react-device-detect";
import i18n from "../../../../lib/i18n/i18n";
const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    height: 6vh;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }

  .react-toggle-track {
    background-color: #f5f5f5 !important;
    border: 1px solid #e5e5e5;
    padding: 0.2vh;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #edca92;
  }

  .react-toggle-thumb {
    background-color: #e5e3e0;
    border: none;
  }
`;

export const ComponentContainer = styled.div`
  background-color: ${(props) => props.isCalendarpPage ? '#fff' : "#fffdf9"};
  width: ${(props) => props.isCalendarpPage ? (isMobile ? '98vw' : '100%') : isMobile ? "96vw" : "100%"};
  //width: ${isMobile ? "96vw" : "100%"};
  padding-left: ${isMobile ? "0" : "0.5vh"};
  // height: ${isMobile ? "unset" : "95vh"};
  min-height: 85vh;
  overflow: auto;
  text-align: right;
  // position: ${isMobile ? 'absolute' : 'inherit'};
  left: ${(props) => isMobile && props.isOpenMenu ? '-250px' : '0'};
  right: ${isMobile ? '5vw' : '0'};
  transition: left .3s linear;
  z-index: 4;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
`;
