import React, { useState } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SivanModal from "../../Modal/NewModal";
import { fetchTextBook } from "../../../actions/textBooksActions";
import {
  Body,
  LeftButton,
  TopButton,
  RightButton,
  BottomButton,
  ButtonText,
  CenterText,
  BottomLeftButton,
} from "./studentMenu.styles";
import { getStudent } from "../../../actions/studentesActions";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import { getVehicles } from "../../../actions/vehicleActions";
import useVehicles from "../../useComponents/useVehicles";
import { getAgreements } from "../../../actions/agreementsActions";
import useAgreements from "../../useComponents/useAgreements";
import Assignment from "../../Students/StudentUpdate/TextBooks/EditTextBook/Assignment/Assignment";
import EditTextBook from "../../Students/StudentUpdate/TextBooks/EditTextBook/EditTextBook";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const EventStatusChange = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  selectedEvent,
  getStudent,
  fetchTextBook,
  vehicles,
  getVehicles,
  isVehiclesLoaded,
  getAgreements,
  getLicenseTypes,
  history,
  user,
}) => {
  const [textBookData, setTextBookData] = useState({});
  const [studentLicense, setStudentLicense] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [isAssigmentOpen, setAssigmentModalOpen] = useState(false);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);
  const agreementList = useAgreements(getAgreements);
  const [isTextBookEditModalOpen, setTextBookEditModalOpen] = useState(false);
  const [textBookView, setTextBookView] = useState();

  const openStudent = async () => {
    const studentData = await getStudent(selectedEvent.studentId);
    history.push("/updateStudent", { studentData: studentData[0] });
  };

  const openTextBook = async () => {
    const textBook = await fetchTextBook(selectedEvent.textBookId);
    // setStudentData(await getStudent(selectedEvent.studentId));
    let licenses = [];
    // if (licenseTypes.length === 0) {
    licenses = await getLicenseTypes();
    // }
    if (selectedEvent.eventType.toString() !== "4") {
      const vehicleLicenseType = vehicles.find(
        (x) => x.id.toString() === selectedEvent.vehicleId.toString()
      ).licenseType;
      const license = licenses.find(
        (x) => x.id === parseInt(vehicleLicenseType)
      );
      // }
      // }
      setStudentLicense(license.vehicleType);
      setTextBookData({
        ...textBook,
        vehicleId: vehicleOptions.find(
          (x) => x.value === parseInt(textBook.vehicleId)
        ),
      });
      setAssigmentModalOpen(true);
    } else {
    }
  };

  const openTextBooka = async (view) => {
    const textBook = await fetchTextBook(selectedEvent.textBookId);
    const studentData = await getStudent(selectedEvent.studentId);
    setStudentData(studentData[0]);
    setTextBookData({
      ...textBook,
      vehicleId: vehicleOptions.find(
        (x) => x.value === parseInt(textBook.vehicleId)
      ),
    });
    setTextBookView(view);
    setTextBookEditModalOpen(true);
  };

  return (
    <SivanModal
      titleText="תפריט תלמיד"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={false}
    >
      {isTextBookEditModalOpen && (
        <EditTextBook
          isTextBookEditModalOpen={isTextBookEditModalOpen}
          setTextBookEditModalOpen={setTextBookEditModalOpen}
          textBookData={textBookData}
          studentData={studentData}
          agreementList={agreementList}
          view={textBookView}
        />
      )}
      {isAssigmentOpen ? (
        <Assignment
          textBookData={textBookData}
          studentId={selectedEvent.studentId}
          studentLicense={studentLicense}
        />
      ) : (
        <Body>
          <LeftButton onClick={openTextBook}>
            <ButtonText>מטלות</ButtonText>
          </LeftButton>
          {/* <Button onClick={openTextBook}>תיק לימוד</Button>
          <Button onClick={openTextBook}>הסכמים</Button> */}
          {user.updateStudent !== 0 && (
            <TopButton onClick={openStudent}>
              <ButtonText>תלמיד</ButtonText>
            </TopButton>
          )}
          {user.updateStudent !== 0 && (
            <RightButton
              onClick={() => openTextBooka(dictionary.editTextBook.dates)}
            >
              <ButtonText>תאריכים</ButtonText>
            </RightButton>
          )}
          {user.updateStudent !== 0 && (
            <BottomButton
              onClick={() => openTextBooka(dictionary.editTextBook.agreements)}
            >
              <ButtonText>הסכמים</ButtonText>
            </BottomButton>
          )}
          {user.updateStudent !== 0 && (
            <BottomLeftButton onClick={openStudent} location={1}>
              <ButtonText>תיק לימוד</ButtonText>
            </BottomLeftButton>
          )}
          <CenterText>
            {`${selectedEvent.firstName} ${
              typeof selectedEvent.nickname === "string"
                ? selectedEvent.nickname
                : ""
            } ${selectedEvent.lastName}
            ${selectedEvent.address ? selectedEvent.address : ""}, ${
              selectedEvent.city ? selectedEvent.city : ""
            }
            ${selectedEvent.phone}`}
          </CenterText>
        </Body>
      )}
    </SivanModal>
  );
};

function mapStateToProps(state) {
  return {
    user: state.session.user,
    vehicles: state.VehiclesReducer.vehicles,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getStudent,
    getVehicles,
    fetchTextBook,
    getAgreements,
    getLicenseTypes,
  })(EventStatusChange)
);
