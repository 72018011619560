import {isChrome, isTablet, osName, OsTypes, isMobile } from 'react-device-detect'

export const isChromeIPad = () => {
  return isChrome && isIpad
}

export const OpenWatsApp = (phone, text="") => {
  let link = `https://wa.me/${phone}`;
  // if (isMobile || isTablet) link = 'whatsapp://send?'
  link += `?text=${text}`;
  window.open(link, '_blank');
}

export const isIpad = () => {
  return isTablet && osName === OsTypes.IOS
}

export const formatPhoneForWhatsapp = function(phoneNumber) {
  phoneNumber = phoneNumber.trim();
  phoneNumber = phoneNumber.replaceAll('-', '');
  if (phoneNumber.includes('+972')) return phoneNumber;
  else if (phoneNumber.includes('972')) return '+' + phoneNumber;
  else {
    return '+972' + phoneNumber;
  }
}
