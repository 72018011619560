import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./professionalTeachers.styles";
import NewProfessionalTeacher from "./NewProfessionalTeacher/NewProfessionalTeacher";
import ProfessionalTeachersData from "./ProfessionalTeachersData/ProfessionalTeachersData";
import { getSchoolProfessionalTeachers } from "../../../actions/professionalTeachersActions";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const vehicleColumns = [
  {
    Header: dictionary.table.name,
    accessor: "firstName",
    Cell: ({ row: { original } }) =>
      `${original.firstName} ${original.lastName}`,
  },
  {
    Header: dictionary.table.phone,
    accessor: "phoneNumber",
  },
  {
    Header: dictionary.table.active,
    accessor: "isActive",
    Cell: ({ row: { original } }) =>
      original.isActive === 1
        ? dictionary.users.active
        : dictionary.users.inactive,
  },
];

const FundingBodies = ({
  professionalTeachers,
  isLoaded,
  getSchoolProfessionalTeachers,
}) => {
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [providerData, setProviderData] = useState(false);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] = useState(
    false
  );

  const onRowClick = async (rowObject) => {
    setProviderDetailsModalOpen(true);
    setProviderData(rowObject.original);
  };

  useEffect(() => {
    async function fetchData() {
      if (!isLoaded) {
        await getSchoolProfessionalTeachers();
      }
    }
    fetchData();
  }, []);

  return (
    <Container>
      <NewProfessionalTeacher
        isModalOpen={isProviderModalOpen}
        setIsModalOpen={setProviderModalOpen}
      />
      <ProfessionalTeachersData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => setProviderDetailsModalOpen(false)}
        providerData={providerData}
      />
      <Title>
        מורים מקצועיים
        <CreateButton
          onClick={() => setProviderModalOpen(true)}
          text="צור חדש"
        />
      </Title>
      <Table
        data={professionalTeachers}
        componentColumns={vehicleColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    professionalTeachers:
      state.ProfessionalTeachersReducer.professionalTeachers,
    isLoaded: state.ProfessionalTeachersReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    editVehicle: state.session.user.editVehicle,
  };
}

export default connect(mapStateToProps, {
  getSchoolProfessionalTeachers,
})(FundingBodies);
