import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');

  * {
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Rubik', sans-serif;
    margin: 0;
  }

  body, input, button {
    font: 14px 'Rubik', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .rt-td  {
    display: flex;
    justify-content: center;
  }

  .MuiPickersBasePicker-container{
    direction: rtl;
  }
`;
