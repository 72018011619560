import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import SivanQuestionModal from "../../../../Modal/QuestionModal";
import {
  Container,
  StudentDataContainer,
  FieldContainer,
  FieldTitle,
  FieldValue,
} from "./studentTicket.styles";
import Table from "../../../../SivanPlusTable/SivanPlusTable";
import {
  getPaymentRequests,
  updatePaymentRequestStatus,
} from "../../../../../actions/fundingBodiesActions";
import moment from "moment";
import { getClientIncomes } from "../../../../../actions/incomesAction";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];

const studentColumns = [
  {
    Header: "מס",
    accessor: "documentNumber",
  },
  {
    Header: "סכום",
    accessor: "amount",
  },
  {
    Header: "נוצר ע״י",
    accessor: "createUsername",
  },

  {
    Header: "סוג",
    accessor: "documentType",
    Cell: ({ row: { original } }) => {
      if (typeof original.documentType !== "undefined") {
        const document = documentTypes.find(
          (x) => x.value === original.documentType
        )?.label;
        return original.documentType === 3 ||
          original.documentType === 4 ||
          original.documentType === 5 < 0 ? (
          <span style={{ color: "red", fontWeight: "bold" }}>{document}</span>
        ) : (
          document
        );
      } else {
        return "חשבונית עסקה";
      }
    },
  },
  {
    Header: "תאריך",
    accessor: "date",
    Cell: ({ row: { original } }) => moment(original.date).format("DD-MM-YYYY"),
  },
  {
    Header: "סטטוס",
    accessor: "status",
    Cell: ({ row: { original } }) => {
      if (original.status === 0) {
        return "ממתין";
      } else if (original.status === 1) {
        return "אושר";
      } else if (original.status === 2) {
        return "בוטל";
      } else {
        return "";
      }
    },
  },
  {
    Header: "בטל",
    accessor: "schoolId",
    Cell: ({ row: { original } }) => {
      if (original.status === 0) {
        return <span style={{ cursor: "pointer" }}>בטל</span>;
      } else {
        return "";
      }
    },
  },
];

const StudentSearch = ({
  getPaymentRequests,
  clientId,
  updatePaymentRequestStatus,
  getClientIncomes,
}) => {
  const { t, i18n } = useTranslation("common");
  const [events, setEvents] = useState([]);
  const [showCanceled, setShowCanceled] = useState(false);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [requestPaymentId, setRequestPaymentId] = useState(null);

  useEffect(() => {
    async function fetchData() {
      // setEvents(await getPaymentRequests(clientId));
      const paymentRequests = await getPaymentRequests(clientId);
      const invoices = await getClientIncomes(clientId);
      setEvents([...paymentRequests, ...invoices]);
    }
    fetchData();
  }, []);

  const cancelRequestPayment = async () => {
    await updatePaymentRequestStatus(requestPaymentId, 2);
    setEvents(await getPaymentRequests(clientId));
    setIsQuestionModalOpen(false);
  };

  const onRowClick = async ({ original, column }) => {
    if (column.Header === "בטל") {
      setIsQuestionModalOpen(true);
      setRequestPaymentId(original.id);
    } else {
      //console.log(original);
      //console.log(column);
      if (original.documentFileName !== undefined) {
        window.open(
          "https://paymentrequest.s3.eu-central-1.amazonaws.com/" +
            original.documentFileName
        );
      } else {
        alert('שם המסמך לא קיים')
      }
    }
  };
  return (
    <Container>
      <Toggle
        id="cheese-status"
        defaultChecked={showCanceled}
        onChange={() => setShowCanceled(!showCanceled)}
      />
      <label htmlFor="cheese-status">הצג מבוטלים</label>
      <SivanQuestionModal
        titleText="מחיקת דרישת תשלום"
        bodySecondRow="האם לבטל את דרישת התשלום?"
        modalIsOpen={isQuestionModalOpen}
        closeModal={() => setIsQuestionModalOpen(false)}
        deleteItem={cancelRequestPayment}
        disableItem={() => {}}
        isDelete={true}
      />
      <Table
        data={showCanceled ? events : events.filter((x) => x.status !== 2)}
        componentColumns={studentColumns}
        onRowClick={onRowClick}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ticketShowCanceledAndCharged:
      state.session.user.ticketShowCanceledAndCharged,
    ticketShowCanceled: state.session.user.ticketShowCanceled,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getPaymentRequests,
    updatePaymentRequestStatus,
    getClientIncomes,
  })(StudentSearch)
);
