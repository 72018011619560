export default (values) => {
  const errors = {};
  if (!values.amount || parseFloat(values.amount) <= 0) {
    errors.amount = "Required";
  }
  if (!values.clientId) {
    errors.clientId = "Required";
  }

  if (!values.date) {
    errors.date = "Required";
  }

  if (
    (values.documentType?.value === 6 || values.documentType?.value === 7) &&
    !values.notes
  ) {
    errors.notes = "Required";
  }
  return errors;
};
