import React from "react";
import TextField from "@material-ui/core/TextField";

const DefaultInput = ({
  placeholder,
  autoFocus,
  isError,
  disabled,
  type,
  name,
  value,
  onChange,
  pattern,
  maxLength,
}) => {
  return (
    <TextField
      inputProps={{
        maxLength,
        pattern,
      }}
      name={name}
      id="outlined-basic"
      margin="dense"
      autoFocus={autoFocus}
      label={placeholder}
      type={type}
      fullWidth
      error={isError}
      disabled={disabled}
      variant="outlined"
      value={value}
      onChange={onChange}
    />
  );
};

export default DefaultInput;
