import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  customStyles,
  Row,
  FieldText,
  Field,
  ButtonContainer,
  Table,
  Tr,
  Td,
  Th,
  Text,
} from "./yearlyActivity.styles";
import { connect } from "react-redux";
import { getAll } from "../../../actions/drivingPermits";
import { getAllSchoolEvents } from "../../../actions/schedulerActions";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import Button from "../../UiComponents/Buttons/Default";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const TeacherWeekly = ({
  isUsersLoaded,
  users,
  user,
  getAll,
  getAllSchoolEvents,
}) => {
  const componentRef = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [allDrivingPermits, setAllDrivingPermits] = useState([]);
  const [endDate, setEndDate] = useState(
    new Date().setDate(new Date().getDate() + 5)
  );

  useEffect(() => {
    (async () => {
      const events = await getAllSchoolEvents();
      const drivingPermits = await getAll();
      const allEventsWithoutCanceled = events.filter(
        (x) => x.eventStatus !== 3 && x.eventStatus !== 4 && x.eventType !== 4
      );
      let totals = {};
      let filteredDrivingPermits = [];
      for (let index = 0; index < allEventsWithoutCanceled.length; index++) {
        const element = allEventsWithoutCanceled[index];
        if (new Date(element.start).getFullYear() === 2022) {
          const month = new Date(element.start).getMonth() + 1;
          totals[month] = totals[month] || {};
          if (element.eventType === 1) {
            const totalLessons =
              typeof totals[month]["totalLessons"] === "undefined"
                ? 1
                : totals[month]["totalLessons"] + 1;
            totals[month] = {
              ...totals[month],
              totalLessons: totalLessons,
            };
          } else if (element.eventType === 2) {
            const totalTests =
              typeof totals[month]["totalTests"] === "undefined"
                ? 1
                : totals[month]["totalTests"] + 1;
            totals[month] = {
              ...totals[month],
              totalTests: totalTests,
            };
          } else if (element.eventType === 3) {
            const totalInternalTests =
              typeof totals[month]["totalInternalTests"] === "undefined"
                ? 1
                : totals[month]["totalInternalTests"] + 1;
            totals[month] = {
              ...totals[month],
              totalInternalTests: totalInternalTests,
            };
          }

          const eventDrivingPermit = drivingPermits.find(
            (x) => x.id === element.drivingPermitId
          );

          const totalDrivingPermit =
            typeof totals[month][eventDrivingPermit.name] === "undefined"
              ? 1
              : totals[month][eventDrivingPermit.name] + 1;
          totals[month] = {
            ...totals[month],
            [eventDrivingPermit.name]: totalDrivingPermit,
          };

          if (!filteredDrivingPermits.includes(eventDrivingPermit.name)) {
            filteredDrivingPermits.push(eventDrivingPermit.name);
          }
        }
      }
      setAllDrivingPermits(filteredDrivingPermits);
      setTableData(totals);
    })();
  }, [startDate, endDate]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Container>
      {/* <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <FieldText>הדפס &nbsp;</FieldText>
        <i className="fa fa-print" aria-hidden="true" />
      </div> */}
      {/* <Row>
        <>
          <Field>
            <FieldText>תאריך סוף הדוח</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>תאריך התחלת הדוח</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row> */}
      <br />
      {/* <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={`דוח הכנסות ${moment(startDate).format(
          "DD/MM/YYYY"
        )} - ${moment(endDate).format("DD/MM/YYYY")}`}
        sheet="הכנסות"
        buttonText="יצא אקסל"
      /> */}
      <Table dir="rtl" id="table-to-xls">
        <thead>
          <Tr>
            <Th>
              <Text>סוג</Text>
            </Th>
            {Object.keys(tableData).map((x) => (
              <Th key={x}>
                <Text>{x}</Text>
              </Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          <Tr>
            <Td>שיעורים</Td>
            {Object.keys(tableData).map((x) => (
              <Td>{tableData[x]["totalLessons"]}</Td>
            ))}
          </Tr>
          <Tr>
            <Td>מפ</Td>
            {Object.keys(tableData).map((x) => (
              <Td>{tableData[x]["totalInternalTests"]}</Td>
            ))}
          </Tr>
          <Tr>
            <Td>טסטים</Td>
            {Object.keys(tableData).map((x) => (
              <Td>{tableData[x]["totalTests"]}</Td>
            ))}
          </Tr>
          {allDrivingPermits.map((y) => {
            return (
              <Tr>
                <Td>{y}</Td>
                {Object.keys(tableData).map((x) => (
                  <Td>{tableData[x][y]}</Td>
                ))}
              </Tr>
            );
          })}
        </tbody>
      </Table>
      {/* <Td>{tableData[x]["totalInternalTests"]}</Td>
              <Td>{tableData[x]["totalTests"]}</Td>
              {allDrivingPermits.map((y) => (
                <Td>{tableData[x][y.name]}</Td>
              ))}
            </Tr> */}
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    user: state.session.user,
    events: state.CalendarReducer.events,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
  };
}

export default connect(mapStateToProps, {
  getAll,
  getAllSchoolEvents,
})(TeacherWeekly);
