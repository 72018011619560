import validator from "validator";
import isIsraeliIdValid from "israeli-id-validator";

export default (values) => {
  const errors = {};
  if (values.hasOwnProperty("student")) {
    if (!values.student.firstName) {
      errors.student = { ...errors.student, firstName: "Required" };
    }
    if (!values.student.lastName) {
      errors.student = { ...errors.student, lastName: "Required" };
    }

    if (
      !values.student.studentId ||
      !validator.isNumeric(values.student.studentId)
    ) {
      errors.student = { ...errors.student, studentId: "Required" };
    } else if (values.student.studentId.toString().length != 9) {
      errors.student = { ...errors.student, studentId: "חובה סדה 9 מספרים" };
    } else if (!isIsraeliIdValid(values.student.studentId)) {
      errors.student = { ...errors.student, studentId: "Israil id is invalid" };
    }

    if (!values.student.gender) {
      errors.student = { ...errors.student, gender: "Required" };
    }

    if (
      !values.student.phone ||
      !validator.isMobilePhone(values.student.phone, ["he-IL"])
    ) {
      errors.student = { ...errors.student, phone: "Required" };
    }

    if (!values.student.branchId) {
      errors.student = { ...errors.student, branchId: "Required" };
    }

    if (!values.student.birthday || values.student.birthday > Date.now()) {
      errors.student = { ...errors.student, birthday: "Required" };
    }

    if (
      !values.textbook.eyeExamination ||
      values.textbook.eyeExamination > Date.now()
    ) {
      errors.textbook = { ...errors.student, eyeExamination: "Required" };
    }
  }
  return errors;
};
