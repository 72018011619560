import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "../../../Navbar/Navbar";
import DatePicker from "react-datepicker";
import i18n from "../../../../lib/i18n/i18n";
import { NavAndBackContainer, StyledLoader } from "./fundingBodiesData.styles";
import GeneralData from "./FundingBodiesDetails/FundingBodiesDetails";
import FundingBodyStudents from "./FundingBodyStudents/FundingBodyStudents";
import StudentTicket from "./StudentTicket/StudentTicket";
import SivanModal from "../../../Modal/Modal";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const FundingBodiesData = ({
  isPending,
  user,
  isModalOpen,
  closeModal,
  fundingBodyData,
}) => {
  const [menuItems, setMenuItems] = useState([dictionary.editStudent.basic]);
  const [currentOpenedView, setCurrentOpenedView] = useState(
    dictionary.editStudent.basic
  );

  useEffect(() => {
    user.studentTicket &&
      setMenuItems([...menuItems, dictionary.editStudent.ticket, "תלמידים"]);
  }, []);

  return (
    <SivanModal
      titleText={fundingBodyData.name}
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
    >
      <NavAndBackContainer>
        <Navbar
          menuOptions={menuItems}
          currentView={currentOpenedView}
          setCurrentOpenedView={setCurrentOpenedView}
        />
      </NavAndBackContainer>
      {currentOpenedView === dictionary.editStudent.basic && (
        <GeneralData
          fundingBodyData={fundingBodyData}
          closeModal={closeModal}
        />
      )}
      {currentOpenedView === "תלמידים" && (
        <FundingBodyStudents fundingBodyData={fundingBodyData} />
      )}
      {currentOpenedView === "כרטסת" && (
        <StudentTicket clientId={fundingBodyData.id} />
      )}
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </SivanModal>
  );
};

FundingBodiesData.defaultProps = {
  location: { state: { studentData: { birthday: new Date() } } },
};

function mapStateToProps(state) {
  return { user: state.session.user };
}

export default connect(mapStateToProps)(FundingBodiesData);
