export const eventTypeToTextbookOpeningBalanceString = {
  1: "remaininglessonsInMinutes",
  2: "remainingTests",
  3: "remainingInternalTests",
};

export const agreementsDetailTypes = {
  1: "lessonsAgreementDetails",
  2: "testsAgreementDetails",
  3: "internalTestsAgreementDetails",
};
