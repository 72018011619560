import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

const filterOptions = createFilterOptions({
  // matchFrom: 'start',
  stringify: option => {
    return option.searchString ? option.searchString : option.label
  },
});

const DefaultSelect = ({
  input,
  placeholder,
  meta: { touched, error },
  disabled,
  options,
  multiple,
}) => {
  return (
    <Autocomplete
      id="combo-box-demo"
      multiple={multiple}
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={filterOptions}
      value={input.value}
      onChange={(event, value) => input.onChange(value)}
      disabled={disabled}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={placeholder}
          variant="outlined"
          error={touched && error}
        />
      )}
    />
  );
};

export default DefaultSelect;
