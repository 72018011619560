import React from "react";
import Modal from "react-modal";
import {
  Title,
  Circle,
  TitleText,
  CloseIcon,
  Text,
  Button,
  ButtonContainer,
  Body,
} from "./modal.styles";
import FadeLoader from "react-spinners/FadeLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    height: "20vh",
    paddingTop: "0",
  },
  overlay: { zIndex: 1200 },
};

Modal.setAppElement("#root");

const SivanPlusModal = ({
  children,
  titleText,
  modalIsOpen,
  closeModal,
  isLoading,
  deleteItem,
  disableItem,
  isDelete,
  bodyFirstRow,
  bodySecondRow,
  successTxtButton,
  cancelTxtButton,
}) => {
  if (!successTxtButton) successTxtButton = 'כן';
  if (!cancelTxtButton) cancelTxtButton = 'בטל';
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      onRequestClose={closeModal}
    >
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={150} color={"#123abc"} loading={true} />
        </div>
      )}
      <Title>
        <TitleText>{titleText}</TitleText>
        <Circle onClick={closeModal}>
          <CloseIcon className="fa fa-close" />
        </Circle>
      </Title>
      <Body>
        <Text dir="auto">האם אתה בטוח?</Text>
        <Text dir="auto">{bodySecondRow}</Text>
        <ButtonContainer>
          <Button
            onClick={() => {
              isDelete ? deleteItem() : disableItem();
            }}
          >
            {successTxtButton}
          </Button>
          <Button reset onClick={closeModal}>
            {cancelTxtButton}
          </Button>
        </ButtonContainer>
      </Body>
    </Modal>
  );
};

export default SivanPlusModal;
