import React from "react";
import { Input, CustomInput } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import enLocale from "date-fns/locale/he";

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const DefaultColumnFilter = (aba) => {
  const {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  } = aba.column;
  let filtera = filterValue;
  if (typeof filterValue === "undefined") {
    const storage = window.localStorage.getItem(
      `${window.location.pathname}/${aba.column.Header}`
    );
    if (typeof storage !== "undefined" && storage && storage.length > 1) {
      filtera = storage;
      setFilter(filtera);
    }
  } else {
    window.localStorage.setItem(
      `${window.location.pathname}/${aba.column.Header}`,
      filterValue
    );
  }
  return (
    <Input
      value={filtera || ""}
      dir="rtl"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`חפש (${length} רשומות)`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  if (typeof filterValue === "undefined") {
    filterValue="1"
    // setFilter("1");
  }
  return (
    <CustomInput
      id="custom-select"
      type="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">הכל</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option === 1 ? "פעיל" : "לא פעיל"}
        </option>
      ))}
    </CustomInput>
  );
};

export const SelectColumnFilterCustomValue = (selectValues) => {
  return ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const options = [...selectValues]
    if (typeof filterValue === "undefined") {
      filterValue = "0"
      // setFilter("0");
    }
    return (
      <CustomInput
        id="custom-select"
        type="select"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="0">הכל</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </CustomInput>
    );
  };
}

export const SelectColumnFilterAll = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  return (
    <CustomInput
      id="custom-select"
      type="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">הכל</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option === 1 ? "פעיל" : "לא פעיל"}
        </option>
      ))}
    </CustomInput>
  );
};

export const DateColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  label = "בחר תאריך"
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
    <DatePicker
      autoOk={true}
      margin="dense"
      inputVariant="outlined"
      format="dd/MM/yyyy"
      value={typeof filterValue === "undefined" ? null : filterValue}
      onChange={(e) => {
        setFilter(e || undefined);
      }}
      label={label}
      fullWidth
      showTodayButton
    />
  </MuiPickersUtilsProvider>
);
