import React, { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import SivanModal from "../../Modal/NewModal";
import { Body, Button, ErrorBlock } from "./promt.styles";
import { updateEventNote } from "../../../actions/schedulerActions";
import { countStudentCompletedAssignments } from "../../../actions/textBooksActions";
import TextField from "@material-ui/core/TextField";
import Promt from "./Promt";
import { DefaultSendEmail } from "../../../const";

const EventStatusChange = ({
  isModalOpen,
  onClose,
  defaultValue,
  txtBtn2="הורד",
  btn2DefaultValue=''
}) => {
  return <Promt
    isModalOpen={isModalOpen}
    onClose={onClose}
    defaultValue={defaultValue}
    title="הורד או שלח"
    txtBtn1="שלח"
    txtBtn2={txtBtn2}
    btn2DefaultValue={btn2DefaultValue}
  />;
};

export default connect(null, {
  updateEventNote,
  countStudentCompletedAssignments,
})(EventStatusChange);
