import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  customStyles,
  StyledLoader,
  Icon,
  Row,
  Title,
  RankRow,
  RankText,
  IconContainer,
} from "./agreements.styles";
import {
  getAgreements,
  getAgreementDetails,
} from "../../../../../../actions/agreementsActions";
import {
  insertNewTextbookAgreement,
  deleteNewTextbookAgreement,
} from "../../../../../../actions/textBooksActions";
import * as _ from "underscore";
import EditAgreement from "../../../../Agreements/EditAgreement/EditAgreement";

const Agreements = ({
  isPending,
  textBookData,
  agreementList,
  insertNewTextbookAgreement,
  deleteNewTextbookAgreement,
  updateagreement,
  getAgreementDetails,
}) => {
  const { t, i18n } = useTranslation("common");
  const [selectedOption, setSelectedOption] = useState();
  const [currentAgreementIndex, setCurrentAgreementIndex] = useState(-1);
  const [startDate, setStartDate] = useState();
  const [textbookAgreements, setTextbookAgreements] = useState([]);
  const [isEditAgreementModalOpen, setEditAgreementModalIsOpen] = useState(
    false
  );
  const [agreementData, setAgreementData] = useState({});

  useEffect(() => {
    const sortedAgreements = sortAgreementsByDate();
    findCurrentAgreementIndex(sortedAgreements);
  }, []);

  const sortAgreementsByDate = () => {
    const agreementSorted = _.sortBy(textBookData.agreements, "startDate");
    let agreements = [];
    for (let i = 0; i < agreementList.length; i++) {
      const element = agreementList[i];
      for (let j = 0; j < agreementSorted.length; j++) {
        const agreement = agreementSorted[j];
        if (element.value === agreement.agreementId) {
          agreements.push({
            agreementId: agreement.agreementId,
            name: element.label,
            startDate: agreement.startDate,
            id: agreement.id,
          });
        }
      }
    }

    setTextbookAgreements(agreements);
    return agreements;
  };

  const findCurrentAgreementIndex = (sortedAgreements) => {
    for (let j = 0; j < sortedAgreements.length; j++) {
      const agreement = sortedAgreements[j];
      if (moment(agreement.startDate).isAfter(moment())) {
        setCurrentAgreementIndex(j - 1);
        break;
      }
    }
  };

  const handleChange = (selectedOption) => setSelectedOption(selectedOption);
  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const createNewAgreement = async () => {
    const id = await insertNewTextbookAgreement(
      selectedOption.value,
      startDate,
      textBookData.id
    );
    if (id) {
      const newAgreementArray = _.sortBy(
        [
          ...textbookAgreements,
          {
            agreementId: selectedOption.value,
            name: selectedOption.label,
            startDate: startDate.toISOString(),
            textbookId: textBookData.id,
            id,
          },
        ],
        "startDate"
      );
      setTextbookAgreements(newAgreementArray);
      findCurrentAgreementIndex(newAgreementArray);
    }

    setStartDate(null);
    setSelectedOption(null);
  };

  const deleteAgreement = async (id) => {
    if (await deleteNewTextbookAgreement(id)) {
      const newAgreementArray = textbookAgreements.filter((x) => x.id !== id);
      setTextbookAgreements(newAgreementArray);
      findCurrentAgreementIndex(_.sortBy(newAgreementArray, "startDate"));
    }
  };

  const onRowClick = async (agreement, agreementId) => {
    if (updateagreement) {
      const agreementDetails = await getAgreementDetails(agreementId);
      setAgreementData({
        ...agreement,
        ...agreementDetails,
        id: agreement.agreementId,
      });
      setEditAgreementModalIsOpen(true);
    }
  };

  const isHebrew = i18n.language === "he";
  return (
    <Container>
      {isEditAgreementModalOpen && (
        <EditAgreement
          isEditAgreementModalOpen={isEditAgreementModalOpen}
          setEditAgreementModalIsOpen={setEditAgreementModalIsOpen}
          agreementData={agreementData}
        />
      )}
      <Row>
        <Icon className="fa fa-plus-square" onClick={createNewAgreement} />
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          locale={i18n.language}
          placeholder="תאריך התחלת הסכם"
        />
        <Select
          isRtl={isHebrew}
          options={agreementList}
          value={selectedOption}
          placeholder="הסכם"
          styles={customStyles}
          onChange={handleChange}
        />
      </Row>
      <Title>הסכם נוכחי</Title>
      {currentAgreementIndex !== -1 && (
        <RankRow>
          <RankText
            onClick={() =>
              onRowClick(
                textbookAgreements[currentAgreementIndex],
                textbookAgreements[currentAgreementIndex].agreementId
              )
            }
          >
            {textbookAgreements[currentAgreementIndex].name}
          </RankText>
          <IconContainer>
            <Icon
              className="fa fa-trash-o"
              small
              onClick={() =>
                deleteAgreement(textbookAgreements[currentAgreementIndex].id)
              }
            />
            <DatePicker
              selected={
                new Date(textbookAgreements[currentAgreementIndex].startDate)
              }
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
            />
          </IconContainer>
        </RankRow>
      )}
      <Title>הסכמים הבאים</Title>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: "42vh",
          overflow: "auto",
        }}
      >
        {_.sortBy(
          textbookAgreements.filter(
            (x, index) => index !== currentAgreementIndex
          ),
          "startDate"
        ).map((x, index) => (
          <RankRow key={index}>
            <RankText onClick={() => onRowClick(x, x.agreementId)}>
              {x.name}
            </RankText>
            <IconContainer>
              <Icon
                className="fa fa-trash-o"
                small
                onClick={() => deleteAgreement(x.id)}
              />
              <DatePicker
                selected={new Date(x.startDate)}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                locale={i18n.language}
              />
            </IconContainer>
          </RankRow>
        ))}
      </div>
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.LicenseTypesReducer.isPending,
    updateagreement: state.session.user.updateagreement,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
  };
}

export default connect(mapStateToProps, {
  getAgreements,
  insertNewTextbookAgreement,
  deleteNewTextbookAgreement,
  getAgreementDetails,
})(Agreements);
