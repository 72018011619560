import React from "react";
import { Field, reduxForm } from "redux-form";
import i18n from "../../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import {
  ToggleContainer,
  ToggleText,
  AdditionalDetailsContainer,
  Fields,
} from "../permissions.styles";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const StudentsPermissions = () => {
  return (
    <Fields>
      <AdditionalDetailsContainer>
        <Field
          label="חיפוש תלמיד"
          component={renderToggle}
          name="studentSearch"
        />
        <Field
          label="יכול לצפות רק בתלמידים שלו"
          component={renderToggle}
          name="teacherOnlyHisStudentsSearch"
        />
        <Field
          label="צור תלמיד"
          component={renderToggle}
          name="createstudent"
        />
        <Field
          label="עדכון תלמיד"
          component={renderToggle}
          name="updateStudent"
        />
        <Field label="הסכמים" component={renderToggle} name="agreements" />
        <Field
          label="יצירת הסכם"
          component={renderToggle}
          name="createagreement"
        />
        <Field
          label="עריכת הסכם"
          component={renderToggle}
          name="updateagreement"
        />
        <Field
          label="מחיקת הסכם"
          component={renderToggle}
          name="deleteAgreement"
        />
        <Field
          label="הודעות כלליות"
          component={renderToggle}
          name="generalMessages"
        />
        <Field
          label="מקומות איסוף"
          component={renderToggle}
          name="pickupLocations"
        />
        <Field
          label="האם יכול לסמן שהכרטסת נבדקה"
          component={renderToggle}
          name="isAllowedTicketCheck"
        />
        <Field
          label="עדכון אובליגו"
          component={renderToggle}
          name="isAllowedToUpdateObligo"
        />
        <Field
          label="עדכון תיק לימוד"
          component={renderToggle}
          name="updateTextBook"
        />
        <Field
          label="מחיקת תיק לימוד"
          component={renderToggle}
          name="deleteTextBook"
        />
        <Field
          placeholder="כמות שיעורים ללא תיאוריה"
          component={InputWrappedWithField}
          name="lessonWithoutTheoryAmount"
        />
        <Field label="כרטסת" component={renderToggle} name="studentTicket" />
        <Field
          label="כרטסת מקוצרת"
          component={renderToggle}
          name="shortStudentTicket"
        />
      </AdditionalDetailsContainer>
    </Fields>
  );
};

export default reduxForm({
  form: "permissions", // a unique identifier for this form
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true, // this is needed!!
})(StudentsPermissions);
