import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  FieldText,
  Field,
  Table,
  Tr,
  Td,
  Th,
  Text,
  PrintFooter,
  InvoiceHeader,
  InvoiceCompanyNameHeader,
  Line,
} from "./cpa.styles";
import { connect } from "react-redux";
import { getAll } from "../../../actions/incomesAction";
import { getSchoolClients } from "../../../actions/clientsActions";
import { getStudentsBetweenDates } from "../../../actions/incomesAction";
import i18n from "../../../lib/i18n/i18n";
import { getUsers } from "../../../actions/userActions";
import { getSchoolFundingBodies } from "../../../actions/fundingBodiesActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const TeacherWeekly = ({
  user,
  getStudentsBetweenDates,
  getAll,
  clients,
  getSchoolClients,
  isClientsLoaded,
  getSchoolFundingBodies,
  fundingBodies,
}) => {
  const componentRef = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [hidePrintData, setHidePrintData] = useState(true);
  const [hideTotals, setHideTotals] = useState(false);
  const [sumData, setSumData] = useState({
    sumAmount: 0,
    sumAmountCash: 0,
    sumAmountCheck: 0,
    sumAmountWireTransfer: 0,
    sumAmountCredit: 0,
    sumAmountApp: 0,
    sumAmountSlika: 0
  });

  const [endDate, setEndDate] = useState(
    new Date().setDate(new Date().getDate() + 5)
  );

  useEffect(() => {
    async function fetchData() {
        await Promise.all([
          getSchoolClients(),
          getSchoolFundingBodies()
        ]);
    }
    fetchData();
  }, []);

  useEffect(() => {
    (async () => {
      if (isClientsLoaded) {
        let incomes = await getStudentsBetweenDates(
          new Date(startDate),
          new Date(endDate)
        );
        const incomesNotFromStudents = await getAll();
        const incomesFoundingBodies = await getAll(0, 1);
        incomes = [
          ...incomes,
          ...incomesNotFromStudents.map((x) => {
            const _client = clients.find((y) => y.id.toString() == x.clientId);
            return {
              ...x,
              clientId: _client ? _client.name : '',
              studentName: _client ? _client.clientId : '',
            }
          }),
          ...incomesFoundingBodies.map((x) => {
            const _fb = fundingBodies.find((y) => y.id.toString() == x.clientId);
            return {
              ...x,
              clientId: _fb ? _fb.pc : '',
              studentName: _fb ? _fb.name : '',
            }
          }),
        ]
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .filter(
            (x) =>
              moment(x.date).isSameOrBefore(moment(endDate).set("hour", 23)) &&
              moment(x.date).isSameOrAfter(moment(startDate).set("hour", 1))
          );
        setTableData(incomes);
        let sumAmount = 0;
        let sumReceipt = 0;
        let sumAmountCash = 0;
        let sumAmountCheck = 0;
        let sumAmountWireTransfer = 0;
        let sumAmountCredit = 0;
        let sumAmountApp = 0;
        let sumAmountSlika = 0;
        for (let index = 0; index < incomes.length; index++) {
          const element = incomes[index];
          let amount = 0;
          if (element.documentType === 5 || element.documentType === 3) {
            amount -= element.amount;
          } else {
            amount = element.amount;
          }
          if (element.documentType === 1 || element.documentType === 4) {
            sumReceipt += 1;
          } else {
            sumAmount += amount;

            if (element.paymentMethod === 0) {
              sumAmountCash += amount;
            } else if (element.paymentMethod === 1) {
              sumAmountCheck += amount;
            } else if (element.paymentMethod === 3) {
              sumAmountWireTransfer += amount;
            } else if (element.paymentMethod === 4) {
              sumAmountCredit += amount;
            } else if (element.paymentMethod === 5) {
              sumAmountApp += amount;
            } else if (element.paymentMethod === 6) {
              sumAmountSlika += amount;
            }
          }
        }

        setSumData({
          sumAmount,
          sumAmountCredit,
          sumAmountApp,
          sumAmountSlika,
          sumAmountCash,
          sumAmountCheck,
          sumAmountWireTransfer,
          sumReceipt,
        });
      }
    })();
  }, [startDate, endDate]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      setHidePrintData(false);
      return Promise.resolve();
    },
    onAfterPrint: () => {
      setHidePrintData(true);
      setHideTotals(false);
    },
  });

  const documentType = (documentTypeNumber) => {
    if (documentTypeNumber === 0) return "חשבונית/קבלה";
    else if (documentTypeNumber === 1) return "קבלה";
    else if (documentTypeNumber === 2) return "חשבונית";
    else if (documentTypeNumber === 3) return "חשבונית/קבלה זיכוי";
    else if (documentTypeNumber === 4) return "קבלה זיכוי";
    else if (documentTypeNumber === 5) return "חשבונית זיכוי";
    else if (documentTypeNumber === 6) return "הנחה";
    else if (documentTypeNumber === 7) return "חיוב";
  };

  const paymentType = (paymentMethod) => {
    if (paymentMethod === 0) return "מזומן";
    else if (paymentMethod === 1) return "שיק";
    else if (paymentMethod === 3) return "העברה";
    else if (paymentMethod === 4) return "אשראי";
    else if (paymentMethod === 5) return "אפליקציה";
    else if (paymentMethod === 6) return "סליקה";
  };

  const getName = (income) => {
    if ([5,3,2].includes(income.documentType)) return `${income.documentNumber} - ${documentType(income.documentType)}`;
    return `${income.documentNumber} - ${documentType(income.documentType)} | ${paymentType(income.paymentMethod)}`
  }

  return (
    <Container ref={componentRef}>
      {!hidePrintData && (
        <InvoiceHeader>
          <InvoiceCompanyNameHeader dir="auto">
            <span>{user.schoolFullName}</span>
          </InvoiceCompanyNameHeader>
          <br />
          <span>
            {user.isCompany ? "חפ:" : "עוסק מורשה:"} {user.bnNumber}
          </span>
          <span>
            {user.schoolPhone}, {user.schoolEmail}
          </span>
          <span>{user.schoolAddress}</span>
          <br />
          <br />
          <br />
          <Line />
        </InvoiceHeader>
      )}
      {hidePrintData && (
        <Row>
          <>
            <Field>
              <FieldText>תאריך סוף הדוח</FieldText>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                locale={i18n.language}
                startDate={startDate}
                dateFormat="dd/MM/yyyy"
                endDate={endDate}
                minDate={startDate}
              />
            </Field>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setHideTotals(true);
                handlePrint();
              }}
            >
              <FieldText>הדפס &nbsp;</FieldText>
              <i className="fa fa-print" aria-hidden="true" />
            </div>
            <div style={{ cursor: "pointer" }} onClick={handlePrint}>
              <FieldText>הדפס כולל סיכום&nbsp;</FieldText>
              <i className="fa fa-print" aria-hidden="true" />
            </div>
            <Field>
              <FieldText>תאריך התחלת הדוח</FieldText>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                dateFormat="dd/MM/yyyy"
                locale={i18n.language}
                endDate={endDate}
              />
            </Field>
          </>
        </Row>
      )}
      {!hidePrintData && (
        <Row style={{ justifyContent: "center" }}>
          <>
            <Field style={{ flexDirection: "row" }}>
              <span>עד {moment(endDate).format("MM / DD")}</span>
              <span>מ {moment(startDate).format("MM / DD")}</span>
              <span>דוח הכנסות</span>
            </Field>
          </>
        </Row>
      )}
      {(hideTotals || hidePrintData) && (
        <Table dir="rtl">
          <thead>
            <Tr>
              <Th>
                <Text>מס שורות</Text>
              </Th>
              <Th>
                <Text>{tableData.length}</Text>
              </Th>
              <Th>
                <Text>מזומן</Text>
              </Th>
              <Th>
                <Text>שיקים</Text>
              </Th>
              <Th>
                <Text>העברות</Text>
              </Th>
              <Th>
                <Text>אשראי</Text>
              </Th>
              <Th>
                <Text>אפליקציה</Text>
              </Th>
              <Th>
                <Text>סליקה</Text>
              </Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>ללא מעמ</Td>
              <Td>
                {(sumData.sumAmount / 1.17).toFixed(2).toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCash / 1.17).toFixed(2).toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCheck / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountWireTransfer / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCredit / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountApp / 1.17).toFixed(2).toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountSlika / 1.17).toFixed(2).toLocaleString("he")}
              </Td>
              <Td>סה״כ קבלות</Td>
              <Td>{sumData.sumReceipt}</Td>
            </Tr>
            <Tr>
              <Td>מעמ</Td>
              <Td>
                {(sumData.sumAmount - sumData.sumAmount / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCash - sumData.sumAmountCash / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCheck - sumData.sumAmountCheck / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(
                  sumData.sumAmountWireTransfer -
                  sumData.sumAmountWireTransfer / 1.17
                )
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCredit - sumData.sumAmountCredit / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountApp - sumData.sumAmountApp / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountSlika - sumData.sumAmountSlika / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
            </Tr>
            <Tr>
              <Td>כולל מעמ</Td>
              <Td>{sumData.sumAmount.toFixed(2).toLocaleString("he")}</Td>
              <Td>{sumData.sumAmountCash.toFixed(2).toLocaleString("he")}</Td>
              <Td>{sumData.sumAmountCheck.toFixed(2).toLocaleString("he")}</Td>
              <Td>
                {sumData.sumAmountWireTransfer.toFixed(2).toLocaleString("he")}
              </Td>
              <Td>{sumData.sumAmountCredit.toFixed(2).toLocaleString("he")}</Td>
              <Td>{sumData.sumAmountApp.toFixed(2).toLocaleString("he")}</Td>
              <Td>{sumData.sumAmountSlika.toFixed(2).toLocaleString("he")}</Td>
            </Tr>
          </tbody>
        </Table>
      )}
      <br />
      {hidePrintData && (
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={`דוח הכנסות ${moment(startDate).format(
            "DD/MM/YYYY"
          )} - ${moment(endDate).format("DD/MM/YYYY")}`}
          sheet="הכנסות"
          buttonText="יצא אקסל"
        />
      )}
      {!hideTotals && (
        <Table dir="rtl" id="table-to-xls">
          <thead>
            <Tr>
              <Th>
                <Text>תאריך</Text>
              </Th>
              <Th>
                <Text>מס.מסמך</Text>
              </Th>
              <Th>
                <Text>עבור</Text>
              </Th>
              <Th>
                <Text>חשבון</Text>
              </Th>
              <Th>
                <Text>מספר אישור</Text>
              </Th>
              <Th>
                <Text>רכב</Text>
              </Th>
              <Th>
                <Text>מורה</Text>
              </Th>
              <Th>
                <Text>ללא מעמ</Text>
              </Th>
              <Th>
                <Text>מעמ</Text>
              </Th>
              <Th>
                <Text>סהכ</Text>
              </Th>
            </Tr>
          </thead>
          <tbody>
            {tableData.map((x) => (
              <Tr type={x.documentType}>
                <Td>{moment(x.date).format("DD/MM/YYYY")}</Td>
                <Td>{getName(x)}</Td>
                <Td>
                  {x.studentName}
                  <br />
                  {x.clientId}
                </Td>
                <Td>{x.accountId || ''}</Td>
                <Td>{x.confirmationNumber || ''}</Td>
                <Td>
                  {x.vehicleNumber !== null ? x.vehicleNumber : "כל הרכבים"}
                </Td>
                <Td>{x.teacherName !== null ? x.teacherName : 'כל המורים'}</Td>
                <Td>
                  {x.documentType === 5 || x.documentType === 3 ? "-" : ""}
                  {[1,4].includes(x.documentType)
                    ? 0
                    : (x.amount / 1.17).toFixed(2).toLocaleString("he")}
                </Td>
                <Td>
                  {x.documentType === 5 || x.documentType === 3 ? "-" : ""}
                  {[1,4].includes(x.documentType)
                    ? 0
                    : (x.amount - x.amount / 1.17)
                        .toFixed(2)
                        .toLocaleString("he")}
                </Td>
                <Td>
                  {x.documentType === 5 || x.documentType === 3 ? "-" : ""}
                  {x.amount}
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
      {!hideTotals && !hidePrintData && (
        <Table dir="rtl" style={{ marginTop: "1vh" }}>
          <thead>
            <Tr>
              <Th>
                <Text>מס שורות</Text>
              </Th>
              <Th>
                <Text>{tableData.length}</Text>
              </Th>
              <Th>
                <Text>מזומן</Text>
              </Th>
              <Th>
                <Text>שיקים</Text>
              </Th>
              <Th>
                <Text>העברות</Text>
              </Th>
              <Th>
                <Text>אשראי</Text>
              </Th>
              <Th>
                <Text>אפליקציה</Text>
              </Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>ללא מעמ</Td>
              <Td>
                {(sumData.sumAmount / 1.17).toFixed(2).toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCash / 1.17).toFixed(2).toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCheck / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountWireTransfer / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCredit / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountApp / 1.17).toFixed(2).toLocaleString("he")}
              </Td>
              <Td>סה״כ קבלות</Td>
              <Td>{sumData.sumReceipt}</Td>
            </Tr>
            <Tr>
              <Td>מעמ</Td>
              <Td>
                {(sumData.sumAmount - sumData.sumAmount / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCash - sumData.sumAmountCash / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCheck - sumData.sumAmountCheck / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(
                  sumData.sumAmountWireTransfer -
                  sumData.sumAmountWireTransfer / 1.17
                )
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountCredit - sumData.sumAmountCredit / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
              <Td>
                {(sumData.sumAmountApp - sumData.sumAmountApp / 1.17)
                  .toFixed(2)
                  .toLocaleString("he")}
              </Td>
            </Tr>
            <Tr>
              <Td>כולל מעמ</Td>
              <Td>{sumData.sumAmount.toFixed(2).toLocaleString("he")}</Td>
              <Td>{sumData.sumAmountCash.toFixed(2).toLocaleString("he")}</Td>
              <Td>{sumData.sumAmountCheck.toFixed(2).toLocaleString("he")}</Td>
              <Td>
                {sumData.sumAmountWireTransfer.toFixed(2).toLocaleString("he")}
              </Td>
              <Td>{sumData.sumAmountCredit.toFixed(2).toLocaleString("he")}</Td>
              <Td>{sumData.sumAmountApp.toFixed(2).toLocaleString("he")}</Td>
            </Tr>
          </tbody>
        </Table>
      )}
      {!hidePrintData && (
        <PrintFooter>
          הופק באמצעות קמע מחשבים סיון בע"מ. טלפון 0537-222-228
        </PrintFooter>
      )}
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    user: state.session.user,
    events: state.CalendarReducer.events,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    clients: state.ClientsReducer.clients,
    isClientsLoaded: state.ClientsReducer.isLoaded,
    fundingBodies: state.FundingBodiesReducer.fundingBodies,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getStudentsBetweenDates,
  getAll,
  getSchoolClients,
  getSchoolFundingBodies,
})(TeacherWeekly);
