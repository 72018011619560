import React, { PureComponent } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import ErrorModal from "../Modal/ErrorModal";
import NewLesson from "./NewLesson/NewLesson";
import NewCharge from "./NewCharge/NewCharge";
import UpdatePickupLocation from "./UpdatePickupLocation/UpdatePickupLocation";
import NoteChange from "./NoteChange/NoteChange";
import EventStatusChange from "./EventStatusChange/EventStatusChange";
import TeacherChange from "./TeacherChange/TeacherChange";
import VehicleChange from "./VehicleChange/VehicleChange";
import EditEvent from "./EditEvent/EditEvent";
import Day from "react-big-calendar/lib/Day";
import StudentMenu from "./StudentMenu/StudentMenu";
import Chip from '@material-ui/core/Chip';
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../../App.css';
import { MobileView, BrowserView, MobileOnlyView, TabletView } from "react-device-detect";
import {
  Container,
  UserOptionsContainer,
  customStyles,
  HeaderContainer,
  Logo,
  LogoContainer,
  LoggedUserContainer,
  LoggedUserName,
  StickyHeader,
  StickyBody,
  StickyBodyMobile,
  TTContainer,
  Icon
} from "./calendar.styles";
import {
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  createEvent,
} from "../../actions/schedulerActions";
import SivanPlusLogo from "../App/_layouts/default/Header/sivanLogo.png";
import { isMobileOnly, isTablet, isMobile } from "react-device-detect";
import { getStudents } from "../../actions/studentesActions";
import EventDayView from "./CalendarViews/EventDayView";
import EventBikeDayView from "./CalendarViews/EventBikeDayView";
import EventAgendaView from "./CalendarViews/EventAgendaView";
import EventMonthView from "./CalendarViews/EventMonthView";
import EventWorkWeekView from "./CalendarViews/EventWorkWeekView";
import EventWeekView from "./CalendarViews/EventWeekView";
import StudentBasicInfo from "./StudentBasicInfo/StudentBasicInfo";
import PrintCalendar from "./PrintCalendar/PrintCalendar";
import StudentSignature from "./StudentSignature/StudentSignature";
import { getUsers } from "../../actions/userActions";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/he";
import Select from "react-select";
import Button from "../UiComponents/Buttons/Default";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toggle from "react-toggle";
import Input from "../UiComponents/Inputs/Default";
import { getVehicles } from "../../actions/vehicleActions";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';

import PrintIcon from '../../images/print.ico';
import AddIncomeIcon from '../../images/newcharge.ico';
import ReCalculateIcon from '../../images/recalculate.ico';
import CreateEventIcon from '../../images/addevent.ico';
import { sendWhatsAppEventMessage } from "../../utils/events";

const localizer = momentLocalizer(moment);
const messages = {
  allDay: "כל היום",
  previous: "הקודם",
  next: "הבא",
  today: "היום",
  month: "חודש",
  week: "שבוע",
  work_week: "שבוע עבודה",
  day: "יום",
  agenda: "אגנדה",
  date: "תאריך",
  time: "שעה",
  event: "אירוע",
  myweek: "יומי אופנועים",
};

class Calendara extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isNewLessonModalOpen: false,
      isStudentMenuModalOpen: false,
      isErrorModalOpen: false,
      errorModalText: "",
      isEditLessonModalOpen: false,
      isNewChargeModalOpen: false,
      isPickupModalOpen: false,
      isNotesModalOpen: false,
      teacherOptions: [],
      teacherOptionsUnfiltered: [],
      selectedTeacher: { value: -999, label: "הכל" },
      selectedVehicle: null,
      isEventStatusChangeModalOpen: false,
      isChangeTeacherModalOpen: false,
      isStudentSignatureModalOpen: false,
      isChangeVehicleModalOpen: false,
      blockText: "",
      isBlockActive: false,
      isPrintOpen: false,
      selectedEvent: null,
      defaultView: props.user.defaultView,
      currentDate: new Date(),
      students: [],
      vehicleOptions: [],
      chosenStudent: null,
      studentOptions: [],
      isStudentInfoShowOpen: !isTablet,
      views: {
        day: true,
        month: true,
        week: EventWorkWeekView,
        agenda: true,
      },
      customDateChange: new Date(Date.now()),
      customDateChangeChanged: false,
      selectedCalendarDate: new Date(Date.now()),
      allEvents: [],
      studentToBold: {
        value: '0000',
        label: ''
      },
      event: null,
      selectedTeachersForCalendar: [],
      teacherNameByValue: {},
    };
  }

  componentWillMount = () => {
    if (this.props.user.showDailyBikes) {
      this.setState({ views: { ...this.state.views, myweek: Day } });
    }
  };

  dayify = (date) => {
    return new Date(date).getFullYear() + '-' + (new Date(date).getMonth() + 1).toString() + '-' + new Date(date).getDay();
  }

  getAllEventsRelevant = () => {
    this.props.getAllSchoolEvents().then(events => {
      let a = events;
      a = a.filter((event_) => { return this.dayify(event_.start) === this.dayify(this.state.selectedCalendarDate) })
      this.setState({allEvents: a});
    });
  }


  getBlackList = () => {
    const blacklist = window.localStorage.getItem('blacklistids');
    if (blacklist === null) return [];
    else {
      return blacklist.split(',');
    }
  }


  removeFromBlackList = (remove) => {
    let blacklist = this.getBlackList();
    var index = blacklist.indexOf(remove);
    if (index !== -1) {
      blacklist.splice(index, 1);
    }
    // console.log('new blacklist:', blacklist);
    window.localStorage.setItem('blacklistids', blacklist);
    this.componentDidMountRun();
  }

  addToBlackList = (add) => {
    let blacklist = this.getBlackList();
    if (!blacklist.includes(add)) blacklist.push(add);
    // console.log('new blacklist:', blacklist);
    window.localStorage.setItem('blacklistids', blacklist);
    this.componentDidMountRun();
  }

  componentDidMountRun = async () => {
    if (this.props.isLoggedIn) {
      this.getAllEventsRelevant()
      this.getAllStudents();
      if (!this.props.isHolidaysLoaded) {
        await this.props.loadHolidays();
      }
      if (!this.props.isUsersLoaded) {
        await this.props.getUsers();
      }
      if (!this.props.isVehiclesLoaded) {
        await this.props.getVehicles();
      }
      const vehicleOptions = this.props.vehicles
        .filter((x) => x.active === 1)
        .map((x) => ({ value: x.id, label: x.description }));
      const getBlackList = this.getBlackList()
      const teacherOptionsUnfiltered = [
        { value: -999, label: "הכל" },
        ...this.props.users
          .filter(
            (x) => (x.userType === 1 || x.userType === 4) && x.isActive === 1
          )
          .map((x) => ({
            value: x.id,
            label: `${x.firstName} ${x.lastName}`,
          })),
      ]
      const teacherOptions = [
        { value: -999, label: "הכל" },
        ...this.props.users
          .filter(
            (x) => (x.userType === 1 || x.userType === 4) && x.isActive === 1
          )
          .map((x) => ({
            value: x.id,
            label: `${x.firstName} ${x.lastName}`,
          })),
      ].filter((teacher) => !getBlackList.includes(teacher.value.toString()));
      let teacherNameByValue = {};
      teacherOptions.forEach((item, i) => {
        teacherNameByValue[item.value.toString()] = item.label;
      });
      this.setState({teacherNameByValue: teacherNameByValue});
      this.setTeachersFilter(teacherOptions);
      this.setState({
        teacherOptions,
        vehicleOptions,
        teacherOptionsUnfiltered
      });
      if (this.props.user.userType !== 555) {
        const calendarUser = window.localStorage.getItem("calendarUser");
        if (calendarUser && JSON.parse(calendarUser) !== null) {
          const parsedCalendarUser = JSON.parse(calendarUser);
          try {
            const index = this.props.users.findIndex(
              (x) => x.id === parsedCalendarUser[0].value
            );
            if (index === -1) {
              await this.handleTeacherChange([
                {
                  value: this.props.session.user.id,
                  label: `${this.props.session.user.firstName} ${this.props.session.user.lastName}`,
                },
              ]);
            } else {
              const localStorageDate = window.localStorage.getItem("date");
              if (localStorageDate) {
                this.setState({ currentDate: new Date(localStorageDate) });
              }
              await this.handleTeacherChange(parsedCalendarUser);
            }
          } catch(err) {

          }
        } else {
          await this.handleTeacherChange([
            {
              value: this.props.session.user.id,
              label: `${this.props.session.user.firstName} ${this.props.session.user.lastName}`,
            },
          ]);
        }
      }
      if (!this.props.isStudentsLoaded) {
        await this.props.getStudents();
        this.setState({
          studentOptions: this.props.students.map((x) => ({
            value: x.studentId,
            label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
          })),
        });
      } else {
        // console.log(this.props.students);
      }
    }
  }

  componentDidMount = async () => {
    this.componentDidMountRun();
  };

  shouldShowTeacher = (teacherValue) => {
    return this.state.selectedTeachersForCalendar.includes(teacherValue);
  }

  filterArrayForRelevantOnly = (array) => {
    // let _ = [];
    // array.forEach((item, i) => {
    //   if (this.state.selectedTeachersForCalendar.includes(item.value.toString()) && item.value != -999) _.push(item);
    // });
    // return _;
    return array;
  }

  setTeachersFilter = (teacherOptions) => {
    const db = window.localStorage.getItem('selectedTeachersForCalendar');
    if (db != null && db != undefined && db != 'undefined' && db != '') {
      // console.log('db exists');
      this.setState({selectedTeachersForCalendar: db.split(',').filter((item) => { return item !== '-999' && item !== -999 })});
    } else {
      // console.log('creating db');
      teacherOptions = teacherOptions.filter((item) => { return item !== '-999' && item !== -999 });
      window.localStorage.setItem('selectedTeachersForCalendar', teacherOptions.map((item) => { return item.value }));
      this.setState({selectedTeachersForCalendar: teacherOptions.map((item) => { return item.value })});
    }
  }

  howManyRemoved = () => {
    const t1 = this.state.teacherOptions.length;
    const t2 = this.state.selectedTeachersForCalendar.length;
    let _ = [];
    const num = t1 - t2 - 1;
    for (let x = 0; x < num; x++) {
      _.push('x')
    }
    return _;
  }

  addRemoveTeacherToDB = (teacherValue) => {
    teacherValue = teacherValue.toString();
    try {
      let teacherOptionsDB = window.localStorage.getItem('selectedTeachersForCalendar').split(',');
      teacherValue = teacherValue.toString();
      if (teacherOptionsDB.includes(teacherValue)) {
        // console.log('exists');
        let index = teacherOptionsDB.indexOf(teacherValue);
        if (index !== -1) {
          teacherOptionsDB.splice(index, 1);
        }
        this.setState({selectedTeachersForCalendar: teacherOptionsDB});
        window.localStorage.setItem('selectedTeachersForCalendar', teacherOptionsDB);
      } else {
        // console.log('not exists');
        let a = teacherOptionsDB;
        a.push(teacherValue);
        this.setState({selectedTeachersForCalendar: a});
        window.localStorage.setItem('selectedTeachersForCalendar', a);
      }
      // console.log(teacherValue);
      // console.log(teacherOptionsDB);
    } catch (err) {
      console.log(err);
    }
  }
  setteacherOptionsFromDB = () => {
  }

  closeModal = (type) => this.setState({ [type]: false });
  openModal = (type, selectedEvent = null) =>
    this.setState({ [type]: true, selectedEvent });

  closeEditLessonModalOpen = () =>
    this.setState({ isEditLessonModalOpen: false });

  setEventColors = (eventType, eventStatus) => {
    const {
      fontLessonColor,
      lessonColor,
      doneLessonColor,
      fontDoneLessonColor,
      paidLessonColor,
      fontPaidLessonColor,
      canceledLessonColor,
      fontCanceledLessonColor,
      canceledAndPaidLessonColor,
      fontCanceledAndPaidLessonColor,
      testColor,
      fontTestColor,
      doneTestColor,
      fontDoneTestColor,
      paidTestColor,
      fontPaidTestColor,
      canceledTestColor,
      fontCanceledTestColor,
      canceledAndPaidTestColor,
      fontCanceledAndPaidTestColor,
      internalTestColor,
      fontInternalTestColor,
      doneInternalTestColor,
      fontDoneInternalTestColor,
      paidInternalTestColor,
      fontPaidInternalTestColor,
      canceledInternalTestColor,
      fontCanceledInternalTestColor,
      canceledAndPaidInternalTestColor,
      fontCanceledAndPaidInternalTestColor,
      meetingColor,
      fontMeetingColor,
      doneMeetingColor,
      fontDoneMeetingColor,
      canceledMeetingColor,
      fontCanceledMeetingColor,
    } = this.props.user;
    let color = "";
    let backgroundColor = "";
    if (eventType === 1) {
      const params = this.eventColors(
        lessonColor,
        fontLessonColor,
        doneLessonColor,
        fontDoneLessonColor,
        paidLessonColor,
        fontPaidLessonColor,
        canceledLessonColor,
        fontCanceledLessonColor,
        canceledAndPaidLessonColor,
        fontCanceledAndPaidLessonColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 2) {
      const params = this.eventColors(
        testColor,
        fontTestColor,
        doneTestColor,
        fontDoneTestColor,
        paidTestColor,
        fontPaidTestColor,
        canceledTestColor,
        fontCanceledTestColor,
        canceledAndPaidTestColor,
        fontCanceledAndPaidTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 3) {
      const params = this.eventColors(
        internalTestColor,
        fontInternalTestColor,
        doneInternalTestColor,
        fontDoneInternalTestColor,
        paidInternalTestColor,
        fontPaidInternalTestColor,
        canceledInternalTestColor,
        fontCanceledInternalTestColor,
        canceledAndPaidInternalTestColor,
        fontCanceledAndPaidInternalTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 4) {
      const params = this.eventColors(
        meetingColor,
        fontMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        eventStatus
      );
      backgroundColor = params.backgroundColor;
      color = params.color;
    }

    return { backgroundColor, color };
  };

  eventColors = (
    orderBackground,
    orderFontColor,
    doneBackground,
    doneFontColor,
    paidBackground,
    paidFontColor,
    canceledBackground,
    canceledFontColor,
    canceledAndPaidBackground,
    canceledAndPaidPaidFontColor,
    eventStatus
  ) => {
    if (eventStatus === 0) {
      return { backgroundColor: orderBackground, color: orderFontColor };
    } else if (eventStatus === 1) {
      return { backgroundColor: doneBackground, color: doneFontColor };
    } else if (eventStatus === 2) {
      return { backgroundColor: paidBackground, color: paidFontColor };
    } else if (eventStatus === 3) {
      return { backgroundColor: canceledBackground, color: canceledFontColor };
    } else if (eventStatus === 4) {
      return {
        backgroundColor: canceledAndPaidBackground,
        color: canceledAndPaidPaidFontColor,
      };
    }
  };
  eventStyleGetter = (event, start, end, isSelected) => {
    const b = this.setEventColors(event.eventType, event.eventStatus);
    var style = {
      borderRadius: "0px",
      border: "0px",
      display: "block",
      borderLeft: "3px solid",
      borderTop: "1px solid",
      borderRight: "1px solid",
      ...b,
    };
    return {
      style: style,
    };
  };

  handleTeacherChange = async (selectedTeacher) => {
    this.setState({ selectedTeacher });
    if (selectedTeacher) {
      window.localStorage.setItem(
        "calendarUser",
        JSON.stringify(selectedTeacher)
      );

      if (
        !selectedTeacher ||
        selectedTeacher.findIndex((x) => x.value === -999) !== -1
      ) {
        await this.props.getAllSchoolEvents();
      } else {
        await this.props.getEvents(selectedTeacher.map((x) => x.value));
      }
    }
  };

  handleVehicleChange = async (selectedVehicle) => {
    this.setState({ selectedVehicle });
  };

  createBlockEvent = async (slot) => {
    this.setState({chosenStudent: '', chosenStudentId: '', selectedTeacher: { value: -999, label: "הכל" }}); // reset
    const { blockText, selectedTeacher, teacherOptions } = this.state;
    const isAllIndex = selectedTeacher.findIndex((x) => x.value === -999);
    let teacherList = selectedTeacher;
    if (isAllIndex !== -1) {
      teacherList = teacherOptions;
    }
    const eventsToCreate = [];
    for (let index = 0; index < teacherList.length; index++) {
      const element = teacherList[index];
      eventsToCreate.push(
        this.props.createEvent({
          notes: blockText,
          eventType: { value: 4, label: "פגישה" },
          updateUsername: `${this.props.user.firstName} ${this.props.user.lastName}`,
          teacherId: element.value,
          pickupLocationId: { value: 999, label: "ללא" },
          start: slot.start,
          end: slot.end,
        })
      );

    }

    await Promise.all(eventsToCreate);
  };

  modifyNameA = (name) => {
    try {
      if (name.includes('אוטומט') && name.includes('B')) return 'BA';
      else if (name.includes('ידני') && name.includes('B')) return 'B';
      else if (name.includes('אוטומט') && name.includes('A1')) return 'A1A';
      else return name;
    } catch (err) {
      return name;
    }
  }
  getAllStudents = async () => {
    let s = this.props.students.filter((student) => { return student.active === 2 || student.active === 1});
    let s_ = [];
    s.forEach((item, i) => {
      s_.push({
        label: item.firstName + ' ' + item.lastName + ' - ' + this.modifyNameA(item.licenseType),
        value: item.id
      })
    });

    await this.props.getStudents();
    this.setState({
      students: s_,
    }, () => {
      // console.log('students:', this.state.students);
    });
  }
  render() {
    const {
      isNewLessonModalOpen,
      selectedEvent,
      isPickupModalOpen,
      defaultView,
      isEventStatusChangeModalOpen,
      currentDate,
      isErrorModalOpen,
      errorModalText,
      teacherOptions,
      selectedTeacher,
      isNotesModalOpen,
      isStudentMenuModalOpen,
      isNewChargeModalOpen,
      studentOptions,
      chosenStudent,
      isChangeTeacherModalOpen,
      isChangeVehicleModalOpen,
      isStudentInfoShowOpen,
      isPrintOpen,
      views,
      selectedVehicle,
      isStudentSignatureModalOpen,
      vehicleOptions,
      teacherOptionsUnfiltered
    } = this.state;
    const { events, holidays, user } = this.props;
    const filteredEvents =
      selectedVehicle === null || selectedVehicle.length === 0
        ? [...events, ...holidays]
        : [
            ...events.filter(
              (x) =>
                selectedVehicle
                  .map((x) => x.value.toString())
                  .indexOf(x.vehicleId) !== -1
            ),
            ...holidays,
          ];

    const hours = (new Date(user.calendarEndTime).getHours() - new Date(user.calendarStartTime).getHours());
    const hoursArray = Array.from(Array(hours).keys())
    const startAt = new Date(user.calendarStartTime).getHours();
    const emptyTeachersArray = Array.from(Array(teacherOptions.length).keys())
    const getTimeIn = (i, startAt, t) => {
      let t_ = startAt + i;
      if (user.calendarSpacingInMinutes === 60 || user.calendarSpacingInMinutes === '60') {
        t_ = startAt + i + 1;
        return t_ + ':' + '00';
      } else {
        t_ = t_.toString() + ':';
        if (t === 0) {
          return t_ + user.calendarSpacingInMinutes;
        } else {
          return t_ + '00';
        }
      }
    }
    const modifyName = (name) => {
      try {
        if (name.includes('אוטומט') && name.includes('B')) return 'BA';
        else if (name.includes('ידני') && name.includes('B')) return 'B';
        else if (name.includes('אוטומט') && name.includes('A1')) return 'A1A';
        else return name;
      } catch (err) {
        return name;
      }
    }
    const findEvent = (allTime, teacher, t) => {
      let hour = parseInt(allTime.split(':')[0])

      const allTimeMinutes = allTime.split(':')[1];
      let isTimeAvailableForThisBlock = false;
      // if ((t === 1 && ))
      // console.log('t:', t);
      let selectedCalendarDate = new Date(this.state.selectedCalendarDate);

      const ev_ = this.state.allEvents.find((event_) => {
        let completeDate = new Date(event_.start);
        // console.log('teacher:', teacher);
        // if (event_.userId.toString() === teacher.toString()) {
        if (event_.teacherId.toString() === teacher.toString()) {
          if (
            completeDate.getFullYear() === selectedCalendarDate.getFullYear() &&
            completeDate.getMonth() === selectedCalendarDate.getMonth() &&
            completeDate.getDate() === selectedCalendarDate.getDate()
          ) {

            let date = new Date(event_.start).getHours();
            let min_t = new Date(event_.start).getMinutes();

            const event_start_hours = new Date(event_.start).getHours();
            const event_start_minutes = new Date(event_.start).getMinutes();

            const event_end_hours = new Date(event_.end).getHours();
            const event_end_minutes = new Date(event_.end).getMinutes();

            // if ((t === 1 && min_t <= parseInt(allTimeMinutes)) || (t === 0 && min_t >= parseInt(allTimeMinutes))) {
              if (
                new Date(event_.start).getHours() <= parseInt(hour) &&
                new Date(event_.end).getHours() >= parseInt(hour)
              ) {
                // if (new Date(event_.start).getMinutes() <= parseInt(allTimeMinutes) && new Date(event_.end).getMinutes() >= parseInt(allTimeMinutes)) {
                  if (event_ !== null && event_ !== undefined) {
                    if (
                      hour === new Date(event_.start).getHours()
                    ) {
                      if (allTimeMinutes >= new Date(event_.start).getMinutes()) {
                        return event_;
                      }
                    } else if (hour === new Date(event_.end).getHours()) {
                      if (allTimeMinutes <= new Date(event_.start).getMinutes()) {
                        return event_;
                      }
                    } else {
                      return event_;
                    }
                  }
                // }
              }
            // } else {
            //   return null;
            // }


          }
        }
      });
      if (ev_ === null || ev_ === undefined) {
        return ''
      } else {
        if (ev_.firstName !== null) {
          const startAt = new Date(ev_.start).getHours().toString().padStart(2, '0') + ':' + new Date(ev_.start).getMinutes().toString().padStart(2, '0')
          const endAt = new Date(ev_.end).getHours().toString().padStart(2, '0') + ':' + new Date(ev_.end).getMinutes().toString().padStart(2, '0')
          const timeString = '('+startAt + '-' + endAt+')'
          return (
            <>
              {ev_.firstName + ' ' + ev_.lastName === this.state.studentToBold.label ? (
                <p style={{direction: 'rtl', color: 'blue', fontWeight: 'bold'}}>{ev_.firstName + ' ' + ev_.lastName + ' - ' + modifyName(ev_.name)} <span style={{fontSize: '13px'}}>{timeString}</span></p>
              ) : (
                <p style={{direction: 'rtl'}}>{ev_.firstName + ' ' + ev_.lastName + ' - ' + modifyName(ev_.name)} <span style={{fontSize: '13px'}}>{timeString}</span></p>
              )}
            </>
          );
        }

      }
    }
    const findEventObject = (allTime, teacher, t) => {
      if (teacher !== '-') {
        let hour = parseInt(allTime.split(':')[0])
        const allTimeMinutes = allTime.split(':')[1];
        let selectedCalendarDate = new Date(this.state.selectedCalendarDate);
        const ev_ = this.state.allEvents.find((event_) => {
          let completeDate = new Date(event_.start);
          // if (event_.userId.toString() === teacher.toString()) {
          if (event_.teacherId.toString() === teacher.toString()) {
            if (
              completeDate.getFullYear() === selectedCalendarDate.getFullYear() &&
              completeDate.getMonth() === selectedCalendarDate.getMonth() &&
              completeDate.getDate() === selectedCalendarDate.getDate()
            ) {

              let date = new Date(event_.start).getHours();
              let min_t = new Date(event_.start).getMinutes();
              let min_te = new Date(event_.end).getMinutes();

              // if ((t === 1 && min_t <= parseInt(allTimeMinutes)) || (t === 0 && min_t >= parseInt(allTimeMinutes))) {
                if (
                  new Date(event_.start).getHours() <= parseInt(hour) && new Date(event_.end).getHours() >= parseInt(hour)
                ) {
                  // if ((t === 1 && min_te <= parseInt(allTimeMinutes)) || (t === 0 && min_t >= parseInt(allTimeMinutes))) {
                    if (event_ !== null && event_ !== undefined) {
                      return event_;
                    }
                  // }
                }
              // } else {
              //   return null;
              // }
            }
          }
        });
        if (ev_ === null || ev_ === undefined) {
          return null;
        } else {
          return ev_;
        }
      } else {
          return null;
      }
    }
    let b = {};
    let bObject = {};
    teacherOptions.map((teacher, i) => {
      if (teacher.value !== -999) {
        b[i] = teacher.value;
        bObject[i] = teacher.label;
      }
    });
    const bb = b;

    const findMyTeacherCell = (i) => {
      try {
        return bb[i];
      } catch (err) {
        return '-';
      }
      // return b[i].label + '>'+i;
    }
    const findMyTeacherCellObject = (i) => {
      try {
        return {
          value: bb[i],
          label: bObject[i]
        };
      } catch (err) {
        return '-';
      }
      // return b[i].label + '>'+i;
    }

    const nextDay = () => {
      let d = this.state.selectedCalendarDate;
      let tomorrow = new Date(d);
      tomorrow.setDate(tomorrow.getDate()+1);
      this.setState({selectedCalendarDate: new Date(tomorrow)}, () => {
        this.getAllEventsRelevant();
      })
    }
    const previousDay = () => {
      let d = this.state.selectedCalendarDate;
      let yesterday = new Date(d);
      yesterday.setDate(yesterday.getDate()-1);
      this.setState({selectedCalendarDate: new Date(yesterday)}, () => {
        this.getAllEventsRelevant();
      })
    }
    const today = () => {
      this.setState({selectedCalendarDate: new Date(Date.now())},() => {
        this.getAllEventsRelevant();
      })
    }
    const formatPhoneForWhatsapp = (phoneNumber) => {
      phoneNumber = phoneNumber.trim();
      phoneNumber = phoneNumber.replaceAll('-', '');
      if (phoneNumber.includes('+972')) return phoneNumber;
      else if (phoneNumber.includes('972')) return '+' + phoneNumber;
      else {
        return '+972' + phoneNumber;
      }
    }
    // const filterOnlyTeachers = teacherOptions.filter((item) => { return window.localStorage.getItem('selectedTeachersForCalendar').toString().includes(item.value.toString())});
    return (
      <TTContainer>
      {this.state.isNewLessonModalOpen && (
        <NewLesson
          isNewAgreementModalOpen={this.state.isNewLessonModalOpen}
          setNewAgreementModalIsOpen={() =>
            this.closeModal("isNewLessonModalOpen")
          }
          onFinish={() => this.onFinish()}
          selectedEvent={selectedEvent}
          step={new Date('Sun Mar 06 2022 05:00:00 GMT+0200 (Israel Standard Time)')}
          studentFromCalendar={null}
          selectedTeacher={this.state.isTeacherInInput ? this.state.selectedTeacher : []}
          selectedVehicle={this.state.isCarInInput ? this.state.selectedVehicle : []}
        />
      )}
      {this.state.isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={this.state.isEditLessonModalOpen}
          setNewAgreementModalIsOpen={() => this.setState({isEditLessonModalOpen: false})}
          step={this.state.eventTime}
          selectedEvent={{
            ...this.state.event,
            name: this.state.event.name
              ? this.state.event.name
              : `${this.state.event.studentId} - ${this.state.event.firstName} ${this.state.event.lastName}`,
          }}
        />
      )}
        <br />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            <div>
              {this.state.students && (
                <Select
                  options={this.state.teacherOptions}
                  isMulti={false}
                  value={this.state.teacherToAdd}
                  placeholder="מורה"
                  styles={customStyles}
                  isRtl={true}
                  onChange={(e) => this.setState({studentToBold: e})}
                  style={{
                    minWidth: '100%'
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <span>{new Date(this.state.selectedCalendarDate).toLocaleString('he-il', {weekday: 'long'})} {new Date(this.state.selectedCalendarDate).getDate() + '/' + (new Date(this.state.selectedCalendarDate).getMonth() + 1).toString() + '/' + new Date(this.state.selectedCalendarDate).getFullYear()}</span>
            &nbsp;
            <input lang="en-US" type="date" value={new Date(this.state.selectedCalendarDate)} onChange={(e) => this.setState({selectedCalendarDate: e.target.value}, () => {
              this.getAllEventsRelevant();
            })}/>
            <br />
            <span></span>
          </div>
          <div>
            <button onClick={(e) => today()} style={{backgroundColor: '#89CFF0', fontSize: '1.5em', width: 70}}>{'היום'}</button>
            &nbsp;&nbsp;
            <button onClick={() => nextDay()} style={{backgroundColor: '#89CFF0', fontSize: '1.5em', width: 70}}>{'<'}</button>
            &nbsp;&nbsp;
            <button onClick={() => previousDay()} style={{backgroundColor: '#89CFF0', fontSize: '1.5em', width: 70}}>{'>'}</button>
            &nbsp;&nbsp;
          </div>
        </div>
        <br />
        <center>
          {teacherOptionsUnfiltered.map((teacher => {
            const blacklist = this.getBlackList();
            if (teacher.value !== -999) {
              return (
                <>&nbsp;
                  <Chip
                  label={teacher.label}
                  deleteIcon={!blacklist.includes(teacher.value.toString()) ? <ClearIcon style={{marginRight: 5}} /> : <AddIcon style={{marginRight: 5}} />}
                  onDelete={(e) => blacklist.includes(teacher.value.toString()) ? this.removeFromBlackList(teacher.value.toString()) : this.addToBlackList(teacher.value.toString())}
                  value={teacher.value.toString()} />
                &nbsp;</>
              );
            }
          }))}
        </center>
        <br />
        <div className="table_wrapper">
          <table className="table" style={{position: 'sticky',
  borderCollapse: 'collapse' }}>
            <tr className="header">
              {this.filterArrayForRelevantOnly(teacherOptions).map((teacher, i) => {
                if (teacher.value !== -999) {
                  return (
                    <th style={{position: 'sticky', top: 0, zIndex: 10}}>{teacher.label}</th>
                  )
                }
              })}
              <th style={{position: 'sticky', top: 0, zIndex: 10}}>שעה</th>
            </tr>
            {hoursArray.map((hour, i) => {
              return (
                <>
                <tr>
                  {this.filterArrayForRelevantOnly(teacherOptions).reverse().map((t_, f_) => {
                    if (f_ !== 0) {
                      if (findEvent(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1) === '' || findEvent(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1) === null || findEvent(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1) === {} || findEvent(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1) === undefined || findEvent(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1) === '-') {
                        return (
                          <td onClick={(e) => {
                            this.setState({
                              selectedTeacher: findMyTeacherCellObject(f_),
                              isTeacherInInput: true
                            }, () => {
                              const dateS = new Date(this.state.selectedCalendarDate);
                              dateS.setHours(getTimeIn(i, startAt, 1).split(':')[0]);
                              dateS.setMinutes(getTimeIn(i, startAt, 1).split(':')[1]);
                              this.openModal("isNewLessonModalOpen", {
                                start: new Date(dateS),
                                slots: [{}, {}],
                                selectedTeacher: [findMyTeacherCellObject(f_)]
                              })
                            })
                          }}>{findEvent(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1)}</td>
                        )
                      } else {
                        const ev1 = findEventObject(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1);
                        if (ev1 !== null) {
                          return (
                            <td onClick={(e) => {
                              this.setState({isEditLessonModalOpen: true, event: findEventObject(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1)});
                            }}><Icon
                              className="icons8-whatsapp"
                              onClick={() => sendWhatsAppEventMessage(ev1, ev1, this.props.user)}
                              data-tip={`<span dir="auto">${ev1.firstName} ${
                                ev1.lastName
                              }</span><br/><span dir="auto">${
                                ev1.address ? ev1.address : ""
                              }, ${
                                ev1.city ? ev1.city : ""
                              }</span><br/><span dir="auto">${ev1.phone}</span>`}
                              data-for={ev1.id.toString()}
                            /> {findEvent(getTimeIn(i, startAt, 1), findMyTeacherCell(f_), 1)}</td>
                          )
                        } else {
                          return <td></td>
                        }

                      }
                    }
                  })}
                  <td>{getTimeIn(i, startAt, 1)}</td>
                </tr>
                <tr>
                  {this.filterArrayForRelevantOnly(teacherOptions).reverse().map((t_, f_) => {
                    if (f_ !== 0) {
                      if (findEvent(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0) === '' || findEvent(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0) === null || findEvent(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0) === {} || findEvent(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0) === undefined || findEvent(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0) === '-') {
                        return (
                          <td onClick={(e) => {
                            this.setState({
                              selectedTeacher: findMyTeacherCellObject(f_),
                              isTeacherInInput: true
                            }, () => {
                              const dateS = new Date(this.state.selectedCalendarDate);
                              dateS.setHours(getTimeIn(i, startAt, 0).split(':')[0]);
                              dateS.setMinutes(getTimeIn(i, startAt, 0).split(':')[1]);
                              this.openModal("isNewLessonModalOpen", {
                                start: new Date(dateS),
                                slots: [{}, {}],
                                selectedTeacher: [findMyTeacherCellObject(f_)]
                              })
                            })
                          }}>{findEvent(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0)}</td>
                        )
                      } else {
                        const ev1 = findEventObject(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0);
                        if (ev1 !== null) {
                          return (
                            <td onClick={(e) => {

                              this.setState({isEditLessonModalOpen: true, event: findEventObject(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0)});
                            }}><Icon
                              className="icons8-whatsapp"
                              onClick={() => sendWhatsAppEventMessage(ev1, ev1, this.props.user)}
                              data-tip={`<span dir="auto">${ev1.firstName} ${
                                ev1.lastName
                              }</span><br/><span dir="auto">${
                                ev1.address ? ev1.address : ""
                              }, ${
                                ev1.city ? ev1.city : ""
                              }</span><br/><span dir="auto">${ev1.phone}</span>`}
                              data-for={ev1.id.toString()}
                            /> {findEvent(getTimeIn(i, startAt, 0), findMyTeacherCell(f_), 0)}</td>
                          )
                        } else {
                          return <td></td>
                        }
                      }
                    }
                  })}
                  <td>{getTimeIn(i, startAt, 0)}</td>
                </tr>
                </>
              )
            })}
          </table>
        </div>
      </TTContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    showDailyBikes: state.session.user.showDailyBikes,
    events: state.CalendarReducer.events,
    holidays: state.CalendarReducer.holidays,
    isHolidaysLoaded: state.CalendarReducer.isHolidaysLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    user: state.session.user,
    students: state.StudentsReducer.students,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  getStudents,
  createEvent,
  getVehicles,
})(Calendara);
