import React from "react";
import Modal from "react-modal";
import {
  Title,
  Circle,
  TitleText,
  CloseIcon,
  Text,
  Button,
  ButtonContainer,
  Body,
} from "./modal.styles";
import FadeLoader from "react-spinners/FadeLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    height: "20vh",
    paddingTop: "0",
  },
  overlay: { zIndex: 1200 },
};

Modal.setAppElement("#root");

const IsOpenLinkModal = ({
  link,
  modalIsOpen,
  closeModal,
  isLoading,
  children,
  titleText
}) => {
  const openLink = () => {
    window.open(link)
    _closeModal(true)
  }
  const _closeModal = (isOpen = false) => {
    if (closeModal) closeModal(isOpen)
  }
  let title = null;
  if (titleText !== undefined) {
    title = <Title>
      <TitleText>{titleText}</TitleText>
      <Circle onClick={_closeModal}>
        <CloseIcon className="fa fa-close" />
      </Circle>
    </Title>
  }
  let _children = <Text dir="auto">שליחת הודעה לתלמיד/ה</Text>
  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      contentLabel="OpenLink"
      onRequestClose={_closeModal}
    >
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={150} color={"#123abc"} loading={true} />
        </div>
      )}
      {title}
      <Body>
        {children === undefined ? _children : children}
        <ButtonContainer>
          <Button
            onClick={openLink}
          >
            כן
          </Button>
          <Button reset onClick={_closeModal}>
            בטל
          </Button>
        </ButtonContainer>
      </Body>
    </Modal>
  );
};

export default IsOpenLinkModal;
