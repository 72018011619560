import React, { useState } from "react";
import Toggle from "react-toggle";
import General from "./General/General";
import Dates from "./Dates/Dates";
import Agreements from "./Agreements/Agreements";
import Assignment from "./Assignment/Assignment";
import Navbar from "../../../../Navbar/Navbar";
import i18n from "../../../../../lib/i18n/i18n";
import SivanModal from "../../../../Modal/Modal";
import { ToggleContainer, ToggleText, Body } from "./editTextBook.styles";
import InputWrappedWithField from "../../../../UiComponents/Inputs/DefaultWrappedWithField";
import DefaultSelect from "../../../../UiComponents/Select/Default";
import DefaultDatePicker from "../../../../UiComponents/DatePicker/Default";
import DefaultDatePickerWithTime from "../../../../UiComponents/DatePicker/WithTime";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const menuItems = [
  dictionary.editTextBook.basic,
  dictionary.editTextBook.dates,
  dictionary.editTextBook.agreements,
  dictionary.editTextBook.assigment,
];

const EditTextBook = ({
  isTextBookEditModalOpen,
  setTextBookEditModalOpen,
  textBookData,
  studentData,
  agreementList,
  view = dictionary.editTextBook.basic,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [studentLicense, setStudentLicense] = useState(false);
  const [currentOpenedView, setCurrentOpenedView] = useState(view);
  return (
    <SivanModal
      titleText={`ערוך תיק לימוד לתלמיד ${studentData.firstName} ${studentData.lastName}`}
      modalIsOpen={isTextBookEditModalOpen}
      closeModal={() => setTextBookEditModalOpen(false)}
      isLoading={isLoading}
    >
      <Body>
        <Navbar
          menuOptions={menuItems}
          currentView={currentOpenedView}
          setCurrentOpenedView={setCurrentOpenedView}
        />
        {currentOpenedView === dictionary.editTextBook.basic && (
          <General
            renderDatePickerField={DefaultDatePickerWithTime}
            renderInput={InputWrappedWithField}
            renderToggle={renderToggle}
            renderSelect={DefaultSelect}
            textBookData={textBookData}
            setStudentLicense={setStudentLicense}
          />
        )}
        {currentOpenedView === dictionary.editTextBook.dates && (
          <Dates
            renderDatePickerField={DefaultDatePicker}
            textBookData={textBookData}
            studentData={studentData}
            setIsLoading={setIsLoading}
            closeModal={() => setTextBookEditModalOpen(false)}
          />
        )}
        {currentOpenedView === dictionary.editTextBook.agreements && (
          <Agreements
            renderDatePickerField={DefaultDatePicker}
            textBookData={textBookData}
            agreementList={agreementList}
          />
        )}
        {currentOpenedView === dictionary.editTextBook.assigment && (
          <Assignment
            textBookData={textBookData}
            studentId={studentData.studentId}
            studentLicense={studentLicense}
          />
        )}
      </Body>
    </SivanModal>
  );
};

export default EditTextBook;
