import React from "react";
import { Container, MenuItem } from "./navbar.styles";

const Navbar = ({ currentView, setCurrentOpenedView, menuOptions }) => {
  return (
    <Container>
      {menuOptions.map((x) => (
        <MenuItem
          active={currentView === x.toLowerCase()}
          onClick={() => setCurrentOpenedView(x.toLowerCase())}
        >
          {x}
        </MenuItem>
      ))}
    </Container>
  );
};

export default Navbar;
