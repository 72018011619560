import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { useTranslation } from "react-i18next";
import { updateSettings } from "../../../actions/settingsActions";
import ColorPicker from "./ColorPicker";
import {
  SectionContainer,
  SectionContainerHeader,
  Icon,
} from "./calendarColors.styles";
import CalendarEventDayColor from "./CalendarEventDayColor";
import { useEffect } from "react";
import { useState } from "react";

const CalendarEventColor = ({ header, elements, type, params }) => {
  const { t, i18n } = useTranslation("common");
  const [count, setCount] = useState(0)
  const addElement = (addType) => {
    if (type && !params.calendarTimeColors) params.calendarTimeColors = {}
    if (type && !params.calendarTimeColors[type]) params.calendarTimeColors[type] = []
    params.calendarTimeColors[addType].push({
      background: '#666',
      font: '#fff',
    })
    setCount(params.calendarTimeColors[addType].length)
  }
  return (
    <SectionContainer>
      <div style={{ textAlign: "center" }}>{header}</div>
      <SectionContainer
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
      >
        {elements.map((x) => (
          <SectionContainer>
            <SectionContainerHeader background={x.background} fontColor={x.font}>
              {x.text} <Icon className={x.icon} />
            </SectionContainerHeader>
            <Field
              name={x.backgroundFieldName}
              component={ColorPicker}
              text={t("settings.background")} />
            <Field
              name={x.fontFieldName}
              component={ColorPicker}
              text={t("settings.font")} />
          </SectionContainer>
        ))}
      </SectionContainer>
      {type && (
        <>
      <div style={{ textAlign: "center" }}>{header} time</div>
        <CalendarEventDayColor elements={params.calendarTimeColors[type]} type={type} addElement={addElement} />
      </>
      )}
    </SectionContainer>
  );
};

export default CalendarEventColor;
