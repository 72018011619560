import React from "react";
import { Field, reduxForm } from "redux-form";
import i18n from "../../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import {
  ToggleContainer,
  ToggleText,
  AdditionalDetailsContainer,
  Fields,
} from "../permissions.styles";

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const StudentsPermissions = () => {
  return (
    <Fields>
      <AdditionalDetailsContainer>
        <Field
          label="דוח שבועי למורה"
          component={renderToggle}
          name="teacherWeekly"
        />
        <Field
          label="דוח שבועי למורה - בחירת מורה אחר"
          component={renderToggle}
          name="teacherWeeklyOnlyHimself"
        />
        <Field label="דוח רו״ח" component={renderToggle} name="cpaReport" />
        <Field
          label="דוח ביטולים"
          component={renderToggle}
          name="canceledReport"
        />
        <Field
          label="דוח מבחנים פנימיים"
          component={renderToggle}
          name="internalTestsReport"
        />
        <Field
          label="דוח מבחנים מעשיים"
          component={renderToggle}
          name="testsReport"
        />
        <Field
          label="דוח תלמידים"
          component={renderToggle}
          name="studentsReport"
        />
        <Field
          label="דוח חייבים"
          component={renderToggle}
          name="studentBalances"
        />
        <Field
          label="תלמידים ללא אירוע"
          component={renderToggle}
          name="inactiveStudents"
        />
        <Field
          label="תלמידים חדשים ללא אירוע"
          component={renderToggle}
          name="inactiveNewStudents"
        />
        <Field
          label='ניתוח פעילות תקופתי'
          component={renderToggle}
          name="activityAnalysis"
        />
      </AdditionalDetailsContainer>
    </Fields>
  );
};

export default reduxForm({
  form: "permissions", // a unique identifier for this form
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  enableReinitialize: true, // this is needed!!
})(StudentsPermissions);
