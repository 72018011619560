import schedulerApi from "../apis/scheduler";
import holidaysApi from "../apis/holidaysApi";
import {
  NEW_EVENT_FAILED,
  NEW_EVENT_SUCCESS,
  NEW_EVENT_STARTED,
  EVENTS_FETCH_FAILED,
  EVENTS_FETCH_SUCCESS,
  EVENTS_FETCH_STARTED,
  EDIT_EVENT_SUCCESS,
  HIDE_EVENT,
  EVENTS_FETCH_HOLIDAYS_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";
import moment from "moment";
import { fetchTextBook, getStudentTextBooks, getTextBookAgreementOpeningBalance } from './textBooksActions'
import { getClientIncomes, getStudentIncomes } from "./incomesAction";
import { getPaymentRequestById, getSchoolFundingBodies } from "./fundingBodiesActions";
import { getStudentDiscountsAndObligations } from "./discountsAndObligationsActions";
import { getAgreements } from "./agreementsActions";
import { getVehicles } from "./vehicleActions";
import { getLicenseTypes } from "./licenseTypes";
import _ from "lodash";

const codeMessage = {
  STUDENT_IS_NOT_CHECKED_TEST: 'כרטסת לא מאושרת לא ניתן לשבץ טסט.',
  STUDENT_IS_NOT_CHECKED_INTERNAL_TEST: 'כרטסת לא מאושרת לא ניתן לשבץ מבחן פנימי'
}

export const eventTypes = {
  1: "שיעור",
  2: "טסט",
  3: "מבחן פנימי",
  4: "פגישה",
  5: "חשבונית/קבלה",
  6: "קבלה",
  7: "חשבונית",
  8: "חשבונית/קבלה זיכוי",
  9: "קבלה זיכוי",
  10: "חשבונית זיכוי",
  11: "הנחה",
  12: "חיוב",
};
const incomesTypes = { 0: 5, 1: 6, 2: 7, 3: 8, 4: 9, 5: 10, 6: 11, 7: 12 };

export const eventStatusTypes = {
  0: "הזמנה",
  1: "בוצע",
  2: "בוצע ותוקבל",
  3: "בוטל",
  4: "בוטל וחויב",
};

export const createEvent =
  (event, currVehicle) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { branchId, id, schoolId } = getState().session.user;
      if (event.price) event.price = parseInt(event.price);
      const response = await schedulerApi.post(
        "/createEvent",
        {
          event: {
            ...event,
            branchId,
            pickupLocationId: event.pickupLocationId.value,
            eventType: event.eventType.value,
            studentId: event.studentId ? event.studentId.studentId : null,
            userId: id,
            schoolId,
          },
        },
        {
          headers,
        }
      );
      const payload = {
        ...event,
        name: event.studentId ? event.studentId.label : null,
        studentId: event.studentId ? event.studentId.studentId : null,
        branchId,
        id: response.data.eventId,
        title: event.eventType.label,
        firstName: event.studentId ? event.studentId.firstName : null,
        lastName: event.studentId ? event.studentId.lastName : null,
        internalTestStatus: event.internalTestStatus,
        address: event.studentId ? event.studentId.address : null,
        city: event.studentId ? event.studentId.city : null,
        phone: event.studentId ? event.studentId.phone : null,
        updateUsername: event.updateUsername ? event.updateUsername : null,
        updated: new Date(),
        eventType: event.eventType.value,
        description: currVehicle ? currVehicle.description : null,
        testStatus: 0,
        pickupLocationId: event.pickupLocationId.value,
        pickupLocationName: event.pickupLocationId.label,
        drivingPermitId: event.drivingPermitId
          ? event.drivingPermitId.toString()
          : null,
        textBookId: event.textBookId ? event.textBookId.toString() : null,
        teacherId: event.teacherId ? event.teacherId : null,
        vehicleId: event.vehicleId ? event.vehicleId : null,
        eventStatus: 0,
      }
      dispatch({
        type: NEW_EVENT_SUCCESS,
        payload,
      });
      return payload;
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      let defaultMessage = "כישלון ביצירת אירוע"
      
      if (error.response && error.response.data.name && codeMessage[error.response.data.name]) {
        defaultMessage = codeMessage[error.response.data.name]
      }
      toast.error(defaultMessage);
      return null;
    }
  };

export const loadHolidays =
  (event, currVehicle) => async (dispatch, getState) => {
    try {
      const response = await holidaysApi.get();

      dispatch({
        type: EVENTS_FETCH_HOLIDAYS_SUCCESS,
        payload: response.data.items.map((x) => ({
          title: x.hebrew,
          start: new Date(x.date),
          end: new Date(x.date),
          allDay: true,
        })),
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      // toast.error("כישלון ביצירת אירוע");
    }
  };

export const updateEvent = (event) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_EVENT_STARTED,
    });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { branchId, id, schoolId } = getState().session.user;
    const response = await schedulerApi.patch(
      "/updateEvent",
      {
        event: {
          ...event,
          branchId,
          eventType: event.eventType.value,
          userId: id,
          schoolId,
          pickupLocationId: event.pickupLocationId.value,
          studentId: event.studentId ? event.studentId.studentId : null,
        },
      },
      {
        headers,
      }
    );

    dispatch({
      type: EDIT_EVENT_SUCCESS,
      payload: {
        ...event,
        title: event.eventType.label,
        eventType: event.eventType.value,
        textBookId: event.textBookId ? event.textBookId.toString() : null,
        drivingPermitId: event.drivingPermitId
          ? event.drivingPermitId.toString()
          : null,
        firstName: event.studentId ? event.studentId.firstName : null,
        studentId: event.studentId ? event.studentId.studentId : null,
        lastName: event.studentId ? event.studentId.lastName : null,
        pickupLocationId: event.pickupLocationId.value,
        pickupLocationName: event.pickupLocationId.label,
      },
    });
  } catch (error) {
    dispatch({
      type: EVENTS_FETCH_FAILED,
    });
    toast.error("כישלון בעדכון אירוע");
  }
};

export const updatePickupLocation =
  (eventId, pickupLocationId, pickupLocationName) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { branchId, id, schoolId } = getState().session.user;

      const response = await schedulerApi.patch(
        "/updatePickupLocation",
        {
          eventId,
          pickupLocationId,
        },
        {
          headers,
        }
      );
      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          pickupLocationId,
          pickupLocationName,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateEventStatus =
  (eventId, eventStatus, cancelReason, eventType, testStatus = 0, type = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { branchId, id, schoolId } = getState().session.user;

      const response = await schedulerApi.patch(
        "/updateEventStatus",
        {
          eventId,
          eventStatus,
          cancelReason,
          eventType,
          testStatus,
          type,
        },
        {
          headers,
        }
      );

      if (eventStatus === 3 || eventStatus === 4) {
        dispatch({
          type: HIDE_EVENT,
          payload: {
            id: eventId,
            eventStatus,
          },
        });
      } else if (type !== "") {
        dispatch({
          type: EDIT_EVENT_SUCCESS,
          payload: {
            id: eventId,
            eventStatus,
            cancelReason,
            [type]: testStatus,
          },
        });
      } else {
        dispatch({
          type: EDIT_EVENT_SUCCESS,
          payload: {
            id: eventId,
            eventStatus,
            cancelReason,
            testStatus,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const getEvents = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENTS_FETCH_STARTED,
    });
    if ((Array.isArray(id) && id.length === 0) || !id) {
      dispatch({
        type: EVENTS_FETCH_SUCCESS,
        payload: [],
      });
      return;
    }
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { showCanceled, showCanceledAndCharged, schoolId } =
      getState().session.user;
    const response = await schedulerApi.get(
      `/getEventsForUser/${id}/${showCanceled}/${showCanceledAndCharged}/${schoolId}`,
      {
        headers,
      }
    );

    dispatch({
      type: EVENTS_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: NEW_EVENT_FAILED,
    });
    //toast.error("כישלון בשליפת אירועים");
  }
};

export const getFundingBodyStudentEvents =
  (students) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.get(
        `/getFundingBodyStudentEvents/${schoolId}/${students}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllSchoolEvents = (from, to) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EVENTS_FETCH_STARTED,
    });

    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const {
      schoolId,
      showCanceled,
      showCanceledAndCharged,
      branchId,
      userType,
    } = getState().session.user;
    const response =
      from && to
        ? await schedulerApi.get(
            `/getAllSchoolEvents/${schoolId}/${showCanceled}/${showCanceledAndCharged}/${branchId}/${userType}/${from}/${to}`,
            {
              headers,
            }
          )
        : await schedulerApi.get(
            `/getAllSchoolEvents/${schoolId}/${showCanceled}/${showCanceledAndCharged}/${branchId}/${userType}`,
            {
              headers,
            }
          );
    dispatch({
      type: EVENTS_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: NEW_EVENT_FAILED,
    });
    //toast.error("כישלון בשליפת אירועים");
  }
};

export const getAllSchoolCanceledEvents =
  (from, to) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.get(
        `/getAllSchoolCanceledEvents/${schoolId}/${from}/${to}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getLastStudentsEvent =
  (studentIds) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.post(
        "/getLastStudentsEvent",
        {
          schoolId,
          studentIds,
        },
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllSchoolTestsEvents =
  (from, to) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await schedulerApi.get(
        `/getAllSchoolTestsEvents/${schoolId}/${from}/${to}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllTeacherEvents =
  (teacherId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENTS_FETCH_STARTED,
      });

      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { showCanceled, showCanceledAndCharged } = getState().session.user;

      const response = await schedulerApi.get(
        `/getEventsForTeacher/${teacherId}/${showCanceled}/${showCanceledAndCharged}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: NEW_EVENT_FAILED,
      });
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getAllTeacherEventsWithCanceled =
  (teacherId, search='') => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENTS_FETCH_STARTED,
      });

      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.get(
        `/getEventsForTeacher/${teacherId}/${1}/${1}${search}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: NEW_EVENT_FAILED,
      });
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getTeachersWithEvents =
  (showCanceled = 1, showCanceledAndCharged=1, search='') => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.get(
        `/getTeachersWithEvents/${showCanceled}/${showCanceledAndCharged}${search}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: NEW_EVENT_FAILED,
      });
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getEventsForTypeBetween =
  (type, start, end, typeId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EVENTS_FETCH_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;

      const response = await schedulerApi.get(
        `/countEventForTypeBetween/${schoolId}/${type}/${typeId}/${start}/${end}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {
      dispatch({
        type: NEW_EVENT_FAILED,
      });
      //toast.error("כישלון בשליפת אירועים");
    }
  };

export const getStudentEventCount =
  (eventType, studentId, textbookId, startDate) =>
  async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const { branchId, id, schoolId } = getState().session.user;
      if (typeof branchId !== "undefined") {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const _startDate = moment()
        // const textBooksData = await fetchTextBook(textbookId)(dispatch, getState)
        const response = await schedulerApi.get(
          `getStudentEventCount/${studentId}/${textbookId}/${eventType}/${_startDate.format()}/${schoolId}/${branchId}`,
          {
            headers,
          }
        );
        // return textBooksData.remaininglessonsInMinutes / 40 + response.data;
        return response.data;
      } else {
        return 0;
      }
    } catch (error) {
      toast.error("כישלון בבדיקת אירועים עבור תלמיד");
    }
  };

export const getStudentTextbookAllEvents =
  (studentId, textbookId) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const { branchId, id, schoolId } = getState().session.user;
      if (typeof branchId !== "undefined") {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await schedulerApi.get(
          `countStudentTextBookAllEvents/${studentId}/${textbookId}/${schoolId}/${branchId}`,
          {
            headers,
          }
        );

        return response.data;
      } else {
        return 0;
      }
    } catch (error) {
      toast.error("כישלון בבדיקת אירועים עבור תלמיד");
    }
  };

export const getStudentData = (studentId, textBookId, ticketShowCanceledAndCharged = false, isShowCanceled = false, eventType = null) => async (dispatch, getState) => {
  let [userEvents, lessons, textBooksData, incomes, 
    tests,
    internalTests,
    licenses,
    schoolVehicles,
    agreements,
    discountsAndObligations,
    bodies,
  ] = await Promise.all([
    dispatch(getStudentEvents(studentId)),
    dispatch(getStudentEventCount(1, studentId, textBookId, new Date())),
    dispatch(fetchTextBook(textBookId)),
    dispatch(getStudentIncomes(studentId)),
    dispatch(getStudentEventCount(2, studentId, textBookId, new Date())),
    dispatch(getStudentEventCount(3, studentId, textBookId, new Date())),
    dispatch(getLicenseTypes()),
    dispatch(getVehicles(false)),
    dispatch(getAgreements()),
    dispatch(getStudentDiscountsAndObligations(studentId)),
    dispatch(getSchoolFundingBodies()),
  ]);
  discountsAndObligations = discountsAndObligations.filter(
    (y) => y.textbookId === textBookId.toString() || y.textbookId === null
  )
  const ticketShowCanceled = getState().session.user.ticketShowCanceled;
  const fetchedStundetTextBooks = await dispatch(getStudentTextBooks(studentId));
  const textbookOptions = fetchedStundetTextBooks.map((x) => ({
    value: x.textBookId,
    label: x.description || "",
  }));
  const schoolVehicle = schoolVehicles.find(
    (x) => x.id.toString() === textBooksData.vehicleId?.toString()
  );
  const vehicleLicenseType = schoolVehicle?.licenseType ?? 0;
  const textbookLicense = licenses.find(
    (x) => x.id === parseInt(vehicleLicenseType)
  );
  const fundingBodyIncomes = textBooksData.fundingBody ? await dispatch(getClientIncomes(
    textBooksData.fundingBody
  )) : [];
  const paymentRequestsStudentTotal = {};
  const studentFundingBodyIncomes = []
  for (let index = 0; index < fundingBodyIncomes.length; index++) {
    const element = fundingBodyIncomes[index];
    if (!element.invoiceData) continue;
    try {
      const _stf = JSON.parse(element.invoiceData).find(
        (x) => x.studentId === studentId
      );
      if (_stf) {
        studentFundingBodyIncomes.push(element);
        paymentRequestsStudentTotal[element.id] = _stf.total || 0;
      }
    } catch(err) {
      paymentRequestsStudentTotal[element.id] = 0;
    }
  }
  incomes = [...incomes, ...studentFundingBodyIncomes].filter(
    (y) =>
      y.textbookId === "[object Object]" ||
      y.textbookId === textBookId.toString() ||
      textbookOptions.findIndex(
        (x) => x.value.toString() === y.textbookId
      ) === -1 ||
      y.textbookId === null
  );

  let studentBalance = parseFloat(textBooksData.openingBalance) || 0;
  let studentBalanceWithFutureBalance = 0;
  studentBalance -= await dispatch(getTextBookAgreementOpeningBalance(
    textBooksData.agreements
  ));
  let eventCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const eventsThatNotHaveTextBook = [];
  for (let index = 0; index < userEvents.length; index++) {
    const event = userEvents[index];
    let isExists = false;
    for (let index = 0; index < textbookOptions.length; index++) {
      const textBook = textbookOptions[index];
      if (textBook.value === event.textBookId) isExists = true;
    }
    if (!isExists)
      eventsThatNotHaveTextBook.push({
        ...event,
        textbookIsMissing: true,
      });
  }

  let eventsFiltered = userEvents.filter((x) => x.textBookId === textBookId);
  // if (eventType) eventsFiltered = eventsFiltered.filter((x) => x.eventType == eventType);
  if (!ticketShowCanceledAndCharged)
    eventsFiltered = eventsFiltered.filter(
      (event) => event.eventStatus !== 4
    );

  if (isShowCanceled) {
    eventsFiltered = eventsFiltered.filter(
      (event) => event.eventStatus === 3
    );
  } else if (!ticketShowCanceled) {
    eventsFiltered = eventsFiltered.filter(
      (event) => event.eventStatus !== 3
    );
  }

  let totalLessonsInMinutes = 0;
  let futureInternalTests = 0;
  let futureTests = 0;
  let totalFutureLessonsInMinutes = 0;
  let totalPaymentRecived = 0;
  let totalDidntShowedUp = 0;
  let totalDidntShowedUpAndCharged = 0;
  let totalDiscounts = 0;
  let testStatus = 0;
  let internalTestStatus = 0;
  let canceledLessons = 0;
  let chargedAndCanceledLessons = 0;
  let studentData = [...eventsFiltered, ...eventsThatNotHaveTextBook];
  const users = getState().UsersReducer.users;
  const teacher = users.find(
    (y) =>
      y.id.toString() ===
      textBooksData.teacherId.toString()
  );
  const studentTicket = getState().session.user.studentTicket;
  if (studentTicket) {
    studentData = [
      ...incomes,
      ...discountsAndObligations,
      ...studentData,
    ];
  }
  const ticketLessonTime = getState().session.user.ticketLessonTime;
  const tableEvents = _.sortBy(studentData, [
    function (o) {
      return o.start ? new Date(o.start) : new Date(o.date);
    },
  ]).map((x, i) => {
    if (x?.hasOwnProperty("clientId") && !x.isFundingBody) {
      if (
        x.documentType === 0 ||
        x.documentType === 1 ||
        x.documentType === 9 ||
        x.documentType === 8
      ) {
        studentBalance += x.amount;
        totalPaymentRecived += x.amount;
      } else if (x.documentType === 3 || x.documentType === 4) {
        studentBalance -= x.amount;
        totalPaymentRecived -= x.amount;
      }
      let teacherName = "לא הוזן מורה";
      let vehicleDescription = "לא הוזן רכב";
      if (x.textbookId && x.textbookId !== "[object Object]") {
        const teacher = users.find(
          (y) => y.id.toString() === x.teacherId?.toString()
        );
        teacherName = `${teacher?.firstName} ${teacher?.lastName}`;
        if (!teacher && x.createUsername) teacherName = x.createUsername;

        const vehicle = schoolVehicles.find(
          (y) => y.id.toString() === x.vehicleId?.toString()
        );
        vehicleDescription = vehicle?.description;
      }
      return {
        number: (eventCount[incomesTypes[x.documentType]] += 1),
        start: moment(x.date).format("DD-MM-YYYY HH:mm"),
        eventType: eventTypes[incomesTypes[x.documentType]],
        eventStatus: x.documentNumber,
        time: 0,
        price: x.amount,
        license: textbookLicense?.name,
        vehicleDescription: vehicleDescription,
        balance: studentBalance.toFixed(2),
        teacher: teacherName,
        notes: x.notes,
        type: "income",
        id: x.id,
        createdBy: x.createUsername || teacherName,
        data: x,
      };
    } else if (x?.hasOwnProperty("isFundingBody") && x.isFundingBody) {
      studentBalance += paymentRequestsStudentTotal[x.id];
      totalPaymentRecived += paymentRequestsStudentTotal[x.id];
      let teacherName = "גוף מימון";
      let vehicleDescription = "גוף מימון";

      return {
        number: (eventCount[incomesTypes[x.documentType]] += 1),
        start: moment(x.date).format("DD-MM-YYYY HH:mm"),
        eventType: "תקבול ג.מ",
        eventStatus: x.documentNumber,
        time: 0,
        price: paymentRequestsStudentTotal[x.id],
        license: textbookLicense?.name,
        vehicleDescription: vehicleDescription,
        balance: studentBalance.toFixed(2),
        teacher: teacherName,
        notes: x.notes,
        type: "income",
        id: x.id,
        data: x,
        createdBy: x.createUsername ? x.createUsername : `${teacherName}`,
      };
    } else if (x.hasOwnProperty("isDiscount")) {
      if (x.isDiscount) {
        totalDiscounts += x.amount;
        studentBalance += x.amount;
      } else {
        studentBalance -= x.amount;
      }

      let teacherName = "תיק לימוד תקול";
      let vehicleDescription = "תיק לימוד תקול";
      if (x.textbookId !== "[object Object]") {
        const teacher = users.find(
          (y) => y.id.toString() === x.teacherId?.toString()
        );
        teacherName = `${teacher?.firstName} ${teacher?.lastName}`;
        if (!teacher && x.createUsername) teacherName = x.createUsername;

        const vehicle = schoolVehicles.find(
          (y) => y.id.toString() === x.vehicleId?.toString()
        );
        vehicleDescription = vehicle?.description;
      }

      return {
        number: (eventCount[incomesTypes[x.isDiscount ? 6 : 7]] += 1),
        start: moment(x.date).format("DD-MM-YYYY HH:mm"),
        eventType: eventTypes[incomesTypes[x.isDiscount ? 6 : 7]],
        price: x.isDiscount ? x.amount : -x.amount,
        balance: studentBalance.toFixed(2),
        license: textbookLicense?.name,
        teacher: teacherName,
        eventStatus: "בוצע",
        vehicleDescription,
        notes: x.notes,
        data: { ...x, clientId: x.studentId },
        time: 0,
        type: "discount",
        createdBy: `${teacherName}`,
      };
    } else {
      try {
        const today = new Date();
        if (x.eventStatus !== 3) {
          if (
            moment(x.start).isSameOrBefore(today) &&
            x.eventStatus !== 0
          ) {
            if (x.eventType === 1) {
              totalLessonsInMinutes +=
                Math.abs(new Date(x.end) - new Date(x.start)) /
                (1000 * 60);
            }

            studentBalance -= x.price;
          } else {
            if (x.eventType === 1) {
              totalFutureLessonsInMinutes +=
                Math.abs(new Date(x.end) - new Date(x.start)) /
                (1000 * 60);
            }
            if (x.eventType === 2) {
              futureTests += 1;
            }
            if (x.eventType === 3) {
              futureInternalTests += 1;
            }
            studentBalanceWithFutureBalance -= x.price;
          }
          if (x.eventStatus !== 4) {
            chargedAndCanceledLessons ++;
          }
          if (x.eventStatus === 3) {
            canceledLessons ++;
          }

          if (
            x.eventStatus === 4 &&
            x.cancelReason === "התלמיד לא הגיע"
          ) {
            totalDidntShowedUp +=
              Math.abs(new Date(x.end) - new Date(x.start)) /
              (1000 * 60) /
              ticketLessonTime;
            totalDidntShowedUpAndCharged +=
              Math.abs(new Date(x.end) - new Date(x.start)) /
              (1000 * 60) /
              ticketLessonTime;
          }
        } else if (x.cancelReason === "התלמיד לא הגיע") {
          totalDidntShowedUp +=
            Math.abs(new Date(x.end) - new Date(x.start)) /
            (1000 * 60) /
            ticketLessonTime;
        }

        const teacher = users.find(
          (y) => y.id.toString() === x.teacherId?.toString()
        );
        const createUserName = users.find(
          (y) => y.id.toString() === x.userId?.toString()
        );
        const vehicle = schoolVehicles.find(
          (y) => y.id.toString() === x.vehicleId?.toString()
        );

        if (x.eventType === 2) testStatus = x.testStatus;
        if (x.eventType === 3) internalTestStatus = x.internalTestStatus;

        return {
          number: (eventCount[x.eventType] += 1),
          start: moment(x.start).format("DD-MM-YYYY HH:mm dddd"),
          eventType: eventTypes[x.eventType],
          eventStatus: `${eventStatusTypes[x.eventStatus]} ${
            x.eventStatus === 3 || x.eventStatus === 4
              ? `- ${x.cancelReason}`
              : ""
          }`,
          time:
            Math.abs(new Date(x.end) - new Date(x.start)) / (1000 * 60),
          teacher: x.hasOwnProperty("textbookIsMissing")
            ? "תיק לימוד נמחק"
            : `${teacher?.firstName} ${teacher?.lastName}`,
          license: textbookLicense?.name,
          vehicleDescription: vehicle?.description,
          price: x.price,
          notes: x.notes,
          balance: studentBalance.toFixed(2),
          type: "event",
          data: x,
          createdBy: `${createUserName?.firstName} ${createUserName?.lastName}`,
        };
      } catch (err) {
        return {
          number: "אירוע תקול",
          start: moment(x.start).format("DD-MM-YYYY HH:mm dddd"),
          eventType: eventTypes[x.eventType],
          eventStatus: "אירוע תקול",
          time: "אירוע תקול",
          teacher: "אירוע תקול",
          license: "אירוע תקול",
          vehicleDescription: "אירוע תקול",
          price: "אירוע תקול",
          notes: "אירוע תקול",
          balance: "אירוע תקול",
          data: x,
          type: "event",
        };
      }
    }
  });
  const testsCount = tests + textBooksData.remainingTests
  const internalTestsCount = internalTests + textBooksData.remainingInternalTests
  let totalEventsForType = lessons;
  switch (eventType) {
    case 2:
      totalEventsForType = testsCount;
      break;
    case 3:
      totalEventsForType = internalTestsCount
      break;
  }
  const lessonsWithFutureLessons = (textBooksData.remaininglessonsInMinutes + totalLessonsInMinutes) /
  ticketLessonTime + totalFutureLessonsInMinutes / ticketLessonTime;
  return {
    studentId,
    textBookId,
    userEvents: userEvents.filter((x) => x.textBookId === textBookId),
    tableEvents,
    textBooksData,
    lessons,
    totalLessonsInMinutes,
    futureInternalTests,
    futureTests,
    totalFutureLessonsInMinutes,
    totalPaymentRecived,
    totalDidntShowedUp,
    totalDidntShowedUpAndCharged,
    totalDiscounts,
    testStatus,
    internalTestStatus,
    studentBalance,
    studentBalanceWithFutureBalance: (studentBalanceWithFutureBalance + studentBalance),
    fundingBodies: bodies,
    agreements,
    isPassedInternal: textBooksData.passedInternalTest ? 1 : 0,
    testsCount,
    internalTestsCount: internalTests + textBooksData.remainingInternalTests,
    lessonInAgreementTime: (textBooksData.remaininglessonsInMinutes + totalLessonsInMinutes) / ticketLessonTime,
    internalTestsWithFutureInternalTests: textBooksData.remainingInternalTests + internalTests + futureInternalTests,
    testsWithFutureTests: tests + futureTests + textBooksData.remainingTests,
    lessonsWithFutureLessons,
    eventType,
    totalEventsForType,
    chargedAndCanceledLessons,
    canceledLessons,
    schoolVehicle,
    textbookLicense,
    teacher,
    discountsAndObligations,
    incomes,
  }
}

export const getStudentEvents = (studentId, params) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const { branchId, id, schoolId } = getState().session.user;
    if (typeof branchId !== "undefined") {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.get(
        `getStudentEvents/${studentId}/${schoolId}/${branchId}`,
        {
          params,
          headers,
        }
      );

      return response.data;
    } else {
      return 0;
    }
  } catch (error) {
    toast.error("כישלון בבדיקת אירועים עבור תלמיד");
  }
};

export const getStudentEventsByTextBook =
  (studentId, textBookId) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const { branchId, id, schoolId } = getState().session.user;
      if (typeof branchId !== "undefined") {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await schedulerApi.get(
          `getStudentEventsByTextBook/${studentId}/${schoolId}/${branchId}/${textBookId}`,
          {
            headers,
          }
        );

        return response.data;
      } else {
        return 0;
      }
    } catch (error) {
      toast.error("כישלון בבדיקת אירועים עבור תלמיד");
    }
  };

export const updateEventNote =
  (eventId, notes) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateEventNote",
        {
          eventId,
          notes,
        },
        {
          headers,
        }
      );

      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          notes,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateStudentFundingBodyStatus =
  (eventId, newStatus) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateStudentFundingBodyStatus",
        {
          eventId,
          newStatus,
        },
        {
          headers,
        }
      );
    } catch (error) {
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateTeacher =
  (eventId, teacherId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateTeacher",
        {
          eventId,
          teacherId,
        },
        {
          headers,
        }
      );

      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          teacherId,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const updateVehicle =
  (eventId, vehicleId, description) => async (dispatch, getState) => {
    try {
      dispatch({
        type: NEW_EVENT_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateVehicle",
        {
          eventId,
          vehicleId,
        },
        {
          headers,
        }
      );

      dispatch({
        type: EDIT_EVENT_SUCCESS,
        payload: {
          id: eventId,
          vehicleId: vehicleId.toString(),
          description,
        },
      });
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };

export const busyEvents = (params) => async (dispatch, getState) => {
  try {
    const response = await schedulerApi.get("/busyEvents", { params });
    return response.data;
  } catch (error) {
    dispatch({
      type: EVENTS_FETCH_FAILED,
    });
    toast.error("כישלון בעדכון אירוע");
  }
};

export const updateEventPrice =
  (eventId, newPrice) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await schedulerApi.patch(
        "/updateEventPrice",
        {
          eventId,
          newPrice,
        },
        {
          headers,
        }
      );
    } catch (error) {
      dispatch({
        type: EVENTS_FETCH_FAILED,
      });
      toast.error("כישלון בעדכון אירוע");
    }
  };
