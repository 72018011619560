import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import Assignment from "./Assignment/Assignment";
import { Collapse } from "react-collapse";

import {
  Container,
  ChapterAssignments,
  ChapterContainer,
  ChapterTitle,
} from "../assignment.styles";

const Chapter = ({
  chapterName,
  assignments,
  changeAssigmentStatus,
  changeAssigmentNote,
  changeAssigmentDate,
}) => {
  const [isChapterCollapsed, setIsChapterCollapsed] = useState(true);
  const renderAssignments = () => {
    return assignments.map((x) => (
      <Assignment
        key={x.assignmentId}
        status={x.status}
        note={x.note}
        date={x.date}
        assignment={x.assignment}
        assignmentId={x.assignmentId}
        changeAssigmentStatus={changeAssigmentStatus}
        changeAssigmentNote={changeAssigmentNote}
        changeAssigmentDate={changeAssigmentDate}
      />
    ));
  };

  return (
    <ChapterContainer>
      <ChapterTitle dir="auto">
        פרק {chapterName}
        <i
          className={`fa fa-${isChapterCollapsed ? "minus" : "plus"}-square-o`}
          aria-hidden="true"
          onClick={() => setIsChapterCollapsed(!isChapterCollapsed)}
        />
      </ChapterTitle>
      <Collapse
        isOpened={isChapterCollapsed}
        style={{ width: "40%", display: "flex" }}
      >
        <ChapterAssignments>{renderAssignments()}</ChapterAssignments>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span>חתימת תלמיד</span>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 400,
              height: 100,
              className: "sigCanvas",
            }}
          />
        </div>
      </Collapse>
    </ChapterContainer>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isAssignmentsLoaded: state.LearningAssignmentsReducer.isLoaded,
    learningAssignments: state.LearningAssignmentsReducer.learningAssignments,
  };
}

export default connect(mapStateToProps)(Chapter);
