import React from "react";
import i18n from "../../../lib/i18n/i18n";
import {
  Container,
  Row,
  SymbolContainer,
  SymbolText,
  Symbol,
} from "./symbols.styles";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const Symbols = ({}) => {
  return (
    <Container>
      <Row>
        <SymbolContainer>
          <Symbol className="icons8-clock" />
          <SymbolText>משך אירוע</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="icons8-car" />
          <SymbolText>רכב</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="icons8-location" />
          <SymbolText>מקום איסוף</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="icons8-user-group-man-man" />
          <SymbolText>כתובת תלמיד</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="fa fa-id-card" />
          <SymbolText>היתר נהיגה</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="icons8-scales" />
          <SymbolText>יתרה</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="icons8-comments" />
          <SymbolText>הערה</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="fa fa-building" />
          <SymbolText>סניף</SymbolText>
        </SymbolContainer>
        <SymbolContainer>
          <Symbol className="icons8-classroom" />
          <SymbolText>מורה</SymbolText>
        </SymbolContainer>
      </Row>
    </Container>
  );
};

export default Symbols;
