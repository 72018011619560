import React, { useState, useEffect } from "react";
import i18n from "../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title, NavAndBackContainer } from "./control.styles";
// import NewClient from "./NewClient/NewClient";
// import ClientData from "./ClientData/ClientData";
import { getSchools, generateStudentsFile, checkStudentsFile, deleteStudentsFile, downloadStudentsFile } from "../../actions/adminActions";
import StudentSearch from "./Schools/Students/StudentSearch";
import SchedulersSearch from "./Schools/Schedulers/SchedulersSearch";
import Navbar from "../Navbar/Navbar";
import Select from "react-select";
import ClientsSearch from "./Schools/Clients/ClientsSearch";
import FundingBodiesSearch from "./Schools/FundingBodies/FundingBodiesSearch";
import ChangeSchool from "./ChangeSchool";
import InvoicesSearch from "./Schools/Invoices/InvoicesSearch";
import { downloadFile } from "../../utils/functions";
import Button from "../UiComponents/Buttons/Default";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { IconButton } from "@material-ui/core";
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const adminCustomSchoolStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "300px",
    minWidth: 150,
    fontSize: "12px"
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    paddingRight: "0.5vw",
  }),
};

const AdminControl = ({ history }) => {
  const [isStudentModalOpen, setStudentModalOpen] = useState(false);
  const [isSchedulerModalOpen, setSchedulerModalOpen] = useState(false);
  const [schools, setSchools] = useState([]);
  const [isActive, setIsActive] = useState(1);
  const [viewSchool, setViewSchool] = useState(null);
  const [isDownloadStudentFile, setIsDownloadStudentFile] = useState(false);
  const [downloadStudentFileIsExists, setDownloadStudentFileIsExists] = useState(0);
  const [menuItems, setMenuItems] = useState([
    dictionary.admin.tabStudets,
    dictionary.admin.tabLessons,
    dictionary.admin.tabClients,
    dictionary.admin.tabFundingBodies,
    dictionary.admin.tabInvoices,
  ]);
  const [currentOpenedView, setCurrentOpenedView] = useState(dictionary.admin.tabStudets);
  
  useEffect(() => {
    async function fetchData() {
      _checkStudentFile().then((v) => setDownloadStudentFileIsExists(parseInt(`${v}`)));
      checkStudentFile();
      const result = await getSchools(isActive);

      setSchools(result.map((v) => ({label: `#${v.id} - ${v.name}`, value: v})))
    }
    fetchData();
  }, []);

  const changeSchool = (school) => {
    setViewSchool(school)
    checkStudentFile();
  }

  const generateDownloadStudentsFile = async () => {
    setIsDownloadStudentFile(true);
    try {
      const result = await generateStudentsFile(new Date());
      switch (parseInt(`${result}`)) {
        case 0:
          toast.warning("The file is already being generated.");
        break;
        case 1:
          toast.success("The file generation will start.");
          checkStudentFile();
        break;
        case -1:
          toast.error("File generation does not start. Please, write to the programmer.");
        break;
        default:
          toast.error("Unknown error. Please, write to the programmer.");
      }
    } catch (e) {
      console.error(e)
    }
    setIsDownloadStudentFile(false);
  }

  const downloadStudentFile = async () => {
    try {
      const file = await downloadStudentsFile();
      downloadFile(file, false, 'exportStudents');
    } catch (e) {
      console.error(e)
    }
  }

  const _checkStudentFile = async () => {
    try {
      const result = await checkStudentsFile();
      return result;
    } catch (e) {
      console.error(e)
      return 0;
    }
  }
 let checkStudentFileIsStarted = false;
  const checkStudentFile = async () => {
    if (checkStudentFileIsStarted) return;
    checkStudentFileIsStarted = true;
    setTimeout(async () => {
      const result = await _checkStudentFile();
      const data = parseInt(`${result}`);
      if (data != 1) checkStudentFile();
      setDownloadStudentFileIsExists(data);
      checkStudentFileIsStarted = false;
    }, 10000)
  }

  const deleteStudentFile = async () => {
    try {
      await deleteStudentsFile();
      setDownloadStudentFileIsExists(0);
    } catch (e) {
      console.error(e)
      return 0;
    }
  }

  return (
    <Container>
      <Title>
        Control panel
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'end',
          alignItems: 'center',
        }}>
          <ChangeSchool changeSchool={changeSchool} />
        </div>
      </Title>
      <NavAndBackContainer>
        <div />
        <Navbar
          menuOptions={menuItems}
          currentView={currentOpenedView}
          setCurrentOpenedView={setCurrentOpenedView}
        />
        <i
          className="fa fa-arrow-right"
          aria-hidden="true"
          style={{ cursor: "pointer" }}
          onClick={() => history.goBack()}
        />
      </NavAndBackContainer>
      
      <FadeLoader size={50} color={"#123abc"} loading={isDownloadStudentFile} />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'end',
            }}>
               {downloadStudentFileIsExists == 1 && 
                <div>
                  <IconButton color="secondary" size="sm" onClick={()=>{deleteStudentFile()}} title="Remove file" >
                    <RemoveCircleOutline />
                  </IconButton>
                  <Button style={{fontSize: "14px"}} isActive text="Download file" onClick={() => downloadStudentFile()} disabled={isDownloadStudentFile} />
                </div>
              }
              { downloadStudentFileIsExists == 0 &&  <Button style={{fontSize: "14px"}} text="export students" onClick={() => generateDownloadStudentsFile()} disabled={isDownloadStudentFile} /> }
              { downloadStudentFileIsExists == 10 &&  <Button style={{fontSize: "14px"}} text="export students is running"  disabled isActive /> }
            </div>
      {viewSchool && currentOpenedView == dictionary.admin.tabStudets && <StudentSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabLessons && <SchedulersSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabClients && <ClientsSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabFundingBodies && <FundingBodiesSearch
        schoolId={viewSchool.value.id}
      />}
      {viewSchool && currentOpenedView == dictionary.admin.tabInvoices && <InvoicesSearch
        schoolId={viewSchool.value.id}
      />}
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps, { })(AdminControl);
