import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";
import { isMobileOnly, isMobile, isTablet } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Row = styled.td`
  margin-top: 0.5vh;
  font-size: 1.6vh;
`;
// export const Container = styled.div`
//   width: ${!isMobile ? "fit-content" : "10.8vw"};
//   height: ${!isMobile ? "fit-content" : "90vh"};
//   z-index: 9;
//   position: ${!isMobile ? "initial" : "fixed"};
//   right: 0;
//   top: 10vh;
//   background: white;
//   display: flex;
//   flex-direction: column;
//   background: white;

//   ${!isMobile && "width: 200px;top:0;"}
// `;
export const Container = styled.div`
  width: ${isMobileOnly ? "75vw" : "200px"};
  height: fit-content;
  z-index: ${isMobileOnly ? "9" : "auto"};
  position: initial;
  right: 0;
  top: 0;
  background: white;
  display: flex;
  flex-direction: column;
  background: white;
`;

export const Table = styled.table`
  direction: rtl;
  border: 1px solid black;
  font-weight: bold;
  font-size: 1vmax;
  width: 100%;
  margin-top: 1vh;

  td {
    border: 1px solid black;
    padding: 2px;
    text-align: center;
  }
`;
