import {
  PICKUP_LOCATIONS_FETCH_FAILED,
  PICKUP_LOCATIONS_FETCH_SUCCESS,
  PICKUP_LOCATIONS_FETCH_STARTED,
  PICKUP_LOCATION_CREATE_SUCCESS,
  PICKUP_LOCATION_DELETE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, locations: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PICKUP_LOCATIONS_FETCH_SUCCESS:
      return { ...state, locations: action.payload, isLoaded: true };
    case PICKUP_LOCATION_CREATE_SUCCESS:
      return { ...state, locations: [...state.locations, action.payload] };
    case PICKUP_LOCATION_DELETE_SUCCESS:
      const locations = state.locations.filter((x) => x.id !== action.payload);
      return { ...state, locations };
    case PICKUP_LOCATIONS_FETCH_FAILED:
    case PICKUP_LOCATIONS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
