import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Row,
  FieldText,
  Field,
  customStyles,
  Container,
} from "./NewStudentsByDay.styles";
import _ from "lodash";
import i18n from "../../../lib/i18n/i18n";
import Select from "react-select";
import { getBranches } from "../../../actions/branchActions";
import Button from "../../UiComponents/Buttons/Default";
import { allActiveStudentsTextbooks } from "../../../actions/studentesActions";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import DatePicker from "react-datepicker";

const NewStudentsByMonth = ({ allActiveStudentsTextbooks, getBranches }) => {
  const [graphData, setGraphData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [currentView, setCurrentView] = useState("bymonths");
  const [rawGraphData, setRawGraphData] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 15))
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [studentsTextbooks, setStudentsTextbooks] = useState([]);
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        setStudentsTextbooks(await allActiveStudentsTextbooks());
        setIsLoaded(true);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (studentsTextbooks.length > 0) {
        const data = [];
        const days = dateRange(startDate, endDate);
        for (let index = 0; index < days.length; index++) {
          const element = days[index];
          if (currentView === "byVehicles") {
            const uniqueVehicle = _.uniqBy(studentsTextbooks, "vehicleNumber");
            const vehicels = uniqueVehicle
              .filter((x) => x.vehicleNumber !== null)
              .map((x) => x.vehicleNumber);
            setSelectOptions(vehicels.map((x) => ({ value: x, label: x })));
            filterByData(
              vehicels,
              element,
              "vehicleNumber",
              data,
              studentsTextbooks
            );
          } else if (currentView === "byLicense") {
            const uniqueLicenses = _.uniqBy(studentsTextbooks, "licenseType");
            const licenses = uniqueLicenses
              .filter((x) => x.licenseType !== null)
              .map((x) => x.licenseType);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(
              licenses,
              element,
              "licenseType",
              data,
              studentsTextbooks
            );
          } else if (currentView === "byBranch") {
            const allBranches = await getBranches();
            setSelectOptions(
              allBranches.map((x) => ({ value: x.name, label: x.name }))
            );
            filterByData(
              allBranches,
              element,
              "branchId",
              data,
              studentsTextbooks,
              true
            );
          } else if (currentView === "byUser") {
            const uniqueLicenses = _.uniqBy(studentsTextbooks, "createdBy");
            const licenses = uniqueLicenses
              .filter((x) => x.createdBy !== null)
              .map((x) => x.createdBy);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(
              licenses,
              element,
              "createdBy",
              data,
              studentsTextbooks
            );
          } else if (currentView === "byTeacher") {
            const uniqueLicenses = _.uniqBy(studentsTextbooks, "teacherName");
            const licenses = uniqueLicenses
              .filter((x) => x.teacherName !== null)
              .map((x) => x.teacherName);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(
              licenses,
              element,
              "teacherName",
              data,
              studentsTextbooks
            );
          } else {
            setSelectOptions([]);
            const currentMonthStudents = studentsTextbooks.filter((x) =>
              moment(x.createdAt).isSame(element, "day")
            );
            data.push({
              date: moment(element).format("DD/MM"),
              students: currentMonthStudents.length,
            });
          }
          setGraphData(data);
          setRawGraphData(data);
        }
      }
    })();
  }, [startDate, endDate, currentView, studentsTextbooks]);

  const filterByData = (
    array,
    parentElement,
    param,
    data,
    events,
    isBranch
  ) => {
    for (let j = 0; j < array.length; j++) {
      const element = array[j];
      const elementValue = isBranch ? element.id : element;
      const currentMonthTests = events.filter(
        (x) =>
          moment(x.createdAt).isSame(parentElement, "day") &&
          x[param] === elementValue
      );
      data.push({
        date: `${isBranch ? element.name : element} - ${moment(
          parentElement
        ).format("DD/MM")}`,
        students: currentMonthTests.length,
      });
    }
  };

  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return dateArray;
  }
  const handleChange = (selected) => {
    setSelectedTeacher(selected);
    if (selected && selected.length > 0) {
      let filteredData = [];
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];
        filteredData = [
          ...filteredData,
          ...rawGraphData.filter((x) => x.date.includes(element.value)),
        ];
      }
      setGraphData(filteredData);
    } else {
      setGraphData(rawGraphData);
    }
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>תלמידים חדשים לפי ימים</h1>
      </div>

      <Row>
        <Button
          text="לפי חודשים"
          onClick={() => {
            setCurrentView("byMonths");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רכבים"
          onClick={() => {
            setCurrentView("byVehicles");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רישון"
          onClick={() => {
            setCurrentView("byLicense");
          }}
          marginRight="1"
        />
        <Button
          text="לפי יוצר"
          onClick={() => {
            setCurrentView("byUser");
          }}
          marginRight="1"
        />
        <Button
          text="לפי מורה"
          onClick={() => {
            setCurrentView("byTeacher");
          }}
          marginRight="1"
        />
        <Button
          text="לפי סניפים"
          onClick={() => {
            setCurrentView("byBranch");
          }}
          marginRight="1"
        />
        <div style={{ width: "33%", marginRight: "1vw" }}>
          <Select
            options={selectOptions}
            value={selectedTeacher}
            isMulti
            placeholder="בחר"
            styles={customStyles}
            onChange={handleChange}
            isRtl
          />
        </div>
        <>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart data={graphData}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="students" fill="#82ca9d" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    students: state.StudentsReducer.students,
    isLoaded: state.StudentsReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  allActiveStudentsTextbooks,
  getBranches,
})(NewStudentsByMonth);
