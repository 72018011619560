import React from "react";
import { getUsers } from "../../../actions/userActions";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import Select from "react-select";

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "300px",
    minWidth: 150
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    paddingRight: "0.5vw",
  }),
};

class SelectTeacher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSave: false,
      options: [],
      selected: null,
    }
  }

  componentDidMount = async () => {
    if (!this.props.isLoggedIn) return;
    if (!this.props.isUsersLoaded) {
      await this.props.getUsers();
    }
    const options = [
      { value: -999, label: "הכל" },
      ...this.props.users
        .filter(
          (x) => (x.userType === 1 || x.userType === 4) && x.isActive === 1
        )
        .map((x) => ({
          value: x.id,
          label: `${x.firstName} ${x.lastName}`,
        })),
    ];
    this.setState({ options })
    const lsV = window.localStorage.getItem('selectedTeacher');
    try {
      if (lsV !== null && lsV !== undefined && lsV !== '') {
        this.setState({
          selected: JSON.parse(lsV),
          isSave: true
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  componentDidUpdate(PrevProps, prevState){
    if (prevState.selected != this.state.selected) {
      if (this.state.isSave) {
        window.localStorage.setItem('selectedTeacher', JSON.stringify(this.state.selected));
      }
      if (this.props.selected) this.props.selected(this.state.selected);
    }
    if (prevState.isSave != this.state.isSave) {
      if (!this.state.isSave) window.localStorage.removeItem('selectedTeacher');
      else {
        if (this.state.isSave) {
          window.localStorage.setItem('selectedTeacher', JSON.stringify(this.state.selected));
        }
      }
    }
  }

  handleSelectedChange = async (selected) => {
    this.setState({ selected });
  };

  render() {
    let _carInInput = null;
    if (this.props.withSave) {
      _carInInput = <Toggle
      id="cheese-status"
      defaultChecked={false}
      checked={this.state.isSave}
      value={this.state.isSave}
      onChange={() =>
        this.setState({ isSave: !this.state.isSave })
      }
      style={{
        marginTop: 5
      }}
    />
    }
    // this.props.user.userType === 1 ? null : 
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'end',
        alignItems: 'center',
      }}>
        <Select
          options={this.state.options}
          isMulti={this.props.isMulti}
          value={this.state.selected}
          placeholder="רכב"
          styles={customStyles}
          isRtl={true}
          onChange={this.handleSelectedChange}
          style={{
            minWidth: '100%'
          }}
        />  &nbsp;&nbsp;
        { _carInInput }
        { this.props.children }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    user: state.session.user,
    isMulti: true,
  };
}

export default connect(mapStateToProps, {
  getUsers,
})(SelectTeacher);