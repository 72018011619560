import {
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAILED,
  STUDENT_CREATE_STARTED,
  STUDENT_FETCH_SUCCESS,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_DELETE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, students: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case STUDENT_FETCH_SUCCESS:
      return {
        ...state,
        students: action.payload,
        isLoaded: true,
        isPending: false,
      };
    case STUDENT_CREATE_SUCCESS:
      return {
        ...state,
        students: [...state.students, action.payload],
        isPending: false,
      };
    case STUDENT_CREATE_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case STUDENT_UPDATE_SUCCESS:
      const students = state.students.map((event) =>
        event.studentId === action.payload.studentId
          ? { ...event, ...action.payload }
          : event
      );
      return {
        ...state,
        students,
        isPending: false,
      };
    case STUDENT_DELETE_SUCCESS:
      return {
        ...state,
        students: state.students.filter(
          (student) => student.id !== action.payload
        ),
        isPending: false,
      };
    case STUDENT_CREATE_FAILED:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
}
