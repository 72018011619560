import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import SivanModal from "../../../Modal/Modal";
import StudentIncomesSearch from "./StudentIncomesSearch";

const StudentIncomes = ({
  isModalOpen,
  closeModal,
  student,
  schoolId,
}) => {
  return (
    <SivanModal
      titleText={student ? `Incomes of student "${student.firstName} ${student.lastName}"` : ''}
      modalIsOpen={isModalOpen}
      closeModal={() => closeModal(false)}
    >
      <StudentIncomesSearch 
        schoolId={schoolId}
        student={student}
      />
    </SivanModal>
  );
};

export default connect(null, {})(StudentIncomes);
