import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import SivanModal from "../../Modal/NewModal";
import { Body, Button } from "./teacherChange.styles";
import { updateTeacher } from "../../../actions/schedulerActions";
import { getUsers } from "../../../actions/userActions";
import useTeacheres from "../../useComponents/useTeachers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const TeacherChange = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  selectedEvent,
  updateTeacher,
  getUsers,
  users,
  isUsersLoaded,
}) => {
  const [teacher, setTeacher] = useState(null);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);

  useEffect(() => {
    if (teachersOptions.length > 0) {
      setTeacher(
        teachersOptions.find(
          (x) => x.value.toString() === selectedEvent.teacherId
        )
      );
    }
  }, [teachersOptions]);

  const makeAction = async () => {
    await updateTeacher(selectedEvent.id, teacher.value.toString());
    setNewAgreementModalIsOpen(false);
  };

  return (
    <SivanModal
      titleText="שינוי מורה"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={false}
    >
      <Body>
        <Autocomplete
          id="combo-box-demo"
          options={teachersOptions}
          getOptionLabel={(option) => option.label}
          value={teacher}
          onChange={(event, value) => setTeacher(value)}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="רכב"
              variant="outlined"
            />
          )}
        />
        <Button onClick={() => makeAction()}>שמור</Button>
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
  };
}

export default connect(mapStateToProps, {
  updateTeacher,
  getUsers,
})(TeacherChange);
