import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getVehicles } from "../../../actions/vehicleActions";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import Select from "react-select";

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "300px",
    minWidth: 150
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    paddingRight: "0.5vw",
  }),
};

class SelectVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCarInInput: false,
      vehicleOptions: [],
      selectedVehicle: null,
    }
  }

  componentDidMount = async () => {
    if (!this.props.isLoggedIn) return;
    if (!this.props.isVehiclesLoaded) {
      await this.props.getVehicles();
    }
    const vehicleOptions = this.props.vehicles
      .filter((x) => x.active === 1)
      .map((x) => ({ value: x.id, label: x.description }));
    this.setState({ vehicleOptions })
    const lsV = window.localStorage.getItem('selectedVehicle');
    try {
      if (lsV !== null && lsV !== undefined && lsV !== '') {
        this.setState({
          selectedVehicle: JSON.parse(lsV),
          isCarInInput: true
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  componentDidUpdate(PrevProps, prevState){
    if (prevState.selectedVehicle != this.state.selectedVehicle) {
      if (this.state.isCarInInput) {
        window.localStorage.setItem('selectedVehicle', JSON.stringify(this.state.selectedVehicle));
      }
      if (this.props.selectedVehicle) this.props.selectedVehicle(this.state.selectedVehicle);
    }
  }

  handleVehicleChange = async (selectedVehicle) => {
    this.setState({ selectedVehicle });
  };

  render() {
    let _carInInput = null;
    if (this.props.withSaveCar) {
      _carInInput = <Toggle
      id="cheese-status"
      defaultChecked={false}
      checked={this.state.isCarInInput}
      value={this.state.isCarInInput}
      onChange={() =>
        this.setState({ isCarInInput: this.state.isCarInInput ? false : true }, () => {
          if (!this.state.isCarInInput) window.localStorage.removeItem('selectedVehicle');
          else {
            if (this.state.isCarInInput) {
              console.log('set vehicle in ls');
              window.localStorage.setItem('selectedVehicle', JSON.stringify(this.state.selectedVehicle));
            }
          }
        })
      }
      style={{
        marginTop: 5
      }}
    />
    }

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'end',
        alignItems: 'center',
      }}>
        <Select
          options={this.state.vehicleOptions}
          isMulti={this.props.isMulti}
          value={this.state.selectedVehicle}
          placeholder="רכב"
          styles={customStyles}
          isRtl={true}
          onChange={this.handleVehicleChange}
          style={{
            minWidth: '100%'
          }}
        />  &nbsp;&nbsp;
        { _carInInput }
        { this.props.children }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    user: state.session.user,
    isMulti: true,
  };
}

export default connect(mapStateToProps, {
  getVehicles,
})(SelectVehicle);