import { useEffect } from "react";

const UseLoadStudents = (isLoaded, getStudents) => {
  useEffect(() => {
    async function fetchData() {
      //TODO: after ticket done added this back
      // if (!isLoaded)

      await getStudents();
    }
    fetchData();
  }, []);
};

export default UseLoadStudents;
