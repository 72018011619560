import React, { useState } from "react";
import {
  Container,
  Icon,
  TitleContainer,
  TextAfterIcon,
  IconsAndTextContainer,
} from "./eventView.styles";
import moment from "moment";
import { connect } from "react-redux";
import EditEvent from "../EditEvent/EditEvent";
import ReactTooltip from "react-tooltip";
import { isMobile } from "react-device-detect";
import { getAgreementDetails } from "../../../actions/agreementsActions";
import {
  getStudentTextBooks,
  fetchTextBook,
} from "../../../actions/textBooksActions";
import {
  getStudentEvents,
  getStudentEventCount,
} from "../../../actions/schedulerActions";
import { sendWhatsAppEventMessage } from "../../../utils/events";

const eventStatusIcons = {
  0: "icons8-question-mark",
  1: "icons8-checkmark",
  2: "icons8-shekel",
  3: "icons8-cancel",
  4: "icons8-cancel-with-usd",
};

const testStatus = [
  { icon: "icons8-question", text: "בהזמנה" },
  { icon: "icons8-happy", text: "עבר" },
  { icon: "icons8-crying", text: "נכשל" },
];

const drivingPermitsOptions = [
  { value: 11, label: "כביש" },
  { value: 10, label: "מגרש" },
];
const formatPhoneForWhatsapp = (phoneNumber) => {
  phoneNumber = phoneNumber.trim();
  phoneNumber = phoneNumber.replaceAll('-', '');
  if (phoneNumber.includes('+972')) return phoneNumber;
  else if (phoneNumber.includes('972')) return '+' + phoneNumber;
  else {
    return '+972' + phoneNumber;
  }
}

const EventView = (event) => {
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const [displayBalance, setDisplayBalance] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isShowCount, setIsShowCount] = useState(false);
  const [studentEventCount, setStudentEventCount] = useState(0);
  const [studentProfile, setStudentProfile] = useState(null);

  const loadStudentProfile = async () => {
    const profile = await event.loadStudentProfile(event.event);
    setStudentProfile(profile)
  }

  const getStudentEventCount = async () => {
    if (!isShowCount) {
      await loadStudentProfile()
      setIsShowCount(true);
    } else {
      setIsShowCount(false);
    }
  };

  const setStudentBalance = async () => {
    if (displayBalance) {
      setDisplayBalance(false);
    } else {
      await loadStudentProfile()
      setDisplayBalance(true);
    }
  };

  const getEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 1:
        return <Icon className="icons8-steering-wheel" style={{ margin: 0 }} />;
      case 2:
        return <Icon className="icons8-graduation-cap" />;
      case 3:
        return <Icon className="icons8-road" />;
      case 4:
        return <Icon className="icons8-tie" />;
      default:
        return "";
    }
  };

  const getNameStyle = (studentData) => {
    let styles = {};
    if (studentData.internalTestStatus) {
      styles.borderTop = "1px solid black";
    }
    if (
      studentData.conversion ||
      studentData.refresh ||
      studentData.renewal ||
      studentData.control
    ) {
      styles.borderLeft = "1px solid black";
      styles.borderRight = "1px solid black";
    }
    if (
      studentData.fundingBody !== 999 &&
      studentData.fundingBody !== null &&
      typeof studentData.fundingBody !== "undefined"
    ) {
      styles.borderBottom = "1px solid black";
    }

    return styles;
  };

  return (
    <Container
      dir="auto"
      style={{ justifyContent: "start" }}
      chosenStudentColor={event.chosenStudentColor}
      fontChosenStudentColor={event.fontChosenStudentColor}
      isStudentChosen={
        typeof event.chosenStudentId !== "undefined" &&
        event.chosenStudentId === event.event.studentId
      }
    >
      <ReactTooltip
        place="top"
        type="light"
        effect="float"
        html={true}
        id={event.event.id.toString()}
      />
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={event.eventTime}
          selectedEvent={{
            ...event.event,
            name: event.event.name
              ? event.event.name
              : `${event.event.studentId} - ${event.event.firstName} ${event.event.lastName}`,
          }}
        />
      )}
      <TitleContainer dir="auto" className="eventView" isDay={event.isMargin}>
        {event.showStudent && typeof event.event.firstName === "string" ? (
          <IconsAndTextContainer
            // onClick={() => event.openStudentMenuModal(event.event)}
            onClick={() =>
              event.onStudentClick({
                event: event.event,
                value: event.event.studentId,
                label: `${event.event.firstName} ${event.event.lastName}`,
              })
            }
          >
            {/*<Icon
              className="icons8-user-group-man-man"
              data-tip={`<span dir="auto">${event.event.studentNameWithoutNickname}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
            />*/}
            <Icon
              className="icons8-whatsapp"
              data-tip={`<span dir="auto">${event.event.studentNameWithoutNickname}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
              onClick={() => sendWhatsAppEventMessage(event.event, event.event, event.user)}
            />
            <TextAfterIcon
              isDay={event.isDay}
              style={{
                ...getNameStyle(event.event),
              }}
            >
              {event.event.studentNameWithoutNickname}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
      </TitleContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {event.showEventStatus ? (
          <IconsAndTextContainer
            onClick={() => event.openEventStatusChangeModal(event.event)}
            style={{ margin: 0 }}
          >
            <Icon
              className={eventStatusIcons[event.event.eventStatus]}
              style={{ margin: 0 }}
              aria-hidden="true"
            />
          </IconsAndTextContainer>
        ) : null}
        {event.showVehicle && typeof event.event.description === "string" ? (
          <IconsAndTextContainer onClick={() => setIsEditLessonModalOpen(true)}>
            <Icon
              className="icons8-car"
              data-tip={`<span dir="auto">${event.event.description}</span>`}
              data-for={event.event.id.toString()}
            />
            <TextAfterIcon>{event.event.description}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {event.showBalance ? (
          <IconsAndTextContainer onClick={() => setStudentBalance()}>
            {displayBalance && <TextAfterIcon>{studentProfile.studentBalanceWithFutureBalance}</TextAfterIcon>}
            <Icon className="icons8-scales" />
          </IconsAndTextContainer>
        ) : null}
        &nbsp;
        {event.showBranch && event.event.eventType !== 4 ? (
          <IconsAndTextContainer>
            <TextAfterIcon>{event.event.branchName}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        &nbsp;
        {event.showEventType ? (
          <IconsAndTextContainer onClick={() => getStudentEventCount()}>
            {getEventTypeIcon(event.event.eventType)}
            <TextAfterIcon>
              {isShowCount ? studentProfile.totalEventsForType : event.event.title}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        <IconsAndTextContainer>
          {event.event.eventType === 3 && (
            <Icon className={testStatus[event.event.internalTestStatus].icon} />
          )}
          {event.event.eventType === 2 && (
            <Icon className={testStatus[event.event.testStatus].icon} />
          )}
        </IconsAndTextContainer>
      </div>
      {event.showNotes && typeof event.event.notes === "string" ? (
        <IconsAndTextContainer
          data-tip={event.event.notes}
          onClick={() => event.openNotesChangeModal(event.event)}
          data-for={event.event.id.toString()}
        >
          <Icon className="icons8-comments" />
          <TextAfterIcon>{event.event.notes}</TextAfterIcon>
        </IconsAndTextContainer>
      ) : null}
      {typeof event.event.drivingPermitId === "number" && (
        <IconsAndTextContainer>
          <Icon className="fa fa-id-card" />
          <TextAfterIcon>
            {
              drivingPermitsOptions.find(
                (x) => x.value === event.event.drivingPermitId
              )?.label
            }
          </TextAfterIcon>
        </IconsAndTextContainer>
      )}
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    showEventStatus: state.session.user.showEventStatus,
    showStudent: state.session.user.showStudent,
    showVehicle: state.session.user.showVehicle,
    showBalance: state.session.user.showBalance,
    showBranch: state.session.user.showBranch,
    eventTime: state.session.user.eventTime,
    showNotes: state.session.user.showNotes,
    users: state.UsersReducer.users,
    chosenStudentColor: state.session.user.chosenStudentColor,
    showEventType: state.session.user.showEventType,
    fontChosenStudentColor: state.session.user.fontChosenStudentColor,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {
  getAgreementDetails,
  getStudentEvents,
  getStudentEventCount,
  getStudentTextBooks,
  fetchTextBook,
})(EventView);
