import pickupLocationsApi from "../apis/pickupLocations";
import {
  PICKUP_LOCATIONS_FETCH_SUCCESS,
  PICKUP_LOCATION_CREATE_SUCCESS,
  PICKUP_LOCATION_DELETE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

//TODO: added teacherId and schoolId
export const createPickupLocation = (location) => async (
  dispatch,
  getState
) => {
  try {
    const token = window.localStorage.getItem("token");
    const { branchId } = getState().session.user;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await pickupLocationsApi.post(
      "/create",
      {
        location: { ...location, branchId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: PICKUP_LOCATION_CREATE_SUCCESS,
      payload: { ...location, id: response.data.pickupId },
    });
  } catch (error) {
    toast.error("כישלון בעדכון מקום איסוף");
  }
};

export const getPickupLocations = () => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const { branchId } = getState().session.user;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await pickupLocationsApi.get(
      `/getPickupLocations/${branchId}`,
      {
        headers,
      }
    );

    dispatch({
      type: PICKUP_LOCATIONS_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    toast.error("כישלון בשליפת נקודות איסוף");
  }
};

export const deletePickupLocation = (pickupId) => async (
  dispatch,
  getState
) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await pickupLocationsApi.delete(
      "/deletePickup",
      {
        data: { pickupId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: PICKUP_LOCATION_DELETE_SUCCESS,
      payload: pickupId,
    });
  } catch (error) {
    toast.error("כישלון במחיקת נקודת איסוף");
  }
};
