export default (values) => {
  const errors = {};
  if (!values.description) {
    errors.description = "Required";
  }
  if (
    !values.lessonsAgreementDetails ||
    !values.lessonsAgreementDetails.length
  ) {
    errors.lessonsAgreementDetails = {
      error: "At least one member must be entered",
    };
  } else {
    const lessonsAgreementDetailsArrayErrors = [];
    values.lessonsAgreementDetails.forEach(
      (agreementDetails, agreementDetailsIndex) => {
        const agreementDetailsErrors = {};
        if (!agreementDetails || !agreementDetails.price) {
          agreementDetailsErrors.price = "Required";
          lessonsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }
      }
    );
    if (lessonsAgreementDetailsArrayErrors.length) {
      errors.lessonsAgreementDetails = lessonsAgreementDetailsArrayErrors;
    }
  }

  if (
    !values.lessonsAgreementDetails ||
    !values.lessonsAgreementDetails.length
  ) {
    errors.lessonsAgreementDetails = {
      error: "At least one member must be entered",
    };
  } else {
    const lessonsAgreementDetailsArrayErrors = [];
    values.lessonsAgreementDetails.forEach(
      (agreementDetails, agreementDetailsIndex) => {
        const agreementDetailsErrors = {};
        if (!agreementDetails || !agreementDetails.price) {
          agreementDetailsErrors.price = "Required";
          lessonsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }

        if (!agreementDetails || !agreementDetails.duration) {
          agreementDetailsErrors.duration = "Required";
          lessonsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }

        if (!agreementDetails || !agreementDetails.amount) {
          agreementDetailsErrors.amount = "Required";
          lessonsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }
      }
    );
    if (lessonsAgreementDetailsArrayErrors.length) {
      errors.lessonsAgreementDetails = lessonsAgreementDetailsArrayErrors;
    }
  }

  if (!values.testsAgreementDetails || !values.testsAgreementDetails.length) {
    errors.testsAgreementDetails = {
      error: "At least one member must be entered",
    };
  } else {
    const testsAgreementDetailsArrayErrors = [];
    values.testsAgreementDetails.forEach(
      (agreementDetails, agreementDetailsIndex) => {
        const agreementDetailsErrors = {};
        if (!agreementDetails || !agreementDetails.price) {
          agreementDetailsErrors.price = "Required";
          testsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }

        if (!agreementDetails || !agreementDetails.amount) {
          agreementDetailsErrors.amount = "Required";
          testsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }
      }
    );
    if (testsAgreementDetailsArrayErrors.length) {
      errors.testsAgreementDetails = testsAgreementDetailsArrayErrors;
    }
  }

  if (
    !values.internalTestsAgreementDetails ||
    !values.internalTestsAgreementDetails.length
  ) {
    errors.internalTestsAgreementDetails = {
      error: "At least one member must be entered",
    };
  } else {
    const internalTestsAgreementDetailsArrayErrors = [];
    values.internalTestsAgreementDetails.forEach(
      (agreementDetails, agreementDetailsIndex) => {
        const agreementDetailsErrors = {};
        if (!agreementDetails || !agreementDetails.price) {
          agreementDetailsErrors.price = "Required";
          internalTestsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }

        if (!agreementDetails || !agreementDetails.amount) {
          agreementDetailsErrors.amount = "Required";
          internalTestsAgreementDetailsArrayErrors[
            agreementDetailsIndex
          ] = agreementDetailsErrors;
        }
      }
    );
    if (internalTestsAgreementDetailsArrayErrors.length) {
      errors.internalTestsAgreementDetails = internalTestsAgreementDetailsArrayErrors;
    }
  }

  if (values.fromTestNumber || values.extraPriceAfter) {
    if (!values.fromTestNumber) errors.fromTestNumber = "Required";
    if (!values.extraPriceAfter) errors.extraPriceAfter = "Required";
  }

  return errors;
};
