import React, { useState, useEffect } from "react";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title } from "./incomesFromStudents.styles";
import IncomeData from "./IncomeData/IncomeData";
import { getAll } from "../../../actions/incomesAction";
import Table from "../../SivanPlusTable/SivanPlusTable";
import CreateButton from "../../UiComponents/Buttons/Default";
import NewCharge from "../../Calendar/NewCharge/NewCharge";
import CreditCardBill from "./CreditCardBill/CreditCardBill";
import { DateColumnFilter, DefaultColumnFilter } from "../../SivanPlusTable/filters";
import moment from "moment";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
  { value: 6, label: "סליקה" },
];
const Incomes = ({
  incomes,
  isLoaded,
  getAll,
  user,
  users,
  isUsersLoaded,
  getUsers,
}) => {
  const [isProviderModalOpen, setProviderModalOpen] = useState(false);
  const [providerData, setProviderData] = useState(false);
  const [isShowBillingCard, setIsShowBillingCard] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] =
    useState(false);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);

  useEffect(() => {
    async function fetchData() {
      await getAll(1);
    }
    fetchData();
  }, []);

  const vehicleColumns = [
    {
      Header: dictionary.table.date,
      accessor: "date",
      Cell: ({ row: { original } }) => {
        // console.log('original:', original);
        try {
          const date = moment(original.date).format("DD/MM/YYYY");
          // console.log('date:', date);
          return (original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 < 0 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>{date}</span>
          ) : (
            date
          ));
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
      Filter: DateColumnFilter,
      filter: (array, s, date) => {
        return array.filter((x) => moment(x.original.date).isSame(date, "day"));
      },
    },
    {
      Header: "מספר מסמך כספי",
      accessor: "documentNumber",
      Cell: ({ row: { original } }) => {
        try {
          return (original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {original.documentNumber}
            </span>
          ) : (
            original.documentNumber
          ));
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
    },
    {
      Header: "אסמתכא",
      accessor: "textbookId",
      Cell: ({ row: { original } }) => {
        try {
          return original.textbookId
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
    },
    {
      Header: "זמן פרעון",
      accessor: "dateOfMaturity",
      Cell: ({ row: { original } }) => {

        try {
          const date = moment(original.dateOfMaturity);
          if (!date.isValid()) return '-';
          return date.format("DD/MM/YYYY");
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
      Filter: DateColumnFilter,
      filter: (array, s, date) => {
        return array.filter((x) => moment(x.original.date).isSame(date, "day"));
      },
    },
    {
      Header: "מורה",
      accessor: "teacherId",
      Cell: ({ row: { original } }) => {
        try {
          let teacherName = "";
          if (teachersOptions.length !== 0)
            teacherName = teachersOptions.find(
              (x) => x.value.toString() === original.teacherId
            )?.label;

          return (original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 < 0 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {teacherName}
            </span>
          ) : (
            teacherName
          ));
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
      Filter: DefaultColumnFilter,
      filter: (array, s, t) => {
        return array.filter((x) => x.original.teacherUsername === t || x.original.teacherUsername.includes(t))
      },
    },
    {
      Header: dictionary.table.studentId,
      accessor: "clientId",
      Cell: ({ row: { original } }) => {
        try {
          return (original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 < 0 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {original.clientId}
            </span>
          ) : (
            original.clientId
          ));
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
    },
    {
      Header: 'חשבון',
      accessor: "accountId",
      Cell: ({ row: { original } }) => {
        return original.accountId || ''
      },
    },
    {
      Header: 'מספר אישור',
      accessor: "confirmationNumber",
      Cell: ({ row: { original } }) => {
        return original.confirmationNumber || ''
      },
    },
    {
      Header: dictionary.table.vehicleNumber,
      accessor: "vehicleNumber",
      Cell: ({ row: { original } }) => {
        try {
          const vehicle =
            original.vehicleId === 999 ? "כל הרכבים" : original.vehicleNumber;

          return (original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 < 0 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>{vehicle}</span>
          ) : (
            vehicle
          ));
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
    },
    {
      Header: 'שם תלמיד',
      accessor: "studentUsername",
      Cell: ({ row: { original } }) => {
        return original.studentUsername
      },
    },
    {
      Header: dictionary.table.amount,
      accessor: "amount",
      Cell: ({ row: { original } }) => {
        try {
          return (original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 < 0 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {original.amount}
            </span>
          ) : (
            original.amount
          ));
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
    },
    {
      Header: dictionary.table.documentType,
      accessor: "documentType",
      Cell: ({ row: { original } }) => {
        try {
          const document = documentTypes.find(
            (x) => x.value === original.documentType
          )?.label;

          return(original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 < 0 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>{document}</span>
          ) : (
            document
          ));
        } catch (err) { console.log('err:', err);
          return ''
        }
      },
      Filter: DefaultColumnFilter,
      filter: (array, s, t) => {
        const dict_r = {
          'חשבונית/קבלה': 0,
          'קבלה': 1,
          'חשבונית': 2,
          'חשבונית/קבלה זיכוי': 3,
          'קבלה זיכוי': 4,
          'חשבונית זיכוי': 5,
          'הנחה': 6,
          'חיוב': 7,
        };
        const dict = {
          0: 'חשבונית/קבלה',
          1: 'קבלה',
          3: 'חשבונית/קבלה זיכוי',
          4: 'קבלה זיכוי',
          5: 'חשבונית זיכוי',
          6: 'הנחה',
          7: 'חיוב'
        };
        try {
          return array.filter((x) => {
            let documentType = null;

            if ('חשבונית'.includes(t)) documentType = 2;
            else if ('חשבונית/קבלה'.includes(t)) documentType = 0;
            else if ('קבלה'.includes(t)) documentType = 1;
            else if ('חשבונית/קבלה זיכוי'.includes(t)) documentType = 3;
            else if ('קבלה זיכוי'.includes(t)) documentType = 4;
            else if ('חשבונית זיכוי'.includes(t)) documentType = 5;
            else if ('הנחה'.includes(t)) documentType = 6;
            else if ('חיוב'.includes(t)) documentType = 7;

            if (documentType !== null) {
              return x.original.documentType === documentType
            }
            return 0;
          })
        } catch (err) {
          return [];
        }
      },
    },
    {
      Header: "צורת תשלום",
      accessor: "paymentMethod",
      Cell: ({ row: { original } }) => {
        try {
          // console.log(original.paymentMethod);
          const method = paymentMethods.find(
            (x) => x.value === original.paymentMethod
          )?.label;

          return (original.documentType === 3 ||
            original.documentType === 4 ||
            original.documentType === 5 < 0 ? (
            <span style={{ color: "red", fontWeight: "bold" }}>{method}</span>
          ) : (
            <>{original.paymentMethod === 6 ? 'סליקה' : method}</>
          ));
        } catch (err) { console.log('err:', err);
          return '';
        }
      },
      Filter: DefaultColumnFilter,
      filter: (array, s, t) => {
        const dict = {
          'אפליקציה': 5,
          'אשראי': 4,
          'העברה': 3,
          'שיק': 2,
          'מזומן':1,
          'סליקה':6
        };
        try {
          let documentType = null;

          if ('אפליקציה'.includes(t)) documentType = 5;
          else if ('אשראי'.includes(t)) documentType = 4;
          else if ('העברה'.includes(t)) documentType = 3;
          else if ('שיק'.includes(t)) documentType = 1;
          else if ('מזומן'.includes(t)) documentType = 0;
          else if ('סליקה'.includes(t)) documentType = 6;

          if (documentType !== null) {
            return array.filter((x) => x.original.paymentMethod === documentType)
          }
          return 0
        } catch (err) {
          return [];
        }
      },
    },
    {
      Header: "נוצר ע״י",
      accessor: "createUsername",
    },
  ];

  const onRowClick = async (rowObject) => {
    setProviderDetailsModalOpen(true);
    setProviderData(rowObject.original);
  };
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });

  const getTotalAmount = () => {
    return filteredData
    .reduce((a, { original }) => {
      let amount = original.amount || 0;
      if (original.documentType === 3 ||
        original.documentType === 4 ||
        original.documentType === 5) {
          amount *= -1
        }
      a += amount;
      return a;
    }, 0)
    .toFixed(2)
  }
  // console.log('incomes:',incomes);
  return (
    <Container>
      {isProviderModalOpen && (
        <NewCharge
          isNewAgreementModalOpen={isProviderModalOpen}
          setNewAgreementModalIsOpen={() => setProviderModalOpen(false)}
        />
      )}
      <CreditCardBill
        isNewAgreementModalOpen={isShowBillingCard}
        setNewAgreementModalIsOpen={() => setIsShowBillingCard(false)}
      />
      <IncomeData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => { getAll(); setProviderDetailsModalOpen(false); }}
        providerData={providerData}
      />
      <Title>
        הכנסות מתלמידים
        {user.createStudentIncome ? (
          <CreateButton
            onClick={() => setProviderModalOpen(true)}
            text="צור חדש"
          />
        ) : null}
        {/* <CreateButton
          onClick={() => setIsShowBillingCard(true)}
          text="חייב אשראי"
        /> */}
      </Title>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="הכנסות מתלמידים"
        sheet="הכנסות"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <span>הדפס &nbsp;</span>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <br />
      סהכ:
      {getTotalAmount()}
      ש״ח
      {incomes.length>=1 && (
        <Table
          data={incomes}
          componentColumns={vehicleColumns}
          onRowClick={onRowClick}
          setFilteredData={setFilteredData}
        />
      )}
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    incomes: state.IncomesReducer.incomesFromStudents,
    isLoaded: state.IncomesReducer.isLoaded,
    createVehicle: state.session.user.createVehicle,
    user: state.session.user,
    editVehicle: state.session.user.editVehicle,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getAll,
})(Incomes);
