import React, { useState, useEffect } from "react";
import Select from "react-select";
import ErrorModal from "../../../Modal/ErrorModal";
import { useTranslation } from "react-i18next";
import EditTextBook from "./EditTextBook/EditTextBook";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  customStyles,
  StyledLoader,
  Row,
  Icon,
  TextBookRow,
} from "./textBooks.styles";
import { getVehicles } from "../../../../actions/vehicleActions";
import { getAgreements } from "../../../../actions/agreementsActions";
import { getStudentTextbookAllEvents } from "../../../../actions/schedulerActions";
import {
  createStudentTextBook,
  getStudentTextBooks,
  deleteStudentTextBook,
  fetchTextBook,
} from "../../../../actions/textBooksActions";
import useTeacheres from "../../../useComponents/useTeachers";
import useAgreements from "../../../useComponents/useAgreements";
import useVehicles from "../../../useComponents/useVehicles";
import { getUsers } from "../../../../actions/userActions";

const TextBooks = ({
  getVehicles,
  isPending,
  getAgreements,
  studentId,
  createStudentTextBook,
  getStudentTextBooks,
  deleteStudentTextBook,
  studentData,
  fetchTextBook,
  isUsersLoaded,
  getUsers,
  users,
  vehicles,
  isVehiclesLoaded,
  deleteTextBook,
  updateTextBook,
  getStudentTextbookAllEvents,
}) => {
  const { t, i18n } = useTranslation("common");
  const [selectedVehicleOption, setSelectedVehicleOption] = useState();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [selectedAgreementOption, setSelectedAgreementOption] = useState();
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [studentTextBooks, setStudentTextBooks] = useState([]);
  const [isTextBookEditModalOpen, setTextBookEditModalOpen] = useState(false);
  const [vehicleOptionsFiltred, setVehicleOptionsFiltred] = useState([]);
  const [textBookData, setTextBookData] = useState({});
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users.filter((x) => x.isActive === 1));
  const agreementList = useAgreements(getAgreements);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  useEffect(() => {
    async function fetchData() {
      if (studentTextBooks.length === 0) {
        const fetchedStundetTextBooks = await getStudentTextBooks(studentId);
        setStudentTextBooks(fetchedStundetTextBooks);
      }
    }
    fetchData();
  }, [isUsersLoaded]);

  useEffect(() => {
    if (studentTextBooks && vehicleOptions.length > 0) {
      setVehicleOptionsFiltred(
        vehicleOptions.filter(
          function (e) {
            return this.toString().indexOf(e.value.toString()) < 0;
          },
          studentTextBooks.map((x) => x.vehicleId)
        )
      );
    }
  }, [studentTextBooks, vehicleOptions]);

  const isHebrew = i18n.language === "he";
  const handleVehicleChange = (selectedOption) =>
    setSelectedVehicleOption(selectedOption);
  const handleAgreementChange = (selectedOption) =>
    setSelectedAgreementOption(selectedOption);
  const handleTeacherChange = (selectedOption) =>
    setSelectedTeacher(selectedOption);

  const addTextBookStudent = async () => {
    if (
      await createStudentTextBook(
        studentId,
        selectedAgreementOption.value,
        selectedVehicleOption.value,
        selectedTeacher.value
      )
    ) {
      const fetchedStundetTextBooks = await getStudentTextBooks(studentId);
      setStudentTextBooks(fetchedStundetTextBooks);
      setSelectedVehicleOption(vehicleOptions[0]);
    }
  };

  const deleteRank = async (studentTextBookId) => {
    const numOfEvents = await getStudentTextbookAllEvents(
      studentId,
      studentTextBookId
    );
    if (numOfEvents === 0) {
      if (await deleteStudentTextBook(studentTextBookId))
        setStudentTextBooks(
          studentTextBooks.filter(
            ({ textBookId }) => textBookId !== studentTextBookId
          )
        );
    } else {
      setErrorModalText("לתלמיד משויכים אירועים לתיק הלימוד");
      setIsErrorModalOpen(true);
    }
  };

  const openTextBook = async (studentTextBook) => {
    const textBook = await fetchTextBook(studentTextBook.textBookId);
    setTextBookData(textBook);
    setTextBookEditModalOpen(true);
  };

  return (
    <Container>
      {isTextBookEditModalOpen && (
        <EditTextBook
          isTextBookEditModalOpen={isTextBookEditModalOpen}
          setTextBookEditModalOpen={setTextBookEditModalOpen}
          textBookData={textBookData}
          studentData={studentData}
          agreementList={agreementList}
        />
      )}
      <Row>
        <Icon className="fa fa-plus-circle" onClick={addTextBookStudent} />
        <FormControlLabel
          value="muslim"
          control={
            <Checkbox
              color="primary"
              disabled={studentTextBooks.length === 0 ? true : false}
            />
          }
          label="העתק מהקודם"
          labelPlacement="end"
        />
        <Select
          isRtl={isHebrew}
          options={vehicleOptionsFiltred}
          value={selectedVehicleOption}
          placeholder="רכב"
          styles={customStyles}
          onChange={handleVehicleChange}
        />
        <Select
          isRtl={isHebrew}
          options={agreementList}
          value={selectedAgreementOption}
          placeholder="הסכם"
          styles={customStyles}
          onChange={handleAgreementChange}
        />
        <Select
          isRtl={isHebrew}
          options={teachersOptions}
          value={selectedTeacher}
          placeholder="מורה"
          styles={customStyles}
          onChange={handleTeacherChange}
        />
      </Row>
      {studentTextBooks.map((textBook) => (
        <TextBookRow
          key={textBook.textBookId}
          onClick={() => (updateTextBook ? openTextBook(textBook) : null)}
        >
          <span>{textBook.vehicleNumber}</span>
          <span>
            {
              teachersOptions.find(
                (x) => x.value.toString() === textBook?.teacherId?.toString()
              )?.label
            }
          </span>
          <span>{textBook.description}</span>
          {studentTextBooks.length > 1 && deleteTextBook && (
            <Icon
              className="fa fa-trash-o"
              small
              onClick={(e) => {
                e.stopPropagation();
                deleteRank(textBook.textBookId);
              }}
            />
          )}
        </TextBookRow>
      ))}
      <StyledLoader active={isPending} spinner text="Creating Student..." />

      {isErrorModalOpen && (
        <ErrorModal
          modalIsOpen={isErrorModalOpen}
          closeModal={() => setIsErrorModalOpen(false)}
          text={errorModalText}
        />
      )}
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    isPending: state.StudentsReducer.isPending,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    deleteTextBook: state.session.user.deleteTextBook,
    updateTextBook: state.session.user.updateTextBook,
  };
}

export default connect(mapStateToProps, {
  getVehicles,
  getAgreements,
  createStudentTextBook,
  getStudentTextBooks,
  deleteStudentTextBook,
  getUsers,
  fetchTextBook,
  getStudentTextbookAllEvents,
})(TextBooks);
