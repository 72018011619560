import React, { useEffect, useState, useMemo } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import i18n from "../../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title, TitleText } from "./studentSearch.styles";
import Table from "../../../SivanPlusTable/SivanPlusTable";
import { SelectColumnFilter, DateColumnFilter } from "../../../SivanPlusTable/filters";
import { getBranches } from "../../../../actions/branchActions";
import {
  getStudentTextBooks,
  fetchTextBook,
} from "../../../../actions/textBooksActions";
import { getLicenseTypes } from "../../../../actions/licenseTypes";
import { getVehicles } from "../../../../actions/vehicleActions";
import { deleteStudents } from "../../../../actions/adminActions";
import { getStudent } from "../../../../actions/studentesActions";
import moment from "moment";
import { getStudents } from "../../../../actions/adminActions";
import QuestionModal from "../../../Modal/QuestionModal";
import { toast } from "react-toastify";
import StudentIncomes from "./StudentIncomes";
import Button from "../../../UiComponents/Buttons/Default";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const getNameStyle = (studentData) => {
  let styles = {};
  if (studentData.internalTestStatus) {
    styles.borderTop = "1px solid black";
  }
  if (
    studentData.conversion ||
    studentData.refresh ||
    studentData.renewal ||
    studentData.control
  ) {
    styles.borderLeft = "1px solid black";
    styles.borderRight = "1px solid black";
  }
  if (
    studentData.fundingBody !== 999 &&
    studentData.fundingBody !== null &&
    typeof studentData.fundingBody !== "undefined"
  ) {
    styles.borderBottom = "1px solid black";
  }

  return styles;
};

const studentColumns = isMobileOnly
  ? [
      {
        Header: dictionary.table.name,
        accessor: "firstName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.firstName}
          </span>
        ),
      },
      {
        Header: dictionary.table.lastName,
        accessor: "lastName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.lastName}
          </span>
        ),
      },
      {
        Header: dictionary.table.id,
        accessor: "studentId",
        Cell: ({ row: { original } }) => {
          return original.isChecked === 1 ? (
            <span>{original.studentId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.studentId}</span>
          );
        },
      },
    ]
  : isTablet
  ? [
      {
        Header: dictionary.table.name,
        accessor: "firstName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.firstName}
          </span>
        ),
      },
      {
        Header: dictionary.table.id,
        accessor: "studentId",
        Cell: ({ row: { original } }) => {
          return original.isChecked === 1 ? (
            <span>{original.studentId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.studentId}</span>
          );
        },
      },
      {
        Header: dictionary.table.teachers,
        accessor: "teacherName",
      },
      {
        Header: dictionary.table.license,
        accessor: "licenseType",
      },
      {
        Header: dictionary.table.active,
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return original.active === 1
            ? dictionary.users.active
            : dictionary.users.inactive;
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ]
  : [
      {
        Header: dictionary.table.name,
        accessor: "firstName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.firstName}
          </span>
        ),
      },
      {
        Header: dictionary.table.lastName,
        accessor: "lastName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.lastName}
          </span>
        ),
      },
      {
        Header: dictionary.table.phone,
        accessor: "phone",
      },
      {
        Header: dictionary.table.id,
        accessor: "studentId",
        Cell: ({ row: { original } }) => {
          return original.isChecked === 1 ? (
            <span>{original.studentId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.studentId}</span>
          );
        },
      },
      {
        Header: dictionary.table.teachers,
        accessor: "teacherName",
      },
      {
        Header: dictionary.table.license,
        accessor: "licenseType",
      },
      {
        Header: dictionary.table.vehicleNumber,
        accessor: "vehicleNumber",
      },
      {
        Header: dictionary.table.active,
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return original.active === 1
            ? dictionary.users.active
            : dictionary.users.inactive;
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: 'נוצר בתאריך',
        Cell: ({ row: { original } }) => {
          return moment(original.createDate).format("DD/MM/YYYY");
        },
        accessor: "createdDate",
        Filter: DateColumnFilter,
        filter: (array, s, date) => {
          return array.filter((x) => {
            return moment(x.original.createDate).isSame(date, "day")
          });
        },
        sortType: (a, b, columnId, desc) => {
          a = a.original.createDate;
          b = b.original.createDate;
          let a_split = a.split('T')[0];
          a = new Date(a_split);
          let b_split = b.split('T')[0];
          b = new Date(b_split);

          return b > a ? 1 : -1;
        }
      },
    ];
const StudentSearch = ({
  history,
  getBranches,
  schoolId,
  user
}) => {
  const { t, i18n } = useTranslation("common");
  const [students, setStudents] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isred, setIsRed] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isIncomesModal, setIsIncomesModal] = useState(false);
  const [isLoadingDeleteStudent, setIsLoadingDeleteStudent] = useState(false);
  const [userData, setUserData] = useState(null);
  const [allData, setAllData] = useState([]);
  const [tableColumns, setTableColumns] = useState(studentColumns);
  const [selectedRows, setSelectedRows] = useState([]);


  const getAllRed = () => {
    if (isred) {
      setIsRed(false);
      setStudents(allData);
    } else {
      setIsRed(true);
      let _ = [];
      students.forEach((item, i) => {
        if (item.isChecked !== 1) {
          _.push(item);
        }

      });
      setStudents(_);
    }

  }

  useEffect(() => {
    fetchData();
  }, [schoolId]);

  const fetchData = async () =>  {
    setIsTableLoading(true)
    let data = [];
    setAllData([]);
    try {
      data = await getStudents(schoolId, user.branchId, user.userType);
      setAllData(data);
    } catch (e) {
      toast.error("כישלון במחיקת תלמיד");
      console.error(e)
    }
    setIsTableLoading(false)
    if (data.length == 0) { return; }
    const allBranches = await getBranches();
    if (allBranches.filter((x) => x.name !== "כל הסניפים").length > 1) {
      if (
        studentColumns.findIndex((x) => x.accessor === "branchName") === -1
      ) {
        const _tableColumns =  [
          ...studentColumns,
          {
            Header: dictionary.table.branch,
            accessor: "branchName",
          },
        ];
        setTableColumns(_tableColumns)
      }
      setStudents(
        data.map((x) => ({
          ...x,
          branchName: allBranches.find((z) => z.id === x.branchId)?.name,
        }))
      );
    } else {
      setStudents(data);
    }
  }

  const selectionRowData = (row) => {    
    return <span
    style={{ flex: "0 20%", fontSize: "20px" }}
    className="icons8-message"
    onClick={() => {
      setUserData(row.original)
      setIsIncomesModal(true);
    } } />
  }

  useEffect(() => {
    fetchData();
    return () => {
      setStudents([]);
      setAllData([]);
      setUserData(null);
    }; 
  }, []);

  const localDeleteStudent = async () => {
    setIsLoadingDeleteStudent(true);
    setIsDeleteModal(false);
    try {
      const isDeleted = await deleteStudents(schoolId, selectedRows.map((v) => v.id))
      if (isDeleted == '1') {
        toast.success("התלמיד הוסר");
        fetchData()
      } else if (typeof isDeleted == "object") {
        for (const val of isDeleted) {
          if (val.status == '-1') {
            toast.warning("למיד אין רשומו"+` studentId# ${val.data}`);
          } else if (val.status == '-2') {
            toast.warning("Student have credit incoms."+` studentId# ${val.data}`);
          }
        }
      }
    } catch(e) {
      toast.error("כישלון במחיקת תלמיד");
      console.error(e)
    }
    setUserData(null);
    setIsLoadingDeleteStudent(false);
  }

  const closeQuestionModal = async () => {
    setUserData(null);
    setIsDeleteModal(false);    
    // fetchData();
  }

  const closeIncomesModal = async () => {
    setUserData(null);
    setIsIncomesModal(false);
  }

  const setCustomSelectedRows = (rows) => {
    if (rows.length != selectedRows.length) {
      setSelectedRows(rows.map((v) => v.original));
    }
  }
  return (
    <Container>
      <Title>
        <TitleText>{t("menuSubCategories.students.studentSearch")}</TitleText>
        <div>   
          <Button className="button-navigation"
            text={() => ({__html: `remove all <div style="font-size: 10px;">${selectedRows.length} records</div>`})}
            disabled={selectedRows.length===0}
            style={{marginLeft: "1.3"}}
            isDelete={ true }
            onClick={ () => setIsDeleteModal(true) }
          />
          <button onClick={() => getAllRed()}>{isred ? 'הצג הכל' : 'הצג רק אדומים'}</button>
        </div>
      </Title>
      <QuestionModal
        titleText="Do you want to remove selected students?"
        isDelete={true}
        isLoaded={isLoadingDeleteStudent}
        deleteItem={localDeleteStudent}
        modalIsOpen={isDeleteModal}
        closeModal={closeQuestionModal}
      />
      {isIncomesModal && <StudentIncomes schoolId={schoolId} student={userData} isModalOpen={isIncomesModal} closeModal={closeIncomesModal} /> }
      <Table
        data={students}
        componentColumns={tableColumns}
        // onRowClick={onRowClick}
        isLoading={isTableLoading}
        selectionRowData={selectionRowData}
        checkedRows={setCustomSelectedRows}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isLoggedIn: state.session.isLoggedIn,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getBranches,
    getStudentTextBooks,
    fetchTextBook,
    getLicenseTypes,
    getVehicles,
    getStudent,
  })(StudentSearch)
);
