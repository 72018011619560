export const documentTypes = {
  0: "חשבונית/קבלה",
  1: "קבלה",
  2: "חשבונית",
  3: "חשבונית/קבלה זיכוי",
  4: "קבלה זיכוי",
  5: "חשבונית זיכוי",
  6: "הנחה",
  7: "חיוב",
};
