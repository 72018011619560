import React, { useEffect, useState, useRef } from "react";
import { Container, Row, FieldText, Field } from "./TestsReport.styles";
import { connect } from "react-redux";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { getStudents } from "../../../actions/studentesActions";
import UseLoadStudents from "../../useComponents/UseLoadStudents";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import useProfessionalTeachers from "../../useComponents/useProfessionalTeachers";
import { getSchoolProfessionalTeachers } from "../../../actions/professionalTeachersActions";
import { getAllSchoolTestsEvents } from "../../../actions/schedulerActions";
import { useReactToPrint } from "react-to-print";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

let columns = [
  {
    Header: "שם",
    accessor: "firstName",
  },
  {
    Header: "שם משפחה",
    accessor: "lastName",
  },
  {
    Header: "תז",
    accessor: "studentId",
  },
  {
    Header: "מורה",
    accessor: "teacher",
  },
  {
    Header: "תאריך",
    accessor: "teacherName",
    Cell: ({ row: { original } }) => {
      return moment(original.start).format("DD.MM.YYYY");
    },
  },
  {
    Header: "שעה",
    accessor: "teacherNfame",
    Cell: ({ row: { original } }) => {
      return moment(original.start).format("HH:mm");
    },
  },
  {
    Header: "תוצאה",
    accessor: "testStatus",
    Cell: ({ row: { original } }) => {
      return original.testStatus === 0
        ? "לא ידוע"
        : original.testStatus === 1
        ? "עבר"
        : "נכשל";
    },
  },
];

const InternalTestsReport = ({
  getAllSchoolTestsEvents,
  getStudents,
  isStudentsLoaded,
  students,
  professionalTeachers,
  isProfessionalTeachersLoaded,
  getSchoolProfessionalTeachers,
  isUsersLoaded,
  users,
  getUsers,
}) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  UseLoadStudents(isStudentsLoaded, getStudents);

  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);

  useEffect(() => {
    (async () => {
      if (teachersOptions.length > 0) {
        const testEvents = await getAllSchoolTestsEvents(startDate, endDate);
        const data = testEvents
          .filter(
            (x) =>
              x.eventType === 2 && (x.eventStatus === 1 || x.eventStatus === 0)
          )
          .map((x) => {
            return {
              ...x,
              firstName: students.find((y) => y.studentId === x.studentId)
                ?.firstName,
              lastName: students.find((y) => y.studentId === x.studentId)
                ?.lastName,
              teacher: teachersOptions.find(
                (y) => y.value.toString() === x.teacherId
              )?.label,
            };
          });
        setTableData(data);
      }
    })();
  }, [startDate, endDate, teachersOptions]);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });

  return (
    <Container>
      <Row>
        <>
          <Field>
            <FieldText>תאריך סוף הדוח</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>תאריך התחלת הדוח</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={`דוח מבחנים מעשיים ${moment(startDate).format(
          "DD/MM/YYYY"
        )} - ${moment(endDate).format("DD/MM/YYYY")}`}
        sheet="מבחנים מעשיים"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <FieldText>הדפס &nbsp;</FieldText>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <Table data={tableData} componentColumns={columns} />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    students: state.StudentsReducer.students,
    userId: state.session.user.id,
    user: state.session.user,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    professionalTeachers:
      state.ProfessionalTeachersReducer.professionalTeachers,
    isProfessionalTeachersLoaded: state.ProfessionalTeachersReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getAllSchoolTestsEvents,
  getStudents,
  getSchoolProfessionalTeachers,
})(InternalTestsReport);
