import axios from "axios";

const axiosCreate = function (){
  const token = window.localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URI}/scheduler`,
    headers,
  })
}

export default axiosCreate();