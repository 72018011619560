import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import AdvancesToIrsModal from "./AdvancesToIrsModal/AdvancesToIrsModal";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  Body,
  PermanentDetailsContainer,
  AdditionalDetailsContainer,
  Input,
  ColumnTitle,
  Button,
  Fields,
  ButtonContainer,
  ToggleContainer,
  ToggleText,
} from "./finance.styles";
import { getVehicles } from "../../../actions/vehicleActions";

const renderInput = ({
  input,
  placeholder,
  meta: { touched, error },
  type = "text",
  disabled,
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <Input
      isRtl={isHebrew}
      dir={dir}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      {...input}
      isError={touched && error}
      autocomplete="off"
    />
  );
};

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const General = ({ getVehicles }) => {
  const [
    isAdvancesToTheIrsModalOpen,
    setIsAdvancesToTheIrsModalOpen,
  ] = useState(false);
  const [registrationFees, setRegistrationFees] = useState(false);

  return (
    <Container>
      <Body>
        <Fields>
          <PermanentDetailsContainer>
            <Field
              name="cpaEmail"
              component={renderInput}
              placeholder="CPA Email"
            />
            <Field
              name="businessInEilat"
              component={renderToggle}
              label="Business In Eilat"
            />
            <Field
              name="monthlySocialSecurityPayment"
              component={renderInput}
              placeholder="Monthly Social Security Payment"
            />
          </PermanentDetailsContainer>
          <AdditionalDetailsContainer>
            <Field
              name="messageFinancialDocumentByEmail"
              component={renderInput}
              placeholder="Message In A Financial Document Sent Email"
            />
            <Field
              name="messageFinancialDocumentByPrinter"
              component={renderInput}
              placeholder="Message In A Financial Document Sent To The Printer"
            />
            <ToggleContainer>
              <ToggleText>Alert Or Stop</ToggleText>
              <Toggle
                defaultChecked={registrationFees}
                icons={false}
                onChange={(option) => setRegistrationFees(option)}
              />
            </ToggleContainer>
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <ColumnTitle>Current Advance Payment Percentage</ColumnTitle>
            <Input placeholder="Work Phone" disabled value="80%" />
          </AdditionalDetailsContainer>
        </Fields>
      </Body>
      <ButtonContainer>
        <Button reset>Save</Button>
      </ButtonContainer>
      <AdvancesToIrsModal
        isModalOpen={isAdvancesToTheIrsModalOpen}
        closeModal={() => setIsAdvancesToTheIrsModalOpen(false)}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    initialValues: {
      cpaEmail: state.session.user.cpaEmail,
      messageFinancialDocumentByEmail:
        state.session.user.messageFinancialDocumentByEmail,
      invoiceDescription: state.session.user.invoiceDescription,
      cityNewStudent: state.session.user.cityNewStudent,
      monthlySocialSecurityPayment:
        state.session.user.monthlySocialSecurityPayment,
    },
  };
}

export default connect(mapStateToProps, { getVehicles })(
  reduxForm({
    form: "settingsFinance",
  })(General)
);
