import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import Toggle from "react-toggle";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
} from "./expenseDetails.styles";
import { update, deleteItem } from "../../../../../actions/expensesActions";
import CreateButton from "../../../../UiComponents/Buttons/Default";
import SivanQuestionModal from "../../../../Modal/QuestionModal";
import { getSchoolExpenseTypes } from "../../../../../actions/expenseTypesAction";
import { getVehicles } from "../../../../../actions/vehicleActions";
import useVehicles from "../../../../useComponents/useVehicles";
import { getSchoolProviders } from "../../../../../actions/providersActions";
import InputWrappedWithField from "../../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../../UiComponents/TextArea/Default";
import Input from "../../../../UiComponents/Inputs/Default";
import DefaultSelect from "../../../../UiComponents/Select/Default";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
  { value: 6, label: "סליקה" },
];

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const renderDatePickerField = ({
  input,
  placeholder,
  meta: { error, touched },
  isNoTime,
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <DatePicker
      {...input}
      selected={input.value}
      dir={dir}
      placeholderText={placeholder}
      dateFormat={isNoTime ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm"}
      showTimeSelect={!isNoTime}
      className={`${touched && error ? "error" : ""} date-input`}
      locale={i18n.language}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
        },
      }}
      onBlur={() => input.onBlur(input.value)}
    />
  );
};

const FundingBodiesDetails = ({
  handleSubmit,
  update,
  providerData,
  deleteItem,
  closeModal,
  getSchoolExpenseTypes,
  expenseTypes,
  isExpenseTypesLoaded,
  getVehicles,
  vehicles,
  isProvidersLoaded,
  providers,
  isVehiclesLoaded,
  getSchoolProviders,
  change,
  expenseType,
  paymentMethod,
  amount,
  vat,
}) => {
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [expenseTypesOptions, setExpenseTypesOptions] = useState([]);
  const [vatRecognizedSum, setVatRecognizedSum] = useState(0);
  const [providersOptions, setProvidersOptions] = useState([]);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  const fundingBodyCreateAction = async (formValues) => {
    await update({
      ...formValues,
      providerId: formValues.providerId.value,
      documentType: formValues.documentType.value,
      expenseType: formValues.expenseType.value,
      paymentMethod: formValues.paymentMethod.value,
      vehicleId: formValues.vehicleId.value,
      date: new Date(formValues.date),
    });
    closeModal();
  };

  useEffect(() => {
    async function fetchData() {
      if (!isExpenseTypesLoaded) {
        await getSchoolExpenseTypes();
      }
      if (!isProvidersLoaded) {
        await getSchoolProviders();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isExpenseTypesLoaded) {
      const options = expenseTypes.map((x) => ({ value: x.id, label: x.name }));
      setExpenseTypesOptions(options);
      change(
        "expenseType",
        options.find((x) => x.value === providerData.expenseType)
      );
    }
  }, [isExpenseTypesLoaded]);

  useEffect(() => {
    if (isProvidersLoaded) {
      const options = providers.map((x) => ({ value: x.id, label: x.name }));
      setProvidersOptions(options);
      change(
        "providerId",
        options.find((x) => x.value === providerData.providerId)
      );
    }
  }, [isProvidersLoaded]);

  useEffect(() => {
    if (isExpenseTypesLoaded && typeof expenseType !== "undefined")
      change(
        "vatRecognized",
        expenseTypes.find((x) => x.id === expenseType.value).vatRecognized
      );
  }, [expenseType]);

  useEffect(() => {
    if (isExpenseTypesLoaded && typeof expenseType !== "undefined") {
      const vatRecognized = expenseTypes.find((x) => x.id === expenseType.value)
        .vatRecognized;

      setVatRecognizedSum(amount * (((vat / 100) * vatRecognized) / 100));
    }
  }, [expenseType, amount]);

  const fundingBodyDeleteAction = async () => {
    setIsQuestionModalOpen(false);
    await deleteItem(providerData.id);
    closeModal();
  };

  return (
    <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
      <SivanQuestionModal
        titleText="מחיקה"
        bodySecondRow="גוף המימון ימחק לצמיתות"
        modalIsOpen={isQuestionModalOpen}
        closeModal={() => setIsQuestionModalOpen(false)}
        deleteItem={fundingBodyDeleteAction}
        isDelete
      />
      <Section basicData noBorder>
        <Row>
          <Field
            name="date"
            component={renderDatePickerField}
            placeholder="תאריך ושעה"
          />
          <Field
            name="providerId"
            component={DefaultSelect}
            placeholder="בחר ספק"
            options={providersOptions}
          />
          <Field
            name="quantity"
            component={InputWrappedWithField}
            placeholder="כמות"
            type="number"
          />
          <Field
            name="expenseType"
            component={DefaultSelect}
            placeholder="סוג הוצאה"
            options={expenseTypesOptions}
          />
          <Field name="isCredit" component={renderToggle} label="זיכוי" />
          <Field
            name="documentType"
            component={DefaultSelect}
            placeholder="סוג"
            options={documentTypes}
          />
          <Field
            name="isEquipmentAndAssets"
            component={renderToggle}
            label="ציוד ונכסים קבועים"
          />
          <Field
            name="vehicleId"
            component={DefaultSelect}
            placeholder="רכב"
            options={[{ value: 999, label: "כל הרכבים" }, ...vehicleOptions]}
          />
        </Row>
        <Row>
          <Field
            name="vat"
            component={InputWrappedWithField}
            placeholder="מע״מ"
            disabled
          />
          <Field
            name="vatRecognized"
            component={InputWrappedWithField}
            placeholder="אחוז מע״מ מוכר"
            type="number"
            disabled
          />
          <Input
            value={vatRecognizedSum}
            component={InputWrappedWithField}
            placeholder="מע״מ מוכר"
            type="number"
            disabled
          />
          <Field
            name="documentNumber"
            component={InputWrappedWithField}
            placeholder="מספר מסמך ידני"
            type="number"
          />
          <Field name="notes" component={NotesInput} placeholder="הערה" />
        </Row>
        <Row>
          <Field
            name="paymentMethod"
            component={DefaultSelect}
            placeholder="סוג תשלום"
            options={paymentMethods}
          />
          <Field
            name="amount"
            component={InputWrappedWithField}
            placeholder="סכום"
            type="number"
          />
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[2]) && (
            <Field
              name="bank"
              component={InputWrappedWithField}
              placeholder="בנק"
              type="number"
            />
          )}
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[2]) && (
            <Field
              name="bankBranch"
              component={InputWrappedWithField}
              placeholder="סניף"
              type="number"
            />
          )}
          {paymentMethod === paymentMethods[1] && (
            <Field
              name="checkNumber"
              component={InputWrappedWithField}
              placeholder="מס המחאה"
              type="number"
            />
          )}
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[4]) && (
            <Field
              name="accountId"
              component={InputWrappedWithField}
              placeholder="חשבון"
              type="number"
            />
          )}
          {paymentMethod === paymentMethods[2] && (
            <Field
              name="reference"
              component={InputWrappedWithField}
              placeholder="אסמכתא"
              type="text"
            />
          )}
          {(paymentMethod === paymentMethods[3] ||
            paymentMethod === paymentMethods[4]) && (
            <Field
              name="confirmationNumber"
              component={InputWrappedWithField}
              placeholder="מספר אישור"
              type="text"
            />
          )}
          {paymentMethod === paymentMethods[3] && (
            <Field
              name="confirmationName"
              component={InputWrappedWithField}
              placeholder="שם מאשר"
              type="text"
            />
          )}
          {(paymentMethod === paymentMethods[1] ||
            paymentMethod === paymentMethods[3]) && (
            <Field
              name="dateOfMaturity"
              component={renderDatePickerField}
              placeholder="תאריך פרעון"
              isNoTime
            />
          )}
          {paymentMethod === paymentMethods[4] && (
            <Field
              name="appName"
              component={InputWrappedWithField}
              placeholder="אפליקציה"
              type="text"
            />
          )}
        </Row>
      </Section>
      <CreateButton
        text="מחק"
        type="button"
        isDelete
        marginRight={2}
        onClick={() => setIsQuestionModalOpen(true)}
      />
      <CreateButton text="עדכן" type="submit" />
    </Body>
  );
};

const selector = formValueSelector("NewExpenseType"); // <-- same as form name
function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    expenseType: selector(state, "expenseType"),
    paymentMethod: selector(state, "paymentMethod"),
    amount: selector(state, "amount"),
    vat: selector(state, "vat"),
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    expenseTypes: state.ExpenseTypesReducer.expenseTypes,
    isExpenseTypesLoaded: state.ExpenseTypesReducer.isLoaded,
    initialValues: {
      ...ownProps.providerData,
      paymentMethod: paymentMethods.find(
        (x) => x.value === ownProps.providerData.paymentMethod
      ),
      documentType: documentTypes.find(
        (x) => x.value === ownProps.providerData.documentType
      ),
      vehicleId: {
        value: ownProps.providerData.vehicleId,
        label: ownProps.providerData.vehicleNumber
          ? ownProps.providerData.vehicleNumber
          : "כל הרכבים",
      },
    },
  };
}

export default connect(mapStateToProps, {
  update,
  deleteItem,
  getSchoolExpenseTypes,
  getVehicles,
  getSchoolProviders,
  change,
})(
  reduxForm({
    form: "UpdateFundingBodies",
  })(FundingBodiesDetails)
);
