import agreementsApi from "../apis/agreements";
import {
  AGREEMENTS_FETCH_SUCCESS,
  AGREEMENTS_FETCH_FAILED,
  AGREEMENTS_FETCH_STARTED,
  AGREEMENT_CREATE_SUCCESS,
  AGREEMENT_UPDATE_SUCCESS,
  AGREEMENT_DELETE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";
import { reset } from "redux-form";

//TODO: added teacherId and schoolId
export const createAgreement = (agreement) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { branchId, schoolId } = getState().session.user;

    const response = await agreementsApi.post(
      "/create",
      {
        agreement,
        schoolId,
      },
      {
        headers,
      }
    );

    dispatch({
      type: AGREEMENT_CREATE_SUCCESS,
      payload: { ...agreement, id: response.data.agreementId, active: 1 },
    });
  } catch (error) {
    toast.error("כישלון ביצירת הסכם");
  }
};

export const getAgreements = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: AGREEMENTS_FETCH_STARTED,
    });
    const { branchId, schoolId } = getState().session.user;

    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await agreementsApi.get(`/allAgreements/${schoolId}`, {
      headers,
    });
    dispatch({
      type: AGREEMENTS_FETCH_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: AGREEMENTS_FETCH_FAILED,
    });
  }
};

export const getAgreementDetails = (agreementId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await agreementsApi.get(
      `/getAgreementDetails/${agreementId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {}
};

export const setAgreementDisabled = (agreementId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await agreementsApi.patch(
      "/disable",
      {
        agreementId,
      },
      {
        headers,
      }
    );
    if (response.data) {
      dispatch({
        type: AGREEMENT_UPDATE_SUCCESS,
        payload: { id: agreementId, active: 2 },
      });
    } else {
      toast.error("קיים תיק לימוד פעיל המקושר להסכם");
    }
  } catch (error) {
    toast.error("כישלון בעדכון הסכם");
  }
};

export const updateAgreement = (agreement) => async (dispatch) => {
  try {
    // dispatch({
    //   type: VEHICLE_FETCH_STARTED,
    // });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await agreementsApi.patch(
      "/updateAgreement",
      {
        agreement,
      },
      {
        headers,
      }
    );

    dispatch({
      type: AGREEMENT_UPDATE_SUCCESS,
      payload: agreement,
    });
  } catch (error) {
    toast.error("כישלון בעדכון הסכם");

    // dispatch({
    //   type: VEHICLE_FETCH_FAILED,
    // });
  }
};

export const deleteAgreement = (agreementId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await agreementsApi.delete(
      "/deleteAgreement",
      {
        data: { agreementId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: AGREEMENT_DELETE_SUCCESS,
      payload: { id: agreementId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת הרכב");
  }
};
