import {
  PROFESSIONAL_TEACHERS_FETCH_FAILED,
  PROFESSIONAL_TEACHERS_FETCH_SUCCESS,
  PROFESSIONAL_TEACHERS_FETCH_STARTED,
  PROFESSIONAL_TEACHER_CREATE_SUCCESS,
  PROFESSIONAL_TEACHER_DELETE_SUCCESS,
  PROFESSIONAL_TEACHER_UPDATE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isPending: false,
  professionalTeachers: [],
  isLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROFESSIONAL_TEACHER_CREATE_SUCCESS:
      return {
        ...state,
        professionalTeachers: [...state.professionalTeachers, action.payload],
      };
    case PROFESSIONAL_TEACHER_DELETE_SUCCESS:
      return {
        ...state,
        professionalTeachers: state.professionalTeachers.filter(
          (professionalTeacher) => professionalTeacher.id !== action.payload.id
        ),
      };
    case PROFESSIONAL_TEACHER_UPDATE_SUCCESS:
      const professionalTeachers = state.professionalTeachers.map(
        (professionalTeacher) =>
          professionalTeacher.id === action.payload.id
            ? { ...professionalTeacher, ...action.payload }
            : professionalTeacher
      );
      return { ...state, professionalTeachers };
    case PROFESSIONAL_TEACHERS_FETCH_SUCCESS:
      return { ...state, professionalTeachers: action.payload, isLoaded: true };
    case PROFESSIONAL_TEACHERS_FETCH_FAILED:
      return { ...state, isPending: false };
    case PROFESSIONAL_TEACHERS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
