import styled from "styled-components";

export const Body = styled.form`
  width: 100%;
  text-align: center;
`;

export const InvoiceCompanyNameHeader = styled.span`
  font-size: 3vh;
`;

export const InvoiceType = styled.span`
  font-size: 2vh;
`;

export const InvoiceStatus = styled.span``;

export const Line = styled.hr`
  border-top: 2px solid dimgrey;
  width: 100%;
`;

export const InvoiceBody = styled.div``;
export const InvoiceHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: bold;
  direction: rtl;
`;

export const InvoiceContainer = styled.div`
  font-family: arial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-variant: normal;

  @page {
    overflow: visible;
    margin: 20mm;
  }
`;

export const Table = styled.table`
  width: 100%;
  font-size: 2vh;
  margin-top: 0.5vh;
  direction: rtl;
`;

export const TableHeader = styled.th`
  border: 1px solid black;
`;

export const TableCell = styled.td`
  border: 1px solid black;
`;

export const TableRow = styled.tr``;

export const Teachers = styled.span`
  font-size: 2vh;
  text-align: right;
`;

export const Title = styled.span`
  font-size: 3vh;
`;

export const PrintFooter = styled.span`
  text-align: center;
  display: block;
  font-size: 18px;
  font-weight: bold;
  bottom: 0;
  width: 99%;
`;
