import { useEffect, useState } from "react";
import { optionsArrrayWithTwoParams } from "../../utils/buildOptionsArray";

const useTeachers = (isUsersLoaded, getUsers, users) => {
  const [teacherssOptions, setTeacherssOptions] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        if (!isUsersLoaded) await getUsers();
        else {
          setTeacherssOptions(
            optionsArrrayWithTwoParams(
              users.filter((x) => x.userType === 1 || x.userType === 4),
              "id",
              "firstName",
              "lastName"
            )
          );
        }
      } catch (err) {
        //console.log(err);
      }
    }
    fetchData();
  }, [isUsersLoaded]);
  return teacherssOptions;
};

export default useTeachers;
