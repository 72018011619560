import styled from "styled-components";
import i18n from "../../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Title = styled.div`
  width: 44vw;
  font-weight: bold;
  display: flex;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.span`
  font-size: 2.5vh;
  color: #404041;
`;

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const Body = styled.form`
  height: 79vh;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  align-items: center;

  .react-datepicker-wrapper:after {
    font-family: "FontAwesome";
    position: relative;
    left: ${isHebrew ? "-90%" : "-5%"};
    content: "\f133";
  }

  .react-datepicker-wrapper {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 30%;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__input-container input {
    width: 100%;
    ${isHebrew ? "padding-right" : "padding-left"}: 1vw;
    direction: ${isHebrew ? "rtl" : "ltr"};

    &::placeholder {
      color: #c2c2c2;
    }
  }
`;

export const Button = styled.button`
  border-radius: 5px;
  height: 6vh;
  background: ${(props) =>
    props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
  border: ${(props) => (props.reset ? "" : "1px solid #e1c89d")};
  color: ${(props) => (props.reset ? "white" : "#e1c89d")};
  font-size: 15pt;
  margin-top: 5vh;
  font-weight: bolder;
  flex: 0 20%;

  &:hover {
    background: ${(props) =>
      !props.reset ? "linear-gradient(to bottom, #ebd794, #d2ab51)" : "white"};
    border: ${(props) => (!props.reset ? "" : "1px solid #e1c89d")};
    color: ${(props) => (!props.reset ? "white" : "#e1c89d")};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
`;

export const AddMoreButton = styled.span`
  color: #e1c89d;
  cursor: pointer;
`;

export const SingleVacationContainer = styled.div`
  border: 1px solid lightgray;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
  border-radius: 5px;
  padding: 0.5vh;
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 1vh;
`;

export const VacationsContainer = styled.div`
  max-height: 73%;
  overflow: auto;
`;

export const Icon = styled.i`
  color: #e1c89d;
  cursor: pointer;
`;
