import studentsApi from "../apis/students";
import {
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAILED,
  STUDENT_CREATE_STARTED,
  STUDENT_FETCH_SUCCESS,
  STUDENT_DELETE_SUCCESS,
  STUDENT_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

//TODO: added teacherId and schoolId
export const createStudent = (student, reset) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDENT_CREATE_FAILED,
    });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await studentsApi.post(
      "/create",
      {
        student,
        branchId,
        schoolId,
      },
      {
        headers,
      }
    );

    if (response.data.id) {
      reset();

      dispatch({
        type: STUDENT_CREATE_SUCCESS,
        payload: { ...student, id: response.data.id },
      });
      return true;
    } else {
      toast.error(`ת.ז. ${student.studentId}  קיימת במערכת`);
      dispatch({
        type: STUDENT_CREATE_FAILED,
      });
      return false;
    }
  } catch (error) {
    toast.error("כישלון ביצירת תלמיד");
    dispatch({
      type: STUDENT_CREATE_STARTED,
    });
    return false;
  }
};

export const getStudents = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDENT_CREATE_FAILED,
    });
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { schoolId, branchId, userType } = getState().session.user;

    const response = await studentsApi.get(
      `/allStudents/${schoolId}/${branchId}/${userType}`,
      {
        headers,
      }
    );

    dispatch({
      type: STUDENT_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: STUDENT_CREATE_STARTED,
    });
  }
};

export const allActiveStudentsTextbooks = () => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { schoolId, branchId, userType } = getState().session.user;

    const response = await studentsApi.get(
      `/allActiveStudentsTextbooks/${schoolId}/${branchId}/${userType}`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {}
};

export const getAllBalances = () => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { schoolId } = getState().session.user;

    const response = await studentsApi.get(`/getAllBalances/${schoolId}`, {
      headers,
    });

    return response.data;
  } catch (error) {}
};

export const getTeacherStudents = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDENT_CREATE_FAILED,
    });
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { schoolId, id } = getState().session.user;

    const response = await studentsApi.get(
      `/teacherStudents/${id}/${schoolId}`,
      {
        headers,
      }
    );
    dispatch({
      type: STUDENT_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: STUDENT_CREATE_STARTED,
    });
  }
};

export const getStudent = (studentId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { branchId, schoolId } = getState().session.user;

    const response = await studentsApi.get(`/getStudent/${studentId}`, {
      headers,
    });
    return response.data;
  } catch (error) {}
};

export const addLicenseType =
  (studentId, licenseTypeId) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await studentsApi.post(
        "/addLicenseRank",
        {
          studentId,
          licenseTypeId,
        },
        {
          headers,
        }
      );
      return true;
    } catch (error) {
      toast.error("כישלון בהוספת רישיון לתלמיד");
      return false;
    }
  };

export const getStudentLicenseType = (studentId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await studentsApi.get(
      `/getStudentLicenseRank/${studentId}`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {}
};

export const deleteLicenseType =
  (studentId, licenseTypeId) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await studentsApi.delete(
        "/deleteLicenseRank",
        {
          data: { studentId, licenseTypeId },
          headers
        },
      );
      return true;
    } catch (error) {
      toast.error("כישלון במחיקת רישיון לתלמיד");
      return false;
    }
  };

export const deleteStudent = (studentId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const result = await studentsApi.delete(
      "/deleteStudent",
      {
        data: { studentId },
        headers,
      },
    );
    switch(`${result.data}`) {
      case '-1':
        toast.error("Student have sheduler.");
        return false;
      case '-2':
        toast.error("Student have credit's income.");
        return false;
      default:
    }

    dispatch({
      type: STUDENT_DELETE_SUCCESS,
      payload: studentId,
    });
    return true;
  } catch (error) {
    toast.error("כישלון במחיקת תלמיד");
    return false;
  }
};

export const updateStudent = (student) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await studentsApi.patch(
      "/update",
      {
        student,
      },
      {
        headers,
      }
    );
    dispatch({
      type: STUDENT_UPDATE_SUCCESS,
      payload: student,
    });
    return true;
  } catch (error) {
    toast.error("כישלון בעדכון התלמיד");
    return false;
  }
};

export const updateIsChecked =
  (id, isChecked, studentId) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await studentsApi.patch(
        "/markAsChecked",
        {
          id,
          isChecked,
        },
        {
          headers,
        }
      );
      dispatch({
        type: STUDENT_UPDATE_SUCCESS,
        payload: { id, isChecked, studentId },
      });
      return true;
    } catch (error) {
      toast.error("כישלון בעדכון התלמיד");
      return false;
    }
  };

export const disableStudent = (studentId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await studentsApi.patch(
      "/disable",
      {
        studentId,
      },
      {
        headers,
      }
    );
    return response.data
  } catch (error) {
    toast.error("כישלון בביטול התלמיד");
  }
};
export const reactivateStudent = (studentId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await studentsApi.patch(
      "/reactivate",
      {
        studentId,
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון בביטול התלמיד");
  }
};

export const sendPaymentRequest =
  (
    userData,
    invoiceData,
    openingBalance,
    openingBalanceLesson,
    openingBalanceInternalTest,
    openingBalanceTest,
    ticketLessonTime,
    balance,
    lessonInAgreementTime,
    internalTestsCount,
    testsCount,
    balanceWithFutureOrders,
    lessonsWithFutureLessons,
    internalTestsWithFutureInternalTests,
    testsWithFutureTests,
    agreement,
    totalStudentDidntShowedUp,
    totalPaid,
    totalStudentDidntShowedUpAndCharged,
    totalStudentDiscounts,
    glasses,
    title,
    emails
  ) =>
  async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await studentsApi.post(
        "/sendPaymentRequest",
        {
          userData,
          invoiceData,
          openingBalance,
          openingBalanceLesson,
          openingBalanceInternalTest,
          openingBalanceTest,
          ticketLessonTime,
          balance,
          lessonInAgreementTime,
          internalTestsCount,
          testsCount,
          balanceWithFutureOrders,
          lessonsWithFutureLessons,
          internalTestsWithFutureInternalTests,
          testsWithFutureTests,
          agreement,
          totalStudentDidntShowedUp,
          totalPaid,
          totalStudentDidntShowedUpAndCharged,
          totalStudentDiscounts,
          glasses,
          title,
          emails,
        },
        {
          headers,
        }
      );
      if (!response.data.id) return;

      var bytes = new Uint8Array(response.data.id.data); // pass your byte response to this constructor

      var blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "כרטסת תלמיד.pdf";
      link.click();
    } catch (error) {
      toast.error("כישלון בעדכון גוף מימון");
    }
  };
