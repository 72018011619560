import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import validate from "./validate";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import SivanModal from "../../../Modal/Modal";
import { Body, Section } from "./newIncome.styles";
import CreateButton from "../../../UiComponents/Buttons/Default";
import { sendPaymentRequest } from "../../../../actions/fundingBodiesActions";
import {
  getFundingBodyStudentEvents,
  updateStudentFundingBodyStatus,
} from "../../../../actions/schedulerActions";
import useFundingBodies from "../../../useComponents/useFundingBodies";
import { getSchoolProviders } from "../../../../actions/providersActions";
import DefaultSelect from "../../../UiComponents/Select/Default";
import { getFundingBodyStudents } from "../../../../actions/textBooksActions";
import CollapsedStudentData from "./CollapsedStudentData";
import FadeLoader from "react-spinners/FadeLoader";
import { findActiveAgreement } from "../../../../utils/calculator";
import { getAgreementDetails } from "../../../../actions/agreementsActions";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { useRef } from "react";
import { getStudentDiscountsAndObligations } from "../../../../actions/discountsAndObligationsActions";

const NewIncome = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  isFundingBodiesLoaded,
  fundingBodies,
  user,
  getSchoolFundingBodies,
  getFundingBodyStudentEvents,
  clientId,
  getFundingBodyStudents,
  sendPaymentRequest,
  ticketLessonTime,
  getAgreementDetails,
  updateStudentFundingBodyStatus,
  getStudentDiscountsAndObligations,
}) => {
  const [students, setStudents] = useState([]);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedDiscountAndObligation, setCheckedDiscountAndObligation] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [chosenOpeningBalance, setChosenOpeningBalance] = useState([]);
  const studentEvents = useRef([])
  const [studentsTotal, setStudentsTotal] = useState([])
  const studentDiscountAndObligation = useRef([])
  const fundingBodiesOptions = useFundingBodies(
    isFundingBodiesLoaded,
    getSchoolFundingBodies,
    fundingBodies
  );

  useEffect(() => {
    async function fetchData() {
      setChecked([]);
      setCheckedDiscountAndObligation([]);
      setChosenOpeningBalance([]);
      if (clientId && clientId?.value !== 999) {
        setIsLoading(true);
        const studentsData = await getFundingBodyStudents(clientId.value);
        let eventsData = []
        if (studentsData.length > 0) {
          eventsData = await getFundingBodyStudentEvents(
            studentsData.map((x) => x.studentId)
          );
        }
        setChosenOpeningBalance(studentsData.map((x) => x.studentId));
        for (let index = 0; index < studentsData.length; index++) {
          const element = studentsData[index];
          const activeAgreementOpeningBalance =
            await getTextBookAgreementOpeningBalance(
              element.agreements,
              element.studentId
            );
          studentsData[index].activeAgreementOpeningBalance =
            activeAgreementOpeningBalance;
        }          
        setEvents(eventsData);
        await setStudentEvents(studentsData, eventsData);
        // setChecked(eventsData.map((x) => x.id));
        setStudents(studentsData);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [clientId]);

  useEffect(() => {
    calculateStudentTotal();
  }, [checked, checkedDiscountAndObligation, students, chosenOpeningBalance]);

  const allAgreementsDetails = []
  const getTextBookAgreementOpeningBalance = async (agreements) => {
    let openingBalance = 0;
    for (let index = 0; index < agreements.length; index++) {
      const element = agreements[index];
      if (moment(new Date()).isAfter(element.startDate)) {
        if (!allAgreementsDetails[element.agreementId]) {
          allAgreementsDetails[element.agreementId] = await getAgreementDetails(
            element.agreementId
          );
        }
        const agreementsDetails = Object.assign({}, allAgreementsDetails[element.agreementId]);
        const activeAgreementAmount = findActiveAgreement(
          agreementsDetails["amountAgreementDetails"],
          "from",
          new Date(element.startDate)
        );
        openingBalance += activeAgreementAmount?.price || 0;
      }
    }
    return openingBalance;
  };

  const clearMany = (items) => {
    let baba = checked;
    for (var i = items.length - 1; i >= 0; i--)
      baba.splice(baba.indexOf(items[i]), 1);
  };

  const calculateStudentTotal = () => {
    const _studentsTotal = [];
    for (let j = 0; j < students.length; j++) {
      const student = students[j];
      let total = 0;

      if (chosenOpeningBalance.includes(student.studentId)) {
        total += student.activeAgreementOpeningBalance;
      }
      for (let index = 0; index < checked.length; index++) {
        const element = checked[index];
        let event = studentEvents.current[student.studentId].find((x) => x.id === element);
        if (!event) continue;
        total += event.price;
      }
      const obligation = studentDiscountAndObligation.current[student.studentId].filter((x) => checkedDiscountAndObligation.includes(`${student.id}#${x.id}`) && x.isDiscount == 0)
      const discount = studentDiscountAndObligation.current[student.studentId].filter((x) => checkedDiscountAndObligation.includes(`${student.id}#${x.id}`) && x.isDiscount == 1)
      for (const elem of obligation) {
        total += elem.amount;
      }
      for (const elem of discount) {
        total -= elem.amount;
      }
      _studentsTotal[`st${student.studentId}`] = total < 0 ? 0 : total;
    }
    setStudentsTotal(_studentsTotal);
  }

  const fundingBodyCreateAction = async (formValues) => {
    const fundingBody = fundingBodies.find((x) => x.id === clientId.value);
    const buba = [];
    for (let j = 0; j < students.length; j++) {
      const student = students[j];
      let agreement = 0;
      const _events = [];
      if (chosenOpeningBalance.includes(student.studentId)) {
        agreement = student.activeAgreementOpeningBalance;
      }

      for (let index = 0; index < checked.length; index++) {
        const element = checked[index];
        let event = events.find((x) => x.id === element && student.studentId === x.studentId);
        if (!event) continue;
        _events.push(event.id)
      }
      const discountAndObligation = [];
      for (const cdoId of checkedDiscountAndObligation) {
        const elem = studentDiscountAndObligation.current[student.studentId].find((x) => `${student.id}#${x.id}` == cdoId);
        if (!elem) continue;
        discountAndObligation.push(elem.id)
      }
      if (_events.length > 0 || discountAndObligation.length > 0)
        buba.push({
          ...student,
          name: student.firstName + " " + student.lastName,
          total_test: studentsTotal[`st${student.studentId}`],
          agreement,
          discountAndObligation,
          events: _events
        });
    }
    const id = await sendPaymentRequest(
      fundingBody,
      {
        name: `${user.firstName} ${user.lastName}`,
        schoolFullName: user.schoolFullName,
        schoolId: user.schoolId,
        bnNumber: user.bnNumber,
        logo: user.logo,
      },
      buba
    );
    setIsModalOpen(false);
  };
  const setStudentEvents = async (_students, _events) => {
    let checked = []
    if (_students.length === 0) {
      studentEvents.current = [];
      setChecked(checked)
      return;
    }
    for (const student of _students) {
      if (studentDiscountAndObligation.current[student.studentId] === undefined) {
        studentDiscountAndObligation.current[student.studentId] = await getStudentDiscountsAndObligations(student.studentId)
      }
      if (student.balance >= 0) {
        studentEvents.current[student.studentId] = [];
        continue;
      }
      let _allEvents = _events.filter((y) => y.studentId === student.studentId)
  
      const _studentEvents = [];
      for (const ev of _allEvents.sort((a,b) => new Date(b.start) - new Date(a.start))) {
        _studentEvents.push(ev);
        if (_studentEvents.reduce((p, c) => p + c.price, 0) >= Math.abs(student.balance)) break;
      }
      studentEvents.current[student.studentId] = _studentEvents;
      checked = [...checked, ..._studentEvents.map((x) => x.id)]
    }
    setChecked(checked)
  }
  return (
    <SivanModal
      titleText="הכנסה חדשה"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Field
            name="clientId"
            component={DefaultSelect}
            placeholder="לקוח"
            options={fundingBodiesOptions}
          />
        </Section>
        <CreateButton
          text="צור"
          type="submit"
          disabled={checked.length === 0 && checkedDiscountAndObligation.length === 0}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          להראות לכל התלמידים
        <Checkbox
            color="primary"
            checked={showAll}
            onChange={() => {
              setShowAll(!showAll);
            }}
          />
        </div>
        <hr/>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flexWrap: "wrap",
          }}
        >
          {isLoading ? (
            <FadeLoader size={150} color={"#123abc"} loading={true} />
          ) : students.length > 0 ? (
            students.map((x) => (
              <CollapsedStudentData
                showAll={showAll}
                student={x}
                events={studentEvents.current[x.studentId]}
                discountAndObligation={studentDiscountAndObligation.current[x.studentId]}
                key={x.studentId}
                setChecked={setChecked}
                total={studentsTotal[`st${x.studentId}`]}
                checked={checked}
                setCheckedDiscountAndObligation={setCheckedDiscountAndObligation}
                checkedDiscountAndObligation={checkedDiscountAndObligation}
                clearMany={clearMany}
                setChosenOpeningBalance={setChosenOpeningBalance}
                chosenOpeningBalance={chosenOpeningBalance}
              />
            ))
          ) : (
            <span>אין תלמידים המששוייכים לגוף המימון</span>
          )}
        </div>
      </Body>
    </SivanModal>
  );
};

const selector = formValueSelector("NewFundingBodyPaymentRequest"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    providers: state.ProvidersReducer.providers,
    clientId: selector(state, "clientId"),
    user: state.session.user,
    fundingBodies: state.FundingBodiesReducer.fundingBodies,
    ticketLessonTime: state.session.user.ticketLessonTime,
    isFundingBodiesLoaded: state.FundingBodiesReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getSchoolProviders,
  change,
  getFundingBodyStudentEvents,
  getFundingBodyStudents,
  sendPaymentRequest,
  getAgreementDetails,
  updateStudentFundingBodyStatus,
  getStudentDiscountsAndObligations,
})(
  reduxForm({
    form: "NewFundingBodyPaymentRequest",
    validate,
  })(NewIncome)
);
