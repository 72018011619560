export default (values) => {
  const errors = {};
  if (!values.start) {
    errors.start = "Required";
  }
  if (!values.end) {
    errors.end = "Required";
  }

  if (!values.eventType) {
    errors.eventType = "Required";
  } else if (values.eventType.value === 1) {
    if (!values.pickupLocationId) {
      errors.pickupLocationId = "Required";
    }
    if (!values.studentId) {
      errors.studentId = "Required";
    }
    if (!values.textBookId) {
      errors.textBookId = "Required";
    }
  } else if (values.eventType.value === 4) {
    if (!values.notes) {
      errors.notes = "Required";
    }
  } else if (values.eventType.value === 4) {
    if (!values.notes) {
      errors.notes = "Required";
    }
  } else if (values.eventType.value === 2) {
    if (!values.testerId) {
      errors.testerId = "Required";
    }
  } else if (values.eventType.value === 3) {
    if (!values.professionalTeacherId) {
      errors.professionalTeacherId = "Required";
    }
  }

  return errors;
};
