import React from "react";
import {
  Container,
  Table,
  Tr,
  Th,
  Td,
  Text,
  PrintFooter,
  TableTileContainer,
  TitleTextContainer,
} from "./teacherWeekly.styles";
import moment from "moment";

const timesArray = [
  { hour: "06", minutes: "00" },
  { hour: "06", minutes: "30" },
  { hour: "07", minutes: "00" },
  { hour: "07", minutes: "30" },
  { hour: "08", minutes: "00" },
  { hour: "08", minutes: "30" },
  { hour: "09", minutes: "00" },
  { hour: "09", minutes: "30" },
  { hour: "10", minutes: "00" },
  { hour: "10", minutes: "30" },
  { hour: "11", minutes: "00" },
  { hour: "11", minutes: "30" },
  { hour: "12", minutes: "00" },
  { hour: "12", minutes: "30" },
  { hour: "13", minutes: "00" },
  { hour: "13", minutes: "30" },
  { hour: "14", minutes: "00" },
  { hour: "14", minutes: "30" },
  { hour: "15", minutes: "00" },
  { hour: "15", minutes: "30" },
  { hour: "16", minutes: "00" },
  { hour: "16", minutes: "30" },
  { hour: "17", minutes: "00" },
  { hour: "17", minutes: "30" },
  { hour: "18", minutes: "00" },
  { hour: "18", minutes: "30" },
  { hour: "19", minutes: "00" },
  { hour: "19", minutes: "30" },
  { hour: "20", minutes: "00" },
];

function getAllIndexes(arr, val) {
  var indexes = [],
    i;
  for (i = 0; i < arr.length; i++)
    if (new Date(arr[i].start).getDate() === new Date(val).getDate())
      indexes.push(i);
  return indexes;
}

class TeacherWeeklyTable extends React.PureComponent {
  buildEvents = () => {
    if (this.props.teacherEventsForWeek.length > 0) {
      let firstday = new Date(this.props.startDate);
      let lastday = new Date(this.props.endDate);
      let datesInWeek = [];
      while (firstday < lastday) {
        datesInWeek.push(new Date(firstday));
        var newDate = firstday.setDate(firstday.getDate() + 1);
        firstday = new Date(newDate);
      }
      const s = [];
      const sortedTeacherEvents = this.props.teacherEventsForWeek.sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      var format = "h:mm:ss";

      for (let index = 0; index < timesArray.length; index++) {
        const element = timesArray[index];
        var aftereTime = "";
        if (index + 1 === timesArray.length) {
          continue;
        } else {
          aftereTime = moment(
            `${timesArray[index + 1].hour}:${timesArray[index + 1].minutes}:00`,
            format
          );
        }

        var beforeTime = moment(
          `${element.hour}:${element.minutes}:00`,
          format
        );

        const t = sortedTeacherEvents.filter(function (l, i) {
          var startTime = moment(
            `${
              new Date(l.start).getHours() < 10
                ? "0" + new Date(l.start).getHours()
                : new Date(l.start).getHours()
            }:${
              new Date(l.start).getMinutes() < 10
                ? "0" + new Date(l.start).getMinutes()
                : new Date(l.start).getMinutes()
            }:30`,
            format
          );
          var endTime = moment(
            `${
              new Date(l.end).getHours() < 10
                ? "0" + new Date(l.end).getHours()
                : new Date(l.end).getHours()
            }:${
              new Date(l.end).getMinutes() < 10
                ? "0" + new Date(l.end).getMinutes()
                : new Date(l.end).getMinutes()
            }:00`,
            format
          );
          const b = startTime.isBetween(beforeTime, aftereTime, null, "[)");
          // const bal = beforeTime.isBetween(startTime, endTime, null, "[)");
          const bala = aftereTime.isBetween(startTime, endTime, null, "[)");
          if (b || bala) return l;
        });

        s.push(
          <Tr key={`${element.hour}${element.minutes}`}>
            {this.buildEventsInHour(element, datesInWeek, t)}
          </Tr>
        );
      }

      return s;
    }
  };

  buildEventsInHour = (element, datesInWeek, teacherfiltereredEvents) => {
    const tuta = [];
    tuta.push(
      <Td
        key={`${element.hour}:${element.minutes}`}
      >{`${element.hour}:${element.minutes}
      `}</Td>
    );
    for (let j = 0; j < 6; j++) {
      const b = getAllIndexes(teacherfiltereredEvents, datesInWeek[j]);

      if (b.length === 1) {
        tuta.push(
          <Td key={teacherfiltereredEvents[b[0]].id}>{`${new Date(
            teacherfiltereredEvents[b[0]].start
          ).getHours()}:${
            new Date(teacherfiltereredEvents[b[0]].start).getMinutes() === 0
              ? "00"
              : new Date(teacherfiltereredEvents[b[0]].start).getMinutes()
          } - ${teacherfiltereredEvents[b[0]].title} ${
            teacherfiltereredEvents[b[0]].eventType === 4
              ? teacherfiltereredEvents[b[0]].notes
              : ""
          }${`
            ${
              teacherfiltereredEvents[b[0]].firstName !== null
                ? `- ${teacherfiltereredEvents[b[0]].firstName}`
                : ""
            }`} ${
            teacherfiltereredEvents[b[0]].lastName !== null
              ? teacherfiltereredEvents[b[0]].lastName
              : ""
          }`}</Td>
        );
      } else if (b.length > 1) {
        tuta.push(
          <Td key={element.id}>
            {b.map((x) => (
              <div>
                <span>{`${new Date(
                  teacherfiltereredEvents[b[0]].start
                ).getHours()}:${
                  new Date(teacherfiltereredEvents[b[0]].start).getMinutes() ===
                  0
                    ? "00"
                    : new Date(teacherfiltereredEvents[b[0]].start).getMinutes()
                } - ${teacherfiltereredEvents[x].title} - ${
                  teacherfiltereredEvents[x].firstName
                } ${teacherfiltereredEvents[x].lastName}`}</span>
              </div>
            ))}
          </Td>
        );
      } else {
        tuta.push(<Td key={j} />);
      }
    }

    return tuta;
  };

  buildDaysAndDates = () => {
    let firstday = new Date(this.props.startDate);
    let lastday = new Date(this.props.endDate);
    var newend = lastday.setDate(lastday.getDate() + 1);
    lastday = new Date(newend);
    const b = [];
    while (firstday < lastday) {
      const momentStartDate = moment(firstday).local();
      b.push(
        <Th key={firstday}>
          <Text>
            {momentStartDate.format("dddd")} <br />
            {momentStartDate.format("DD/MM/YY")}
          </Text>
        </Th>
      );

      var newDate = firstday.setDate(firstday.getDate() + 1);
      firstday = new Date(newDate);
    }

    return b;
  };

  render() {
    return (
      <Container>
        <TableTileContainer>
          <TitleTextContainer>תוכנית שבועית</TitleTextContainer>
          <TitleTextContainer>
            מורה - {this.props.teacherName}
          </TitleTextContainer>
        </TableTileContainer>
        <Table dir="rtl">
          <thead>
            <Tr>
              <Th>
                <Text>
                  יום <br />
                  שעה
                </Text>
              </Th>
              {this.buildDaysAndDates()}
            </Tr>
          </thead>
          <tbody>{this.buildEvents()}</tbody>
        </Table>
        <br />
        <PrintFooter>
          הופק באמצעות קמע מחשבים סיון בע"מ. טלפון 0537-222-228
        </PrintFooter>
      </Container>
    );
  }
}

export default TeacherWeeklyTable;
