import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";
import { isMobile } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Text = styled.span``;
export const PrintFooter = styled.span`
  text-align: center;
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: bold;
`;

export const Container = styled.div`
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: landscape;
    // size: auto;
    margin: 10mm;
  }

  table tr:nth-child(odd) td {
    background: white;
  }
  table tr:nth-child(even) td {
    background: #eae9e9;
  }

  .css-1bhz48m-container {
    width: 100%;
  }
  .css-1wao28o-control {
    height: 6vh;
  }
`;
export const Th = styled.th`
  padding: 1px;
  border: 1px solid black;
`;
export const Td = styled.td`
  padding: 1px;
  border: 1px solid black;
`;
export const Tr = styled.tr`
  border: 1px solid black;
`;
export const Table = styled.table`
  border: 1px solid black;
  text-align: center;
  width: 100%;
  direction: rtl;
`;

export const TitleTextContainer = styled.span`
  width: 50%;
  display: block;
  direction: rtl;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: flex-end;
`;

export const FieldText = styled.span``;

export const Field = styled.div`
  flex: 0 33%;
  text-align: center;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TableTileContainer = styled.div`
  height: 4vh;
  font-size: 24px;
  font-weight: bold;
  display: flex;
`;
