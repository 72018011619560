import {
  PROVIDERS_FETCH_FAILED,
  PROVIDERS_FETCH_SUCCESS,
  PROVIDERS_FETCH_STARTED,
  PROVIDER_CREATE_SUCCESS,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_UPDATE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, providers: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROVIDER_CREATE_SUCCESS:
      return {
        ...state,
        providers: [...state.providers, action.payload],
      };
    case PROVIDER_DELETE_SUCCESS:
      return {
        ...state,
        providers: state.providers.filter(
          (provider) => provider.id !== action.payload.id
        ),
      };
    case PROVIDER_UPDATE_SUCCESS:
      const providers = state.providers.map((provider) =>
        provider.id === action.payload.id
          ? { ...provider, ...action.payload }
          : provider
      );
      return { ...state, providers };
    case PROVIDERS_FETCH_SUCCESS:
      return { ...state, providers: action.payload, isLoaded: true };
    case PROVIDERS_FETCH_FAILED:
      return { ...state, isPending: false };
    case PROVIDERS_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
