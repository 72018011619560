import { useEffect, useState } from "react";
import { optionsArrrayWithTwoParams } from "../../utils/buildOptionsArray";

const UseVehicles = (isVehiclesLoaded, getVehicles, vehicles) => {
  const [vehicleOptionsArray, setVehicleOptionsArray] = useState([]);
  useEffect(() => {
    (async () => {
      if (!isVehiclesLoaded) await getVehicles();
      else {
        setVehicleOptionsArray(
          optionsArrrayWithTwoParams(
            vehicles,
            "id",
            "vehicleNumber",
            "description"
          )
        );
      }
    })();
  }, [isVehiclesLoaded]);
  return vehicleOptionsArray;
};

export default UseVehicles;
