import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  createMessage,
  getMessages,
  deleteMessage,
} from "../../../actions/generalMessages";
import {
  Container,
  Title,
  Body,
  Row,
  CreateButton,
  InputsContainer,
  DeleteButton,
} from "./generalMessages.styles";
import Input from "../../UiComponents/Inputs/Default";

const GeneralMessages = ({ createMessage, getMessages, deleteMessage }) => {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setMessages(await getMessages());
    }
    fetchData();
  }, []);

  const handleChange = (e) => setInputValue(e.target.value);

  return (
    <Container>
      <Title>General Messages</Title>
      <InputsContainer>
        <Input
          value={inputValue}
          onChange={handleChange}
          placeholder="תוכן ההודעה"
        />
        <CreateButton onClick={() => createMessage(inputValue)}>
          Add
        </CreateButton>
      </InputsContainer>
      <Body>
        {messages.map(({ message }) => (
          <Row key={message}>
            {message}
            <DeleteButton
              className="fa fa-close"
              onClick={() => deleteMessage(message)}
            />
          </Row>
        ))}
      </Body>
    </Container>
  );
};

export default connect(null, { createMessage, getMessages, deleteMessage })(
  GeneralMessages
);
