import React, { PureComponent } from "react";
import {
  Container,
  Title,
  Body,
  Column,
  Input,
  NotesInput,
  CreateButton,
  ReceiptDetails
} from "./receipt.styles";

class FundingBodyReceipt extends PureComponent {
  render() {
    return (
      <Container>
        <Title>Receipt Funding Body</Title>
        <Body>
          <ReceiptDetails>
            <Column>
              <Input placeholder="Receipt Number" />
              <Input placeholder="Amount" />
            </Column>
            <Column>
              <Input placeholder="Last Receipt Number" />
              <Input placeholder="For" />
              <NotesInput placeholder="Note" rows="10" />
            </Column>
          </ReceiptDetails>
          <CreateButton>Create</CreateButton>
        </Body>
      </Container>
    );
  }
}

export default FundingBodyReceipt;
