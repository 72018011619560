import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "../../../Navbar/Navbar";
import DatePicker from "react-datepicker";
import i18n from "../../../../lib/i18n/i18n";
import { NavAndBackContainer, StyledLoader } from "./clientData.styles";
import GeneralData from "./ClientDetails/ClientDetails";
// import StudentTicket from "./StudentTicket/StudentTicket";
import SivanModal from "../../../Modal/Modal";
import Incomes from "../../Incomes/Incomes";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const FundingBodiesData = ({
  isPending,
  user,
  isModalOpen,
  closeModal,
  clientData,
}) => {
  const [menuItems, setMenuItems] = useState([dictionary.editStudent.basic]);
  const [currentOpenedView, setCurrentOpenedView] = useState(
    dictionary.editStudent.basic
  );

  useEffect(() => {
    if (user.studentTicket) {
      setMenuItems([...menuItems, dictionary.editStudent.ticket]);
      setCurrentOpenedView(dictionary.editStudent.ticket)
    }
  }, []);

  return (
    <SivanModal
      titleText={clientData.name}
      modalIsOpen={isModalOpen}
      closeModal={closeModal}
    >
      <NavAndBackContainer>
        <Navbar
          menuOptions={menuItems}
          currentView={currentOpenedView}
          setCurrentOpenedView={setCurrentOpenedView}
        />
      </NavAndBackContainer>
      {currentOpenedView === dictionary.editStudent.basic && (
        <GeneralData clientData={clientData} closeModal={closeModal} />
      )}
      {currentOpenedView === dictionary.editStudent.ticket && (
        <Incomes isClientIncoms={1} filter={{clients:clientData.clientId}} />
        // <StudentTicket studentId={location.state.studentData.studentId} />
      )}
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </SivanModal>
  );
};

FundingBodiesData.defaultProps = {
  location: { state: { studentData: { birthday: new Date() } } },
};

function mapStateToProps(state) {
  return { user: state.session.user };
}

export default connect(mapStateToProps)(FundingBodiesData);
