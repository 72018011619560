import React, { useState } from "react";
import { connect } from "react-redux";
import SivanModal from "../../Modal/NewModal";
import { Body, Button, ErrorBlock, Row } from "./promt.styles";
import TextField from "@material-ui/core/TextField";

const PromtBase = ({
  isModalOpen,
  onClose,
  defaultValue,
  title,
  txtBtn1,
  txtBtn2,
  btn2DefaultValue = '',
}) => {
  const [notes, setNotes] = useState(defaultValue);
  const [error, setError] = useState('');

  const makeAction = async (result) => {
    onClose(result);
  };

  return (
    <SivanModal
      titleText={title}
      modalIsOpen={isModalOpen}
      closeModal={() => onClose(false)}
      isLoading={false}
    >
      <Body>
        {error &&
          <ErrorBlock>{{error}}</ErrorBlock>
        }
        <TextField
          value={notes}
          onChange={(x) => setNotes(x.target.value)}
          id="outlined-basic"
          margin="dense"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
        />
        <Row>
          <Button onClick={() => makeAction(notes)}>{txtBtn1}</Button>
          <Button onClick={() => makeAction(btn2DefaultValue)}>{txtBtn2}</Button>
        </Row>
      </Body>
    </SivanModal>
  );
};

export default connect(null, {})(PromtBase);
