import odometerApi from "../apis/odometer";
import {
  INCOMES_FETCH_FAILED,
  INCOMES_FETCH_SUCCESS,
  INCOMES_FETCH_STARTED,
  INCOME_CREATE_SUCCESS,
  INCOME_DELETE_SUCCESS,
  INCOME_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (report) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;
    const response = await odometerApi.post(
      "/create",
      {
        income: {
          ...report,
          schoolId,
          vehicleId: report.vehicleId.value,
          date: new Date(new Date(report.date).setHours(7, 0, 0)),
        },
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון ביצירת דיווח");
  }
};

export const getAllVehicleReports = (vehicleId) => async (
  dispatch,
  getState
) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await odometerApi.get(`/getAll/${schoolId}/${vehicleId}`, {
      headers,
    });

    return response.data;
  } catch (error) {
    toast.error("כישלון ביצירת דיווח");
  }
};
