import styled from "styled-components";
import i18n from "../../lib/i18n/i18n";
import { isMobile, isMobileOnly } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";
export const Icon = styled.span`
  font-size: ${isMobileOnly ? "6vw" : "3vh"};
  margin-right: ${isMobileOnly && "0.3vw"};

  @media (min-width: 720px) and (max-width: 1024px) and (orientation: landscape) {
    font-size: 22px;
  }
`;

export const TTContainer = styled.div`
  .table_wrapper {

  }
  .table {

  }
  table, th, td {
    border: 1px solid #ddd;
  padding: 8px;
  }
  th {
    padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
  }
  .header {
    text-align: center;
    font-weight: bold;
  }
`







export const Container = styled.div`
  .rbc-header,
  .rbc-month-view,
  .rbc-month-row,
  .rbc-day-bg,
  .rbc-timeslot-group,
  .rbc-time-content,
  .rbc-events-container,
  .rbc-time-view,
  .rbc-time-header-content {
    border-color: #ffefca;
  }
  // .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  .rbc-agenda-table > tbody > tr {
    display: table-row !important;
  }

  .rbc-row-content {
    z-index: 0;
  }

  .rbc-time-slot {
    min-height: 15vh;
  }

  .rbc-agenda-event-cell,
  .rbc-agenda-time-cell {
    display: table-cell !important;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-timeslot-group:nth-child(2n + 1) {
    background-color: #fbf9f9;
  }

  .rbc-toolbar-label {
    ${isMobileOnly && "display: none;"}
  }

  .react-datepicker-wrapper {
    width: ${isMobile ? "40vw" : "10%"};
    height: ${isMobile ? "4vh" : "2.5vh"};
    margin-top: 0;
  }

  .react-datepicker-popper {
    z-index: 343443;
  }

  .rbc-agenda-content {
    max-height: 72vh;
  }

  .rbc-day-slot .rbc-events-container {
    ${isMobileOnly && "margin:0;"}
  }

  .rbc-event {
    ${isMobileOnly && "padding: 1px 2px;"}
  }
`;

export const UserOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${isMobile ? "column" : "row"};
  align-items: center;
  justify-content: center;
`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    minWidth: 150
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    [isHebrew ? "paddingRight" : "paddingLeft"]: "0.5vw",
  }),
};

//


export const HeaderContainer = styled.div`
  border-bottom: 1px solid #ffefca;
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  height: ${isMobile ? "5vh" : "10vh"};
  padding: ${isMobile && '5%'};
  margin-bottom: ${isMobile && '2%'}
`;

export const LoggedUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "center" : "space-between"};
  flex-direction: ${isMobile ? "column" : "row"};
  width: ${isMobile ? "55vw" : "100%"};
  padding-right: 2vw;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ffefca;
  width: 25vw;
  background-color: #fffdf9;
`;

export const Logo = styled.img`
  height: ${isMobile ? "3vh" : "8vh"};
  width: auto;
`;

export const LoggedUserName = styled.span`
  font-size: ${isMobileOnly ? "2vw" : "12pt"};
  font-weight: ${isMobileOnly ? 'bold' : 'normal'};
  margin-top: ${isMobileOnly ? '' : '-0pt'};
  margin-right: ${isMobileOnly ? '' : '-50pt'};
`;


export const StickyHeader = styled.div`
position: fixed;
z-index: 1000;
width: 100%;
background-color: white;
top: 0;
`;
export const StickyBody = styled.div`
padding-top: 300px;
`;
export const StickyBodyMobile = styled.div`
padding-top: 3000px;
`;
