import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
import i18n from "../../../lib/i18n/i18n";
import UseLoadStudents from "../../useComponents/UseLoadStudents";
import { getBranches } from "../../../actions/branchActions";
import Button from "../../UiComponents/Buttons/Default";
import { getStudents } from "../../../actions/studentesActions";
import { getAllSchoolTestsEvents } from "../../../actions/schedulerActions";
import {
  Row,
  FieldText,
  Field,
  customStyles,
  Container,
} from "./NewStudentsVSPassedTests.styles";
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Tooltip,
  Line,
  XAxis,
} from "recharts";

const ActivityReport = ({
  isLoading,
  getStudents,
  students,
  getAllSchoolTestsEvents,
  getBranches,
}) => {
  const [graphData, setGraphData] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("bymonths");
  const [startDate, setStartDate] = useState(new Date().setMonth(0));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [rawGraphData, setRawGraphData] = useState([]);
  UseLoadStudents(isLoading, getStudents);

  useEffect(() => {
    (async () => {
      const response = await getAllSchoolTestsEvents(
        new Date(`01-01-${new Date().getFullYear()}`),
        new Date(`12-31-${new Date().getFullYear()}`)
      );
      setEvents(
        response.filter(
          (x) => x.eventType === 2 && x.eventStatus !== 3 && x.eventStatus !== 4
        )
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (students.length > 0 && events.length > 0) {
        const data = [];
        for (
          var day = moment(new Date(startDate));
          day.isSameOrBefore(new Date(endDate), "day");
          day.add(1, "months")
        ) {
          if (currentView === "byVehicles") {
            const uniqueVehicle = _.uniqBy(students, "vehicleNumber");
            const vehicels = uniqueVehicle
              .filter((x) => x.vehicleNumber !== null)
              .map((x) => x.vehicleNumber);
            setSelectOptions(vehicels.map((x) => ({ value: x, label: x })));
            filterByData(vehicels, day, "vehicleNumber", data, students);
          } else if (currentView === "byBranch") {
            const allBranches = await getBranches();
            setSelectOptions(
              allBranches.map((x) => ({ value: x.name, label: x.name }))
            );
            filterByData(allBranches, day, "branchId", data, students, true);
          } else if (currentView === "byUser") {
            const uniqueLicenses = _.uniqBy(students, "createdBy");
            const licenses = uniqueLicenses
              .filter((x) => x.createdBy !== null)
              .map((x) => x.createdBy);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(licenses, day, "createdBy", data, students);
          } else if (currentView === "byTeacher") {
            const uniqueLicenses = _.uniqBy(students, "teacherName");
            const licenses = uniqueLicenses
              .filter((x) => x.teacherName !== null)
              .map((x) => x.teacherName);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(licenses, day, "teacherName", data, students);
          } else {
            setSelectOptions([]);
            data.push({
              date: day.format("MM/YY"),
              students: students.filter(
                (y) =>
                  moment(new Date(y.createDate)).isSame(day, "month") &&
                  moment(new Date(y.createDate)).isSame(day, "year")
              ).length,
              passed: events.filter(
                (x) =>
                  x.testStatus === 1 &&
                  moment(new Date(x.createDate)).isSame(day, "month") &&
                  moment(new Date(x.createDate)).isSame(day, "year")
              ).length,
            });
          }
        }
        setGraphData(data);
        setRawGraphData(data);
      }
    })();
  }, [startDate, endDate, currentView, students, events]);

  const filterByData = (array, day, param, data, students, isBranch) => {
    for (let j = 0; j < array.length; j++) {
      const element = array[j];
      const elementValue = isBranch ? element.id : element;
      data.push({
        date: `${isBranch ? element.name : element} - ${day.format("MM/YY")}`,
        students: students.filter(
          (y) =>
            moment(new Date(y.createDate)).isSame(day, "month") &&
            moment(new Date(y.createDate)).isSame(day, "year") &&
            y[param] === elementValue
        ).length,
        passed: events.filter(
          (x) =>
            x.testStatus === 1 &&
            moment(new Date(x.createDate)).isSame(day, "month") &&
            moment(new Date(x.createDate)).isSame(day, "year") &&
            x[param === "createdBy" ? "createUsername" : param].toString() ===
              elementValue
        ).length,
      });
    }
  };

  const handleChange = (selected) => {
    setSelectedTeacher(selected);
    if (selected && selected.length > 0) {
      let filteredData = [];
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];

        filteredData = [
          ...filteredData,
          ...rawGraphData.filter((x) => x.date.includes(element.value)),
        ];
      }
      setGraphData(filteredData);
    } else {
      setGraphData(rawGraphData);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>תלמידים חדשים מול עברו טסט</h1>
        <p>תלמידים חדשים כנגד טסטים שבוצעו ועברו באותו החודש</p>
      </div>
      <Row>
        <Button
          text="לפי חודשים"
          onClick={() => {
            setCurrentView("byMonths");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רכבים"
          onClick={() => {
            setCurrentView("byVehicles");
          }}
          marginRight="1"
        />
        <Button
          text="לפי יוצר"
          onClick={() => {
            setCurrentView("byUser");
          }}
          marginRight="1"
        />
        <Button
          text="לפי סניפים"
          onClick={() => {
            setCurrentView("byBranch");
          }}
          marginRight="1"
        />
        <>
          <div style={{ width: "33%", marginRight: "1vw" }}>
            <Select
              options={selectOptions}
              value={selectedTeacher}
              isMulti
              placeholder="בחר"
              styles={customStyles}
              onChange={handleChange}
              isRtl
            />
          </div>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="MMMM"
              endDate={endDate}
              minDate={startDate}
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </Field>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={i18n.language}
              dateFormat="MMMM"
              showMonthYearPicker
              showFullMonthYearPicker
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={graphData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="date" />
          <Tooltip />
          <CartesianGrid stroke="#f5f5f5" />
          <Line
            type="monotone"
            dataKey="students"
            stroke="#ff7300"
            yAxisId={0}
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="passed"
            stroke="#387908"
            yAxisId={1}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    students: state.StudentsReducer.students,
    isLoading: state.StudentsReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getStudents,
  getAllSchoolTestsEvents,
  getBranches,
})(ActivityReport);
