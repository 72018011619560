import validator from "validator";
import isIsraeliIdValid from "israeli-id-validator";

export default (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Required";
  } else if (!validator.isMobilePhone(values.phoneNumber, "he-IL")) {
    errors.phoneNumber = "Required";
  }

  if (!values.userId || !validator.isNumeric(values.userId)) {
    errors.userId = "Required";
  } else if (!isIsraeliIdValid(values.userId)) {
    errors.userId = "Required";
  }
  if (!values.email || !validator.isEmail(values.email)) {
    errors.email = "Required";
  }

  if (!values.userType && !values.copyPermissions) {
    errors.userType = "Required";
  }

  return errors;
};
