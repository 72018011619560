import React, { useState } from "react";
import {
  Container,
  Icon,
  TitleContainer,
  IconsAndTextContainer,
  TextAfterIcon,
} from "./eventView.styles";
import { isMobile, isMobileOnly } from "react-device-detect";
import { connect } from "react-redux";
import EditEvent from "../EditEvent/EditEvent";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";

const eventStatusIcons = {
  0: "icons8-question-mark",
  1: "icons8-checkmark",
  2: "icons8-shekel",
  3: "icons8-cancel",
  4: "icons8-cancel-with-usd",
};

const formatPhoneForWhatsapp = (phoneNumber) => {
  phoneNumber = phoneNumber.trim();
  phoneNumber = phoneNumber.replaceAll('-', '');
  if (phoneNumber.includes('+972')) return phoneNumber;
  else if (phoneNumber.includes('972')) return '+' + phoneNumber;
  else {
    return '+972' + phoneNumber;
  }
}

const EventView = (event) => {
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const teachersOptions = useTeacheres(
    event.isUsersLoaded,
    event.getUsers,
    event.users
  );

  const getNameStyle = (studentData) => {
    let styles = {};
    if (studentData.internalTestStatus) {
      styles.borderTop = "1px solid black";
    }
    if (
      studentData.conversion ||
      studentData.refresh ||
      studentData.renewal ||
      studentData.control
    ) {
      styles.borderLeft = "1px solid black";
      styles.borderRight = "1px solid black";
    }
    if (
      studentData.fundingBody !== 999 &&
      studentData.fundingBody !== null &&
      typeof studentData.fundingBody !== "undefined"
    ) {
      styles.borderBottom = "1px solid black";
    }

    return styles;
  };

  return (
    <Container
      dir="auto"
      isStudentChosen={
        typeof event.chosenStudentId !== "undefined" &&
        event.chosenStudentId === event.event.studentId
      }
      chosenStudentColor={event.chosenStudentColor}
      fontChosenStudentColor={event.fontChosenStudentColor}
    >
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={event.eventTime}
          selectedEvent={{
            ...event.event,
            name: event.event.name
              ? event.event.name
              : `${event.event.studentId} - ${event.event.firstName} ${event.event.lastName}`,
          }}
        />
      )}
      <TitleContainer
        onClick={() => setIsEditLessonModalOpen(true)}
        dir="auto"
        className="eventView"
        isDay={isMobile}
        style={{ flexDirection: "column", hegiht: "100%" }}
      >
      {event.showStudent && typeof event.event.firstName === "string" ? (
        <IconsAndTextContainer
          style={{
            maxWidth: "100%",
            marginTop: "0.5vmax",
            fontWeight: "bold",
          }}
          onClick={() =>
            event.onStudentClick({
              event: event.event,
              value: event.event.studentId,
              label: `${event.event.firstName} ${event.event.lastName}`,
            })
          }
        >
          <TextAfterIcon
            isDay={event.isDay}
            style={{
              width: "100%",
              ...getNameStyle(event.event),
            }}
          >
            <span style={{ whiteSpace: "nowrap" }}>
              {event.event.firstName}
            </span>
            &nbsp;
            {!isMobileOnly &&
              event.event.nickname &&
              `(${event.event.nickname}) `}
            <span style={{ whiteSpace: "nowrap" }}>
              {event.event.lastName}
            </span>
            <Icon
              className="icons8-whatsapp"
              data-tip={`<span dir="auto">${event.event.studentNameWithoutNickname}</span><br/><span dir="auto">${event.event.address}, ${event.event.city}</span><br/><span dir="auto">${event.event.phone}</span>`}
              data-for={event.event.id.toString()}
              onClick={() => window.open('https://wa.me/'+formatPhoneForWhatsapp(event.event.phone)+'?text='+`
 שלום לך, `+event.event.firstName + ' ' + event.event.lastName+`,
 ברצוני להזכירך כי בתאריך `+event.event.start.getDate() + '/' + (event.event.start.getMonth() + 1).toString() + '/' + event.event.start.getFullYear()+`, ב`+new Date(event.event.start).toLocaleString('he-il', {weekday:'long'})+`,
 בשעה `+event.event.start.getHours() +':'+event.event.start.getMinutes()+`
 קבענו שיעור.
 נתראה`+''+`
              `, '_blank')}
            />
          </TextAfterIcon>
        </IconsAndTextContainer>
      ) : null}

        {event.showEventStatus ? (
          <IconsAndTextContainer
            onClick={() => event.openEventStatusChangeModal(event.event)}
            style={{ margin: 0 }}
          >
            <Icon
              className={eventStatusIcons[event.event.eventStatus]}
              style={{ margin: 0 }}
              aria-hidden="true"
            />
          </IconsAndTextContainer>
        ) : null}
        {!isMobileOnly && event.showBranch && event.event.eventType !== 4 ? (
          <IconsAndTextContainer style={{ maxWidth: "100%" }}>
            <TextAfterIcon
              style={{
                width: "100%",
              }}
            >
              {event.event.branchName}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {!isMobileOnly &&
        event.showTeacher &&
        teachersOptions.length !== 0 &&
        event.event.teacherId !== null ? (
          <IconsAndTextContainer
            style={{ maxWidth: "100%", marginTop: "0.5vmax" }}
          >
            <TextAfterIcon
              style={{
                width: "100%",
              }}
            >
              {
                teachersOptions.find(
                  (x) => x.value.toString() === event.event.teacherId.toString()
                )?.label
              }
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {!isMobileOnly &&
        event.showVehicle &&
        typeof event.event.description === "string" ? (
          <IconsAndTextContainer
            style={{ maxWidth: "100%", marginTop: "0.5vmax" }}
          >
            <TextAfterIcon
              style={{
                width: "100%",
              }}
            >
              {event.event.description}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {!isMobileOnly &&
        event.showNotes &&
        typeof event.event.notes === "string" ? (
          <IconsAndTextContainer
            data-tip={event.event.notes}
            onClick={() => event.openNotesChangeModal(event.event)}
            style={{
              maxWidth: "100%",
              marginTop: "0.5vmax",
            }}
          >
            <TextAfterIcon
              style={{
                width: "100%",
              }}
            >
              {event.event.notes}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}

      </TitleContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    showEventType: state.session.user.showEventType,
    showEventStatus: state.session.user.showEventStatus,
    showStudent: state.session.user.showStudent,
    showVehicle: state.session.user.showVehicle,
    showTime: state.session.user.showTime,
    showBalance: state.session.user.showBalance,
    showBranch: state.session.user.showBranch,
    showNotes: state.session.user.showNotes,
    showTeacher: state.session.user.showTeacher,
    eventTime: state.session.user.eventTime,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    chosenStudentColor: state.session.user.chosenStudentColor,
    fontChosenStudentColor: state.session.user.fontChosenStudentColor,
  };
}

export default connect(mapStateToProps, { getUsers })(EventView);
