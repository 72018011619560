import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, FormSection } from "redux-form";
import { useTranslation } from "react-i18next";
import {
  Body,
  PermanentDetailsContainer,
  AdditionalDetailsContainer,
  ColumnTitle,
  Button,
  Fields,
  ButtonContainer,
} from "./createStudent.styles";
import { getBranches } from "../../../actions/branchActions";

const CreateStudent = ({
  handleSubmit,
  reset,
  renderSelect,
  renderInput,
  renderDatePickerField,
  renderTextArea,
  getBranches,
}) => {
  const { t, i18n } = useTranslation("common");
  const genderOptions = [
    { value: "male", label: t("newStudent.male") },
    { value: "female", label: t("newStudent.female") },
  ];
  const [branchOptions, setBranchOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const optionsArray = [];
      const allBranches = await getBranches();
      allBranches.map(({ id, name }) =>
        optionsArray.push({ value: id, label: name })
      );
      setBranchOptions(optionsArray);
    }
    fetchData();
  }, []);

  return (
    <Body onSubmit={handleSubmit} autoComplete="off">
      <FormSection isRtl={i18n.language === "he"} name="student">
        <Fields isRtl={i18n.language === "he"}>
          <PermanentDetailsContainer>
            <ColumnTitle dir="auto">
              {t("newStudent.permanentDetails")}
            </ColumnTitle>
            <Field
              name="lastName"
              component={renderInput}
              placeholder={t("newStudent.lastName")}
            />
            <Field
              name="firstName"
              component={renderInput}
              placeholder={t("newStudent.firstName")}
            />
            <Field
              name="studentId"
              component={renderInput}
              placeholder={t("newStudent.id")}
              type="number"
            />
            <Field
              name="birthday"
              component={renderDatePickerField}
              placeholder={t("newStudent.birthday")}
            />
            <Field
              name="branchId"
              component={renderSelect}
              placeholder={t("newStudent.branch")}
              options={branchOptions}
            />
          </PermanentDetailsContainer>
          <AdditionalDetailsContainer>
            <ColumnTitle dir="auto">
              {t("newStudent.additionalDetails")}
            </ColumnTitle>
            <Field
              name="gender"
              component={renderSelect}
              placeholder={t("newStudent.gender")}
              options={genderOptions}
            />
            <Field
              name="nickname"
              component={renderInput}
              placeholder={t("newStudent.nickname")}
            />
            <Field
              name="address"
              component={renderInput}
              placeholder={t("newStudent.address")}
            />
            <Field
              name="city"
              component={renderInput}
              placeholder={t("newStudent.city")}
            />
            <Field
              name="postalCode"
              component={renderInput}
              placeholder={t("newStudent.postalCode")}
            />
            <Field
              name="phone"
              component={renderInput}
              placeholder={t("newStudent.phone")}
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <ColumnTitle />
            <Field
              name="homePhone"
              component={renderInput}
              placeholder={t("newStudent.homePhone")}
            />
            <Field
              name="workPhone"
              component={renderInput}
              placeholder={t("newStudent.workPhone")}
            />
            <Field
              name="email"
              component={renderInput}
              placeholder={t("newStudent.email")}
            />
            <Field
              name="business"
              component={renderInput}
              placeholder={t("newStudent.business")}
            />
            <Field
              name="notes"
              component={renderTextArea}
              placeholder={t("newStudent.notes")}
            />
          </AdditionalDetailsContainer>
        </Fields>
      </FormSection>
      <ButtonContainer>
        <Button type="submit">{t("buttons.next")}</Button>
        <Button reset onClick={reset}>
          {t("buttons.reset")}
        </Button>
      </ButtonContainer>
    </Body>
  );
};

// export default reduxForm({
//   form: "createStudent", // a unique identifier for this form
//   destroyOnUnmount: false, // <------ preserve form data
//   forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
// })(CreateStudent);

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps, { getBranches })(
  reduxForm({
    form: "createStudent", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(CreateStudent)
);
