export const downloadFile = (file, inNewWindow=true, fileName='') => {
  if (!file) {
    console.log('file not found');
    return;
  }
  if (inNewWindow) {
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
    return;
  }
  let link = document.createElement('a');
  if (fileName.length > 0) link.download = fileName;
  link.href = URL.createObjectURL(file);
  link.click();
  URL.revokeObjectURL(link.href);
}