import incomesApi from "../apis/incomes";
import store from '../store'
import {
  INCOMES_FETCH_FAILED,
  INCOMES_FETCH_SUCCESS,
  INCOMES_FETCH_STARTED,
  INCOME_CREATE_SUCCESS,
  INCOME_DELETE_SUCCESS,
  INCOME_UPDATE_SUCCESS,
  INCOMES_FUNDING_BODIES_FETCH_SUCCESS,
  INCOMES_FROM_STUDENTS_FETCH_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (income) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await incomesApi.post(
      "/create",
      {
        income: { ...income, vehicleId: income.vehicleId.value, schoolId },
      },
      {
        headers,
      }
    );
    // console.log(response);
    dispatch({
      type: INCOME_CREATE_SUCCESS,
      payload: {
        ...income,
        id: response.data.id,
        vehicleId: income.vehicleId.value,
        vehicleNumber: income.vehicleId.label,
        isOriginalPrinted: false,
      },
    });

    return {
      ...income,
      id: response.data.id,
      vehicleId: income.vehicleId.value,
      vehicleNumber: income.vehicleId.label,
      isOriginalPrinted: false,
    };
  } catch (error) {
    toast.error("כישלון ביצירת הכנסה");
  }
};

export const getAll =
  (isStudentsIncomes = 0, isFundingBodies=0, params={}) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: INCOMES_FETCH_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const { branchId, schoolId } = getState().session.user;
      if (schoolId === null || schoolId === undefined) {
        dispatch({
          type: INCOMES_FETCH_FAILED,
        });
      } else {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await incomesApi.get(
          `/getAll/${schoolId}/${isStudentsIncomes}/${isFundingBodies}`,
          {
            params,
            headers,
          }
        );

        if (response.status === 200) {
          // console.log(response.data);
          dispatch({
            type: isStudentsIncomes == 0 ? INCOMES_FETCH_SUCCESS : INCOMES_FROM_STUDENTS_FETCH_SUCCESS,
            payload: response.data,
          });

          return response.data;
        } else {
          dispatch({
            type: INCOMES_FETCH_FAILED,
          });
        }

      }

    } catch (error) {
      dispatch({
        type: INCOMES_FETCH_FAILED,
      });
    }
  };

  export const getAllWithoutDispatch =
  async (isStudentsIncomes = 0, isFundingBodies=0, params={}) => {
    try {
      const token = window.localStorage.getItem("token");
      const { schoolId } = store.getState().session.user;
      if (schoolId === null || schoolId === undefined) {
        return [];
      } 
      
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await incomesApi.get(
        `/getAll/${schoolId}/${isStudentsIncomes}/${isFundingBodies}`,
        {
          params,
          headers,
        }
      );

      if (response.status !== 200) {
        return []
      }
      return response.data;
    } catch (error) {
      console.error(error)
    }
    return []
  };

export const getAllFundingBodiesIncomes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: INCOMES_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await incomesApi.get(`/getAll/${schoolId}/0/1`, {
      headers,
    });
    dispatch({
      type: INCOMES_FUNDING_BODIES_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: INCOMES_FETCH_FAILED,
    });
  }
};

export const updateOne =
  (id, vehicleId, textbookId, teacherId) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await incomesApi.patch(
        "/updateData",
        {
          id,
          vehicleId,
          textbookId,
          teacherId,
        },
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {}
  };

export const getOne = (incomeId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await incomesApi.get(`/getOne/${incomeId}`, {
      headers,
    });

    return response.data;
  } catch (error) {}
};

export const getStudentsBetweenDates =
  (from, to) => async (dispatch, getState) => {
    try {
      dispatch({
        type: INCOMES_FETCH_STARTED,
      });
      const token = window.localStorage.getItem("token");
      const { branchId, schoolId } = getState().session.user;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await incomesApi.get(
        `/getStudentsBetweenDates/${schoolId}/${from}/${to}`,
        {
          headers,
        }
      );

      dispatch({
        type: INCOMES_FETCH_SUCCESS,
        payload: response.data,
      });

      return response.data;
    } catch (error) {
      dispatch({
        type: INCOMES_FETCH_FAILED,
      });
    }
  };

export const getStudentIncomes = (studentId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const { schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await incomesApi.get(
      `/getStudentIncomes/${schoolId}/${studentId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    toast.error("כישלון בשליפת הכנסות מתלמיד");
  }
};

export const getClientIncomes = (clientId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const { schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await incomesApi.get(
      `/getClientIncomes/${schoolId}/${clientId}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    toast.error("כישלון בשליפת הכנסות");
  }
};

export const getDocumentNumber =
  (documentTypeNumber) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const { branchId, schoolId } = getState().session.user;

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await incomesApi.get(
        `/getDocumentNumber/${schoolId}/${documentTypeNumber}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {}
  };

export const updateInvoicePrinted = (income) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await incomesApi.patch(
      "/updateInvoicePrinted",
      {
        incomeId: income.id,
      },
      {
        headers,
      }
    );

    dispatch({
      type: INCOME_UPDATE_SUCCESS,
      payload: { ...income, isOriginalPrinted: true },
    });

    return response.data;
  } catch (error) {
    toast.error("כישלון בעדכון הכנסה");
  }
};

export const updateInvoiceTextbook =
  (income, textBookId) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await incomesApi.patch(
        "/updateTextbook",
        {
          incomeId: income.id,
          textBookId: income.textBookId,
        },
        {
          headers,
        }
      );

      dispatch({
        type: INCOME_UPDATE_SUCCESS,
        payload: { ...income, isOriginalPrinted: true },
      });

      return response.data;
    } catch (error) {
      toast.error("כישלון בעדכון הכנסה");
    }
  };

export const deleteItem = (expenseTypeId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await incomesApi.delete(
      "/delete",
      {
        data: { expenseTypeId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: INCOME_DELETE_SUCCESS,
      payload: { id: expenseTypeId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת הכנסה");
  }
};
