
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
  InvoiceCompanyNameHeader,
  InvoiceType,
  InvoiceStatus,
  Line,
  InvoiceBody,
  InvoiceHeader,
  InvoiceContainer,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  PrintFooter,
  ClientDataText,
  ClientData,
  NotesContainer,
  NotesText,
} from "./IncomesFromStudents/IncomeData/IncomeDetails/incomeDetails.styles";

const FinancialInvoiceHeader = ({
  user,
  invoiceType,
  documentType,
  documentNumber,
  children
}) => {
  return (
  <InvoiceHeader>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontWeight: "bold",
                  direction: "rtl",
                }}
              >
                <InvoiceCompanyNameHeader dir="auto">
                  <span>{user.schoolFullName}</span>
                </InvoiceCompanyNameHeader>
                <br />
                <span>
                  {user.isCompany ? "חפ:" : "עוסק מורשה:"} {user.bnNumber}
                </span>
                <span>
                  {user.schoolPhone}, {user.schoolEmail}
                </span>
                <span>{user.schoolAddress}</span>
                <br />
                <InvoiceType>
                  {documentType?.label} מס / {documentNumber}
                  <InvoiceStatus> {invoiceType}</InvoiceStatus>
                </InvoiceType>
                {children}
              </div>
              {user.logo && <img src={user.logo} style={{ height: "15vh" }} />}
            </div>
            <Line />
          </InvoiceHeader>
          );
}

// <-- same as form name
function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {})(
  reduxForm({
    form: "financialInvoiceHeader",
  })(FinancialInvoiceHeader)
);