import moment from "moment";

export const EventType = {
  lesson: 1,
  test: 2,
  internalTest: 3,
  meeting: 4,
};

export const EventStatus = {
  New: 0,
  Finished: 1,
  FinishedAndSuccess: 2,
  Canceled: 3,
  CanceledAndCharged: 4,
};

export const testWhatsAppMessage = function(event, user) {
  return `שלום, ${event.firstName} ${event.lastName} מאזכרים אותך שוב כי הזמנת טסט ליום ${moment(event.start).format("dddd")} ${moment(event.start).format("DD/MM/YYYY")} בשעה ${moment(event.start).format("HH:mm")} דע/י אי הגעה למבחן מחויב במחיר מלא ואגרה. *נא להגיע 20 דקות טרם השעה המוזמנת, אין להגיע באיחור* (איחור גורם לנעילת הטאבלט של הבוחן ולביטול המבחן) להצטייד ברישיון נהיגה מפלסטיק בתוקף, ולמי שאין- נייר בצירוף ת.ז, נעליים סגורות ומכנס ארוך, אמצעי ראיה (למי שצריך) בהצלחה, בי"ס לנהיגה ${user.schoolName}`
}