import { IconCopy, IconImg } from './icon.styles'
const typeCopy = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAuklEQVRIie3WvQ6CMBSG4Vd3HbweL0Di5OAduGjC/TpbLoJF62BNSlPCaT12Ol/SkPDTB8IXKFjyOQID4BfGCFw1YSdAPfAK46YFS1APXIBnwPuWMNp4CVyNx0WqheFTsu87FxUuLlIpvJ/BxxRZZWCfOZ4+TU0m1lphwqoYbLDBBv8N3oTt8KPjJCfFH/1T2NchX4Wk4wEcSuE7sJNcpJH0jh1wBraayNJvUXPeSXLlalKkXJoUydIsb25KjTHUSBgsAAAAAElFTkSuQmCC';

const index = ({
  type = 'copy',
  onClick
}) => {
  let elem = '';
  switch (type) {
    case '':
      break;
      default:
        elem = <IconCopy onClick={() => onClick && onClick()}><IconImg src={typeCopy}/></IconCopy>;
  }
  return elem;
}
export default index;