import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import validate from "./validate";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  Title,
  ToggleContainer,
  ToggleText,
  StyledLoader,
} from "./createStudent.styles";
import GeneralData from "./GeneralData";
import AdditionalData from "./AdditionalData";
import { createStudent } from "../../../actions/studentesActions";
import FadeLoader from "react-spinners/FadeLoader";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../UiComponents/TextArea/Default";
import DefaultSelect from "../../UiComponents/Select/Default";
import DefaultDatePicker from "../../UiComponents/DatePicker/Default";
import {
  createStudentTextBook,
  updateTextBookDates,
} from "../../../actions/textBooksActions";

const renderToggle = ({ input, label }) => {
  const isHebrew = i18n.language === "he";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const CreateStudent = ({
  createStudent,
  isPending,
  reset,
  history,
  createStudentTextBook,
  updateTextBookDates,
  user,
}) => {
  const [currentData, setCurrentData] = useState("generalData");
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  const createNewStudent = async (formValue) => {
    setIsLoading(true);
    const isSuccess = await createStudent(
      {
        ...formValue.student,
        branchId: formValue.student.branchId.value,
        createdBy: `${user.firstName} ${user.lastName}`,
      },
      reset
    );
    const id = await createStudentTextBook(
      formValue.student.studentId,
      formValue.textbook.agreementId.value,
      formValue.textbook.vehicleId.value,
      formValue.textbook.teacherId.value
    );
    await updateTextBookDates({ id, ...formValue.textbook });

    setIsLoading(false);
    if (isSuccess) history.push("/studentSearch");
  };

  return (
    <Container>
      {isLoading && (
        <div
          style={{
            zIndex: "1",
            height: "78%",
            width: "74%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={100} color={"#123abc"} loading={true} />
        </div>
      )}
      <Title dir="auto">{t("newStudent.title")}</Title>
      {currentData === "generalData" ? (
        <GeneralData
          onSubmit={() => setCurrentData("additionalData")}
          renderSelect={DefaultSelect}
          renderInput={InputWrappedWithField}
          renderDatePickerField={DefaultDatePicker}
          renderTextArea={NotesInput}
        />
      ) : (
        <AdditionalData
          back={() => setCurrentData("generalData")}
          onSubmit={createNewStudent}
          renderSelect={DefaultSelect}
          renderInput={InputWrappedWithField}
          renderDatePickerField={DefaultDatePicker}
          renderToggle={renderToggle}
        />
      )}
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    user: state.session.user,
    initialValues: {
      student: {
        registrationFees: false,
        smsOnEventCreation: false,
        glasses: false,
        registrationFees: false,
        birthday: new Date(),
      },
      textbook: {},
    },
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createStudent,
    createStudentTextBook,
    updateTextBookDates,
  })(
    reduxForm({
      form: "createStudent",
      validate,
    })(CreateStudent)
  )
);
