import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import UserReducer from "./reducer_user";
import StudentsReducer from "./reducer_students";
import VehiclesReducer from "./reducer_vehicles";
import UsersReducer from "./reducer_users";
import AgreementsReducer from "./reducer_agreements";
import PickupLocationsReducer from "./reducer_pickupLocations";
import LicenseTypesReducer from "./reducer_licenseTypes";
import DrivingPermitsReducer from "./reducer_drivingPermits.js";
import FundingBodiesReducer from "./reducer_fundingBodies";
import ClientsReducer from "./reducer_clients";
import ProvidersReducer from "./reducer_providers";
import ExpenseTypesReducer from "./reducer_expenseTypes";
import ProfessionalTeachersReducer from "./reducer_professionalTeachers";
import ExpensesReducer from "./reducer_expenses";
import IncomesReducer from "./reducer_incomes";
import TestersReducer from "./reducer_testers";
import CalendarReducer from "./reducer_calendar";
import LearningAssignmentsReducer from "./reducer_learningAssignments";
import session from "./session";

const appReducer = combineReducers({
  loggedInUser: UserReducer,
  StudentsReducer,
  UsersReducer,
  VehiclesReducer,
  form: formReducer,
  LicenseTypesReducer,
  AgreementsReducer,
  LearningAssignmentsReducer,
  PickupLocationsReducer,
  session,
  CalendarReducer,
  DrivingPermitsReducer,
  FundingBodiesReducer,
  ClientsReducer,
  ProvidersReducer,
  ExpenseTypesReducer,
  ProfessionalTeachersReducer,
  TestersReducer,
  ExpensesReducer,
  IncomesReducer,
});

export default (state, action) => {
  if (action.type === "CLEAR_SESSION") {
    state = undefined;
  }
  if (action.type === "CLEAR_SESSION_DATA") {
    state = {
      user: state.user,
      isLoggedIn: true,
      credentials: state.credentials
    };
  }

  return appReducer(state, action);
};
