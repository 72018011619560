import React, { useState, useEffect } from "react";
import { Row, FieldText, Field } from "./TestStatistics.styles";
import { connect } from "react-redux";
import { getAllSchoolTestsEvents } from "../../../actions/schedulerActions";
import i18n from "../../../lib/i18n/i18n";
import moment from "moment";
import DatePicker from "react-datepicker";
import { PieChart, ResponsiveContainer, Tooltip, Pie, Cell } from "recharts";
const COLORS = ["#c40024", "#00C49F"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const ActivityReport = ({ getAllSchoolTestsEvents }) => {
  const [graphData, setGraphData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    (async () => {
      let events = await getAllSchoolTestsEvents(
        moment(startDate).format("MM-DD-YYYY"),
        moment(endDate).format("MM-DD-YYYY")
      );
      events = events.filter(
        (x) => x.eventType === 2 && x.eventStatus !== 3 && x.eventStatus !== 4
      );

      const data = [
        {
          name: "נכשלו",
          value: events.filter((x) => x.testStatus === 2).length,
        },
        {
          name: "עברו",
          value: events.filter((x) => x.testStatus === 1).length,
        },
      ];

      setGraphData(data);
    })();
  }, [startDate, endDate]);

  return (
    <>
      <Row>
        <>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Tooltip />
          <Pie
            data={graphData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {graphData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default connect(null, {
  getAllSchoolTestsEvents,
})(ActivityReport);
