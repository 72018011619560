import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import i18n from "../../../lib/i18n/i18n";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import validate from "./validate";
import { connect } from "react-redux";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Container,
  Title,
  StyledLoader,
  Body,
  AdditionalDetailsContainer,
  Button,
  Fields,
  ButtonContainer,
  CheckBoxContainer,
  RowFieldsContainer,
  DayContainer,
} from "./createVehicle.styles";
import { createVehicle } from "../../../actions/vehicleActions";
import { getBranches } from "../../../actions/branchActions";
import FadeLoader from "react-spinners/FadeLoader";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../UiComponents/TextArea/Default";
import DefaultSelect from "../../UiComponents/Select/Default";

const renderDatePickerField = ({
  input,
  placeholder,
  meta: { error, touched },
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <DatePicker
      {...input}
      selected={input.value}
      dir={dir}
      placeholderText={placeholder}
      dateFormat="dd/MM/yyyy"
      className={`${touched && error ? "error" : ""} date-input`}
      locale={i18n.language}
      onBlur={() => input.onBlur(input.value)}
    />
  );
};

const renderCheckBox = ({ input, label }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={true}
          checked={input.value}
          onChange={input.onChange}
        />
      }
      label={label}
      labelPlacement="end"
    />
  );
};

const CreateVehicle = ({
  handleSubmit,
  isPending,
  createVehicle,
  reset,
  getBranches,
  history,
  getLicenseTypes,
  licenseTypes,
  change,
}) => {
  const [branchOptions, setBranchOptions] = useState([]);
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(true);
  const [licenseTypesOptions, setLicenseTypesOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const optionsArray = [];
      const allBranches = await getBranches();

      allBranches.map(({ id, name }) =>
        optionsArray.push({ value: id, label: name })
      );

      const allBranchesOption = optionsArray.find(
        (x) => x.label === "כל הסניפים"
      );
      change("sunday", allBranchesOption);
      change("monday", allBranchesOption);
      change("tuesday", allBranchesOption);
      change("wednesday", allBranchesOption);
      change("thursday", allBranchesOption);
      change("friday", allBranchesOption);
      change("saturday", allBranchesOption);
      setBranchOptions(optionsArray);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (licenseTypes.length === 0) {
        await getLicenseTypes();
      } else {
        let optionsArray = [];
        licenseTypes.map(({ id, name }) =>
          optionsArray.push({ value: id, label: name })
        );

        setLicenseTypesOptions(optionsArray);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [licenseTypes]);

  const createVehicleAction = async (formValues) => {
    setIsLoading(true);
    const {
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
    } = formValues;
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const day = days[new Date().getDay()];

    await createVehicle(
      {
        ...formValues,
        active: 1,
        branchName: formValues[day].label,
        sunday: sunday.value,
        monday: monday.value,
        tuesday: tuesday.value,
        wednesday: wednesday.value,
        thursday: thursday.value,
        friday: friday.value,
        saturday: saturday.value,
      },
      reset
    );
    setIsLoading(false);
    history.push("/vehicles");
  };

  return (
    <Container>
      {isLoading && (
        <div
          style={{
            height: "78%",
            width: "74%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={100} color={"#123abc"} loading={true} />
        </div>
      )}
      <Title dir="auto">{t("createVehicle.title")}</Title>
      <Body onSubmit={handleSubmit(createVehicleAction)}>
        <Fields>
          <RowFieldsContainer>
            <AdditionalDetailsContainer>
              <Field
                name="vehicleNumber"
                component={InputWrappedWithField}
                placeholder={t("createVehicle.vehicleNumber")}
                type="number"
              />
              <Field
                name="comprehensiveInsurance"
                component={renderDatePickerField}
                placeholder={t("createVehicle.comprehensiveDate")}
              />
              <Field
                name="insurance"
                component={renderDatePickerField}
                placeholder={t("createVehicle.insuranceUntil")}
              />
              <Field
                name="carLicense"
                component={renderDatePickerField}
                placeholder={t("createVehicle.carLicenseUntil")}
              />
              <Field
                name="alertBeforeExpiration"
                component={InputWrappedWithField}
                placeholder={t("createVehicle.alertBeforeExpiration")}
                type="number"
              />
            </AdditionalDetailsContainer>
            <AdditionalDetailsContainer>
              <Field
                name="licenseType"
                component={DefaultSelect}
                placeholder={t("createVehicle.teachingLicenseType")}
                options={licenseTypesOptions}
              />
              <Field
                name="description"
                component={NotesInput}
                placeholder={t("createVehicle.description")}
              />
              <Field
                name="internalDuration"
                component={InputWrappedWithField}
                placeholder={t("createVehicle.internalDuration")}
                type="number"
              />
              <CheckBoxContainer>
                <Field
                  name="reportOz"
                  component={renderCheckBox}
                  label={t("createVehicle.reportOz")}
                />
              </CheckBoxContainer>
            </AdditionalDetailsContainer>
          </RowFieldsContainer>
          <RowFieldsContainer>
            <DayContainer>
              <label>א</label>
              <Field
                name="sunday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
              />
            </DayContainer>
            <DayContainer>
              <label>ב</label>
              <Field
                name="monday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
              />
            </DayContainer>
            <DayContainer>
              <label>ג</label>
              <Field
                name="tuesday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
              />
            </DayContainer>
            <DayContainer>
              <label>ד</label>
              <Field
                name="wednesday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
              />
            </DayContainer>
            <DayContainer>
              <label>ה</label>
              <Field
                name="thursday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
              />
            </DayContainer>
            <DayContainer>
              <label>ו</label>
              <Field
                name="friday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
              />
            </DayContainer>
            <DayContainer>
              <label>ש</label>
              <Field
                name="saturday"
                component={DefaultSelect}
                placeholder={t("createVehicle.branch")}
                options={branchOptions}
              />
            </DayContainer>
          </RowFieldsContainer>
        </Fields>
        <ButtonContainer>
          <Button type="submit">{t("buttons.save")}</Button>
        </ButtonContainer>
      </Body>
      <StyledLoader active={isPending} spinner text="Creating Student..." />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isPending: state.StudentsReducer.isPending,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    initialValues: {
      active: true,
      reportOz: true,
    },
  };
}

export default withRouter(
  connect(mapStateToProps, {
    createVehicle,
    getBranches,
    getLicenseTypes,
    change,
  })(
    reduxForm({
      validate,
      form: "createVehicle",
    })(CreateVehicle)
  )
);
