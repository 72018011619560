import React, { useState } from "react";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import { FieldArray, reduxForm, Field, change } from "redux-form";
import { useTranslation } from "react-i18next";
import i18n from "../../../../lib/i18n/i18n";
import validate from "./validate";
import DatePicker from "react-datepicker";
import SivanModal from "../../../Modal/Modal";
import {
  CreateButton,
  Icon,
  Body,
  Section,
  Row,
  SubTitle,
  AddMoreButton,
  SectionContainer,
} from "./editAgreement.styles";
import {
  createAgreement,
  setAgreementDisabled,
  updateAgreement,
  deleteAgreement,
} from "../../../../actions/agreementsActions";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../UiComponents/TextArea/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const renderDatePickerField = ({
  input,
  placeholder,
  meta: { error, touched },
  fields,
  fieldsArrayName,
  fieldName,
  index = -1,
  disabled = false,
  change,
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <div dir="auto" style={{ flex: "0 15%" }}>
      <span>{placeholder}</span>
      <DatePicker
        selected={input.value ? new Date(input.value) : null}
        dir={dir}
        onChange={(e) =>
          onChange(input, e, fieldsArrayName, fieldName, index, fields, change)
        }
        // onChange={input.onChange}
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        className={`${touched && error ? "error" : ""} date-input`}
        locale={i18n.language}
        disabled={disabled}
      />
    </div>
  );
};

const renderAgreementDetailsFields = ({
  fields,
  meta: { error, submitFailed },
  change,
  hide,
}) => {
  return fields.map((agreementDetail, index) => (
    <SectionContainer key={index}>
      <Section>
        {!hide && (
          <Field
            name={`${agreementDetail}.amount`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.amount}
            type="number"
            fullWidth={false}
          />
        )}
        <Field
          name={`${agreementDetail}.price`}
          component={InputWrappedWithField}
          placeholder={dictionary.newAgreement.price}
          type="number"
          fullWidth={false}
        />
        {!hide && (
          <Field
            name={`${agreementDetail}.priceAfter`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.priceAfter}
            type="number"
            fullWidth={false}
          />
        )}
        <Field
          name={`${agreementDetail}.from`}
          component={renderDatePickerField}
          placeholder={dictionary.newAgreement.from}
          fieldsArrayName={fields.name}
          fieldName="until"
          index={index}
          fields={fields}
          change={change}
        />
        <Field
          name={`${agreementDetail}.until`}
          component={renderDatePickerField}
          placeholder={dictionary.newAgreement.until}
          fieldsArrayName={fields.name}
          fieldName="from"
          disabled
          change={change}
          format={null}
        />
        {!hide && (
          <Field
            name={`${agreementDetail}.duration`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.duration}
            type="number"
            fullWidth={false}
          />
        )}
        {submitFailed && error && <span>{error}</span>}

        {index ? (
          <Icon className="fa fa-trash" onClick={() => fields.remove(index)} />
        ) : null}
      </Section>
      <AddMoreButton dir="auto" onClick={() => fields.push({ id: 0 })}>
        + {dictionary.teacherActionsModal.addMore}
      </AddMoreButton>
    </SectionContainer>
  ));
};

const onChange = (
  input,
  inputNewValue,
  fieldsArrayName,
  fieldName,
  index,
  fields,
  changeFunc
) => {
  let valid = true;
  if (index !== 0) {
    const newDate = new Date(inputNewValue);
    const previousFromDate = new Date(fields.get(index - 1).from);
    if (newDate > previousFromDate) {
      newDate.setDate(newDate.getDate() - 1);
      changeFunc(`${fieldsArrayName}[${index - 1}].${fieldName}`, newDate);
    } else {
      valid = false;
    }
  }

  if (valid) input.onChange(inputNewValue);
};

const renderAgreementExtraDetailsFields = ({
  fields,
  meta: { error, submitFailed },
  change,
}) => {
  return fields.map((agreementDetail, index) => (
    <SectionContainer key={index}>
      <Section noBorder basicData>
        <Row>
          <Field
            name={`${agreementDetail}.fromTestNumber`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.fromTestNumber}
            type="number"
            fullWidth
          />
          <Field
            name={`${agreementDetail}.extraPriceAfter`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.extraPriceAfter}
            type="number"
            fullWidth
          />
        </Row>
        <Row>
          <Field
            name={`${agreementDetail}.everyLessonIsFree`}
            component={InputWrappedWithField}
            placeholder={dictionary.newAgreement.everyLessonIsFree}
            type="number"
            fullWidth
          />
          <Field
            name={`${agreementDetail}.extrasActiveFrom`}
            component={renderDatePickerField}
            placeholder={dictionary.newAgreement.extrasActiveFrom}
            index={0}
          />
        </Row>
        {submitFailed && error && <span>{error}</span>}

        {index ? (
          <Icon className="fa fa-trash" onClick={() => fields.remove(index)} />
        ) : null}
      </Section>
      <AddMoreButton dir="auto" onClick={() => fields.push({ id: 0 })}>
        + {dictionary.teacherActionsModal.addMore}
      </AddMoreButton>
    </SectionContainer>
  ));
};

const EditAgreement = ({
  isEditAgreementModalOpen,
  setEditAgreementModalIsOpen,
  handleSubmit,
  updateAgreement,
  agreementData,
  setAgreementDisabled,
  submitting,
  change,
  deleteAgreement,
  deleteAgreementAllowed,
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  const setLoadingAndUpdateAgreement = async (formValues) => {
    setIsLoading(true);
    if (formValues.price !== "undefiend" && formValues.price === "")
      formValues.price = 0;
    await updateAgreement(formValues);
    setIsLoading(false);
    setEditAgreementModalIsOpen(false);
  };

  const setActiveOrInactive = async () => {
    setIsLoading(true);
    agreementData.active === 1
      ? await setAgreementDisabled(agreementData.id)
      : await setLoadingAndUpdateAgreement({
          ...agreementData,
          active: 1,
        });
    setIsLoading(false);
    setEditAgreementModalIsOpen(false);
  };

  const callDeleteAndCloseModal = async () => {
    setIsLoading(true);

    await deleteAgreement(agreementData.id);

    setIsLoading(false);
    setEditAgreementModalIsOpen(false);
  };

  return (
    <SivanModal
      titleText={t("editAgreement.title")}
      modalIsOpen={isEditAgreementModalOpen}
      closeModal={() => setEditAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body onSubmit={handleSubmit(setLoadingAndUpdateAgreement)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="description"
              component={NotesInput}
              placeholder={t("newAgreement.description")}
              fullWidth
            />
          </Row>
        </Section>
        <SubTitle border dir="auto">
          מחיר ההסכם
        </SubTitle>
        <FieldArray
          name="amountAgreementDetails"
          component={renderAgreementDetailsFields}
          change={change}
          hide
        />
        <SubTitle border dir="auto">
          {t("newAgreement.lessons")}
        </SubTitle>
        <FieldArray
          name="lessonsAgreementDetails"
          component={renderAgreementDetailsFields}
          change={change}
        />
        <SubTitle border dir="auto">
          {t("newAgreement.internalTests")}
        </SubTitle>
        <FieldArray
          name="internalTestsAgreementDetails"
          component={renderAgreementDetailsFields}
          change={change}
        />
        <SubTitle border dir="auto">
          {t("newAgreement.tests")}
        </SubTitle>
        <FieldArray
          name="testsAgreementDetails"
          component={renderAgreementDetailsFields}
          change={change}
        />
        <SubTitle border dir="auto">
          {t("newAgreement.extras")}
        </SubTitle>
        <FieldArray
          name="extra"
          component={renderAgreementExtraDetailsFields}
          change={change}
        />
        <div>
          <CreateButton type="submit" disabled={submitting}>
            {t("buttons.save")}
          </CreateButton>
          {deleteAgreementAllowed ? (
            <CreateButton
              type="submit"
              type="button"
              onClick={callDeleteAndCloseModal}
            >
              מחק
            </CreateButton>
          ) : null}
          <CreateButton
            type="submit"
            type="button"
            onClick={setActiveOrInactive}
          >
            {agreementData.active === 1
              ? t("buttons.inactive")
              : t("buttons.active")}
          </CreateButton>
        </div>
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    deleteAgreementAllowed: state.session.user.deleteAgreement,
    isPending: state.StudentsReducer.isPending,
    initialValues: {
      active: true,
      default: false,
      ...ownProps.agreementData,
      extrasActiveFrom: !ownProps.extrasActiveFrom
        ? null
        : new Date(parseInt(ownProps.agreementData.extrasActiveFrom)),
    },
  };
}

export default connect(mapStateToProps, {
  createAgreement,
  setAgreementDisabled,
  updateAgreement,
  deleteAgreement,
})(
  reduxForm({
    form: "editAgreement",
    validate,
  })(EditAgreement)
);
