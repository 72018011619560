import moment from "moment";
import reportsApi from "../apis/report";

export const getActivityAnalysisTeacher = (dateStart, dateEnd, teacher) => async (dispatch, getState) => {
  try {
    const _dateStart = moment(dateStart).utc().format('YYYY-MM-DD')
    const _dateEnd = moment(dateEnd).utc().format('YYYY-MM-DD')
    const params = { dateStart: _dateStart, dateEnd: _dateEnd }
    if (teacher) {
      params.teacher = teacher;
      if (Array.isArray(teacher)) params.teacher = teacher.map((v) => v.value ? v.value : v).join(',');
      if (params.teacher.includes('-999')) delete params.teacher;
    }
    const response = await reportsApi.get('activityAnalysisTeacher', { params });
    return response.data;
  } catch (error){
    console.error(error);
    return [];
  }
}

export const getActivityAnalysisVehicle = (dateStart, dateEnd, vehicle) => async (dispatch, getState) => {
  try {
    const _dateStart = moment(dateStart).utc().format('YYYY-MM-DD')
    const _dateEnd = moment(dateEnd).utc().format('YYYY-MM-DD')
    const params = { dateStart: _dateStart, dateEnd: _dateEnd }
    if (vehicle) {
      params.vehicle = vehicle;
      if (Array.isArray(vehicle)) params.vehicle = vehicle.map((v) => v.value ? v.value : v).join(',');
      if (params.vehicle.includes('-999')) delete params.vehicle;
    }
    const response = await reportsApi.get('activityAnalysisVehicle', { params });
    return response.data;
  } catch (error){
    console.error(error);
    return [];
  }
}

export const getActivityAnalysis = (dateStart, dateEnd, vehicle) => async (dispatch, getState) => {
  try {
    const _dateStart = moment(dateStart).utc().format('YYYY-MM-DD')
    const _dateEnd = moment(dateEnd).utc().format('YYYY-MM-DD')
    const params = { dateStart: _dateStart, dateEnd: _dateEnd }
    const response = await reportsApi.get('getActivityAnalysis', { params });
    return response.data;
  } catch (error){
    console.error(error);
    return [];
  }
}
