import React, { useState, useEffect } from "react";
import ErrorModal from "../../Modal/ErrorModal";
import SivanQuestionModal from "../../Modal/QuestionModal";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import {
  reduxForm,
  Field,
  formValueSelector,
  change,
  getFormValues,
} from "redux-form";
import validate from "./validate";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SivanModal from "../../Modal/NewModal";
import { Body, Section, Row } from "./newLesson.styles";
import { getVehicles, getVehicle } from "../../../actions/vehicleActions";
import useVehicles from "../../useComponents/useVehicles";
import usePickupLocations from "../../useComponents/usePickupLocations";
import { getPickupLocations } from "../../../actions/pickupLocationsActions";
import UseLoadStudents from "../../useComponents/UseLoadStudents";
import { getStudents, getStudent } from "../../../actions/studentesActions";
import {
  createEvent,
  getStudentEventCount,
  getEventsForTypeBetween,
  getStudentEvents,
} from "../../../actions/schedulerActions";
import { getAgreementDetails } from "../../../actions/agreementsActions";
import useTeacheres from "../../useComponents/useTeachers";
import useTesters from "../../useComponents/useTesters";
import useProfessionalTeachers from "../../useComponents/useProfessionalTeachers";
import { getUsers } from "../../../actions/userActions";
import {
  getStudentTextBooks,
  fetchTextBook,
  increaseCounter,
  increaseStudentBalance,
} from "../../../actions/textBooksActions";
import CreateButton from "../../UiComponents/Buttons/Default";
import { getBranches } from "../../../actions/branchActions";
import { getAll } from "../../../actions/drivingPermits";
import { getSchoolTesters } from "../../../actions/testersActions";
import { getSchoolProfessionalTeachers } from "../../../actions/professionalTeachersActions";
import { getTeacheresVehicles } from "../../../actions/teacherActions";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../UiComponents/TextArea/Default";
import DefaultSelect from "../../UiComponents/Select/Default";
import DefaultDatePicker from "../../UiComponents/DatePicker/WithTime";
import { getStudentIncomes } from "../../../actions/incomesAction";
import { getStudentDiscountsAndObligations } from "../../../actions/discountsAndObligationsActions";
import {
  eventPriceCalculator,
  findActiveAgreement,
  studentBalance,
} from "../../../utils/calculator";

let eventTypes = [
  { value: 1, label: "שיעור" },
  { value: 2, label: "טסט" },
  { value: 3, label: "מבחן פנימי" },
  { value: 4, label: "פגישה" },
];

const agreementsDetailTypes = {
  1: "lessonsAgreementDetails",
  2: "testsAgreementDetails",
  3: "internalTestsAgreementDetails",
};

const timeOptions = [
  { value: 0.5, label: "0.5" },
  { value: 1, label: "1" },
  { value: 1.5, label: "1.5" },
  { value: 2, label: "2" },
  { value: 2.5, label: "2.5" },
  { value: 3, label: "3" },
  { value: 3.5, label: "3.5" },
  { value: 4, label: "4" },
  { value: 4.5, label: "4.5" },
  { value: 5, label: "5" },
  { value: 5.5, label: "5.5" },
  { value: 6, label: "6" },
  { value: 6.5, label: "6.5" },
  { value: 7, label: "7" },
  { value: 7.5, label: "7.5" },
  { value: 8, label: "8" },
  { value: 8.5, label: "8.5" },
  { value: 9, label: "9" },
  { value: 9.5, label: "9.5" },
  { value: 10, label: "10" },
];

const onlyIntTimeOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
];

function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

const NewEvent = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  handleSubmit,
  submitting,
  pickupLocations,
  isPickupLocationsLoaded,
  getPickupLocations,
  isStudentsLoaded,
  getStudents,
  students,
  createEvent,
  getStudentTextBooks,
  change,
  textBookId,
  eventType,
  step,
  getVehicle,
  getStudent,
  studentId,
  events,
  newEventState,
  isUsersLoaded,
  isVehiclesLoaded,
  users,
  vehicles,
  getVehicles,
  start,
  fetchTextBook,
  getAgreementDetails,
  getStudentEventCount,
  amount,
  getBranches,
  userBranchId,
  drivingPermits,
  getAll,
  getSchoolTesters,
  isTestersLoaded,
  testers,
  isProfessionalTeachersLoaded,
  professionalTeachers,
  getSchoolProfessionalTeachers,
  formVehicleId,
  isAllowedTestWithoutCardCheck,
  isAllowedInternalTestWithoutCardCheck,
  isAllowedSettingTest,
  isAllowedSettingInternalTest,
  isAllowedSettingLesson,
  isAllowedSettingMeetings,
  studentFromCalendar,
  getEventsForTypeBetween,
  getStudentIncomes,
  getStudentEvents,
  getStudentDiscountsAndObligations,
  userType,
  teacherId,
  user,
  getTeacheresVehicles,
  increaseCounter,
  increaseStudentBalance,
  allowBypassObligo,
  isSplitLessons,
  selectedTeacher,
  selectedVehicle
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningModalText, setWarningModalText] = useState("");
  const [errorModalText, setErrorModalText] = useState("");
  const [errorObligoModalText, setErrorObligoModalText] = useState("");
  const [currVehicle, setCurrVehicle] = useState("");
  const [textBookOptions, setTextBookOptions] = useState([]);
  const [textBooksData, setTextBookData] = useState([]);
  const [studentEvents, setStudentEvents] = useState([]);
  const [duration, setDuration] = useState(step);
  let pickupLocationsOptions = usePickupLocations(
    isPickupLocationsLoaded,
    pickupLocations,
    getPickupLocations
  );
  const testersOptions = useTesters(isTestersLoaded, getSchoolTesters, testers);
  const professionalTeachersOptions = useProfessionalTeachers(
    isProfessionalTeachersLoaded,
    getSchoolProfessionalTeachers,
    professionalTeachers
  );
  const teachersOptions = useTeacheres(
    isUsersLoaded,
    getUsers,
    users.filter((x) => x.isActive === 1)
  );
  const vehicleOptions = useVehicles(
    isVehiclesLoaded,
    getVehicles,
    vehicles.filter((x) => x.active === 1)
  );
  const [branchOptions, setBranchOptions] = useState([]);
  const [drivingPermitsOptions, setDrivingPermitsOptions] = useState([]);
  const [isStudentTicketChecked, setIsStudentTicketChecked] = useState([]);
  let eventPrice = 0;

  UseLoadStudents(isStudentsLoaded, getStudents);

  useEffect(() => {
    async function fetchData() {
      const optionsArray = [];
      const allBranches = await getBranches();
      allBranches.map(({ id, name }) =>
        optionsArray.push({ value: id, label: name })
      );
      setBranchOptions(optionsArray);
      change(
        "branchId",
        optionsArray.find((x) => x.value.toString() === userBranchId.toString())
      );

      if (drivingPermits.length === 0) {
        await getAll();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isStudentsLoaded && branchOptions.length > 0) {
      const branchesLength = branchOptions.filter(
        (x) => x.label !== "כל הסניפים"
      ).length;
      let activeStudents = [];
      students.forEach((item, i) => {
        if (item.active === 1) activeStudents.push(item);
      });

      const options = activeStudents.map((x) => ({
        value: x.studentId,
        label: `${x.studentId} - ${x.firstName} ${x.lastName}${
          branchesLength > 1
            ? `, ${branchOptions.find((y) => y.value === x.branchId)?.label}`
            : ""
        }`,
      }));
      setSuggestions(options);
      if (studentFromCalendar !== null) {
        change(
          "studentId",
          options.find((x) => x.value.toString() === studentFromCalendar)
        );
      }
    }

    try {

      if (selectedTeacher.length >= 1) {
        try {
          const teacher = teachersOptions.find(
            (x) => x.value.toString() === selectedTeacher[0].value.toString()
          );
          change("teacherId", teacher);
        } catch(err) {
          console.log(err);
        }
      } else {
        try {
          const teacher = teachersOptions.find(
            (x) => x.value.toString() === selectedTeacher.value.toString()
          );
          change("teacherId", teacher);
        } catch(err) {
          console.log(err);
        }
      }
      if (selectedVehicle.length >= 1) {
        try {
          const vehicle = vehicleOptions.find(
            (x) => x.value.toString() === selectedVehicle[0].value.toString()
          );
          change("vehicleId", vehicle);
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log('err:', err);
    }
  }, [isStudentsLoaded, branchOptions]);

  useEffect(() => {
    (async () => {
      if (typeof studentId !== "undefined" && studentId) {
        const fetchedStundetTextBooks = await getStudentTextBooks(
          studentId?.value
        );
        setStudentEvents(await getStudentEvents(studentId.value));
        setTextBookData(fetchedStundetTextBooks);
        const options = fetchedStundetTextBooks.map((x) => ({
          value: x.textBookId,
          label: x.description,
        }));
        if (options.length === 0) {
          setIsErrorModalOpen(true);
          setErrorModalText("חסר לתלמיד תיק לימוד");
        } else {
          setTextBookOptions(options);
          if (fetchedStundetTextBooks.length === 1)
            change("textBookId", options[0]);
        }
      }
    })();
  }, [studentId]);

  useEffect(() => {
    (async () => {
      if (
        typeof textBookId !== "undefined" &&
        textBooksData.length > 0 &&
        studentId
      ) {
        await calculateUserPrice(studentId.value);
        if (eventType.value === 2) {
          change("testerId", testersOptions[0]);
        }
        if (eventType.value === 3) {
          change("internalTestStatus", 0);

          if (professionalTeachersOptions.length === 2) {
            change("professionalTeacherId", professionalTeachersOptions[1]);
          } else {
            change("professionalTeacherId", professionalTeachersOptions[0]);
          }
        }
      }
    })();
  }, [eventType, amount]);

  useEffect(() => {
    (async () => {
      if (
        typeof textBookId !== "undefined" &&
        textBooksData.length > 0 &&
        studentId
      ) {
        const chosenTextBook = textBooksData.find(
          (x) => x.textBookId === textBookId.value
        );

        await calculateUserPrice(studentId.value);

        let isAnyBlock = false;
        if (user.lessonWithoutTheoryAmount !== 0) {
          const numberOfEvents = await getStudentEventCount(
            eventType.value,
            studentId.value,
            textBookId.value,
            start
          );
          if (
            numberOfEvents >= user.lessonWithoutTheoryAmount &&
            chosenTextBook.theory === null
          ) {
            setErrorModalText(
              "לא ניתן ליצור אירוע עבור תלמיד, נא להזין תאוריה"
            );
            setCanCreate(true);
            isAnyBlock = true;
          }
        }
        if (
          chosenTextBook.obligo === "block" ||
          chosenTextBook.obligo === "notify"
        ) {
          const studentBalance = await calculateStudentBalance();
          if (
            studentBalance - eventPrice <
            -Math.abs(chosenTextBook.obligoAmount)
          ) {
            setIsErrorModalOpen(true);
            if (
              chosenTextBook.obligo !== "block" ||
              userType === 4 ||
              allowBypassObligo
            ) {
              setErrorObligoModalText("התלמיד בחוב (אובליגו התראה)");
            } else {
              setErrorModalText(
                "(אובליגו חסימה) לא ניתן ליצור אירוע עבור תלמיד"
              );
              isAnyBlock = true;
              setCanCreate(true);
            }
          }
        }
        setCanCreate(isAnyBlock);

        const vehicleId = chosenTextBook.vehicleId;
        const student = await getStudent(studentId.value);
        setIsStudentTicketChecked(student[0].isChecked === 1);
        const vehicle = await getVehicle(vehicleId);
        const vehicleMinStudyAge = vehicle[0].minAgeStudy;
        const teacher = teachersOptions.find(
          (x) => x.value.toString() === chosenTextBook.teacherId.toString()
        );

        try {
          if (selectedTeacher.length >= 1) {
          } else {
            change("teacherId", teacher);
          }
        }catch(err) {
          change("teacherId", teacher);
        }
        try {
          if (selectedVehicle.length >= 1) {
          } else {
            change(
             "vehicleId",
             vehicleOptions.find(
               (x) => x.value.toString() === chosenTextBook.vehicleId.toString()
             )
           );
          }
        }catch(err) {
          change(
           "vehicleId",
           vehicleOptions.find(
             (x) => x.value.toString() === chosenTextBook.vehicleId.toString()
           )
         );
        }
        // change("teacherId", teacher);
        // change(
        //   "vehicleId",
        //   vehicleOptions.find(
        //     (x) => x.value.toString() === chosenTextBook.vehicleId.toString()
        //   )
        // );

        const drivingPermitsOptions = drivingPermits
          .filter(
            (x) =>
              x.licenseTypeId.toString() === vehicle[0].licenseType.toString()
          )
          .map((x) => ({ value: x.id, label: x.name }));

        setDrivingPermitsOptions(drivingPermitsOptions);

        change(
          "drivingPermitId",
          user.schoolId === 1
            ? drivingPermitsOptions[4]
            : drivingPermitsOptions[0]
        );
        const monthsDiff = monthDiff(
          new Date(student[0].birthday),
          new Date(start)
        );
        if (vehicleMinStudyAge * 12 > monthsDiff) {
          setIsErrorModalOpen(true);
          setErrorModalText("התלמיד צעיר מידי");
        } else {
          setCurrVehicle(vehicle[0]);
        }
      }
    })();
  }, [textBookId]);

  useEffect(() => {
    (async () => {
      if (
        typeof textBookId !== "undefined" &&
        textBooksData.length > 0 &&
        studentId
      ) {
        const vehicle = await getVehicle(formVehicleId.value);
        const drivingPermitsOptions = drivingPermits
          .filter(
            (x) =>
              x.licenseTypeId.toString() === vehicle[0].licenseType.toString()
          )
          .map((x) => ({ value: x.id, label: x.name }));
        setDrivingPermitsOptions(drivingPermitsOptions);

        change(
          "drivingPermitId",
          user.schoolId === 1
            ? drivingPermitsOptions[4]
            : drivingPermitsOptions[0]
        );
      }
    })();
  }, [formVehicleId]);

  useEffect(() => {
    (async () => {
      if (
        typeof textBookId !== "undefined" &&
        textBooksData.length > 0 &&
        studentId
      ) {
        const teacherVehicles = await getTeacheresVehicles(teacherId.value);
        const vehicleIndex = teacherVehicles.findIndex(
          (x) => x.id === formVehicleId?.value
        );
        if (
          typeof teacherId !== "undefined" &&
          textBooksData[0].teacherId !== teacherId.value.toString()
        ) {
          setIsErrorModalOpen(true);
          setErrorObligoModalText("המורה לא קיים בתיק לימוד");
        } else if (
          typeof formVehicleId !== "undefined" &&
          textBooksData[0].vehicleId !== formVehicleId.value.toString() &&
          user.schoolId !== 1
        ) {
          setIsErrorModalOpen(true);
          setErrorObligoModalText("הרכב לא קיים בתיק לימוד");
        } else if (
          vehicleIndex === -1 &&
          users.find((x) => x.id == teacherId.value)?.userType !== 4 &&
          typeof formVehicleId !== "undefined"
        ) {
          setIsErrorModalOpen(true);
          setErrorObligoModalText("המורה לא מורשה לרכב");
        }
      }
    })();
  }, [formVehicleId, teacherId]);

  useEffect(() => {
    if (
      typeof eventType !== "undefined" &&
      ((eventType.value === 2 && !isAllowedSettingTest) ||
        (eventType.value === 3 && !isAllowedSettingInternalTest) ||
        (eventType.value === 1 && !isAllowedSettingLesson))
    ) {
      setCanCreate(true);
      setErrorModalText("אין הרשאה ליצירת אירוע מהסוג הנבחר");
      setIsErrorModalOpen(true);
    } else if (
      !isStudentTicketChecked &&
      typeof eventType !== "undefined" &&
      ((eventType.value === 2 && !isAllowedTestWithoutCardCheck) ||
        (eventType.value === 3 && !isAllowedInternalTestWithoutCardCheck))
    ) {
      setCanCreate(true);
      setErrorModalText("לא ניתן להזמין טסט, פנה למנהל לבדיקת הכרטסת");
      setIsErrorModalOpen(true);
    } else if (
      typeof eventType !== "undefined" &&
      eventType.value === 4 &&
      !isAllowedSettingMeetings
    ) {
      setCanCreate(true);
      setErrorModalText("אין הרשאה ליצירת אירוע מהסוג הנבחר");
      setIsErrorModalOpen(true);
    } else {
      setErrorModalText("");
      setCanCreate(false);
    }
  }, [eventType]);

  const calculateUserPrice = async () => {
    const response = await fetchTextBook(textBookId.value);

    const activeAgreement = findActiveAgreement(
      response.agreements,
      "startDate",
      start
    );

    if (typeof activeAgreement === "undefined") {
      setCanCreate(true);
      setErrorModalText("אין לתלמיד הסכם");
      setIsErrorModalOpen(true);
    } else {
      const agreementsDetails = await getAgreementDetails(
        activeAgreement.agreementId
      );

      const agreementDetails =
        agreementsDetails[agreementsDetailTypes[eventType.value]];

      const activeAgreementEventType = findActiveAgreement(
        agreementDetails,
        "from",
        start
      );
      setDuration(activeAgreementEventType.duration);
      const studentEventsFiltered = studentEvents.filter(
        (x) => x.eventStatus !== 3 && x.textBookId === textBookId.value
      );

      const price = eventPriceCalculator(
        {
          eventType: eventType.value,
          start: start,
          end: new Date(
            start.getTime() +
              activeAgreementEventType.duration * 60000 * amount.value
          ),
        },
        studentEventsFiltered,
        agreementsDetails,
        response
      );

      eventPrice = price;
      change("price", eventPrice);
    }
  };

  const getTextBookAgreementOpeningBalance = async (agreements) => {
    let openingBalance = 0;
    for (let index = 0; index < agreements.length; index++) {
      const element = agreements[index];
      if (moment(new Date()).isAfter(element.startDate)) {
        const agreementsDetails = await getAgreementDetails(
          element.agreementId
        );
        const activeAgreementAmount = findActiveAgreement(
          agreementsDetails["amountAgreementDetails"],
          "from",
          new Date(element.startDate)
        );
        openingBalance += activeAgreementAmount.price;
      }
    }
    return openingBalance;
  };

  const calculateStudentBalance = async () => {
    const incomes = await getStudentIncomes(studentId.value);
    const discountsAndObligations = await getStudentDiscountsAndObligations(
      studentId.value
    );
    return studentBalance(
      incomes,
      discountsAndObligations,
      fetchTextBook,
      getTextBookAgreementOpeningBalance,
      studentEvents,
      textBooksData
    );
  };

  const checkIfDateExists = async (formValues, end, currStudent) => {
    let warningText = "";
    let vehicleEvents = 0;
    let teacherEvents = 0;
    let studentEvents = 0;
    if (typeof formValues.vehicleId !== "undefined")
      vehicleEvents = await getEventsForTypeBetween(
        "vehicleId",
        formValues.start,
        end,
        formValues.vehicleId.value
      );

    if (typeof formValues.teacherId !== "undefined")
      teacherEvents = await getEventsForTypeBetween(
        "teacherId",
        formValues.start,
        end,
        formValues.teacherId.value
      );

    if (typeof formValues.studentEvents !== "undefined")
      studentEvents = await getEventsForTypeBetween(
        "studentId",
        formValues.start,
        end,
        currStudent.studentId
      );

    if (vehicleEvents !== 0) {
      warningText = "לרכב ";
    } else if (teacherEvents !== 0) {
      warningText = "למורה ";
    } else if (studentEvents !== 0) {
      warningText = "לתלמיד ";
    }

    if (warningText.length > 0) {
      warningText += "קיים אירוע בין השעות הנבחרות";
      setWarningModalText(warningText);
      setIsWarningModalOpen(true);
    } else {
      callCreateNewEvent();
    }
  };

  const createNewEvent = async (formValues) => {
    setIsLoading(true);
    if (errorModalText.length === 0) {
      let currStudent = undefined;
      if (formValues.studentId) {
        currStudent = students.find(
          (x) => x.studentId === formValues.studentId.value
        );
      }
      const end = new Date(
        formValues.start.getTime() + duration * 60000 * formValues.amount.value
      );

      await checkIfDateExists(formValues, end, currStudent);
      setIsLoading(false);
    }
  };

  const formatPhoneForWhatsapp = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    phoneNumber = phoneNumber.replaceAll('-', '');
    if (phoneNumber.includes('+972')) return phoneNumber;
    else if (phoneNumber.includes('972')) return '+' + phoneNumber;
    else {
      return '+972' + phoneNumber;
    }
  }

  const callCreateNewEvent = async () => {
    if (errorModalText.length === 0) {
      let currStudent = undefined;
      if (newEventState.studentId) {
        currStudent = students.find(
          (x) => x.studentId === newEventState.studentId.value
        );
      }
      const end = new Date(
        newEventState.start.getTime() +
          duration * 60000 * newEventState.amount.value
      );

      let eventToSave = {
        pickupLocationId: newEventState.pickupLocationId,
        notes: newEventState.notes,
        eventType: newEventState.eventType,
        start: newEventState.start,
        studentId: currStudent,
        vehicleId: newEventState.vehicleId
          ? newEventState.vehicleId.value
          : null,
        updateUsername: `${user.firstName} ${user.lastName}`,
        teacherId: newEventState.teacherId
          ? newEventState.teacherId.value
          : null,
        end,
      };
      if (newEventState.eventType.value !== 4) {
        eventToSave = {
          ...eventToSave,
          ...{
            textBookId: newEventState.textBookId.value,
            price: newEventState.price,
            internalTestStatus: newEventState.internalTestStatus,
          },
        };
      }

      if (newEventState.drivingPermitId)
        eventToSave = {
          ...eventToSave,
          drivingPermitId: newEventState.drivingPermitId.value,
        };

      if (newEventState.eventType.value === 2 && newEventState.testerId) {
        eventToSave = {
          ...eventToSave,
          testerId: newEventState.testerId.value,
        };
      }

      if (
        newEventState.eventType.value === 3 &&
        newEventState.professionalTeacherId
      ) {
        eventToSave = {
          ...eventToSave,
          professionalTeacherId: newEventState.professionalTeacherId.value,
        };
      }

      if (!isErrorModalOpen)
        if (newEventState.eventType.value === 4) {
          if (isSplitLessons) {
            for (let index = 0; index < parseInt(amount.value); index++) {
              await createEvent({
                ...eventToSave,
                start: moment(eventToSave.start).add(
                  duration * index,
                  "minutes"
                ),
                end: moment(eventToSave.start).add(
                  duration * (index + 1),
                  "minutes"
                ),
              });
            }

            if (amount.value % 2 === 0.5) {
              await createEvent({
                ...eventToSave,
                start: moment(eventToSave.start).add(
                  duration * parseInt(amount.value),
                  "minutes"
                ),
                end: moment(eventToSave.start).add(
                  duration * parseInt(amount.value) + duration * 0.5,
                  "minutes"
                ),
              });
            }
          } else {
            await createEvent(eventToSave);
          }
        } else {
          if (isSplitLessons) {
            for (let index = 0; index < parseInt(amount.value); index++) {
              await createEvent(
                {
                  ...eventToSave,
                  start: moment(eventToSave.start).add(
                    duration * index,
                    "minutes"
                  ),
                  end: moment(eventToSave.start).add(
                    duration * (index + 1),
                    "minutes"
                  ),
                  price: eventToSave.price / amount.value,
                },
                currVehicle
              );
            }

            if (amount.value % 2 === 0.5) {
              await createEvent(
                {
                  ...eventToSave,
                  start: moment(eventToSave.start).add(
                    duration * parseInt(amount.value),
                    "minutes"
                  ),
                  end: moment(eventToSave.start).add(
                    duration * parseInt(amount.value) + duration * 0.5,
                    "minutes"
                  ),
                  price: eventToSave.price / amount.value,
                },
                currVehicle
              );
            }
          } else {
            await createEvent(eventToSave, currVehicle);
          }
          if (moment(end).isBefore(moment(new Date()))) {
            await increaseCounter(
              newEventState.eventType.value,
              newEventState.textBookId.value
            );
            await increaseStudentBalance(
              newEventState.textBookId.value,
              newEventState.price
            );
          }
        }
      if (
        isSplitLessons &&
        currStudent?.smsType === "whatsapp" &&
        amount.value > 1
      ) {

        window.open(`https://wa.me/${
          formatPhoneForWhatsapp(currStudent.phone.startsWith("0") ? currStudent.phone.substring(1) : currStudent.phone)
        }?text=${`שלום לך ${
            currStudent.firstName
          } ${currStudent.lastName},%0aברצוני להזכירך כי בתאריך ${moment(
            newEventState.start
          ).format("DD/MM/YYYY")}, ביום ${moment(newEventState.start).format(
            "dddd"
          )} בשעה ${moment(newEventState.start).format("HH:mm")}%0aקבענו ${
            amount.value
          } ${newEventState.eventType.label}ים.%0aנתראה, ${
            newEventState.teacherId.label
          }`}`, '_blank'
        );
      } else if (currStudent?.smsType === "whatsapp") {

        window.open(`https://wa.me/${
          formatPhoneForWhatsapp(currStudent.phone.startsWith("0") ? currStudent.phone.substring(1) : currStudent.phone)
        }?text=${`שלום לך ${
            currStudent.firstName
          } ${currStudent.lastName},%0aברצוני להזכירך כי בתאריך ${moment(
            newEventState.start
          ).format("DD/MM/YYYY")}, ביום ${moment(newEventState.start).format(
            "dddd"
          )} בשעה ${moment(newEventState.start).format("HH:mm")}%0aקבענו ${
            newEventState.eventType.label
          }.%0aנתראה, ${newEventState.teacherId.label}`}`, '_blank'
        );
      }
      setIsLoading(false);
      setNewAgreementModalIsOpen(false);
    }
  };

  return (
    <SivanModal
      titleText="אירוע חדש"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      {isErrorModalOpen && (
        <ErrorModal
          modalIsOpen={isErrorModalOpen}
          closeModal={() => setIsErrorModalOpen(false)}
          text={errorModalText || errorObligoModalText}
        />
      )}
      <Body onSubmit={handleSubmit(createNewEvent)}>
        <div style={{ width: "100%", maxHeight: "80vh" }}>
          <Section basicData noBorder>
            <Row>
              <Field
                name="studentId"
                component={DefaultSelect}
                options={suggestions}
                placeholder="בחר תלמיד"
              />
              <Field
                name="eventType"
                component={DefaultSelect}
                placeholder="סוג אירוע"
                options={eventTypes}
              />
              {eventType === eventTypes[0] &&
                drivingPermitsOptions.length !== 0 && (
                  <Field
                    name="drivingPermitId"
                    component={DefaultSelect}
                    placeholder="היתר נהיגה"
                    options={drivingPermitsOptions}
                  />
                )}
              {eventType === eventTypes[2] && (
                <Field
                  name="professionalTeacherId"
                  component={DefaultSelect}
                  placeholder="מנהל מקצועי"
                  options={professionalTeachersOptions}
                />
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <Field
                  name="start"
                  component={DefaultDatePicker}
                  placeholder="התחלה"
                />
                <Field
                  name="amount"
                  component={DefaultSelect}
                  placeholder="כמות"
                  options={
                    eventType === eventTypes[2] || eventType === eventTypes[1]
                      ? onlyIntTimeOptions
                      : timeOptions
                  }
                />
              </div>
              {eventType !== eventTypes[3] && (
                <Field
                  name="textBookId"
                  component={DefaultSelect}
                  placeholder="תיק לימוד"
                  options={textBookOptions}
                />
              )}
              <Field
                name="branchId"
                component={DefaultSelect}
                placeholder={t("newStudent.branch")}
                options={branchOptions}
                isDisabled={branchOptions.length === 1}
              />
              {eventType === eventTypes[1] && (
                <Field
                  name="testerId"
                  component={DefaultSelect}
                  placeholder="טסטר"
                  options={testersOptions}
                />
              )}
            </Row>
            <Row>
              <Field
                name="teacherId"
                component={DefaultSelect}
                placeholder="מורה"
                options={teachersOptions}
              />
              <Field
                name="vehicleId"
                component={DefaultSelect}
                placeholder="רכב"
                options={vehicleOptions}
              />
              <Field
                name="pickupLocationId"
                component={DefaultSelect}
                placeholder="נקודת איסוף"
                options={pickupLocationsOptions}
              />
              {eventType !== eventTypes[3] && (
                <Field
                  name="price"
                  component={InputWrappedWithField}
                  placeholder={t("newAgreement.agreementPrice")}
                  type="number"
                  disabled
                />
              )}
              <Field name="notes" component={NotesInput} placeholder="הערות" />
            </Row>
          </Section>
        </div>
        <CreateButton
          type="submit"
          disabled={submitting || canCreate}
          text="שמירה"
          marginTop={2}
        />
      </Body>
      <SivanQuestionModal
        titleText={warningModalText}
        bodySecondRow=""
        modalIsOpen={isWarningModalOpen}
        closeModal={() => setIsWarningModalOpen(false)}
        deleteItem={() => setIsWarningModalOpen(false)}
        disableItem={callCreateNewEvent}
        // isDelete={isDelete}
      />
    </SivanModal>
  );
};
const selector = formValueSelector("newEvent"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    testers: state.TestersReducer.testers,
    professionalTeachers:
      state.ProfessionalTeachersReducer.professionalTeachers,
    isProfessionalTeachersLoaded: state.ProfessionalTeachersReducer.isLoaded,
    isAllowedTestWithoutCardCheck:
      state.session.user.isAllowedTestWithoutCardCheck,
    isAllowedSettingMeetings: state.session.user.isAllowedSettingMeetings,
    isAllowedSettingLesson: state.session.user.isAllowedSettingLesson,
    isAllowedSettingTest: state.session.user.isAllowedSettingTest,
    isSplitLessons: state.session.user.isSplitLessons,
    isAllowedSettingInternalTest:
      state.session.user.isAllowedSettingInternalTest,
    allowBypassObligo: state.session.user.allowBypassObligo,
    isAllowedInternalTestWithoutCardCheck:
      state.session.user.isAllowedInternalTestWithoutCardCheck,
    isTestersLoaded: state.TestersReducer.isLoaded,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    userBranchId: state.session.user.branchId,
    userType: state.session.user.userType,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    events: state.CalendarReducer.events,
    pickupLocations: state.PickupLocationsReducer.locations,
    isPickupLocationsLoaded: state.PickupLocationsReducer.isLoaded,
    textBookId: selector(state, "textBookId"),
    amount: selector(state, "amount"),
    studentId: selector(state, "studentId"),
    eventType: selector(state, "eventType"),
    start: selector(state, "start"),
    formVehicleId: selector(state, "vehicleId"),
    teacherId: selector(state, "teacherId"),
    newEventState: getFormValues("newEvent")(state),
    user: state.session.user,
    students: state.StudentsReducer.students,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    initialValues: {
      start: ownProps.selectedEvent.start,
      internalTestStatus: 0,
      end: ownProps.selectedEvent.end,
      // drivingPermitId: drivingPermitsOptions[5],
      eventType: eventTypes[0],
      amount: {
        value: ownProps.selectedEvent.slots.length - 1,
        label: (ownProps.selectedEvent.slots.length - 1).toString(),
      },
      pickupLocationId: { value: 999, label: "ללא" },
    },
  };
}

export default connect(mapStateToProps, {
  getPickupLocations,
  getVehicles,
  getStudents,
  createEvent,
  getUsers,
  getStudentTextBooks,
  change,
  fetchTextBook,
  getVehicle,
  getStudent,
  getAgreementDetails,
  getStudentEventCount,
  getBranches,
  getAll,
  getSchoolTesters,
  getSchoolProfessionalTeachers,
  getEventsForTypeBetween,
  getStudentIncomes,
  getStudentEvents,
  getStudentDiscountsAndObligations,
  getTeacheresVehicles,
  increaseCounter,
  increaseStudentBalance,
})(
  reduxForm({
    form: "newEvent",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true,
    validate,
  })(NewEvent)
);
