import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";
import { isMobile } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Body = styled.div`
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  border-radius: 50%;
  width: ${isMobile ? "70vw" : "12vw"};
  height: ${isMobile ? "70vw" : "12vw"};
`;

export const LeftButton = styled.div`
  position: absolute;
  height: ${isMobile ? "20vw" : "4vw"};;
  width: ${isMobile ? "20vw" : "4vw"};;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  cursor pointer;

  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  &:hover {
    background-image: linear-gradient(to bottom, #ebd794, #b5913e);
  }
`;

export const TopButton = styled.div`
  position: absolute;
  height: ${isMobile ? "20vw" : "4vw"};;
  width: ${isMobile ? "20vw" : "4vw"};;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  cursor pointer;

  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  &:hover {
    background-image: linear-gradient(to bottom, #ebd794, #b5913e);
  }
`;
export const RightButton = styled.div`
  position: absolute;
  height: ${isMobile ? "20vw" : "4vw"};;
  width: ${isMobile ? "20vw" : "4vw"};;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 50%;
  cursor pointer;

  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  &:hover {
    background-image: linear-gradient(to bottom, #ebd794, #b5913e);
  }
`;

export const BottomButton = styled.div`
  position: absolute;
  height: ${isMobile ? "20vw" : "4vw"};;
  width: ${isMobile ? "20vw" : "4vw"};;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  bottom: 0;
  cursor pointer;

  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  &:hover {
    background-image: linear-gradient(to bottom, #ebd794, #b5913e);
  }
`;

export const BottomLeftButton = styled.div`
  position: absolute;
  height: ${isMobile ? "20vw" : "4vw"};;
  width: ${isMobile ? "20vw" : "4vw"};;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 19%;
  bottom: 0;
  cursor pointer;

  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  &:hover {
    background-image: linear-gradient(to bottom, #ebd794, #b5913e);
  }
`;

export const CenterText = styled.div`
  position: absolute;
  height: ${isMobile ? "20vw" : "4vw"};
  width: ${isMobile ? "20vw" : "4vw"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40%;
  bottom: 31%;
  text-align: center;
`;

export const ButtonText = styled.span``;
