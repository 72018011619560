import textBooksApi from "../apis/textBooks";
import {
  LEARNING_ASSIGNMENTS_FETCH_SUCCESS,
  LEARNING_ASSIGNMENTS_FETCH_FAILED,
  LEARNING_ASSIGNMENTS_FETCH_STARTED,
} from "../constants/actionTypes";
import { toast } from "react-toastify";
import { getAgreementDetails } from "./agreementsActions";
import { findActiveAgreement } from "../utils/calculator";
import moment from "moment";

//TODO: added teacherId and schoolId
export const createStudentTextBook =
  (studentId, agreementId, vehicleId, teacherId) =>
  async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId, firstName, lastName } = getState().session.user;
      const response = await textBooksApi.post(
        "/createStudentTextBook",
        {
          studentId,
          agreementId,
          vehicleId,
          teacherId,
          obligo: "block",
          obligoAmount: 0,
          schoolId,
          createdBy: `${firstName} ${lastName}`,
        },
        {
          headers,
        }
      );

      return response.data.id;
    } catch (error) {
      toast.error("כישלון ביצירת תיק לימוד");
      return false;
    }
  };

export const getStudentTextBooks =
  (studentId) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;
      const response = await textBooksApi.get(
        `/getStudentTextBooks/${studentId}/${schoolId}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {}
  };

export const deleteStudentTextBook = (textBookId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await textBooksApi.delete(
      "/deleteStudentTextBook",
      {
        data: { textBookId },
      },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    toast.error("כישלון במחיקת תיק לימוד");
    return false;
  }
};

export const updateTextBook = (textBook) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { firstName, lastName } = getState().session.user;
    await textBooksApi.post(
      "/createOrUpdateTextBook",
      {
        texbookData: {
          updatedAt: new Date(),
          updatedBy: `${firstName} ${lastName}`,
          ...textBook,
        },
      },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    toast.error("כישלון");
    return false;
  }
};

export const getTextBookAgreementOpeningBalance = (agreements) => async (dispatch) => {
  let openingBalance = 0;
  for (let index = 0; index < agreements.length; index++) {
    const element = agreements[index];
    const startDate = element.startDate ? element.startDate : new Date(2001);

    if (moment(new Date()).isAfter(startDate)) {
      const agreementsDetails = await dispatch(getAgreementDetails(
        element.agreementId
      ));

      const activeAgreementAmount = findActiveAgreement(
        agreementsDetails["amountAgreementDetails"],
        "from",
        new Date(element.startDate)
      );
      openingBalance += activeAgreementAmount?.price || 0;
    }
  }
  return openingBalance;
};

export const fetchTextBook = (textBookId) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await textBooksApi.get(`/fetchTextBook/${textBookId}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return false;
  }
};

export const updateTextBookDates = (textBook) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { firstName, lastName } = getState().session.user;
    await textBooksApi.post(
      "/updateDates",
      {
        texbookData: {
          ...textBook,
          updatedAt: new Date(),
          updatedBy: `${firstName} ${lastName}`,
        },
      },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    toast.error("כישלון");
    return false;
  }
};

export const increaseCounter = (eventType, textBookId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await textBooksApi.patch(
      "/increaseEventCoutner",
      {
        eventType,
        textBookId,
      },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const decreaseCoutner = (eventType, textBookId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await textBooksApi.patch(
      "/decreaseEventCoutner",
      {
        eventType,
        textBookId,
      },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const increaseStudentBalance =
  (textBookId, amount) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      await textBooksApi.patch(
        "/increaseStudentBalance",
        {
          textBookId,
          amount,
        },
        {
          headers,
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };

export const setStudentBalance = (textBookId, amount) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    await textBooksApi.patch(
      "/setStudentBalance",
      {
        textBookId,
        amount,
      },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const decreaseStudentBalance =
  (textBookId, amount) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      await textBooksApi.patch(
        "/decreaseStudentBalance",
        {
          textBookId,
          amount,
        },
        {
          headers,
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };

export const insertNewTextbookAgreement =
  (agreementId, startDate, textBookId) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await textBooksApi.post(
        "/newAgreement",
        {
          agreementId,
          startDate,
          textBookId,
        },
        {
          headers,
        }
      );
      return response.data.id;
    } catch (error) {
      toast.error("כישלון");
      return false;
    }
  };

export const deleteNewTextbookAgreement = (id) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await textBooksApi.delete(
      "/deleteAgreement",
      {
        data: { id },
      },
      {
        headers,
      }
    );
    return true;
  } catch (error) {
    toast.error("כישלון");
    return false;
  }
};

export const getAssignments = () => async (dispatch) => {
  try {
    dispatch({
      type: LEARNING_ASSIGNMENTS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await textBooksApi.get(`/getAssignments`, {
      headers,
    });

    dispatch({
      type: LEARNING_ASSIGNMENTS_FETCH_SUCCESS,
      payload: response.data.assignments,
    });
  } catch (error) {
    dispatch({
      type: LEARNING_ASSIGNMENTS_FETCH_FAILED,
    });
  }
};

export const getFundingBodyStudents =
  (fundingBody) => async (dispatch, getState) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const { schoolId } = getState().session.user;

      const response = await textBooksApi.get(
        `/fetchFundingBodyStudents/${fundingBody}/${schoolId}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {}
  };

export const countStudentCompletedAssignments =
  (studentId) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await textBooksApi.get(
        `/countStudentCompletedAssignments/${studentId}`,
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {}
  };

export const setStudentAssignments = (assignments) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await textBooksApi.post(
      "/setStudentAssignments",
      {
        assignments,
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון");
  }
};

export const getStudentAssignments =
  (studentId, studentLicense) => async (dispatch) => {
    try {
      const token = window.localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await textBooksApi.get(
        `/getStudentAssignments/${studentId}/${studentLicense}`,
        {
          headers,
        }
      );

      return response.data;
    } catch (error) {}
  };
