import React, { useState } from "react";
import { connect } from "react-redux";
import SivanModal from "../Modal/NewModal";
import { Body, Button, ErrorBlock, Row } from "./Promt/promt.styles";

const Alert = ({
  children,
  isModalOpen,
  onClose,
  title,
  txtBtn1,
  txtBtn2,
}) => {
  const [error, setError] = useState('');

  const makeAction = async (result) => {
    onClose(result);
  };

  return (
    <SivanModal
      titleText={title}
      modalIsOpen={isModalOpen}
      closeModal={() => onClose(false)}
      isLoading={false}
    >
      <Body>
        {error &&
          <ErrorBlock>{{error}}</ErrorBlock>
        }
        {children }
        <Row>
          <Button onClick={() => makeAction(1)}>{txtBtn1}</Button>
          {txtBtn2 && <Button onClick={() => makeAction(2)}>{txtBtn2}</Button>}
        </Row>
      </Body>
    </SivanModal>
  );
};

export default connect(null, {})(Alert);
