import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../lib/i18n/i18n";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import Toggle from "react-toggle";
import FinancialInvoiceHeader from "../../../FinancialInvoiceHeader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
  InvoiceCompanyNameHeader,
  InvoiceType,
  InvoiceStatus,
  Line,
  InvoiceBody,
  InvoiceHeader,
  InvoiceContainer,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  PrintFooter,
  ClientDataText,
  ClientData,
  NotesContainer,
  NotesText,
} from "./incomeDetails.styles";
import {
  updateInvoicePrinted,
  deleteItem,
} from "../../../../../actions/incomesAction";
import Button from "../../../../UiComponents/Buttons/Default";
import { getVehicles } from "../../../../../actions/vehicleActions";
import useVehicles from "../../../../useComponents/useVehicles";
import { getStudents } from "../../../../../actions/studentesActions";
import DefaultWrappedWithField from "../../../../UiComponents/Inputs/DefaultWrappedWithField";
import Input from "../../../../UiComponents/Inputs/Default";
import NotesInput from "../../../../UiComponents/TextArea/Default";
import DefaultSelect from "../../../../UiComponents/Select/Default";
import DefaultDatePicker from "../../../../UiComponents/DatePicker/WithTime";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { Document, Page } from "react-pdf";
import { downloadFile, generateIncomePdf } from "../../../../../actions/pdfAction";
import PromtEmail from "../../../../App/Promt/Email";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
  { value: 3, label: "חשבונית/קבלה זיכוי" },
  { value: 4, label: "קבלה זיכוי" },
  { value: 5, label: "חשבונית זיכוי" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
  { value: 6, label: "סליקה" },
];
let invoiceType = "העתק";

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} disabled />
    </ToggleContainer>
  );
};

const FundingBodiesDetails = ({
  handleSubmit,
  getVehicles,
  vehicles,
  isVehiclesLoaded,
  paymentMethod,
  documentType,
  students,
  isStudentsLoaded,
  change,
  providerData,
  getStudents,
  user,
  documentNumber,
  updateInvoicePrinted,
  amount,
  closeModal,
  generateIncomePdf,
}) => {
  const [clientsOptions, setClientsOptions] = useState([]);
  const [sum, setSum] = useState(0);
  const [clientData, setClientData] = useState(0);
  const [providersOptions, setProvidersOptions] = useState([]);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);
  const [isShowPrintModal, setIsShowPrintModal] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!isStudentsLoaded) {
        await getStudents();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (students.length) {
      const options = students.map((x) => ({
        value: x.studentId,
        label: `${x.firstName} ${x.lastName}`,
      }));
      const client = students.find(
        (x) => x.studentId === providerData.clientId.toString()
      ) || {};
      setClientData(client);
      setClientsOptions(options);
      change("clientId", {
        value: client.studentId,
        label: `${client.firstName} ${client.lastName}`,
      });
    }
  }, [students]);

  const paymentMethodDetails = () => {
    if (providerData.paymentMethod === 0) {
      return "";
    } else if (providerData.paymentMethod === 1) {
      return `${
        providerData.accountId ? ` חשבון: ${providerData.accountId}, ` : ""
      }${providerData.bankBranch ? ` סניף: ${providerData.bankBranch}` : ""} ${
        providerData.bank ? `, בנק: ${providerData.bank}` : ""
      } ${providerData.checkNumber ? `, שיק: ${providerData.checkNumber}` : ""}
      `;
    } else if (providerData.paymentMethod === 3) {
      return ` חשבון: ${providerData.accountId}, ${
        providerData.bankBranch ? `סניף: ${providerData.bankBranch},` : ""
      } בנק: ${providerData.bank} ${
        providerData.reference ? `, אסמכתא: ${providerData.reference}` : ""
      }`;
    } else if (providerData.paymentMethod === 4) {
      return ` מספר אישור: ${providerData.confirmationNumber}${
        providerData.confirmationName !== null
          ? `, מאשר: ${providerData.confirmationName}`
          : ""
      }`;
    } else if (providerData.paymentMethod === 5) {
      return ` חשבון: ${providerData.accountId}, מספר אישור: ${providerData.confirmationNumber}, סוג: ${providerData.appName}`;
    }
  };

  const generatePdf = async (original, emails='') => {
    setIsShowPrintModal(false);
    if (emails === false) return;
    const file = await generateIncomePdf(providerData.id, original, emails);
    if (!file && emails.length > 0) {
      closeModal();
      return toast('File sent to email.', { type: 'success' })
    }
    downloadFile(file)

    if (window.location.pathname === "/calendar") {
      closeModal();
    }
  };

  const sendToEmail = () => {
    setIsShowPrintModal(true);
  }

  const fundingBodyCreateAction = async (formValues) => {};
  return (
    <>
    { isShowPrintModal && 
      <PromtEmail
        isModalOpen={isShowPrintModal}
        onClose={(res) => generatePdf(providerData.isOriginalPrinted==1 ? 0 : 1, res)}
        txtBtn2="ביטול"
        defaultValue={clientData.email}
        btn2DefaultValue={false}
      />
    }
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        {/* <Section basicData noBorder>
          <Row>
            <Field
              name="date"
              component={DefaultDatePicker}
              placeholder="תאריך ושעה"
            />
            <Field
              name="clientId"
              component={DefaultSelect}
              placeholder="לקוח"
              options={clientsOptions}
              disabled
            />
            <Field name="description" component={NotesInput} placeholder="תאור" />
            <Field
              name="quantity"
              component={DefaultWrappedWithField}
              placeholder="כמות"
              type="number"
              disabled
            />
            <Field
              name="isEquipmentAndAssets"
              component={renderToggle}
              label="ציוד ונכסים קבועים"
            />
            <Field
              name="vehicleId"
              component={DefaultSelect}
              placeholder="רכב"
              options={[{ value: 999, label: "כל הרכבים" }, ...vehicleOptions]}
              disabled
            />
          </Row>
          <Row>
            <Field
              name="documentType"
              component={DefaultSelect}
              placeholder="סוג"
              options={documentTypes}
              disabled
            />
            <Field name="isCredit" component={renderToggle} label="זיכוי" />
            <Field
              name="vat"
              component={DefaultWrappedWithField}
              placeholder="מע״מ"
              disabled
            />
            <Field
              name="documentNumber"
              component={DefaultWrappedWithField}
              placeholder="מספר מסמך"
              type="number"
              disabled
            />
            <Field
              name="notes"
              component={NotesInput}
              placeholder="הערה"
              disabled
            />
          </Row>
          <Row>
            {documentType?.value !== 2 && (
              <Field
                name="paymentMethod"
                component={DefaultSelect}
                placeholder="סוג תשלום"
                options={paymentMethods}
                disabled
              />
            )}
            <Field
              name="amount"
              component={DefaultWrappedWithField}
              placeholder="סכום"
              type="number"
              disabled
            />
            <Input placeholder="סהכ לחיוב" value={sum} disabled />
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bank"
                component={DefaultWrappedWithField}
                placeholder="בנק"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bankBranch"
                component={DefaultWrappedWithField}
                placeholder="סניף"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[1] && (
              <Field
                name="checkNumber"
                component={DefaultWrappedWithField}
                placeholder="מס המחאה"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="accountId"
                component={DefaultWrappedWithField}
                placeholder="חשבון"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[2] && (
              <Field
                name="reference"
                component={DefaultWrappedWithField}
                placeholder="אסמכתא"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[3] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="confirmationNumber"
                component={DefaultWrappedWithField}
                placeholder="מספר אישור"
                type="text"
              />
            )}
            {paymentMethod === paymentMethods[3] && (
              <Field
                name="confirmationName"
                component={DefaultWrappedWithField}
                placeholder="שם מאשר"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[3]) && (
              <Field
                name="dateOfMaturity"
                component={DefaultDatePicker}
                placeholder="תאריך פרעון"
                isNoTime
              />
            )}
            {paymentMethod === paymentMethods[4] && (
              <Field
                name="appName"
                component={DefaultWrappedWithField}
                placeholder="אפליקציה"
                type="text"
              />
            )}
          </Row>
        </Section> */}
        <InvoiceContainer className="A4">
          <div className="">
            <FinancialInvoiceHeader documentType={documentType} invoiceType={invoiceType} documentNumber={providerData.documentNumber} />
            <ClientData>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ClientDataText>
                  לכבוד: {clientData.firstName} {clientData.lastName} (
                  {clientData.studentId})
                </ClientDataText>
                <ClientDataText>
                  תאריך: {moment(providerData.date).format("DD/MM/YYYY HH:mm")}
                </ClientDataText>
              </div>
              {(clientData.address || clientData.city) && (
                <ClientDataText>
                  פרטי כתובת: {clientData.address},{clientData.city}
                </ClientDataText>
              )}
            </ClientData>
            {(documentType?.value === 2 ||
              documentType?.value === 0 ||
              documentType?.value === 3 ||
              documentType?.value === 4 ||
              documentType?.value === 5) && (
              <InvoiceBody>
                <Table>
                  <TableRow style={{ background: "dimgrey", color: "white" }}>
                    <TableHeader alignRight>מוצר/שירות</TableHeader>
                    <TableHeader style={{ width: "8%", textAlign: "center" }}>
                      סה״כ
                    </TableHeader>
                  </TableRow>
                  <TableRow>
                    <TableCell specialBackground style={{ textAlign: "right" }}>
                      {providerData.description}
                    </TableCell>
                    <TableCell specialBackground>
                      {(amount / 1.17).toFixed(2)?.toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell alignLeft bold>
                      17% מע״מ:
                    </TableCell>
                    <TableCell specialBackground bold>
                      {(amount - amount / 1.17).toFixed(2)?.toLocaleString()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell alignLeft bold>
                      סה״כ כולל מע״מ:
                    </TableCell>
                    <TableCell specialBackground bold>
                      {amount?.toLocaleString()}
                    </TableCell>
                  </TableRow>
                </Table>
                <Line />
              </InvoiceBody>
            )}
            {(documentType?.value === 1 ||
              documentType?.value === 0 ||
              documentType?.value === 4) && (
              <InvoiceBody>
                <Table>
                  <TableRow style={{ background: "dimgrey", color: "white" }}>
                    <TableHeader alignRight>סוג תשלום</TableHeader>
                    {providerData.paymentMethod !== 0 ? (
                      <TableHeader alignRight>פרטים נוספים</TableHeader>
                    ) : (
                      ""
                    )}
                    {providerData.paymentMethod !== 0 &&
                    (paymentMethod?.value === 3 ||
                      paymentMethod?.value === 4 ||
                      paymentMethod?.value === 1) ? (
                      <TableHeader>תאריך פרעון</TableHeader>
                    ) : (
                      ""
                    )}
                    <TableHeader style={{ width: "8%", textAlign: "center" }}>
                      סה״כ
                    </TableHeader>
                  </TableRow>
                  <TableRow>
                    <TableCell specialBackground style={{ textAlign: "right" }}>
                      {paymentMethod?.label}
                    </TableCell>
                    {paymentMethod?.value !== 0 ? (
                      <TableCell specialBackground style={{ textAlign: "right" }}>
                        {paymentMethodDetails()}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {providerData.paymentMethod !== 0 &&
                    (paymentMethod?.value === 3 ||
                      paymentMethod?.value === 4 ||
                      paymentMethod?.value === 1) ? (
                      <TableCell specialBackground>
                        {new Date(
                          providerData.dateOfMaturity
                        ).toLocaleDateString()}
                      </TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell specialBackground>
                      {amount.toLocaleString()}
                    </TableCell>
                  </TableRow>
                </Table>
                <NotesContainer>
                  <NotesText>{providerData.notes}</NotesText>
                </NotesContainer>
                <Line />
              </InvoiceBody>
            )}
            <div style={{ textAlign: "right" }}>
              נוצר ע״י {providerData.createUsername} בתאריך
              {moment(providerData.created).format("DD.MM.YYYY HH:mm")}
            </div>
            <PrintFooter>
              הופק באמצעות קמע מחשבים סיון בע"מ. טלפון 0537-222-228
            </PrintFooter>
          </div>
        </InvoiceContainer>
        <Button
          text="הפק העתק"
          onClick={() => generatePdf(0)}
          marginRight={1}
        />
        <Button text="הפק מקור" disabled={providerData.isOriginalPrinted==1 ? true : false} onClick={() => generatePdf(1)} marginRight={1} />
        <Button text="שלח למייל" onClick={() => sendToEmail()} />
      </Body>
    </>
  );
};

const selector = formValueSelector("incomeDetails"); // <-- same as form name
function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    user: state.session.user,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    paymentMethod: selector(state, "paymentMethod"),
    amount: selector(state, "amount"),
    documentType: selector(state, "documentType"),
    clientId: selector(state, "clientId"),
    isCredit: selector(state, "isCredit"),
    documentNumber: selector(state, "documentNumber"),
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    students: state.StudentsReducer.students,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    initialValues: {
      ...ownProps.providerData,
      paymentMethod: paymentMethods.find(
        (x) => x.value === ownProps.providerData.paymentMethod
      ),
      documentType: documentTypes.find(
        (x) => x.value === ownProps.providerData.documentType
      ),
      vehicleId: {
        value: ownProps.providerData.vehicleId,
        label: ownProps.providerData.vehicleNumber
          ? ownProps.providerData.vehicleNumber
          : "כל הרכבים",
      },
    },
  };
}

export default connect(mapStateToProps, {
  updateInvoicePrinted,
  deleteItem,
  getVehicles,
  getStudents,
  change,
  generateIncomePdf,
})(
  reduxForm({
    form: "incomeDetails",
  })(FundingBodiesDetails)
);
