import React from "react";
import { CreateButton } from "./buttons.styles";

const ButtonText = ({text}) => {
  if (typeof text == 'function') return <div dangerouslySetInnerHTML={text()}></div>;
  return text;
}

const DefaultButton = ({
  text,
  onClick,
  type = "button",
  disabled = false,
  marginTop = 0,
  marginRight = 0,
  isDelete = false,
  isActive = false,
  className = '',
  style = {}
}) => (
  <CreateButton
    onClick={onClick}
    type={type}
    disabled={disabled}
    style={{ marginTop: `${marginTop}vh`, marginRight: `${marginRight}vw`, ...style }}
    isDelete={isDelete}
    isActive={isActive}
    className={className}
  >
    <ButtonText text={text} />
  </CreateButton>
);

export default DefaultButton;
