import styled from "styled-components";
import i18n from "../../../../lib/i18n/i18n";
import { isMobile, isMobileOnly } from "react-device-detect";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  padding: 1rem;
`;

export const Title = styled.span`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
  display: block;
  height: 7vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
  align-items: center;
`;

export const TitleText = styled.span`
  font-size: 2.5vh;
  font-weight: bold;
  color: #404041;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;

  .MuiFormControl-marginDense {
    margin: 0
  }

  &.row-select {
    padding: ${isMobileOnly ? "2px" : "5px"};
  }

  .button-action {
    padding: 7px;
  }

  .button-navigation {
    width: 120px;
    padding: 7px;
    flex-grow: 1;
  }

  .split-button-navigation {
    font-size: 13pt;
    width: 120px;
    padding: 7px;
    flex-grow: 1;
    background: linear-gradient(to bottom,#ebd794,#d2ab51);
  }

  .split-button-navigation-toggle {
    background: linear-gradient(to bottom,#ebd794,#d2ab51);
  }

  .split-button-navigation {
    border-color: #fff !important;
  }

  .calendar-date-label {
    direction: rtl;
    background: #fff;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;        
    width: ${!isMobile && "170px"};
    height: 38px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    text-align: center;
  }

  input[type="date"] {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;        
    height: 38px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;

    ::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      width: auto;
    }

    :before {
      background: none;
      display: block;
      font-family: 'FontAwesome';
      content: '\f073';
      padding-left: 5px;
      padding-right: 5px;        
      color: #d2ab51;
    }    
  }
  
  .react-toggle-thumb {
    width: 29px;
    height: 18px;
    border-radius: 5px;
    background-color: #ffffff;    
  }

  .react-toggle-track {
    width: 56px;
    height: 18px;
    border-radius: 5px;
    background-color: #e8d290 !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #ffffff;
  }

`;