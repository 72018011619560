import styled from "styled-components";

export const Container = styled.div``;
export const Title = styled.span`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
`;

export const Body = styled.div`
  height: fit-content;
  width: 100%;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  align-items: center;
`;

export const Row = styled.div`
  padding-left: 2vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 33%;
  align-items: center;
  padding-top: 1vh;
  padding-bottom: 1vh;
`;

export const CreateButton = styled.button`
  border-radius: 5px;
  color: white;
  height: 3vw;
  width: 8vw;
  background-image: linear-gradient(to bottom right, #ebd794, #d2ab51);
  font-size: 15pt;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  margin-top: 2vh;
  align-items: center;
`;

export const DeleteButton = styled.i`
  color: #d2ab51;
  font-size: 2.5vh;
  cursor: pointer;
`;
