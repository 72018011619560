import React from "react";
import PropTypes from "prop-types";

const LoginLayout = ({ children }) => <div>{children}</div>;

LoginLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default LoginLayout;
