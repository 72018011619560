import styled from "styled-components";

export const Body = styled.form`
  width: 100%;
  text-align: center;
`;

export const Section = styled.div`
  display: flex;
  direction: rtl;
  flex-direction: row;
  border: ${(props) => (props.noBorder ? "" : "1px solid #e5e5e5")};
  border-radius: ${(props) => (props.noBorder ? "" : "5px")};
  padding: 1vh;
  justify-content: space-around;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  align-items: flex-end;
`;
