import styled from "styled-components";
import i18n from "../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Container = styled.div`
  background-color: #f5f3ef;
  display: flex;
  flex-direction: ${isHebrew ? "row-reverse" : "row"};
  padding: 0.5vh;
  border-radius: 5px;
`;

export const MenuItem = styled.div`
  border-radius: 5px;
  padding: 2vh;
  background-color: ${(props) => (props.active ? "white" : "")};
  box-shadow: ${(props) =>
    props.active ? "0px 0px 3px 1px rgba(0,0,0,0.1)" : ""};
  cursor: pointer;

  &:hover {
    font-weight: ${(props) => (props.active ? "" : "bold")};
  }
`;
