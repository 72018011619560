import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setSchool, getSchools } from "../../actions/adminActions";
import Select from "react-select";
import { withRouter } from "react-router-dom";

const adminCustomSchoolStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "300px",
    minWidth: 150,
    fontSize: "12px"
  }),
  control: (provided, state) => ({
    ...provided,
    height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    paddingRight: "0.5vw",
  }),
};

const ChangeSchool = ({ history, state, user, changeSchool=() => {}, setSchool }) => { 
  const [schools, setSchools] = useState([]);
  const [viewSchool, setViewSchool] = useState(null);
 
  useEffect(() => {
    async function fetchData() {
      const result = await getSchools(1);

      setSchools(result.map((v) => ({label: `#${v.id} - ${v.name}`, value: v})))
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (schools.length === 0) return;
    let selectSchool = schools.filter((s) => s.value.id == user.schoolId)[0]
    if (!selectSchool) selectSchool = schools[0]
    _changeSchool(selectSchool, false)
  }, [schools]);

  const _changeSchool = async (school, withSet = true) => {
    setViewSchool(school)
    if (withSet) {
      await setSchool(school.value.id)
      state.session.user.schoolId = school.value.id
      // history.push(`${window.location.pathname}?school=${school.value.id}`)
    }
    changeSchool(school)
  }

  return (
    <Select
      options={schools}
      isMulti={false}
      value={viewSchool}
      placeholder="Select school"
      isRtl={true}
      onChange={_changeSchool}
      styles={adminCustomSchoolStyles}
    />
  );
};

function mapStateToProps(state, ownProps) {
  return {
    state,
    user: state.session.user,
  };
}

export default withRouter(connect(mapStateToProps, {setSchool})(ChangeSchool));
