import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { useTranslation } from "react-i18next";
import { updateSettings } from "../../../actions/settingsActions";
import ColorPicker from "./ColorPicker";
import {
  SectionContainer,
  SectionContainerHeader,
  Icon,
  Container,
  TimeInputContainer,
  RemoveButton,
} from "./calendarColors.styles";
import { IconButton } from "@material-ui/core";
import PlusOne from '@material-ui/icons/PlusOne';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import DefaultInput from "../../UiComponents/Inputs/Default";
import Select from "react-select";
import { useState } from "react";

const CalendarEventDayColor = ({ elements, type, addElement }) => {
  const { t, i18n } = useTranslation("common");
  const eventStatusTypes = {
    lesson: [
      {label: 'Any', key: null},
      {
        label: t("settings.lessonColor"),
        value: 'lesson',
        key: 0,
        icon: "icons8-question-mark",
      },
      {
        label: t("settings.doneLessonColor"),
        value: "doneLesson",
        key: 1,
        icon: "icons8-checkmark",
      },
      {
        label: t("settings.paidLessonColor"),
        value: "paidLesson",
        key: 2,
        icon: "icons8-shekel",
      },
      {
        label: t("settings.canceledLessonColor"),
        value: "canceledLesson",
        key: 3,
        icon: "icons8-cancel",
      },
      {
        label: t("settings.canceledAndPaidLessonColor"),
        value: "canceledAndPaidLessonColor",
        key: 4,
        icon: "icons8-cancel-with-usd",
      },
    ],
    test: [
      {label: 'Any', key: null},
      {
        label: t("settings.lessonColor"),
        value: "test",
        key: 0,
        icon: "icons8-question-mark",
      },
      {
        label: t("settings.doneLessonColor"),
        value: "doneTest",
        key: 1,
        icon: "icons8-checkmark",
      },
      {
        label: t("settings.paidLessonColor"),
        value: "paidTest",
        key: 2,
        icon: "icons8-shekel",
      },
      {
        label: t("settings.canceledLessonColor"),
        value: "canceledTest",
        key: 3,
        icon: "icons8-cancel",
      },
      {
        label: t("settings.canceledAndPaidLessonColor"),
        value: "canceledAndPaidTest",
        key: 4,
        icon: "icons8-cancel-with-usd",
      },
    ],
    internalTest: [
      {label: 'Any', key: null},
      {
        label: t("settings.lessonColor"),
        value: "internalTest",
        key: 0,
        icon: "icons8-question-mark",
      },
      {
        label: t("settings.doneLessonColor"),
        value: "doneInternalTest",
        key: 1,
        icon: "icons8-checkmark",
      },
      {
        label: t("settings.paidLessonColor"),
        value: "paidInternalTest",
        key: 2,
        icon: "icons8-shekel",
      },
      {
        label: t("settings.canceledLessonColor"),
        value: "canceledInternalTest",
        key: 3,
        icon: "icons8-cancel",
      },
      {
        label: t("settings.canceledAndPaidLessonColor"),
        value: "canceledAndPaidInternalTest",
        key: 4,
        icon: "icons8-cancel-with-usd",
      },
    ],
    meeting: [
      {label: 'Any', key: null},
      {
        label: t("settings.lessonColor"),
        value: "meeting",
        key: 0,
        icon: "icons8-question-mark",
      },
      {
        label: t("settings.doneLessonColor"),
        value: "doneMeeting",
        key: 1,
        icon: "icons8-checkmark",
      },
      {
        label: t("settings.canceledLessonColor"),
        value: "canceledMeeting",
        key: 2,
        icon: "icons8-cancel",
      },
    ],
  };
  const [key, setKey] = useState(0)
  const onChange = (field, index, value) => {
    elements[index][field] = value;
    setKey(`${field}-${value}`)
  }
  const remove = (index) => {
    elements.splice(index, 1)
    setKey(`remove-${index}`)
  }
  if (!type) return '';
  return (
    <SectionContainer
      style={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {elements && elements.map((x, index) => (
        <SectionContainer className="contanerEventColorTime">
          <RemoveButton className="removeCollorButton">
            <IconButton size="sm" onClick={()=>{remove(index)}} >
              <RemoveCircleOutline />
            </IconButton>
          </RemoveButton>
          {x.status != undefined && (
            <Select
            options={eventStatusTypes[type]}
            isMulti={false}
            value={eventStatusTypes[type].find((v) => x.status == v.key)}
            placeholder="type"
            isRtl={true}
            onChange={(value) => {onChange('status', index, value.key)}}
            style={{
              minWidth: '100%'
            }} />
          )}
          <input type="hidden" name={`params[calendarTimeColors][${[type]}][${index}]status`} value={x.status} />
          <SectionContainerHeader background={x.background} fontColor={x.font} style={{marginTop: '10px'}}>
              {eventStatusTypes[type][0].label} <Icon className={eventStatusTypes[type][0].icon} />
            </SectionContainerHeader>
            {x.timeFrom && (
              <TimeInputContainer>
              <DefaultInput type="time" name={`params[calendarTimeColors][${[type]}][${index}]timeFrom`} value={x.timeFrom} onChange={(value) => onChange('timeFrom', index, value.currentTarget.value)} />
              <DefaultInput type="time" name={`params[calendarTimeColors][${[type]}][${index}]timeTo`} value={x.timeTo} onChange={(value) => onChange('timeTo', index, value.currentTarget.value)} />
            </TimeInputContainer>
            )}
            <Field
              name={`params[calendarTimeColors][${[type]}][${index}]background`}
              component={ColorPicker}
              text={t("settings.background")} />
            <Field
              name={`params[calendarTimeColors][${[type]}][${index}]font`}
              component={ColorPicker}
              text={t("settings.font")} />
        </SectionContainer>
      ))}
      <IconButton onClick={()=>{addElement(type)}} >
        <PlusOne />
      </IconButton>
    </SectionContainer>
  );
};

export default CalendarEventDayColor;
