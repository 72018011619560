import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  FieldText,
  Field,
  Table,
} from "./monthlyActivity.styles";
import { connect } from "react-redux";
import { getAllSchoolEvents } from "../../../actions/schedulerActions";
import { getAll } from "../../../actions/drivingPermits";
import { getStudents } from "../../../actions/studentesActions";
import i18n from "../../../lib/i18n/i18n";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";

const TeacherWeekly = ({
  getAllSchoolEvents,
  students,
  getStudents,
  getAll,
  drivingPermits,
}) => {
  const today = new Date();
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  useEffect(() => {
    (async () => {
      if (students.length === 0) {
        await getStudents();
      }
      if (drivingPermits.length === 0) {
        await getAll();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setEvents(await getAllSchoolEvents(startDate, endDate));
    })();
  }, [startDate, endDate]);
  return (
    <Container>
      <Row>
        <>
          <Field>
            <FieldText>תאריך סוף הדוח</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>תאריך התחלת הדוח</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <Table>
        <tbody>
          <tr>
            <td>מצבת מורים</td>
            <td>{_.uniqBy(events, "teacherId").length}</td>
          </tr>
          <tr>
            <td>מצבת תלמידים בבית הספר</td>
            <td>{students.filter((x) => x.active === 1).length}</td>
          </tr>
          {/* <tr>
            <td>מספר תלמידים לא פעילים</td>
            <td>
              {students.filter((x) => x.active === 1).length -
                _.uniqBy(events, "studentId").length}
            </td>
          </tr> */}

          {drivingPermits.map((x, i) => (
            <tr>
              <td>{x.name}</td>
              <td>
                {
                  _.uniqBy(events, "studentId").filter(
                    (y) => y.drivingPermitId === x.id
                  ).length
                }
              </td>
            </tr>
          ))}
          <tr>
            <td>מבחן פנימי</td>
            <td>
              {
                _.uniqBy(
                  events.filter((y) => y.eventType === 3),
                  "studentId"
                ).length
              }
            </td>
          </tr>
          <tr>
            <td>טסטים</td>
            <td>
              {
                _.uniqBy(
                  events.filter((y) => y.eventType === 2),
                  "studentId"
                ).length
              }
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    students: state.StudentsReducer.students,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {
  getStudents,
  getAllSchoolEvents,
  getAll,
})(TeacherWeekly);
