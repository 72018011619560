import React, { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import SivanModal from "../../Modal/NewModal";
import { Body, Button } from "./noteChange.styles";
import { updateEventNote } from "../../../actions/schedulerActions";
import { countStudentCompletedAssignments } from "../../../actions/textBooksActions";
import NotesInput from "../../UiComponents/TextArea/Default";
import TextField from "@material-ui/core/TextField";

const EventStatusChange = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  selectedEvent,
  updateEventNote,
}) => {
  const [notes, setNotes] = useState(selectedEvent.eventId.notes);

  const makeAction = async () => {
    await updateEventNote(selectedEvent.eventId.id, notes);
    setNewAgreementModalIsOpen(false);
  };

  return (
    <SivanModal
      titleText="שינוי סטטוס אירוע"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={false}
    >
      <Body>
        <TextField
          value={notes}
          onChange={(x) => setNotes(x.target.value)}
          id="outlined-basic"
          margin="dense"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
        />
        <Button onClick={() => makeAction()}>שמור</Button>
      </Body>
    </SivanModal>
  );
};

export default connect(null, {
  updateEventNote,
  countStudentCompletedAssignments,
})(EventStatusChange);
