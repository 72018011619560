import styled from "styled-components";

export const Container = styled.div``;
export const Title = styled.span`
  font-size: 22pt;
  font-weight: bold;
  color: #404041;
`;

export const Body = styled.div`
  height: 75vh;
  width: 100%;
  background-color: white;
  border: 1px solid #ffefca;
  border-radius: 5px;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  align-items: center;
`;

export const ColumnTitle = styled.span`
  font-size: 18pt;
  color: #404041;
  height: 7vh;
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  padding-left: 2vh;
  flex: 1;
`;

export const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  height: 5vh;
  margin-top: 1vh;

  &::placeholder {
    padding-left: 1vw;
    color: #c2c2c2;
  }
`;

export const NotesInput = styled.textarea`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100%;
  height: 23vh;
  margin-top: 1vh;

  &::placeholder {
    padding-left: 1vw;
    padding-top: 1vh;
    font-size: 15px;
    color: #c2c2c2;
  }
`;

export const CreateButton = styled.button`
  border-radius: 5px;
  color: white;
  height: 3vw;
  width: 8vw;
  background-image: linear-gradient(to bottom right, #ebd794, #d2ab51);
  font-size: 15pt;
`;

export const ReceiptDetails = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90%;
`;
