import React, { useEffect, useState, useMemo } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import i18n from "../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title, TitleText } from "./studentSearch.styles";
import Table from "../../SivanPlusTable/SivanPlusTable";
import { SelectColumnFilter, DateColumnFilter } from "../../SivanPlusTable/filters";
import {
  getStudents,
  getTeacherStudents,
} from "../../../actions/studentesActions";
import UseLoadStudents from "../../useComponents/UseLoadStudents";
import CreateButton from "../../UiComponents/Buttons/Default";
import { getBranches } from "../../../actions/branchActions";
import Assignment from "../../Students/StudentUpdate/TextBooks/EditTextBook/Assignment/Assignment";
import {
  getStudentTextBooks,
  fetchTextBook,
} from "../../../actions/textBooksActions";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import { getVehicles } from "../../../actions/vehicleActions";
import SivanModal from "../../Modal/NewModal";
import EditTextBook from "../../Students/StudentUpdate/TextBooks/EditTextBook/EditTextBook";
import { getStudent } from "../../../actions/studentesActions";
import useAgreements from "../../useComponents/useAgreements";
import { getAgreements } from "../../../actions/agreementsActions";
import moment from "moment";
import AddStudentIcon from '../../../images/addStudent.ico';
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const getNameStyle = (studentData) => {
  let styles = {};
  if (studentData.internalTestStatus) {
    styles.borderTop = "1px solid black";
  }
  if (
    studentData.conversion ||
    studentData.refresh ||
    studentData.renewal ||
    studentData.control
  ) {
    styles.borderLeft = "1px solid black";
    styles.borderRight = "1px solid black";
  }
  if (
    studentData.fundingBody !== 999 &&
    studentData.fundingBody !== null &&
    typeof studentData.fundingBody !== "undefined"
  ) {
    styles.borderBottom = "1px solid black";
  }

  return styles;
};

let studentColumns = isMobileOnly
  ? [
      {
        Header: dictionary.table.name,
        accessor: "firstName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.firstName}
          </span>
        ),
      },
      {
        Header: dictionary.table.lastName,
        accessor: "lastName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.lastName}
          </span>
        ),
      },
      {
        Header: dictionary.table.id,
        accessor: "studentId",
        Cell: ({ row: { original } }) => {
          return original.isChecked === 1 ? (
            <span>{original.studentId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.studentId}</span>
          );
        },
      },
    ]
  : isTablet
  ? [
      {
        Header: dictionary.table.name,
        accessor: "firstName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.firstName}
          </span>
        ),
      },
      {
        Header: dictionary.table.id,
        accessor: "studentId",
        Cell: ({ row: { original } }) => {
          return original.isChecked === 1 ? (
            <span>{original.studentId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.studentId}</span>
          );
        },
      },
      {
        Header: dictionary.table.teachers,
        accessor: "teacherName",
      },
      {
        Header: dictionary.table.license,
        accessor: "licenseType",
      },
      {
        Header: dictionary.table.active,
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return original.active === 1
            ? dictionary.users.active
            : dictionary.users.inactive;
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ]
  : [
      {
        Header: dictionary.table.name,
        accessor: "firstName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.firstName}
          </span>
        ),
      },
      {
        Header: dictionary.table.lastName,
        accessor: "lastName",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.lastName}
          </span>
        ),
      },
      {
        Header: dictionary.table.phone,
        accessor: "phone",
      },
      {
        Header: dictionary.table.id,
        accessor: "studentId",
        Cell: ({ row: { original } }) => {
          return original.isChecked === 1 ? (
            <span>{original.studentId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.studentId}</span>
          );
        },
      },
      {
        Header: dictionary.table.teachers,
        accessor: "teacherName",
      },
      {
        Header: dictionary.table.license,
        accessor: "licenseType",
      },
      {
        Header: dictionary.table.vehicleNumber,
        accessor: "vehicleNumber",
      },
      {
        Header: dictionary.table.active,
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return original.active === 1
            ? dictionary.users.active
            : dictionary.users.inactive;
        },
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: 'נוצר בתאריך',
        Cell: ({ row: { original } }) => {
          return moment(original.createDate).format("DD/MM/YYYY");
        },
        accessor: "createdDate",
        Filter: DateColumnFilter,
        filter: (array, s, date) => {
          return array.filter((x) => {
            return moment(x.original.createDate).isSame(date, "day")
          });
        },
        sortType: (a, b, columnId, desc) => {
          a = a.original.createDate;
          b = b.original.createDate;
          let a_split = a.split('T')[0];
          a = new Date(a_split);
          let b_split = b.split('T')[0];
          b = new Date(b_split);

          return b > a ? 1 : -1;
        }
      },
    ];

const StudentSearch = ({
  getStudents,
  data,
  history,
  isLoading,
  updateStudent,
  createStudent,
  getTeacherStudents,
  teacherOnlyHisStudentsSearch,
  studentSearch,
  getBranches,
  getStudentTextBooks,
  fetchTextBook,
  getVehicles,
  getAgreements,
  getLicenseTypes,
  schoolId,
  getStudent,
  isLoggedIn,
  studentTicket,
  shortStudentTicket,
}) => {
  const { t, i18n } = useTranslation("common");
  const [students, setStudents] = useState([]);
  const [studentId, setStudentId] = useState([]);
  const [isAssigmentOpen, setAssigmentModalOpen] = useState(false);
  const [studentLicense, setStudentLicense] = useState(false);
  const [textBookData, setTextBookData] = useState({});
  const [studentData, setStudentData] = useState({});
  const [textBookView, setTextBookView] = useState();
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isTextBookEditModalOpen, setTextBookEditModalOpen] = useState(false);
  const [isred, setIsRed] = useState(false);
  const agreementList = useAgreements(getAgreements);

  if (studentSearch && !teacherOnlyHisStudentsSearch && isLoggedIn) {
    UseLoadStudents(isLoading, getStudents);
  } else if (teacherOnlyHisStudentsSearch && isLoggedIn) {
    UseLoadStudents(isLoading, getTeacherStudents);
  }

  const getAllRed = () => {
    if (isred) {
      setIsRed(false);
      setStudents(data);
    } else {
      setIsRed(true);
      let _ = [];
      students.forEach((item, i) => {
        if (item.isChecked !== 1) {
          _.push(item);
        }

      });
      setStudents(_);
    }

  }

  useEffect(() => {
    async function fetchData() {
      if (
        studentColumns.findIndex((x) => x.accessor === "gender") === -1 &&
        schoolId
      ) {
        const schoolVehicles = await getVehicles();
        studentColumns = [
          {
            accessor: "gender",
            Cell: ({ row: { original } }) => (
              <div style={{ width: "100%", fontSize: "2vh" }}>
                <span
                  style={{ flex: "0 20%", cursor: "pointer" }}
                  className="icons8-phone"
                  onClick={() => window.open(`tel:${original.phone}`)}
                />
                <span
                  style={{
                    flex: "0 20%",
                    cursor: "pointer",
                    marginRight: "15px",
                  }}
                  className="icons8-checklist"
                  onClick={() =>
                    openStudentAssignments(original.studentId, schoolVehicles)
                  }
                />
                <span
                  style={{
                    flex: "0 20%",
                    cursor: "pointer",
                    marginRight: "15px",
                  }}
                  className="icons8-handshake"
                  onClick={() =>
                    openTextBookView(
                      original.studentId,
                      dictionary.editTextBook.agreements
                    )
                  }
                />
                <span
                  style={{
                    flex: "0 20%",
                    cursor: "pointer",
                    marginRight: "15px",
                  }}
                  className="icons8-book-with-calendar"
                  onClick={() =>
                    openTextBookView(
                      original.studentId,
                      dictionary.editTextBook.dates
                    )
                  }
                />
                <span
                  style={{
                    flex: "0 20%",
                    cursor: "pointer",
                    marginRight: "15px",
                  }}
                  className="icons8-open-folder"
                  onClick={() =>
                    openTextBookView(
                      original.studentId,
                      dictionary.editTextBook.agreements
                    )
                  }
                />
                {studentTicket || shortStudentTicket ? (
                  <span
                    style={{ flex: "0 20%", marginRight: "15px" }}
                    className="icons8-report-card"
                    onClick={() => {
                      history.push(`/updateStudent`, {
                        studentData: original,
                        view: dictionary.editStudent.ticket,
                      });
                    }}
                  />
                ) : null}
              </div>
            ),
          },
          ...studentColumns,
        ];
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (students.length > 0 || isLoading) setIsTableLoading(false);
  }, [students, isLoading]);

  useEffect(() => {
    async function fetchData() {
      if (data.length) {
        const allBranches = await getBranches();
        if (allBranches.filter((x) => x.name !== "כל הסניפים").length > 1) {
          if (
            studentColumns.findIndex((x) => x.accessor === "branchName") === -1
          ) {
            studentColumns = [
              ...studentColumns,
              {
                Header: dictionary.table.branch,
                accessor: "branchName",
              },
            ];
          }
          setStudents(
            data.map((x) => ({
              ...x,
              branchName: allBranches.find((z) => z.id === x.branchId)?.name,
            }))
          );
        } else {
          setStudents(data);
        }
      }
    }
    fetchData();
  }, [isLoading, data]);

  const openStudentAssignments = async (studentId, schoolVehicles) => {
    setIsTableLoading(true);
    setStudentId(studentId);
    const studentTextBooks = await getStudentTextBooks(studentId);

    if (studentTextBooks.length) {
      const textBook = await fetchTextBook(
        studentTextBooks[studentTextBooks.length - 1].textBookId
      );
      let licenses = await getLicenseTypes();
      const vehicleLicenseType = schoolVehicles.find(
        (x) =>
          x.id.toString() ===
          studentTextBooks[studentTextBooks.length - 1].vehicleId.toString()
      ).licenseType;
      const license = licenses.find(
        (x) => x.id === parseInt(vehicleLicenseType)
      );
      setStudentLicense(license.vehicleType);
      setTextBookData({
        ...textBook,
        vehicleId: studentTextBooks[studentTextBooks.length - 1].vehicleId,
      });
      setAssigmentModalOpen(true);
      setIsTableLoading(false);
    }
  };

  const openTextBookView = async (studentId, view) => {
    setIsTableLoading(true);
    setStudentId(studentId);
    const studentTextBooks = await getStudentTextBooks(studentId);
    if (studentTextBooks.length) {
      const textBook = await fetchTextBook(
        studentTextBooks[studentTextBooks.length - 1].textBookId
      );
      const studentData = await getStudent(studentId);
      setStudentData(studentData[0]);
      setTextBookData({
        ...textBook,
        vehicleId: studentTextBooks[studentTextBooks.length - 1].vehicleId,
      });
      setTextBookView(view);
      setTextBookEditModalOpen(true);
    }
    setIsTableLoading(false);
  };

  const onRowClick = async (rowObject) => {
    if (rowObject.column.id === "gender") {
    } else if (updateStudent)
      history.push("/updateStudent", { studentData: rowObject.original });
  };
  return (
    <Container>
      <Title>
        <TitleText>{t("menuSubCategories.students.studentSearch")}</TitleText>
        {createStudent ? (
          <>
            <div>
              <img src={AddStudentIcon} onClick={() => history.push("/createStudent")} style={{width: 50}}/>
            </div>
            {/*<CreateButton
              onClick={() => history.push("/createStudent")}
              text={t("newStudent.title")}
            />*/}
          </>
        ) : null}
        {isAssigmentOpen ? (
          <SivanModal
            titleText="מטלות לימוד"
            modalIsOpen={isAssigmentOpen}
            closeModal={() => setAssigmentModalOpen(false)}
            isLoading={false}
          >
            <Assignment
              textBookData={textBookData}
              studentId={studentId}
              studentLicense={studentLicense}
            />
          </SivanModal>
        ) : null}
        {isTextBookEditModalOpen && (
          <EditTextBook
            isTextBookEditModalOpen={isTextBookEditModalOpen}
            setTextBookEditModalOpen={setTextBookEditModalOpen}
            textBookData={textBookData}
            studentData={studentData}
            agreementList={agreementList}
            view={textBookView}
          />
        )}
        <button onClick={() => getAllRed()}>{isred ? 'הצג הכל' : 'הצג רק אדומים'}</button>
      </Title>
      <Table
        data={students}
        componentColumns={studentColumns}
        onRowClick={onRowClick}
        isLoading={isTableLoading}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    data: state.StudentsReducer.students,
    isLoading: state.StudentsReducer.isLoaded,
    updateStudent: state.session.user.updateStudent,
    schoolId: state.session.user.schoolId,
    studentSearch: state.session.user.studentSearch,
    teacherOnlyHisStudentsSearch:
      state.session.user.teacherOnlyHisStudentsSearch,
    studentTicket: state.session.user.studentTicket,
    shortStudentTicket: state.session.user.shortStudentTicket,
    createStudent: state.session.user.createStudent,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isLoggedIn: state.session.isLoggedIn,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getStudents,
    getTeacherStudents,
    getBranches,
    getStudentTextBooks,
    fetchTextBook,
    getAgreements,
    getLicenseTypes,
    getVehicles,
    getStudent,
  })(StudentSearch)
);
