import React, { useEffect, useState, useMemo } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import i18n from "../../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title, TitleText } from "./studentSearch.styles";
import Table from "../../../SivanPlusTable/SivanPlusTable";
import { SelectColumnFilter, DateColumnFilter } from "../../../SivanPlusTable/filters";
import { getStudentIncomes } from "../../../../actions/adminActions";
import moment from "moment";
import { documentTypes } from "../../../../constants/incomes";
import { toast } from "react-toastify";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const getNameStyle = (studentData) => {
  let styles = {};
  if (studentData.internalTestStatus) {
    styles.borderTop = "1px solid black";
  }
  if (
    studentData.conversion ||
    studentData.refresh ||
    studentData.renewal ||
    studentData.control
  ) {
    styles.borderLeft = "1px solid black";
    styles.borderRight = "1px solid black";
  }
  if (
    studentData.fundingBody !== 999 &&
    studentData.fundingBody !== null &&
    typeof studentData.fundingBody !== "undefined"
  ) {
    styles.borderBottom = "1px solid black";
  }

  return styles;
};

const StudentIncomesSearch = ({
  schoolId,
  student,
  user
}) => {
  const { t, i18n } = useTranslation("common");
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  let tableColumns = [
    {
      Header: "documentType",
      accessor: "documentType",
      Cell: ({ row: { original } }) => (
        <b>
          {documentTypes[original.documentType]}
        </b>
      ),
    },
    {
      Header: "documentNumber",
      accessor: "documentNumber",
    },
    {
      Header: "quantity",
      accessor: "quantity",
    },
    {
      Header: "vehicleNumber",
      accessor: "vehicleNumber",
    },
    {
      Header: "amount",
      accessor: "amount",
      Cell: ({ row: { original } }) => (
        <b>{original.amount}</b>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [schoolId, student]);

  const fetchData = async () =>  {
    if (!student) return;
    setIsTableLoading(true)
    let data = [];
    setAllData(data);
    try {
      data = await getStudentIncomes(schoolId, student.studentId);
      setAllData(data);
    } catch (e) {
      toast.error("כישלון במחיקת תלמיד");
      console.error(e)
    }
    setIsTableLoading(false)
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Container>
      <Title>
        <TitleText>{student ? `Incomes of student: "${student.firstName} ${student.lastName}"` : ""}</TitleText>
      </Title>
      <Table
        data={allData}
        componentColumns={tableColumns}
        // onRowClick={onRowClick}
        isLoading={isTableLoading}
        setFilteredData={setFilteredData}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
    isLoggedIn: state.session.isLoggedIn,
  };
}
export default connect(mapStateToProps, {})(StudentIncomesSearch);
