import clientsApi from "../apis/discountsAndObligations";
import { toast } from "react-toastify";

export const create = (client) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;
    const response = await clientsApi.post(
      "/create",
      {
        client: { ...client, schoolId },
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון ביצירת לקוח");
  }
};

export const getStudentDiscountsAndObligations = (studentId) => async (
  dispatch,
  getState
) => {
  try {
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await clientsApi.get(`/getAll/${studentId}`, {
      headers,
    });
    return response.data;
  } catch (error) {}
};

export const update = (client) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await clientsApi.patch(
      "/update",
      {
        client,
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון בעדכון לקוח");
  }
};

export const deleteItem = (clientId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await clientsApi.delete(
      "/delete",
      {
        data: { clientId },
      },
      {
        headers,
      }
    );
  } catch (error) {
    toast.error("כישלון במחיקת לקוח");
  }
};
