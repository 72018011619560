import React, { useState } from "react";
import {
  Container,
  Icon,
  TitleContainer,
  WheelIcon,
  IconsAndTextContainer,
  TextAfterIcon,
} from "./eventView.styles";
import { connect } from "react-redux";
import EditEvent from "../EditEvent/EditEvent";

const EventView = (event) => {
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const getEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 1:
        return <Icon className="icons8-steering-wheel" style={{ margin: 0 }} />;
      case 2:
        return <Icon className="icons8-graduation-cap" />;
      case 3:
        return <Icon className="icons8-road" />;
      case 4:
        return <Icon className="icons8-tie" />;
      default:
        return "";
    }
  };

  const getNameStyle = (studentData) => {
    let styles = {};
    if (studentData.internalTestStatus) {
      styles.borderTop = "1px solid black";
    }
    if (
      studentData.conversion ||
      studentData.refresh ||
      studentData.renewal ||
      studentData.control
    ) {
      styles.borderLeft = "1px solid black";
      styles.borderRight = "1px solid black";
    }
    if (
      studentData.fundingBody !== 999 &&
      studentData.fundingBody !== null &&
      typeof studentData.fundingBody !== "undefined"
    ) {
      styles.borderBottom = "1px solid black";
    }

    return styles;
  };

  return (
    <Container
      dir="auto"
      isStudentChosen={
        typeof event.chosenStudentId !== "undefined" &&
        event.chosenStudentId === event.event.studentId
      }
      chosenStudentColor={event.chosenStudentColor}
      fontChosenStudentColor={event.fontChosenStudentColor}
    >
      {isEditLessonModalOpen && (
        <EditEvent
          isNewAgreementModalOpen={isEditLessonModalOpen}
          setNewAgreementModalIsOpen={setIsEditLessonModalOpen}
          step={event.eventTime}
          selectedEvent={{
            ...event.event,
            name: event.event.name
              ? event.event.name
              : `${event.event.studentId} - ${event.event.firstName} ${event.event.lastName}`,
          }}
        />
      )}
      <TitleContainer
        onClick={() => setIsEditLessonModalOpen(true)}
        dir="auto"
        className="eventView"
        isDay={false}
      >
        {event.showTime ? (
          <IconsAndTextContainer onClick={() => setIsEditLessonModalOpen(true)}>
            <Icon className="icons8-clock" />
            <TextAfterIcon>{event.event.eventDurationInMinutes}</TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
        {event.showEventType ? (
          <IconsAndTextContainer style={{ maxWidth: "9vw" }}>
            {getEventTypeIcon(event.event.eventType)}
            <TextAfterIcon
              style={{
                ...getNameStyle(event.event),
              }}
            >
              {event.event.studentNameWithoutNickname}
            </TextAfterIcon>
          </IconsAndTextContainer>
        ) : null}
      </TitleContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    showEventType: state.session.user.showEventType,
    showTime: state.session.user.showTime,
    chosenStudentColor: state.session.user.chosenStudentColor,
    eventTime: state.session.user.eventTime,
    fontChosenStudentColor: state.session.user.fontChosenStudentColor,
  };
}

export default connect(mapStateToProps)(EventView);
