import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import validate from "./validate";
import i18n from "../../../lib/i18n/i18n";
import { userCreate } from "../../../actions/userActions";
import {
  Container,
  Title,
  Body,
  Section,
  SectionTitle,
} from "./createUser.styles";
import FadeLoader from "react-spinners/FadeLoader";
import CreateButton from "../../UiComponents/Buttons/Default";
import InputWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import DefaultSelect from "../../UiComponents/Select/Default";

const CreateStudent = ({ handleSubmit, userCreate, reset, history }) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  const userTypeOptions = [
    { value: 1, label: t("createUser.teacher") },
    { value: 2, label: t("createUser.clerk") },
    { value: 3, label: t("createUser.chiefClerk") },
  ];

  const userCreateAction = async (formValues) => {
    setIsLoading(true);
    await userCreate(
      { ...formValues, userType: formValues.userType.value, isActive: 1 },
      reset
    );
    setIsLoading(false);
    history.push("/users");
  };

  return (
    <Container>
      {isLoading && (
        <div
          style={{
            height: "78%",
            width: "74%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FadeLoader size={100} color={"#123abc"} loading={true} />
        </div>
      )}
      <Title dir="auto">{t("createUser.title")}</Title>
      <Body onSubmit={handleSubmit(userCreateAction)}>
        <Section>
          <Field
            name="lastName"
            component={InputWrappedWithField}
            placeholder={t("createUser.lastName")}
            type="text"
          />
          <Field
            name="firstName"
            component={InputWrappedWithField}
            placeholder={t("createUser.firstName")}
            type="text"
          />
          <Field
            name="phoneNumber"
            component={InputWrappedWithField}
            placeholder={t("createUser.phoneNumber")}
            type="tel"
          />
          <Field
            name="userId"
            component={InputWrappedWithField}
            placeholder={t("createUser.id")}
            type="number"
          />
          <Field
            name="password"
            component={InputWrappedWithField}
            placeholder="סיסמה"
            type="password"
          />
          <Field
            name="email"
            component={InputWrappedWithField}
            placeholder={t("createUser.email")}
            type="email"
          />
        </Section>
        <SectionTitle dir="auto">{t("createUser.permissions")}</SectionTitle>
        <Section>
          <Field
            name="copyPermissions"
            component={DefaultSelect}
            placeholder={t("createUser.copyPermissions")}
            options={userTypeOptions}
          />
          <Field
            name="userType"
            component={DefaultSelect}
            placeholder={t("createUser.userType")}
            options={userTypeOptions}
          />
        </Section>
        <CreateButton type="submit" text={t("buttons.create")} />
      </Body>
    </Container>
  );
};

export default withRouter(
  connect(null, { userCreate })(
    reduxForm({
      form: "createStudent",
      validate,
    })(CreateStudent)
  )
);
