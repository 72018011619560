import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import i18n from "../../../lib/i18n/i18n";
import {
  Container,
  ToggleContainer,
  DataContainer,
  DataName,
  DataValue,
  Row,
  Text,
} from "./studentBalances.styles";
import { connect } from "react-redux";
import { getAllBalances } from "../../../actions/studentesActions";
import { getStudentTextBooks } from "../../../actions/textBooksActions";
import Table from "../../SivanPlusTable/SivanPlusTable";
import Toggle from "react-toggle";
import { SelectColumnFilterAll } from "../../SivanPlusTable/filters";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SivanModal from "../../Modal/NewModal";
import { minHeight, width } from "@mui/system";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const getToggleValueFromLocalStorage = (type) => {
  const val = window.localStorage.getItem(`studentReport-${type}`);
  if (val && JSON.parse(val) !== null) {
    return JSON.parse(val);
  } else {
    return false;
  }
};

const StudentBalances = ({ getAllBalances, history, getStudentTextBooks }) => {
  const [data, setData] = useState([]);
  const [isShowTextbookDetail, setIsShowTextbookDetail] = useState(false);
  const [textbooksList, setTextbooksList] = useState([]);
  const [loadingTextbookDetail, setLoadingTextbookDetail] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [total, setTotal] = useState({
    balance: 0,
    length: 0
  });
  let isShowTextbookDetail2 = false
  const studentColumns = [
    {
      Header: dictionary.table.name,
      accessor: "firstName",
    },
    {
      Header: dictionary.table.lastName,
      accessor: "lastName",
    },
    {
      Header: dictionary.table.id,
      accessor: "studentId",
      Cell: ({ row: { original } }) => {
        return original.isChecked === 1 ? (
          original.studentId
        ) : (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {original.studentId}
          </span>
        );
      },
    },
    {
      Header: "שיעורים",
      accessor: "totalLessons",
    },
    {
      Header: "מ.פ",
      accessor: "totalInternalTests",
    },
    {
      Header: "טסטים",
      accessor: "totalTests",
    },
    {
      Header: "מאזן",
      accessor: "totalBalance",
      Cell: ({ row: { original } }) => {
        return original.totalBalance < 0 ? (
          <div>
            <span style={{ color: "red", fontWeight: "bold" }}>
              {original.totalBalance}
            </span>
            {original.count_tb > 1 ? <HelpOutlineIcon titleAccess="הצג פרטי יתרה" style={{marginLeft: "5px", color: '#007cff'}} onClick={(event) => {showTextbooksList(event, original)}}/> : ''}
          </div>
        ) : (
          original.totalBalance
        );
      },
    },
    {
      Header: "מורים",
      accessor: "teacherName",
    },
    {
      Header: "רכב",
      accessor: "vehicle",
    },
    {
      Header: dictionary.table.active,
      accessor: "active",
      Cell: ({ row: { original } }) => {
        return original.active === 1
          ? dictionary.users.active
          : dictionary.users.inactive;
      },
      Filter: SelectColumnFilterAll,
      filter: 'equals',
    },
  ];

  const showTextbooksList = async (event, rowData) => {
    console.log('showTextbooksList ', rowData);
    setIsShowTextbookDetail(true);
    isShowTextbookDetail2 = true;
    setLoadingTextbookDetail(true);
    const textBooks = await getStudentTextBooks(rowData.studentId)
    setTextbooksList(textBooks);
    setLoadingTextbookDetail(false)
  }

  const [showNullBalance, setShowNullBalance] = useState(
    getToggleValueFromLocalStorage("showNullBalance")
  );
  const [showPositiveOnly, setShowPositiveOnly] = useState(
    getToggleValueFromLocalStorage("showPositiveOnly")
  );
  const [showNegativeOnly, setShowNegativeOnly] = useState(
    getToggleValueFromLocalStorage("showNegativeOnly")
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const balances = await getAllBalances();
      setData(balances);
      if (showNegativeOnly) {
        setFilteredData(balances.filter((x) => x.totalBalance < 0));
      } else if (showPositiveOnly) {
        setFilteredData(balances.filter((x) => x.totalBalance > 0));
      } else if (showNullBalance) {
        setFilteredData(balances.filter((x) => x.totalBalance !== 0));
      } else {
        setFilteredData(balances);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (showNegativeOnly) {
      setFilteredData(data.filter((x) => x.totalBalance < 0));
    } else if (showPositiveOnly) {
      setFilteredData(data.filter((x) => x.totalBalance > 0));
    } else if (showNullBalance) {
      setFilteredData(data.filter((x) => x.totalBalance !== 0));
    } else {
      setFilteredData(data);
    }
    setIsLoading(false);
  }, [showNegativeOnly, showPositiveOnly, showNullBalance]);

  const changeToggle = (type, value, cb) => {
    window.localStorage.setItem(`studentReport-${type}`, value);
    cb(value);
  };
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("table-to-xls"),
  });
  const onFiltered = (data) => {
    let length = 0;
    let balance = data.reduce((a, b) => {
      length++;
      return a + b.original.totalBalance;
    }, 0)
    .toFixed(2)
    .toLocaleString("he")
    console.log('onFiltered length', length);
    console.log('onFiltered balance', balance);
    setTotal({ balance, length })
    // setFilteredData(data.map((v) => v.original));
  };
  return (
    <Container>
      <ToggleContainer>
        <Toggle
          id="cheese-status"
          defaultChecked={showNullBalance}
          onChange={() =>
            changeToggle(
              "showNullBalance",
              !showNullBalance,
              setShowNullBalance
            )
          }
        />
        <label htmlFor="cheese-status">האם להסתיר יתרה 0</label>&nbsp;
        <Toggle
          id="cheese-status"
          defaultChecked={showPositiveOnly}
          onChange={() =>
            changeToggle(
              "showPositiveOnly",
              !showPositiveOnly,
              setShowPositiveOnly
            )
          }
        />
        <label htmlFor="cheese-status">יתרה חיובית</label>&nbsp;
        <Toggle
          id="cheese-status"
          defaultChecked={showNegativeOnly}
          onChange={() =>
            changeToggle(
              "showNegativeOnly",
              !showNegativeOnly,
              setShowNegativeOnly
            )
          }
        />
        <label htmlFor="cheese-status">יתרה שלילית</label>
      </ToggleContainer>
      <DataContainer>
        <DataName>סה״כ תלמידים</DataName>
        <DataValue>{total.length}</DataValue>
        <DataName>סה״כ כסף</DataName>
        <DataValue>
          {total.balance}
        </DataValue>
      </DataContainer>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="דוח חייבים"
        sheet="חייבים"
        buttonText="יצא אקסל"
      />
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <span>הדפס &nbsp;</span>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <Table
        data={filteredData}
        setFilteredData={(data) => {onFiltered(data)}}
        componentColumns={studentColumns}
        onRowClick={(rowObject) => setTimeout(() => {
          if (!isShowTextbookDetail2) {
            history.push(`/updateStudent`, {
              studentData: rowObject.original,
              view: dictionary.editStudent.ticket,
            })
          }
        }, 200)
        }
        isLoading={isLoading}
      />
    <SivanModal
      titleText="פרטי יתרה"
      modalIsOpen={isShowTextbookDetail}
      closeModal={() => {
        setIsShowTextbookDetail(false)
        isShowTextbookDetail2=false
      }}
      isLoading={loadingTextbookDetail}
    >
      <div style={{minWidth: "340px"}}>
        {textbooksList.map((t) => {
          return (<><Row style={{minHeight: '50px'}} key={t.id}>
              <Text>{t.description}</Text>
              <Text style={t.balance < 0 ? { color: "red", fontWeight: "bold" }: {}}>{t.balance}</Text>
            </Row>
            <hr style={{margin: "0", borderTop: "2px solid #cb9d3c"}} />
          </>)
        })}
      </div>
    </SivanModal>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    drivingPermits: state.DrivingPermitsReducer.drivingPermits,
    user: state.session.user,
    students: state.StudentsReducer.students,
    isLoading: state.StudentsReducer.isLoaded,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isLoaded: state.LicenseTypesReducer.isLoaded,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    getAllBalances,
    getStudentTextBooks,
  })(StudentBalances)
);
