export default (values) => {
  const errors = {};
  if (!values.km || parseFloat(values.km) <= 0) {
    errors.km = "Required";
  }
  if (!values.vehicleId) {
    errors.vehicleId = "Required";
  }
  if (!values.date) {
    errors.date = "Required";
  }

  return errors;
};
