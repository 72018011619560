import licenseTypesApi from "../apis/licenseTypes";
import {
  LICENSE_TYPES_FETCH_SUCCESS,
  LICENSE_TYPES_FETCH_STARTED,
  LICENSE_TYPES_FETCH_FAILED,
} from "../constants/actionTypes";
import { toast } from "react-toastify";
import { reset } from "redux-form";

export const getLicenseTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: LICENSE_TYPES_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await licenseTypesApi.get("/allLicenseTypes", {
      headers,
    });

    dispatch({
      type: LICENSE_TYPES_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: LICENSE_TYPES_FETCH_FAILED,
    });
  }
};
