import React from "react";
import TextField from "@material-ui/core/TextField";

const DefaultInput = ({
  input,
  placeholder,
  disabled,
  meta: { touched, error },
}) => {
  return (
    <TextField
      id="outlined-basic"
      margin="dense"
      label={placeholder}
      fullWidth
      disabled={disabled}
      variant="outlined"
      {...input}
      multiline
      rows={4}
      error={touched && error}
    />
  );
};

export default DefaultInput;
