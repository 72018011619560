import providersApi from "../apis/providers";
import {
  PROVIDERS_FETCH_FAILED,
  PROVIDERS_FETCH_SUCCESS,
  PROVIDERS_FETCH_STARTED,
  PROVIDER_CREATE_SUCCESS,
  PROVIDER_DELETE_SUCCESS,
  PROVIDER_UPDATE_SUCCESS,
} from "../constants/actionTypes";
import { toast } from "react-toastify";

export const create = (provider) => async (dispatch, getState) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { branchId, schoolId } = getState().session.user;

    const response = await providersApi.post(
      "/create",
      {
        provider: { ...provider, schoolId },
      },
      {
        headers,
      }
    );
    dispatch({
      type: PROVIDER_CREATE_SUCCESS,
      payload: { ...provider, id: response.data.id, isActive: 1 },
    });
  } catch (error) {
    toast.error("כישלון ביצירת ספק");
  }
};

export const getSchoolProviders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROVIDERS_FETCH_STARTED,
    });
    const token = window.localStorage.getItem("token");
    const { branchId, schoolId } = getState().session.user;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await providersApi.get(`/getAll/${schoolId}`, {
      headers,
    });

    dispatch({
      type: PROVIDERS_FETCH_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: PROVIDERS_FETCH_FAILED,
    });
  }
};

export const update = (provider) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await providersApi.patch(
      "/update",
      {
        provider,
      },
      {
        headers,
      }
    );

    dispatch({
      type: PROVIDER_UPDATE_SUCCESS,
      payload: { ...provider },
    });
  } catch (error) {
    toast.error("כישלון בעדכון ספק");
  }
};

export const deleteItem = (providerId) => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await providersApi.delete(
      "/delete",
      {
        data: { providerId },
      },
      {
        headers,
      }
    );

    dispatch({
      type: PROVIDER_DELETE_SUCCESS,
      payload: { id: providerId },
    });
  } catch (error) {
    toast.error("כישלון במחיקת ספק");
  }
};
