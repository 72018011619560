import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { findActiveAgreement } from "../../../../utils/calculator";
import { getAgreementDetails } from "../../../../actions/agreementsActions";
import { connect } from "react-redux";
import { eventDurationInMinutes } from "../../../../utils/calculator";
import _ from 'lodash'
const types = { 1: "שיעור", 2: "טסט", 3: "מבחן פנימי" };
const NewIncome = ({
  student,
  events,
  total,
  discountAndObligation,
  checked,
  setChecked,
  checkedDiscountAndObligation,
  setCheckedDiscountAndObligation,
  clearMany,
  chosenOpeningBalance,
  setChosenOpeningBalance,
  showAll,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [addAgreementBalance, setAddAgreementBalance] = useState(true);
  const allEvents = events.map((x) => x.id);

  const checkDiscountAndObligation = (discountOrObligation) => {
    const _key = `${student.id}#${discountOrObligation.id}`;
    if (checkedDiscountAndObligation.includes(_key)) {
      setCheckedDiscountAndObligation(checkedDiscountAndObligation.filter((e) => e !== _key));
    } else {
      setCheckedDiscountAndObligation([...checkedDiscountAndObligation, _key]);
    }
  }
  const addOpeningBalance = (isOpening) => {    
    if (!isOpening) {
      setAddAgreementBalance(false);
      setChosenOpeningBalance(
        chosenOpeningBalance.filter((x) => x !== student.studentId)
      );
    } else {
      setAddAgreementBalance(true);
      setChosenOpeningBalance(_.uniq([
        ...chosenOpeningBalance,
        student.studentId,
      ]));
    }
  }
  const getIsAll = () => {
    return checked.filter((v) => allEvents.includes(v)).length === allEvents.length;
  }
  if (!showAll && events.length === 0) return '';
  return (
    <div
      style={{
        direction: "rtl",
        textAlign: "right",
        marginTop: "1.5vh",
        flex: "0 30%",
      }}
    >
      <span
        onClick={() => setCollapsed(!collapsed)}
        style={{ cursor: "pointer" }}
      >
        {`${student.firstName} ${student.lastName}`} -
        {total} ({student.balance})
        ₪
        <i className={`fa fa-angle-${collapsed ? "up" : "down"}`} />
      </span>
      <Collapse isOpened={collapsed}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            color="primary"
            checked={getIsAll()}
            onChange={(e) => {
              console.log(e)
              if (!e.target.checked) {
                setChecked(checked.filter((v) => !allEvents.includes(v)));
                addOpeningBalance(false)
              } else {
                setChecked(_.uniq([...checked, ...allEvents]));
                addOpeningBalance(true)
              }
            }}
          />
          הכל
        </div>
          {student.activeAgreementOpeningBalance > 0 && (
        <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox
          color="primary"
          checked={addAgreementBalance}
          onChange={() => {
            addOpeningBalance(!addAgreementBalance)
          }}
        />
        הסכם - {student.activeAgreementOpeningBalance}
      </div>
          )}
        {discountAndObligation && discountAndObligation.map((x) => (
          <div
            key={x.id+"-discountAndObligation"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={checkedDiscountAndObligation.includes(`${student.id}#${x.id}`)}
              onChange={() => {checkDiscountAndObligation(x)}}
            />
            <div style={{color: x.isDiscount == 1 ? "green" : "red"}}>
              <span>{`${moment(x.date).format("DD/MM/YYYY hh:mm")}, `}</span>
              <span>{`${x.amount}₪, `}</span>
              <span>{x.notes} ({x.isDiscount == 1 ? 'הנחה' : 'חיוב'})</span>
            </div>
          </div>
        ))}
        {events.map((x) => (
          <div
            key={x.id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={checked.includes(x.id)}
              onChange={() => {
                if (checked.includes(x.id)) {
                  setChecked(checked.filter((e) => e !== x.id));
                } else {
                  setChecked([...checked, x.id]);
                }
              }}
            />
            <div>
              <span>{`${moment(x.start).format("DD/MM/YYYY hh:mm")}, `}</span>
              <span>{`${x.price}₪, `}</span>
              <span>{`${eventDurationInMinutes(x.start, x.end)} דק, `}</span>
              <span>{`${types[x.eventType]}  על `}</span>
              <span>{x.vehicleNumber}</span>
            </div>
          </div>
        ))}
      </Collapse>
    </div>
  );
};

export default connect(null, {
  getAgreementDetails,
})(NewIncome);
