import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import i18n from "../../../lib/i18n/i18n";
import SivanModal from "../../Modal/NewModal";
import { Body, Button } from "./vehicleChange.styles";
import { updateVehicle } from "../../../actions/schedulerActions";
import { getVehicles } from "../../../actions/vehicleActions";
import useVehicles from "../../useComponents/useVehicles";
import useTeacheres from "../../useComponents/useTeachers";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const TeacherChange = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  selectedEvent,
  updateVehicle,
  isVehiclesLoaded,
  getVehicles,
  vehicles,
}) => {
  const [vehicle, setVehicle] = useState(null);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  useEffect(() => {
    if (vehicleOptions.length > 0) {
      setVehicle(
        vehicleOptions.find(
          (x) => x.value.toString() === selectedEvent.vehicleId
        )
      );
    }
  }, [vehicleOptions]);

  const makeAction = async () => {
    await updateVehicle(
      selectedEvent.id,
      vehicle.value.toString(),
      vehicle.label.toString()
    );
    setNewAgreementModalIsOpen(false);
  };

  return (
    <SivanModal
      titleText="שינוי רכב"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={false}
    >
      <Body>
        <Autocomplete
          id="combo-box-demo"
          options={vehicleOptions}
          getOptionLabel={(option) => option.label}
          value={vehicle}
          onChange={(event, value) => setVehicle(value)}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="רכב"
              variant="outlined"
            />
          )}
        />
        <Button onClick={() => makeAction()}>שמור</Button>
      </Body>
    </SivanModal>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    vehicles: state.VehiclesReducer.vehicles,
  };
}

export default connect(mapStateToProps, {
  updateVehicle,
  getVehicles,
})(TeacherChange);
