import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  customStyles,
  Row,
  FieldText,
  Field,
  ButtonContainer,
} from "./teacherWeekly.styles";
import { connect } from "react-redux";
import { getAllTeacherEvents } from "../../../actions/schedulerActions";
import Select from "react-select";
import useTeacheres from "../../useComponents/useTeachers";
import i18n from "../../../lib/i18n/i18n";
import { getUsers } from "../../../actions/userActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import TeacherWeeklyTable from "./TeacherWeeklyTable";
import Button from "../../UiComponents/Buttons/Default";

const TeacherWeekly = ({
  getAllTeacherEvents,
  isUsersLoaded,
  getUsers,
  users,
  teacherWeeklyOnlyHimself,
  user,
}) => {
  const [selectedTeacher, setSelectedTeacher] = useState({});
  const [teacherEventsForWeek, setTeacherEventsForWeek] = useState([]);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);
  const componentRef = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date().setDate(new Date().getDate() + 5)
  );

  useEffect(() => {
    if (teachersOptions.length > 0) {
      if (user.userType === 1) {
        setSelectedTeacher(teachersOptions.find((x) => x.value === user.id));
      } else {
        setSelectedTeacher(teachersOptions[0]);
      }
    }
  }, [teachersOptions]);

  useEffect(() => {
    (async () => {
      if (teachersOptions.length > 0) {
        const result = await getAllTeacherEvents(selectedTeacher.value);
        const firstday = moment(startDate);
        const lastday = moment(endDate);
        const data = [];
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          if (
            moment(element.start).isBetween(firstday, lastday, "days", true)
          ) {
            data.push(element);
          }
        }
        setTeacherEventsForWeek(data);
      }
    })();
  }, [selectedTeacher, startDate, endDate]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const nextWeek = () => {
    setStartDate(
      new Date(startDate).setDate(new Date(startDate).getDate() + 7)
    );
    setEndDate(new Date(endDate).setDate(new Date(endDate).getDate() + 7));
  };

  return (
    <Container>
      <div style={{ cursor: "pointer" }} onClick={handlePrint}>
        <FieldText>הדפס &nbsp;</FieldText>
        <i className="fa fa-print" aria-hidden="true" />
      </div>
      <Row>
        {teacherWeeklyOnlyHimself ? (
          <Field>
            <FieldText>מורה</FieldText>
            <Select
              options={teachersOptions}
              value={selectedTeacher}
              placeholder="מורה"
              styles={customStyles}
              isRtl={true}
              onChange={(selectedOption) => setSelectedTeacher(selectedOption)}
            />
          </Field>
        ) : null}
        <ButtonContainer>
          <div />
          <Button onClick={nextWeek} text="הבא" />
        </ButtonContainer>
        <>
          <Field>
            <FieldText>תאריך סוף הדוח</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              endDate={endDate}
              minDate={startDate}
            />
          </Field>
          <Field>
            <FieldText>תאריך התחלת הדוח</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              dateFormat="dd/MM/yyyy"
              locale={i18n.language}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <TeacherWeeklyTable
        ref={componentRef}
        teacherEventsForWeek={teacherEventsForWeek}
        teacherName={selectedTeacher.label}
        startDate={startDate}
        endDate={endDate}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    user: state.session.user,
    events: state.CalendarReducer.events,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    teacherWeeklyOnlyHimself: state.session.user.teacherWeeklyOnlyHimself,
  };
}

export default connect(mapStateToProps, {
  getAllTeacherEvents,
  getUsers,
})(TeacherWeekly);
