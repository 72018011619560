export default (object) => {
  let objectForDb = {};
  for (var key in object) {
    // skip loop if the property is from prototype
    if (!object.hasOwnProperty(key)) continue;
    let obj = object[key];

    if (
      obj &&
      typeof obj === "object" &&
      obj.hasOwnProperty("value") &&
      obj.hasOwnProperty("label")
    ) {
      objectForDb[key] = obj.value;
    } else {
      objectForDb[key] = obj;
    }
  }
  return objectForDb;
};
