import React, { useState } from "react";
import { connect } from "react-redux";
import ErrorModal from "../../Modal/ErrorModal";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import SivanModal from "../../Modal/NewModal";
import {
  fetchTextBook,
  decreaseCoutner,
} from "../../../actions/textBooksActions";
import { Body, Button, customStyles } from "./eventStatusChange.styles";
import { updateEventStatus } from "../../../actions/schedulerActions";
import { getStudent } from "../../../actions/studentesActions";
import { countStudentCompletedAssignments } from "../../../actions/textBooksActions";
import { getLicenseTypes } from "../../../actions/licenseTypes";
import { getVehicles } from "../../../actions/vehicleActions";
import useVehicles from "../../useComponents/useVehicles";
import { getAgreements } from "../../../actions/agreementsActions";
import useAgreements from "../../useComponents/useAgreements";
import Assignment from "../../Students/StudentUpdate/TextBooks/EditTextBook/Assignment/Assignment";

const cancelReasonOptions = [
  { value: "המשרד ביטל", label: "המשרד ביטל" },
  { value: "המורה ביטל", label: "המורה ביטל" },
  { value: "התלמיד ביטל", label: "התלמיד ביטל" },
  { value: "טעות הקלדה", label: "טעות הקלדה" },
  { value: "התלמיד לא הגיע", label: "התלמיד לא הגיע" },
  { value: "בוטל עקב מזג אוויר", label: "בוטל עקב מזג אוויר" },
  { value: "אישור רפואי", label: "אישור רפואי" },
  { value: "ביטול זכיין", label: "ביטול זכיין" },
];

const testStatuses = [
  { value: 0, label: "לא ידוע" },
  { value: 1, label: "עבר" },
  { value: 2, label: "נכשל" },
];

const EventStatusChange = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  selectedEvent,
  updateEventStatus,
  user,
  getStudent,
  fetchTextBook,
  vehicles,
  getVehicles,
  isVehiclesLoaded,
  getAgreements,
  getLicenseTypes,
  decreaseCoutner,
}) => {
  const [errorModalText, setErrorModalText] = useState(
    "אף מטלת לימוד לא סומנה"
  );
  const [textBookData, setTextBookData] = useState({});
  const [studentLicense, setStudentLicense] = useState(false);
  // const [setStudentData] = useState({});
  const [isTextBookEditModalOpen, setTextBookEditModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [testStatus, setTestStatus] = useState({
    value: selectedEvent.testStatus,
    label: testStatuses.find((x) => x.value === selectedEvent.testStatus)
      ?.label,
  });
  const [cancelReason, setCancelReason] = useState(
    selectedEvent.cancelReason
      ? {
          value: selectedEvent.cancelReason,
          label: selectedEvent.cancelReason,
        }
      : null
  );
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);
  const agreementList = useAgreements(getAgreements);

  const makeAction = async (eventStatus) => {
    if (eventStatus === 1 || eventStatus === 2) {
      if (selectedEvent.eventType === 3) {
        await updateEventStatus(
          selectedEvent.id,
          eventStatus,
          "",
          selectedEvent.eventType,
          testStatus === null ? 0 : testStatus.value,
          "internalTestStatus"
        );
        setNewAgreementModalIsOpen(false);
      } else if (selectedEvent.eventType === 2) {
        await updateEventStatus(
          selectedEvent.id,
          eventStatus,
          "",
          selectedEvent.eventType,
          testStatus === null
            ? 2
            : testStatus.value === 0
            ? 2
            : testStatus.value,
          "testStatus"
        );
        setNewAgreementModalIsOpen(false);
      } else {
        await updateEventStatus(selectedEvent.id, eventStatus, "");
        await openTextBook();
      }
    } else {
      await updateEventStatus(
        selectedEvent.id,
        eventStatus,
        cancelReason.value
      );
      await decreaseCoutner(selectedEvent.eventType, selectedEvent.textBookId);

      setNewAgreementModalIsOpen(false);
    }
  };

  function checkIfAllowedToCancel() {
    if (selectedEvent.eventType === 1) {
      return user.cancelLesson === 1;
    } else if (selectedEvent.eventType === 2) {
      return user.cancelTest === 1;
    } else if (selectedEvent.eventType === 3) {
      return user.cancelInternalTest === 1;
    } else if (selectedEvent.eventType === 4) {
      return user.cancelMeetings === 1;
    } else {
      return true;
    }
  }

  const openTextBook = async (studentTextBook) => {
    const textBook = await fetchTextBook(selectedEvent.textBookId);
    // setStudentData(await getStudent(selectedEvent.studentId));
    let licenses = [];
    // if (licenseTypes.length === 0) {
    licenses = await getLicenseTypes();
    // }
    if (selectedEvent.eventType.toString() !== "4") {
      const vehicleLicenseType = vehicles.find(
        (x) => x.id.toString() === selectedEvent.vehicleId.toString()
      ).licenseType;
      const license = licenses.find(
        (x) => x.id === parseInt(vehicleLicenseType)
      );
      // }
      // }
      setStudentLicense(license.vehicleType);
      setTextBookData({
        ...textBook,
        vehicleId: vehicleOptions.find(
          (x) => x.value === parseInt(textBook.vehicleId)
        ),
      });
      setTextBookEditModalOpen(true);
    } else {
    }
  };

  const checkIfStatusCanBeChanged = async (newStatus) => {
    if ((newStatus === 4 || newStatus === 3) && !cancelReason) {
      setErrorModalText("נא למלא סיבת ביטול");
      setIsErrorModalOpen(true);
    } else {
      await makeAction(newStatus);
    }
  };
  return (
    <SivanModal
      titleText="שינוי סטטוס אירוע"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={false}
    >
      {isErrorModalOpen && (
        <ErrorModal
          modalIsOpen={isErrorModalOpen}
          closeModal={() => setIsErrorModalOpen(false)}
          text={errorModalText}
        />
      )}
      {isTextBookEditModalOpen ? (
        <Assignment
          textBookData={textBookData}
          studentId={selectedEvent.studentId}
          studentLicense={studentLicense}
        />
      ) : (
        <Body>
          <Button onClick={() => checkIfStatusCanBeChanged(1)}>בוצע</Button>
          {/* {selectedEvent.eventType.toString() !== "4" && (
            <Button onClick={() => checkIfStatusCanBeChanged(2)}>
              בוצע ותקבול
            </Button>
          )} */}
          {checkIfAllowedToCancel() ? (
            <Button onClick={() => checkIfStatusCanBeChanged(3)}>בטל</Button>
          ) : null}
          {selectedEvent.eventType.toString() !== "4" &&
          checkIfAllowedToCancel() ? (
            <Button onClick={() => checkIfStatusCanBeChanged(4)}>
              בטל וחייב
            </Button>
          ) : null}
          {checkIfAllowedToCancel() ? (
            <Select
              value={cancelReason}
              isRtl={true}
              onChange={(selectedOption) => setCancelReason(selectedOption)}
              options={cancelReasonOptions}
              placeholder="סיבת ביטול"
              styles={customStyles}
            />
          ) : null}
          {selectedEvent.eventType === 2 || selectedEvent.eventType === 3 ? (
            <Select
              value={testStatus}
              isRtl={true}
              onChange={(selectedOption) => setTestStatus(selectedOption)}
              options={testStatuses}
              placeholder="תוצאה"
              styles={customStyles}
            />
          ) : null}
        </Body>
      )}
    </SivanModal>
  );
};

function mapStateToProps(state) {
  return {
    user: state.session.user,
    vehicles: state.VehiclesReducer.vehicles,
    licenseTypes: state.LicenseTypesReducer.licenseTypes,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateEventStatus,
    getStudent,
    countStudentCompletedAssignments,
    getVehicles,
    fetchTextBook,
    getAgreements,
    getLicenseTypes,
    decreaseCoutner,
  })(EventStatusChange)
);
