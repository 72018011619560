import styled from "styled-components";
import { isTablet, isMobileOnly, isMobile } from "react-device-detect";

export const Container = styled.div`
  border-bottom: 1px solid #ffefca;
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  height: ${isMobile ? "5vh" : "10vh"};
  padding: ${isMobile && '5%'};
  width: 100%;
  margin-bottom: ${isMobile && '2%'};
  align-items: center;
`;

export const LogoNew = styled.img`
  height: ${isMobileOnly ? "5vh" : "50px"};
  width: auto;
`;

export const LogoWithUserContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #f5f5f7;
  padding: 0px 10px 0px 0px;
  width: ${isMobile ? "auto" : "20vw"};
`;

export const IconSwitch = styled.i`
  cursor: pointer;
  class: ${(props) => props.className};
  color: ${(props) => props.isFoldMenu ? "#e8d290" :"#000"};
  ${ isMobileOnly ? 'font-size: 25px;' : '' }
  ${ isMobile ? '' : 'margin-right: 20px;' }
`
export const Column = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'flex-end'};
  flex-direction: row;
  width: 100%;

  .MuiFormControl-marginDense {
    margin: 0
  }

  &.row-select {
    padding: ${isMobileOnly ? "2px" : "5px"};
  }

  .button-action {
    padding: 7px;
  }

  .button-navigation {
    width: 120px;
    padding: 7px;
    flex-grow: 1;
  }

  .split-button-navigation {
    font-size: 13pt;
    width: 120px;
    padding: 7px;
    flex-grow: 1;
    background: linear-gradient(to bottom,#ebd794,#d2ab51);
  }

  .split-button-navigation-toggle {
    background: linear-gradient(to bottom,#ebd794,#d2ab51);
  }

  .split-button-navigation {
    border-color: #fff !important;
  }

  .calendar-date-label {
    direction: rtl;
    background: #fff;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;        
    width: ${!isMobile && "170px"};
    height: 38px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    text-align: center;
  }

  input[type="date"] {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;        
    height: 38px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;

    ::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      width: auto;
    }

    :before {
      background: none;
      display: block;
      font-family: 'FontAwesome';
      content: '\f073';
      padding-left: 5px;
      padding-right: 5px;        
      color: #d2ab51;
    }    
  }
  
  .react-toggle-thumb {
    width: 29px;
    height: 18px;
    border-radius: 5px;
    background-color: #ffffff;    
  }

  .react-toggle-track {
    width: 56px;
    height: 18px;
    border-radius: 5px;
    background-color: #e8d290 !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #ffffff;
  }

`;



export const LoggedUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "center" : "space-between"};
  flex-direction: ${isMobile ? "column" : "row"};
  width: ${isMobile ? "55vw" : "75vw"};
  padding-right: 2vw;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ffefca;
  width: 25vw;
  background-color: #fffdf9;
`;

export const Logo = styled.img`
  height: ${isMobile ? "3vh" : "8vh"};
  width: auto;
`;

export const LoggedUserName = styled.span`
  font-size: ${isMobileOnly ? "4vw" : "16pt"};
  font-weight: ${isMobileOnly ? 'bold' : 'normal'};
`;
