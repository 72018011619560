import { CLEAR_SESSION, SET_SESSION } from "../constants/actionTypes";
import usersApi from "../apis/users";
import { toast } from "react-toastify";

export const clearSession = () => ({
  type: CLEAR_SESSION,
});

export const setSession = (session) => ({
  type: SET_SESSION,
  session,
});

export const tryLogin = (token) => async (dispatch) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await usersApi.get("/auth", {
      params: {},
      headers,
    });
    window.localStorage.setItem("token", response.data.token);
    dispatch({
      type: SET_SESSION,
      session: response.data,
    });
  } catch (error) {}
};

export const logOut = () => async (dispatch) => {
  try {
    const token = window.localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // await usersApi.post("/logout", null, {
    //   headers,
    // });
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("calendarUser");
    window.localStorage.removeItem("date");
    window.localStorage.clear();
    // dispatch({
    //   type: USER_LOGOUT_SUCCESS,
    // });
    dispatch({
      type: CLEAR_SESSION,
    });
  } catch (error) {
    // dispatch({
    //   type: USER_LOGOUT_FAILED,
    // });
  }
};

export const userSignIn = ({ id, password }) => async (dispatch) => {
  try {
    // dispatch({
    //   type: USER_SIGN_IN_START,
    // });
    const response = await usersApi.post("/login", {
      id,
      password,
    });

    // if (typeof response.data.verified !== "undefined") {
    //   toast.error("please verify your email");

    //   dispatch({
    //     type: USER_SIGN_IN_FAILED,
    //   });

    //   return;
    // }
    window.localStorage.setItem("token", response.data.token);
    dispatch({
      type: SET_SESSION,
      session: response.data,
    });
    // window.location.reload();

    return true;
  } catch (error) {
    // dispatch({
    //   type: USER_SIGN_IN_FAILED,
    // });
    toast.error("please try again");
    return false;
  }
};
