import { useEffect, useState } from "react";
import { optionsArrrayWithTwoParams } from "../../utils/buildOptionsArray";

const useTesters = (isTestersLoaded, getTesters, testers) => {
  const [testersOptions, setTestersOptions] = useState([]);
  useEffect(() => {
    async function fetchData() {
      if (!isTestersLoaded) await getTesters();
      else {
        setTestersOptions([
          { value: 999, label: "ללא" },
          ...optionsArrrayWithTwoParams(testers, "id", "firstName", "lastName"),
        ]);
      }
    }
    fetchData();
  }, [isTestersLoaded]);
  return testersOptions;
};

export default useTesters;
