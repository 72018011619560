import React, { useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../../lib/i18n/i18n";
import Toggle from "react-toggle";
import { Field, reduxForm } from "redux-form";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
} from "./clientDetails.styles";
import { update, deleteItem } from "../../../../../actions/clientsActions";
import CreateButton from "../../../../UiComponents/Buttons/Default";
import SivanQuestionModal from "../../../../Modal/QuestionModal";
import InputWrappedWithField from "../../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../../UiComponents/TextArea/Default";

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle checked={input.value} onChange={input.onChange} />
    </ToggleContainer>
  );
};

const FundingBodiesDetails = ({
  handleSubmit,
  update,
  clientData,
  deleteItem,
  closeModal,
}) => {
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);

  const fundingBodyCreateAction = async (formValues) => {
    await update(formValues);
    closeModal();
  };

  const fundingBodyDeleteAction = async () => {
    setIsQuestionModalOpen(false);
    await deleteItem(clientData.id);
    closeModal();
  };

  return (
    <div>
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <SivanQuestionModal
          titleText="מחיקה"
          bodySecondRow="גוף המימון ימחק לצמיתות"
          modalIsOpen={isQuestionModalOpen}
          closeModal={() => setIsQuestionModalOpen(false)}
          deleteItem={fundingBodyDeleteAction}
          isDelete
        />
        <Section basicData noBorder>
          <Row>
            <Field
              name="name"
              component={InputWrappedWithField}
              placeholder="שם"
              type="text"
            />
            <Field
              name="clientId"
              component={InputWrappedWithField}
              placeholder="ת.ז."
              type="number"
            />
            <Field
              name="address"
              component={InputWrappedWithField}
              placeholder="כתובת"
              type="text"
            />
            <Field
              name="city"
              component={InputWrappedWithField}
              placeholder="עיר"
              type="text"
            />
            <Field
              name="postalCode"
              component={InputWrappedWithField}
              placeholder="מיקוד"
              type="number"
            />
          </Row>
          <Row>
            <Field
              name="phoneNumber"
              component={InputWrappedWithField}
              placeholder="טלפון"
              type="text"
            />
            <Field
              name="email"
              component={InputWrappedWithField}
              placeholder="מייל"
              type="email"
            />
            <Field name="notes" component={NotesInput} placeholder="הערות" />
            <Field
              name="vatExemption"
              component={renderToggle}
              label="פטור ממע״מ"
            />
            <Field name="isActive" component={renderToggle} label="פעיל" />
          </Row>
        </Section>
        <CreateButton
          text="מחק"
          type="button"
          isDelete
          marginRight={2}
          onClick={() => setIsQuestionModalOpen(true)}
        />
        <CreateButton text="עדכן" type="submit" />
      </Body>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      ...ownProps.clientData,
    },
  };
}

export default connect(mapStateToProps, { update, deleteItem })(
  reduxForm({
    form: "UpdateFundingBodies",
  })(FundingBodiesDetails)
);
