import {
  NEW_EVENT_FAILED,
  NEW_EVENT_SUCCESS,
  NEW_EVENT_STARTED,
  EVENTS_FETCH_FAILED,
  EVENTS_FETCH_SUCCESS,
  EVENTS_FETCH_STARTED,
  EDIT_EVENT_SUCCESS,
  HIDE_EVENT,
  EVENTS_FETCH_HOLIDAYS_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  isPending: false,
  events: [],
  isLoaded: false,
  holidays: [],
  isHolidaysLoaded: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENTS_FETCH_SUCCESS:
      try {
        return {
          ...state,
          events: action.payload
            .map((x) => {
              const start = new Date(x.start);
              const end = new Date(x.end);
              return {
                ...x,
                start,
                end,
                eventDurationInMinutes: Math.round(
                  (((end - start) % 86400000) % 3600000) / 60000
                ),
                studentNameWithoutNickname: x.firstName
                  ? `${x.firstName} ${x.lastName}`
                  : null,
              };
            })
            .sort(function (a, b) {
              if (!a.licenseType || !b.licenseType) return 0;
              if (a.licenseType.trim() < b.licenseType.trim()) {
                return -1;
              }
              if (a.licenseType.trim() > b.licenseType.trim()) {
                return 1;
              }
              return 0;
            }),
          isLoaded: true,
          isPending: false,
        };
      } catch (error) {
        //console.log(error);
      }
    case EVENTS_FETCH_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidays: action.payload,
        isHolidaysLoaded: true,
        isPending: false,
      };
    case NEW_EVENT_SUCCESS:
      const newEventsArray = [...state.events, action.payload];
      return {
        ...state,
        events: newEventsArray.map((x) => {
          const start = new Date(x.start);
          const end = new Date(x.end);
          return {
            ...x,
            start,
            end,
            eventDurationInMinutes: Math.round(
              (((end - start) % 86400000) % 3600000) / 60000
            ),
            studentNameWithoutNickname: x.firstName
              ? `${x.firstName} ${x.lastName}`
              : null,
          };
        }),
        isPending: false,
      };
    case HIDE_EVENT:
      const filteredEvents = state.events.filter(
        (event) => event.id !== action.payload.id
      );
      return {
        ...state,
        events: filteredEvents,
        isPending: false,
      };
    case EDIT_EVENT_SUCCESS:
      const events = state.events.map((event) =>
        event.id === action.payload.id ? { ...event, ...action.payload } : event
      );
      return {
        ...state,
        events,
        isPending: false,
      };
    case NEW_EVENT_STARTED:
    case EVENTS_FETCH_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case NEW_EVENT_FAILED:
    case EVENTS_FETCH_FAILED:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
}
