import React, { useEffect, useState, useMemo } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import i18n from "../../../../lib/i18n/i18n";
import { connect } from "react-redux";
import { Container, Title, TitleText } from "./clientsSearch.styles";
import Table from "../../../SivanPlusTable/SivanPlusTable";
import { deleteClient } from "../../../../actions/adminActions";
import { getClients } from "../../../../actions/adminActions";
import QuestionModal from "../../../Modal/QuestionModal";
import { toast } from "react-toastify";
const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;

const getNameStyle = (studentData) => {
  let styles = {};
  if (studentData.internalTestStatus) {
    styles.borderTop = "1px solid black";
  }
  if (
    studentData.conversion ||
    studentData.refresh ||
    studentData.renewal ||
    studentData.control
  ) {
    styles.borderLeft = "1px solid black";
    styles.borderRight = "1px solid black";
  }
  if (
    studentData.fundingBody !== 999 &&
    studentData.fundingBody !== null &&
    typeof studentData.fundingBody !== "undefined"
  ) {
    styles.borderBottom = "1px solid black";
  }

  return styles;
};

const clientColumns = isMobileOnly
  ? [
      {
        Header: dictionary.table.name,
        accessor: "name",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.name}
          </span>
        ),
      },
      {
        Header: dictionary.table.id,
        accessor: "clientId",
        Cell: ({ row: { original } }) => {
          return original.isActive === 1 ? (
            <span>{original.clientId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.clientId}</span>
          );
        },
      },
    ]
  : isTablet
  ? [
      {
        Header: dictionary.table.name,
        accessor: "name",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.name}
          </span>
        ),
      },
      {
        Header: dictionary.table.id,
        accessor: "clientId",
        Cell: ({ row: { original } }) => {
          return original.isActive === 1 ? (
            <span>{original.clientId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.clientId}</span>
          );
        },
      },
      {
        Header: dictionary.table.email,
        accessor: "email",
      },
    ]
  : [
      {
        Header: dictionary.table.name,
        accessor: "name",
        Cell: ({ row: { original } }) => (
          <span
            style={{
              ...getNameStyle(original),
            }}
          >
            {original.name}
          </span>
        ),
      },
      {
        Header: dictionary.table.id,
        accessor: "clientId",
        Cell: ({ row: { original } }) => {
          return original.isActive === 1 ? (
            <span>{original.clientId}</span>
          ) : (
            <span style={{ color: "red" }}>{original.clientId}</span>
          );
        },
      },
      {
        Header: dictionary.table.email,
        accessor: "email",
      },
    ];

const ClientsSearch = ({
  history,
  schoolId,
  user
}) => {
  const { t, i18n } = useTranslation("common");
  const [clients, setClients] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isred, setIsRed] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoadingDeleteStudent, setIsLoadingDeleteStudent] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [tableColumns, setTableColumns] = useState(clientColumns);

  useEffect(() => {
    fetchData();
  }, [schoolId]);

  const fetchData = async () =>  {
    setIsTableLoading(true)
    let data = [];
    try {
      data = await getClients(schoolId);
    } catch (e) {
      toast.error("כישלון במחיקת תלמיד");
      console.error(e)
    }
    setClients(data);
    setIsTableLoading(false)
  }

  useEffect(() => {
    fetchData();
    if (tableColumns[0].accessor !== "notes") {
      const _tableColumns = [
        {
          Header: '',
          accessor: "notes",
          Cell: ({ row: { original } }) => {
            return (
              <><span
                style={{ flex: "0 20%", fontSize: "18px" }}
                className="icons8-delete"
                onClick={() => {
                  setDeleteUserData(original)
                  setIsDeleteModal(true);
                } } />
              </>
            )
          }
        },
        ...clientColumns
      ];
      setTableColumns(_tableColumns)
    }
  }, []);

  const localDelete = async () => {
    setIsLoadingDeleteStudent(true);
    setIsDeleteModal(false);
    try {
      const iseDeleted = await deleteClient(schoolId, deleteUserData.id)
      // if (iseDeleted == '1') {
        toast.success("התלמיד הוסר");
      //   fetchData()
      // } else if (iseDeleted == '-1') {
      //   toast.warning("Student have active lessons.");
      // } else if (iseDeleted == '-2') {
      //   toast.warning("Student have credit incoms.");
      // }
    } catch(e) {
      toast.error("כישלון במחיקת תלמיד");
      console.error(e)
    }
    setDeleteUserData(null);
    setIsLoadingDeleteStudent(false);
  }

  const closeQuestionModal = async () => {
    setDeleteUserData(null);
    setIsDeleteModal(false);    
    // fetchData();
  }
  return (
    <Container>
      <Title>
        <TitleText>Client list</TitleText>
      </Title>
      <QuestionModal
        titleText="Do you whant delete client ?"
        isDelete={true}
        isLoaded={isLoadingDeleteStudent}
        deleteItem={localDelete}
        modalIsOpen={isDeleteModal}
        closeModal={closeQuestionModal}
      />
      <Table
        data={clients}
        componentColumns={tableColumns}
        // onRowClick={onRowClick}
        isLoading={isTableLoading}
      />
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
    isLoggedIn: state.session.isLoggedIn,
  };
}

export default withRouter(
  connect(mapStateToProps, {})(ClientsSearch)
);
