import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { getBranches } from "../../../actions/branchActions";
import {
  Row,
  FieldText,
  Field,
  customStyles,
  Container,
} from "./IncomesByMonth.styles";
import i18n from "../../../lib/i18n/i18n";
import Button from "../../UiComponents/Buttons/Default";
import { getAll } from "../../../actions/incomesAction";
import { getStudentsBetweenDates } from "../../../actions/incomesAction";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const ActivityReport = ({ getAll, getBranches, getStudentsBetweenDates }) => {
  const [graphData, setGraphData] = useState([]);
  const [currentView, setCurrentView] = useState("bymonths");
  const [startDate, setStartDate] = useState(new Date().setMonth(0));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [rawGraphData, setRawGraphData] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     await getAll(1);
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      const incomes = await getIncomes();
      if (incomes.length > 0) {
        const data = [];
        setSelectedTeacher(null);

        for (
          let index = new Date(startDate).getMonth();
          index <= new Date(endDate).getMonth();
          index++
        ) {
          if (currentView === "byVehicles") {
            const uniqueVehicle = _.uniqBy(incomes, "vehicleNumber");
            const vehicels = uniqueVehicle
              .filter((x) => x.vehicleNumber !== null)
              .map((x) => x.vehicleNumber);
            setSelectOptions(vehicels.map((x) => ({ value: x, label: x })));
            filterByData(incomes, vehicels, index, "vehicleNumber", data);
          } else if (currentView === "byLicense") {
            const uniqueLicenses = _.uniqBy(incomes, "name");
            const licenses = uniqueLicenses
              .filter((x) => x.name !== null)
              .map((x) => x.name);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(incomes, licenses, index, "name", data);
          } else if (currentView === "byTeacher") {
            const uniqueLicenses = _.uniqBy(incomes, "teacherUsername");
            const licenses = uniqueLicenses
              .filter((x) => x.teacherUsername !== null)
              .map((x) => x.teacherUsername);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(incomes, licenses, index, "teacherUsername", data);
          } else if (currentView === "byBranch") {
            const allBranches = await getBranches();
            setSelectOptions(
              allBranches.map((x) => ({ value: x.name, label: x.name }))
            );
            filterByData(incomes, allBranches, index, "branchId", data, true);
          } else if (currentView === "byUser") {
            const uniqueLicenses = _.uniqBy(incomes, "createUsername");
            const licenses = uniqueLicenses
              .filter((x) => x.createUsername !== null)
              .map((x) => x.createUsername);
            setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
            filterByData(incomes, licenses, index, "createUsername", data);
          } else {
            setSelectOptions([]);
            const currentMonthTests = incomes.filter(
              (x) =>
                new Date(x.date).getMonth() === index &&
                new Date(x.date).getFullYear() === new Date().getFullYear()
            );

            const totalPlus = Math.round(
              currentMonthTests
                .filter((x) => x.documentType !== 5 && x.documentType !== 3)
                .reduce((a, b) => a + b.amount * b.quantity, 0)
            );

            const total = Math.round(
              currentMonthTests
                .reduce((total, element) => {
                  let amount = 0;
                  if (element.documentType === 5 || element.documentType === 3) {
                    amount -= element.amount;
                  } else {
                    amount = element.amount;
                  }
                  total += amount;
                  return total;
                }, 0)
            );

            data.push({
              date: index + 1,
              total,
            });
          }
        }
        setRawGraphData(data);
        setGraphData(data);
      }
    })();
  }, [currentView, startDate, endDate]);

  const getIncomes = async () => {
    const incomes = await getStudentsBetweenDates(
      new Date(startDate),
      new Date(endDate)
    );
    const incomesNotFromStudents = await getAll();
    const incomesFoundingBodies = await getAll(0, 1);
    return [
      ...incomes,
      ...incomesNotFromStudents,
      ...incomesFoundingBodies,
    ]
  }

  const filterByData = (incomes, array, index, param, data, isBranch) => {
    for (let j = 0; j < array.length; j++) {
      const element = array[j];
      const elementValue = isBranch ? element.id : element;
      const currentMonthTests = incomes.filter(
        (x) =>
          new Date(x.date).getMonth() === index &&
          new Date(x.date).getFullYear() === new Date().getFullYear() &&
          x[param] === elementValue
      );

      const totalPlus = Math.round(
        currentMonthTests
          .filter((x) => x.documentType === 0 || x.documentType === 1)
          .reduce((a, b) => a + b.amount * b.quantity, 0)
      );

      const totalMinus = Math.round(
        currentMonthTests
          .filter((x) => x.documentType === 3 || x.documentType === 4)
          .reduce((a, b) => a + b.amount * b.quantity, 0)
      );

      data.push({
        date: `${isBranch ? element.name : element} - ${index + 1}`,
        total: totalPlus - totalMinus,
      });
    }
  };

  const handleChange = (selected) => {
    setSelectedTeacher(selected);
    if (selected && selected.length > 0) {
      let filteredData = [];
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];
        filteredData = [
          ...filteredData,
          ...rawGraphData.filter((x) => x.date.includes(element.value)),
        ];
      }
      setGraphData(filteredData);
    } else {
      setGraphData(rawGraphData);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>הכנסות לפי חודשים</h1>
      </div>

      <Row>
        <Button
          text="לפי חודשים"
          onClick={() => {
            setCurrentView("byMonths");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רכבים"
          onClick={() => {
            setCurrentView("byVehicles");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רישון"
          onClick={() => {
            setCurrentView("byLicense");
          }}
          marginRight="1"
        />
        <Button
          text="לפי יוצר"
          onClick={() => {
            setCurrentView("byUser");
          }}
          marginRight="1"
        />
        <Button
          text="לפי מורה"
          onClick={() => {
            setCurrentView("byTeacher");
          }}
          marginRight="1"
        />
        {/* <Button
        text="לפי סניפים"
        onClick={() => {
          setCurrentView("byBranch");
        }}
        marginRight="1"
      /> */}
        <div style={{ width: "33%", marginRight: "1vw" }}>
          <Select
            options={selectOptions}
            value={selectedTeacher}
            isMulti
            placeholder="בחר"
            styles={customStyles}
            onChange={handleChange}
            isRtl
          />
        </div>
        <>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="MMMM"
              endDate={endDate}
              minDate={startDate}
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </Field>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={i18n.language}
              dateFormat="MMMM"
              showMonthYearPicker
              showFullMonthYearPicker
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={graphData}>
          <XAxis dataKey="date" />
          <YAxis dataKey="total" />
          <Tooltip />
          <Bar dataKey="total" fill="#ffc658" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    // incomes: state.IncomesReducer.incomesFromStudents,
    isLoaded: state.IncomesReducer.isLoaded,
  };
}

export default connect(mapStateToProps, {
  getAll,
  getBranches,
  getStudentsBetweenDates,
})(ActivityReport);
