import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import Week from "react-big-calendar/lib/Week";
import TimeGrid from "react-big-calendar/lib/TimeGrid";

function workWeekRange(
  date,
  options,
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday
) {
  let dayToDelete = [];
  if (!sunday) {
    dayToDelete.push(0);
  }
  if (!monday) {
    dayToDelete.push(1);
  }
  if (!tuesday) {
    dayToDelete.push(2);
  }
  if (!wednesday) {
    dayToDelete.push(3);
  }
  if (!thursday) {
    dayToDelete.push(4);
  }
  if (!friday) {
    dayToDelete.push(5);
  }
  if (!saturday) {
    dayToDelete.push(6);
  }

  return Week.range(date, options).filter(
    (d) => dayToDelete.indexOf(d.getDay()) === -1
  );
}

class MyWorkWeek extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let {
      date,
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      ...props
    } = this.props;
    let range = workWeekRange(
      date,
      this.props,
      sunday,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday
    );
    return <TimeGrid {...props} range={range} eventOffset={50}></TimeGrid>;
  }
}

MyWorkWeek.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

MyWorkWeek.defaultProps = TimeGrid.defaultProps;

MyWorkWeek.range = workWeekRange;

MyWorkWeek.navigate = Week.navigate;

MyWorkWeek.title = (date, { localizer }) => {
  let [start, ...rest] = Week.range(date, { localizer });

  return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
};

function mapStateToProps(state) {
  return {
    sunday: state.session.user.sunday,
    monday: state.session.user.monday,
    tuesday: state.session.user.tuesday,
    wednesday: state.session.user.wednesday,
    thursday: state.session.user.thursday,
    friday: state.session.user.friday,
    saturday: state.session.user.saturday,
  };
}

export default connect(mapStateToProps)(MyWorkWeek);
