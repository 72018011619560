import React, { useState, useEffect } from "react";
import SivanModal from "../../../Modal/Modal";
import { FieldArray, reduxForm, Field, formValueSelector } from "redux-form";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import i18n from "../../../../lib/i18n/i18n";
import { connect } from "react-redux";
import validate from "./validate";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {
  Body,
  Button,
  ButtonContainer,
  Title,
  AddMoreButton,
  TitleText,
  Icon,
  SingleVacationContainer,
  VacationsContainer,
  Section,
} from "./teacherActionsModal.styles";
import {
  updateTeacherVacations,
  getTeacherVacations,
  getTeacheresVehicles,
  updateTeacherVehicles,
} from "../../../../actions/teacherActions";
import { getVehicles } from "../../../../actions/vehicleActions";
import {
  userUpdateData,
  setUserDisabled,
  setUserActive,
  deleteUser,
} from "../../../../actions/userActions";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import DefaultSelect from "../../../UiComponents/Select/Default";

const currLanguage = i18n.language;
const dictionary = i18n.getDataByLanguage(currLanguage).common;
const userTypeOptions = [
  { value: 1, label: dictionary.createUser.teacher },
  { value: 2, label: dictionary.createUser.clerk },
  { value: 3, label: dictionary.createUser.chiefClerk },
  { value: 4, label: 'מנהל' },
];

const renderDatePickerField = ({ input, placeholder, meta: { error } }) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <DatePicker
      selected={input.value}
      dir={dir}
      onChange={input.onChange}
      placeholderText={placeholder}
      dateFormat="dd/MM/yyyy"
      className={`${error && "error"} date-input`}
      locale={i18n.language}
    />
  );
};

const renderVacations = ({ fields, meta: { error, submitFailed } }) => (
  <VacationsContainer>
    <Title>
      <TitleText>{dictionary.teacherActionsModal.vacations}</TitleText>
      <AddMoreButton onClick={() => fields.push({ id: 0 })}>
        + {dictionary.teacherActionsModal.addMore}
      </AddMoreButton>
    </Title>
    {submitFailed && error && <span>{error}</span>}
    {fields.map((vacation, index) => (
      <SingleVacationContainer key={index}>
        <Field
          name={`${vacation}.start`}
          component={renderDatePickerField}
          placeholder={dictionary.teacherActionsModal.start}
        />
        <Field
          name={`${vacation}.end`}
          component={renderDatePickerField}
          placeholder={dictionary.teacherActionsModal.end}
        />
        <Field
          name={`${vacation}.notes`}
          component={InputWrappedWithField}
          placeholder={dictionary.teacherActionsModal.notes}
        />
        <Icon className="fa fa-trash" onClick={() => fields.remove(index)} />
      </SingleVacationContainer>
    ))}
  </VacationsContainer>
);

const EditVehicle = ({
  isTeacherActionsModalOpen,
  setTeacherActionsModalIsOpen,
  teacherData,
  handleSubmit,
  updateTeacherVacations,
  getVehicles,
  vehicles,
  setUserActive,
  setUserDisabled,
  updateTeacherVehicles,
  userUpdateData,
  phoneNumber,
  deleteUser,
}) => {
  const { t, i18n } = useTranslation("common");
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const optionsArray = [];
      if (vehicles.length === 0) {
        const allVehicles = await getVehicles();
        allVehicles.map(({ id, vehicleNumber }) =>
          optionsArray.push({ value: id, label: vehicleNumber.toString() })
        );
      } else {
        vehicles.map(({ id, vehicleNumber }) =>
          optionsArray.push({ value: id, label: vehicleNumber.toString() })
        );
      }
      setVehicleOptions(optionsArray);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const updateUser = async (formValues) => {
    setIsLoading(true);
    let updateActions = [];
    updateActions.push(
      await userUpdateData({
        lastName: formValues.lastName,
        firstName: formValues.firstName,
        email: formValues.email,
        id: formValues.id,
        phoneNumber: formValues.phoneNumber,
        phoneNumber: formValues.phoneNumber,
        userType: formValues.userType.value,
        isActive: formValues.isActive,
        userId: formValues.userId,
        password: formValues.password,
      })
    );
    if (formValues.userType.value === 1) {
      updateActions.push(
        await updateTeacherVacations(formValues.vacations, teacherData.id)
      );
      updateActions.push(
        await updateTeacherVehicles(formValues.vehicles, teacherData.id)
      );
    }

    if (await Promise.all(updateActions)) {
      setTeacherActionsModalIsOpen(false);
    }

    setIsLoading(false);
  };

  const setActiveOrInactive = async () => {
    setIsLoading(true);
    teacherData.isActive === 1
      ? await setUserDisabled(teacherData.id)
      : await updateUser({
          ...teacherData,
          userType: { value: teacherData.userType },
          isActive: 1,
          vacations: [],
        });
    setIsLoading(false);
    setTeacherActionsModalIsOpen(false);
  };
  return (
    <SivanModal
      titleText={t("teacherActionsModal.title")}
      modalIsOpen={isTeacherActionsModalOpen}
      closeModal={() => setTeacherActionsModalIsOpen(false)}
      isLoading={isLoading}
    >
      <Body onSubmit={handleSubmit(updateUser)}>
        <Section>
          <Field
            name="lastName"
            component={InputWrappedWithField}
            placeholder={t("createUser.lastName")}
            type="text"
          />
          <Field
            name="firstName"
            component={InputWrappedWithField}
            placeholder={t("createUser.firstName")}
            type="text"
          />
          <Field
            name="email"
            component={InputWrappedWithField}
            placeholder={t("createUser.email")}
            type="email"
          />
          <Field
            name="password"
            component={InputWrappedWithField}
            placeholder="סיסמה"
            type="password"
          />
          <Field
            name="phoneNumber"
            component={InputWrappedWithField}
            placeholder={t("createUser.phoneNumber")}
            type="tel"
          />
          <Field
            name="userId"
            component={InputWrappedWithField}
            placeholder={t("createUser.id")}
            type="number"
          />
          <Field
            name="userType"
            component={DefaultSelect}
            placeholder={t("createUser.userType")}
            options={userTypeOptions}
          />
        </Section>
        {teacherData.userType === 1 && (
          <FieldArray name="vacations" component={renderVacations} />
        )}
        {teacherData.userType === 1 && (
          <Title style={{ width: "97%" }}>
            <TitleText>{t("teacherActionsModal.vehicels")}</TitleText>
          </Title>
        )}
        {teacherData.userType === 1 && (
          <Field
            name="vehicles"
            component={DefaultSelect}
            placeholder={t("teacherActionsModal.vehicels")}
            options={vehicleOptions}
            multiple
          />
        )}
        <ButtonContainer>
          <Button type="submit">{t("buttons.save")}</Button>
          <Button
            type="button"
            onClick={() => window.open(`tel:${phoneNumber}`)}
          >
            {t("buttons.call")}
          </Button>
          <Button type="button" reset onClick={setActiveOrInactive}>
            {teacherData.isActive === 1
              ? t("buttons.inactive")
              : t("buttons.active")}
          </Button>
          <Button
            type="button"
            onClick={async () => {
              await deleteUser(teacherData.id);
              setTeacherActionsModalIsOpen(false);
            }}
          >
            {t("buttons.delete")}
          </Button>
        </ButtonContainer>
      </Body>
    </SivanModal>
  );
};

const selector = formValueSelector("teacherActions"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    phoneNumber: selector(state, "phoneNumber"),
    isActive: selector(state, "isActive"),
    vehicles: state.VehiclesReducer.vehicles,
    initialValues: {
      ...ownProps.teacherData,
      userType: userTypeOptions.find(
        (x) => x.value === ownProps.teacherData.userType
      ),
      vacations: ownProps.teacherVacations.length
        ? ownProps.teacherVacations
        : [{ start: null, end: null }],
    },
  };
}

export default connect(mapStateToProps, {
  updateTeacherVacations,
  getTeacherVacations,
  getVehicles,
  updateTeacherVehicles,
  setUserActive,
  setUserDisabled,
  userUpdateData,
  deleteUser,
})(
  reduxForm({
    form: "teacherActions",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    enableReinitialize: true, // this is needed!!
    validate,
  })(EditVehicle)
);
