import {
  LICENSE_TYPES_FETCH_SUCCESS,
  LICENSE_TYPES_FETCH_FAILED,
  LICENSE_TYPES_FETCH_STARTED,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, licenseTypes: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LICENSE_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        isPending: false,
        licenseTypes: action.payload,
        isLoaded: true,
      };
    case LICENSE_TYPES_FETCH_STARTED:
      return {
        ...state,
        isPending: true,
      };
    case LICENSE_TYPES_FETCH_FAILED:
      return {
        ...state,
        isPending: false,
      };
    default:
      return state;
  }
}
