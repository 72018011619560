import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import _ from "lodash";
import Select from "react-select";
import i18n from "../../../lib/i18n/i18n";
import {
  Row,
  FieldText,
  Field,
  customStyles,
  Container,
} from "./TestByMonth.styles";
import Button from "../../UiComponents/Buttons/Default";
import { getAllSchoolTestsEvents } from "../../../actions/schedulerActions";
import { getBranches } from "../../../actions/branchActions";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const TestStatistics = ({ getAllSchoolTestsEvents, getBranches }) => {
  const [graphData, setGraphData] = useState([]);
  const [currentView, setCurrentView] = useState("bymonths");
  const [startDate, setStartDate] = useState(new Date().setMonth(0));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [selectOptions, setSelectOptions] = useState([]);
  const [rawGraphData, setRawGraphData] = useState([]);

  useEffect(() => {
    (async () => {
      let events = await getAllSchoolTestsEvents(
        new Date(`01-01-${new Date().getFullYear()}`),
        new Date(`12-31-${new Date().getFullYear()}`)
      );

      events = events.filter(
        (x) => x.eventType === 2 && x.eventStatus !== 3 && x.eventStatus !== 4
      );
      const data = [];
      setSelectedTeacher(null);
      for (
        let index = new Date(startDate).getMonth();
        index <= new Date(endDate).getMonth();
        index++
      ) {
        if (currentView === "byVehicles") {
          const uniqueVehicle = _.uniqBy(events, "vehicleNumber");
          const vehicels = uniqueVehicle
            .filter((x) => x.vehicleNumber !== null)
            .map((x) => x.vehicleNumber);
          setSelectOptions(vehicels.map((x) => ({ value: x, label: x })));
          filterByData(vehicels, index, "vehicleNumber", data, events);
        } else if (currentView === "byLicense") {
          const uniqueLicenses = _.uniqBy(events, "name");
          const licenses = uniqueLicenses
            .filter((x) => x.name !== null)
            .map((x) => x.name);
          setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
          filterByData(licenses, index, "name", data, events);
        } else if (currentView === "byBranch") {
          const allBranches = await getBranches();
          setSelectOptions(
            allBranches.map((x) => ({ value: x.name, label: x.name }))
          );
          filterByData(allBranches, index, "branchId", data, events, true);
        } else if (currentView === "byUser") {
          const uniqueLicenses = _.uniqBy(events, "createUsername");
          const licenses = uniqueLicenses
            .filter((x) => x.createUsername !== null)
            .map((x) => x.createUsername);
          setSelectOptions(licenses.map((x) => ({ value: x, label: x })));
          filterByData(licenses, index, "createUsername", data, events);
        } else {
          setSelectOptions([]);
          const currentMonthTests = events.filter(
            (x) =>
              new Date(x.start).getMonth() === index &&
              new Date(x.start).getFullYear() === new Date().getFullYear()
          );

          data.push({
            date: index + 1,
            total: currentMonthTests.length,
          });
        }
      }
      setGraphData(data);
      setRawGraphData(data);
    })();
  }, [startDate, endDate, currentView]);

  const filterByData = (array, index, param, data, events, isBranch) => {
    for (let j = 0; j < array.length; j++) {
      const element = array[j];
      const elementValue = isBranch ? element.id : element;
      const currentMonthTests = events.filter(
        (x) =>
          new Date(x.start).getMonth() === index &&
          new Date(x.start).getFullYear() === new Date().getFullYear() &&
          x[param] === elementValue
      );

      data.push({
        date: `${isBranch ? element.name : element} - ${index + 1}`,
        total: currentMonthTests.length,
      });
    }
  };

  const handleChange = (selected) => {
    setSelectedTeacher(selected);
    if (selected && selected.length > 0) {
      let filteredData = [];
      for (let index = 0; index < selected.length; index++) {
        const element = selected[index];

        filteredData = [
          ...filteredData,
          ...rawGraphData.filter((x) => x.date.includes(element.value)),
        ];
      }
      setGraphData(filteredData);
    } else {
      setGraphData(rawGraphData);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>טסטים לפי חודש</h1>
        <p>טסטים שבוצעו לפי חודש</p>
      </div>

      <Row>
        <Button
          text="לפי חודשים"
          onClick={() => {
            setCurrentView("byMonths");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רכבים"
          onClick={() => {
            setCurrentView("byVehicles");
          }}
          marginRight="1"
        />
        <Button
          text="לפי רישון"
          onClick={() => {
            setCurrentView("byLicense");
          }}
          marginRight="1"
        />
        <Button
          text="לפי יוצר"
          onClick={() => {
            setCurrentView("byUser");
          }}
          marginRight="1"
        />
        <Button
          text="לפי סניפים"
          onClick={() => {
            setCurrentView("byBranch");
          }}
          marginRight="1"
        />
        <div style={{ width: "33%", marginRight: "1vw" }}>
          <Select
            options={selectOptions}
            value={selectedTeacher}
            isMulti
            placeholder="בחר"
            styles={customStyles}
            onChange={handleChange}
            isRtl
          />
        </div>
        <>
          <Field>
            <FieldText>מ</FieldText>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={i18n.language}
              startDate={startDate}
              dateFormat="MMMM"
              endDate={endDate}
              minDate={startDate}
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </Field>
          <Field>
            <FieldText>עד</FieldText>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={i18n.language}
              dateFormat="MMMM"
              showMonthYearPicker
              showFullMonthYearPicker
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </Field>
        </>
      </Row>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={graphData}>
          <XAxis dataKey="date" />
          <YAxis dataKey="total" />
          <Tooltip />
          <Bar dataKey="total" fill="#ffc658" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default connect(null, {
  getAllSchoolTestsEvents,
  getBranches,
})(TestStatistics);
