import {
  EXPENSE_TYPES_FETCH_FAILED,
  EXPENSE_TYPES_FETCH_SUCCESS,
  EXPENSE_TYPES_FETCH_STARTED,
  EXPENSE_TYPE_CREATE_SUCCESS,
  EXPENSE_TYPE_DELETE_SUCCESS,
  EXPENSE_TYPE_UPDATE_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = { isPending: false, expenseTypes: [], isLoaded: false };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EXPENSE_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        expenseTypes: [...state.expenseTypes, action.payload],
      };
    case EXPENSE_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        expenseTypes: state.expenseTypes.filter(
          (expenseType) => expenseType.id !== action.payload.id
        ),
      };
    case EXPENSE_TYPE_UPDATE_SUCCESS:
      const expenseTypes = state.expenseTypes.map((expenseType) =>
        expenseType.id === action.payload.id
          ? { ...expenseType, ...action.payload }
          : expenseType
      );
      return { ...state, expenseTypes };
    case EXPENSE_TYPES_FETCH_SUCCESS:
      return { ...state, expenseTypes: action.payload, isLoaded: true };
    case EXPENSE_TYPES_FETCH_FAILED:
      return { ...state, isPending: false };
    case EXPENSE_TYPES_FETCH_STARTED:
      return { ...state, isPending: true };
    default:
      return state;
  }
}
