import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import General from "./General/General";
import Tests from "./Tests/Tests";
import Expired from "./Expired/Expired";
import CalendarColors from "./CalendarColors/CalendarColors";
import Finance from "./Finance/Finance";
import Calendar from "./Calendar/Calendar";
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { Container } from "./settings.styles";

const menuItems = [
  "כללי",
  // "Tests",
  // "Expired",
  // "Student Colors",
  // "פיננסי",
  "צבעי יומן",
  "יומן",
];

const Settings = () => {
  const [currentOpenedSettings, setCurrentOpenedSettings] = useState("כללי");

  return (
    <Container>
      <Navbar
        currentView={currentOpenedSettings}
        setCurrentOpenedView={setCurrentOpenedSettings}
        menuOptions={menuItems}
      />
      {currentOpenedSettings === "כללי" && <General />}
      {currentOpenedSettings === "tests" && <Tests />}
      {currentOpenedSettings === "expired" && <Expired />}
      {currentOpenedSettings === "studentColors" && <General />}
      {currentOpenedSettings === "פיננסי" && <Finance />}
      {currentOpenedSettings === "צבעי יומן" && <CalendarColors />}
      {currentOpenedSettings === "יומן" && <Calendar />}
    </Container>
  );
};

export default Settings;
