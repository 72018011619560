import styled from "styled-components";
import { isMobile, isMobileOnly } from "react-device-detect";

export const CreateButton = styled.button`
  border-radius: 5px;
  color: white;
  background: ${(props) =>
    props.isDelete ? "red" : props.disabled ? 'gainsboro' : props.isActive ? '#000000' :"linear-gradient(to bottom, #ebd794, #d2ab51)"};
  font-size: 15pt;
  padding: 10px;
  cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
  outline: 0 !important;
  min-width: fit-content;

  ${isMobileOnly && "padding:4px; font-size:2vh;"}
`;
